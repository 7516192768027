import * as React from 'react'
import CommonTable from '../common/CommonTable/CommonTable'
import CommonButton from '../common/CommonButton/CommonButton'
import CommonIconButton from '../common/CommonIconButton/CommonIconButton'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import Grid from '@mui/material/Grid/Grid'
import Stack from '@mui/material/Stack/Stack'
import Divider from '@mui/material/Divider'
import type { IHoliday } from '../../interfaces/IHoliday'
import { type ISkills } from '../../interfaces/ISkills'

interface ISkillsTable {
    rows: ISkills[] | null
    openNewSkillModal: () => void
    selectRow: any
}
const SkillsTable = ({ rows, openNewSkillModal, selectRow }: ISkillsTable) => {
    const getTableHead = () => {
        return (
            <Grid
                container
                spacing={0}
                sx={{
                    backgroundColor: '#494454',
                    height: '40px',
                    paddingLeft: 2,
                    paddingRight: 4,
                }}
                alignItems={'center'}
            >
                <Grid
                    item
                    xs={5}
                    justifyContent={'center'}
                    sx={{ color: 'common.white', textAlign: 'flex-start' }}
                >
                    Product Name
                </Grid>
                <Grid
                    item
                    xs={5}
                    justifyContent={'center'}
                    sx={{ color: 'common.white', textAlign: 'flex-start' }}
                >
                    Skills
                </Grid>

                <Grid item xs={2} alignItems={'end'}>
                    <Stack
                        sx={{ width: '100%' }}
                        direction={'row'}
                        justifyContent={'end'}
                    >
                        <CommonButton
                            sx={{
                                width: 104,
                                height: 28,
                                // backgroundColor: 'background.default',
                                borderColor: '#FFF',
                                color: '#fff',
                                fontSize: '14px',
                                p: 0,
                                // color: 'text.',s
                                '&:hover': {
                                    backgroundColor: 'background.default',
                                    color: 'text.primary',
                                },
                            }}
                            color={'primary'}
                            variant="outlined"
                            onClick={openNewSkillModal}
                        >
                            Add New
                        </CommonButton>
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    const getTableBody = () => {
        return rows?.map((row, index) => (
            <Grid container key={index}>
                <Grid
                    container
                    spacing={0}
                    sx={{
                        borderRadius: '10px',
                        height: '50px',
                        marginLeft: 1,
                        marginRight: '36px',
                        padding: 2,
                        marginTop: 0.5,
                        marginBottom: 0.5,
                        '&:hover': { backgroundColor: '#E5E5E5' },
                    }}
                    onClick={() => {
                        selectRow(index)
                        openNewSkillModal()
                    }}
                    alignItems={'center'}
                >
                    <Grid item xs={5}>
                        {row.name}
                    </Grid>
                    <Grid item xs={6}>
                        {row.skills}
                    </Grid>
                </Grid>
                <Grid
                    container
                    key={`divider${index}`}
                    sx={{
                        marginLeft: 1,
                        marginRight: '36px',
                    }}
                >
                    <Grid item xs={12}>
                        {index !== rows.length - 1 && (
                            <Divider variant="middle" />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        ))
    }

    const getTableEmptyBody = () => {
        return (
            <Grid
                container
                sx={{
                    borderRadius: '10px',
                    height: '50px',
                    marginLeft: 1,
                    marginRight: '36px',
                    padding: 2,
                    marginTop: 0.5,
                    marginBottom: 0.5,
                }}
                alignItems={'center'}
            >
                <Grid item xs={6}>
                    -------
                </Grid>
                <Grid item xs={2}>
                    -------
                </Grid>
                <Grid item xs={2}>
                    -------
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        )
    }

    return (
        <CommonTable>
            {getTableHead()}
            {rows !== null && getTableBody()}
            {rows === null && getTableEmptyBody()}
        </CommonTable>
    )
}

export default SkillsTable
