import React, { useState } from 'react';
import './ProductSelector.css';



const ProductSelector = ({ onProductSelect,products,selectedProducts,setSelectedProducts }:any) => {
  console.log('products', products)
  
 

  // Function to handle product selection
  const handleProductSelect = (event:any) => {
    const productName:number = +event.target.value;
    console.log('selectedProducts', selectedProducts)
    let arr = []
    if (selectedProducts.includes(productName)) {
      arr= selectedProducts.filter((product:any) => product !== productName)
      setSelectedProducts(arr);
    } else {
      arr =[...selectedProducts, productName]
      setSelectedProducts(arr);
    }
    
      onProductSelect(arr); // Pass the updated selectedProducts array to the parent component
    
  };


  
  return (
    <div className='product-container'>
      <h4>Product Selector</h4>
      <div className='product-select'>
      {products.map((product:any) => (
        <div key={product.name}>
          <label>
            <input
              type="checkbox"
              value={product.id}
              checked={selectedProducts.includes(+product.id)}
              onChange={handleProductSelect}
            />
            {product.product.name}
          </label>
        
        </div>
      ))}
      </div>
      
    </div>
  );
};

export default ProductSelector;
