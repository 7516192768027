import type { RootState } from '../../app/store'
import { createSelector } from '@reduxjs/toolkit'

// binding select customer Reducer with it's state in store.
export const selectCutomersTableListReducer = (state: RootState) =>
    state.customersTableList
// Creating select customer Reducer so we will be able to select customer state from store.
export const customersTableListSelector = createSelector(
    selectCutomersTableListReducer,
    (state) => state
)
