import React from 'react'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PortraitRoundedIcon from '@mui/icons-material/PortraitRounded'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { ReactComponent as Logo } from '../../../assets/images/SmalLogo.svg'
import { ReactComponent as People } from '../../../assets/images/people.svg'
import { ReactComponent as Tickets } from '../../../assets/images/ticketsIcon.svg'
import { ReactComponent as HomeIcon } from '../../../assets/images/homeIcon.svg'
import { ReactComponent as CalenderIcon } from '../../../assets/images/calendarIcon.svg'
import { ReactComponent as ContractDetails } from '../../../assets/images/contractDetailsIcon.svg'
import { ReactComponent as Home } from '../../../assets/images/home.svg'

// navbar items file
export const mainNavbarItems = [
    {
        id: 0,
        icon: <HomeRoundedIcon />,
        label: 'Home',
        route: '/',
        type: 'listItem',
    },
    {
        id: 1,
        icon: <PeopleOutlineOutlinedIcon />,
        label: 'Customers',
        route: '/customers',
        type: 'listItem',
    },
    {
        id: 2,
        icon: <SettingsOutlinedIcon />,
        label: 'Customer Support',
        route: '/',
        type: 'collapseController',
        controller: true,
    },
    {
        id: 5,
        icon: <AutoAwesomeOutlinedIcon />,
        label: 'Products',
        route: '/skills',
        type: 'collapsedListItem',
    },
    {
        id: 4,
        icon: <GroupsOutlinedIcon />,
        label: 'Teams',
        route: '/teams',
        type: 'collapsedListItem',
    },
    {
        id: 3,
        icon: <PortraitRoundedIcon />,
        label: 'Engineers',
        route: '/engineers',
        type: 'collapsedListItem',
    },
    // {
    //     id: 6,
    //     icon: <AssignmentOutlinedIcon />,
    //     label: 'Reports',
    //     route: '/',
    //     type: 'listItem',
    // },
]

export const EngineerSmallNavBarItems = [
    {
        id: 10,
        icon: <Tickets />,
        label: '',
        route: '/requests',
    },
    {
        id: 13,
        icon: <CalenderIcon />,
        label: '',
        route: '/calender',
    },
]
export const EngineerLeaderSmallNavBarItems = [
    {
        id: 10,
        icon: <Tickets />,
        label: '',
        route: '/requests',
    },
    {
        id: 13,
        icon: <People />,
        label: '',
        route: '/availability',
    },
    {
        id: 14,
        icon: <CalenderIcon />,
        label: '',
        route: '/calender',
    },
]

export const UserSmallNavBarItems = [
    {
        id: 10,
        icon: <Tickets />,
        label: '',
        route: '/requests',
    },
    {
        id: 12,
        icon: <ContractDetails />,
        label: '',
        route: '/contract',
    },
    {
        id: 13,
        icon: <Home />,
        label: '',
        route: '/dashboard',
    },
]
