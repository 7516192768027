import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import cspService, {
    apiClient,
    apiClientForm,
} from '../../services/cspServices'

export interface LoginResponse {
    access: string
    refresh: string
}

// async action for fetching a customer data
// AsynkThunk can only accept one paramter thats why we send user data as an object
export const setUser = createAction('user/setUser', (token: string) => {
    return {
        payload: token,
    }
})

export const login = createAsyncThunk('user/login', async (user: any) => {
    const response = await cspService.login(user)
    // apiClient.defaults.withCredentials = true
    const data = (response.data as LoginResponse).access
    const refresh = (response.data as LoginResponse).refresh
    localStorage.setItem('refreshToken', refresh)
    apiClient.defaults.headers.Authorization = `Bearer ${data}`
    apiClientForm.defaults.headers.Authorization = `Bearer ${data}`
    // console.log('response.data', response.data)
    return response.data
})
