import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import Grid from '@mui/material/Grid/Grid'
import { customerSelector } from '../../../features/customers'
import { useAppSelector } from '../../../app/hooks'
import ContractCard from '../../../components/Cards/ContractCard/ContractCard'
import handleSupporPeriodStr from '../../../helper/handleSupporPeriodStr'
import handleSupporPeriodPercentage from '../../../helper/handleSupporPeriodPercentage'

interface IListingCustomerContracts {
    createNew: () => void
    seeDetails: (contractIdToBeEdited: number | undefined) => void
}

const ListingCustomerContracts = ({
    createNew,
    seeDetails,
}: IListingCustomerContracts) => {
    const { customer } = useAppSelector(customerSelector)

    return (
        <Stack direction={'column'}>
            <CommonTypography variant={'subtitle1'} sx={{ fontWeight: 600 }}>
                All Contracts
            </CommonTypography>
            <Stack direction={'row'} justifyContent={'end'}>
                <CommonButton
                    variant={'outlined'}
                    color={'secondary'}
                    sx={{ width: '140px', height: '38px' }}
                    onClick={createNew}
                >
                    New Contract
                </CommonButton>
            </Stack>
            <Grid container sx={{ marginTop: 3 }} rowGap={4} spacing={2}>
                {customer.contracts.map((contract) => (
                    <Grid item xs={12} md={6} lg={4} key={contract.id}>
                        <ContractCard
                            name={contract.name}
                            supporPeriod={handleSupporPeriodStr(
                                contract.support_starting_date,
                                contract.support_ending_date
                            )}
                            progressValue={handleSupporPeriodPercentage(
                                contract.support_starting_date,
                                contract.support_ending_date
                            )}
                            contractDescription={contract.description}
                            navigateToDetails={() => {
                                seeDetails(contract.id)
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default ListingCustomerContracts
