import { useState } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import Box from '@mui/material/Box/Box'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import Stack from '@mui/material/Stack/Stack'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import CommonCheckbox from '../../common/CommonCheckbox/CommonCheckbox'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import { useWindowSize } from 'usehooks-ts'

interface ISlaOptionsModal {
    open: boolean
    handleClose: () => void
    irrespectiveOfWorkingHours: boolean
    irrespectiveOfTheWeekends: boolean
    irrespectiveOfTheHolidays: boolean
    handleMainAction: (
        irrOfWorkingHours: boolean,
        irrOfTheWeekends: boolean,
        irrOfTheHolidays: boolean
    ) => void
    isPending: boolean
    Default?: string
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
    width: 670,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const SlaOptionsModal = ({
    handleClose,
    handleMainAction,
    irrespectiveOfTheHolidays,
    irrespectiveOfTheWeekends,
    irrespectiveOfWorkingHours,
    Default,
    isPending,
    open,
}: ISlaOptionsModal) => {
    const { width, height } = useWindowSize()

    const [selectedRadio, setSelectedRadio] = useState<any>(Default)
    const [irrOfWorkingHours, setIrrOfWorkingHours] = useState(
        irrespectiveOfWorkingHours
    )
    const [irrOfTheWeekends, setIrrOfTheWeekends] = useState(
        irrespectiveOfTheWeekends
    )
    const [irrOfTheHolidays, setIrrOfTheHolidays] = useState(
        irrespectiveOfTheHolidays
    )
    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <CommonTypography
                    sx={{ fontWeight: 600 }}
                    variant={'h5'}
                    gutterBottom
                >
                    SLA Options
                </CommonTypography>
                <Stack direction={'column'} spacing={1}>
                    <RadioGroup
                        aria-label="slaOptions"
                        value={selectedRadio}
                        sx={{ marginTop: '15px' }}
                        onChange={(e) => {
                            setSelectedRadio(e.target.value)
                            if (e.target.value == 'default_Working_hours') {
                                setIrrOfWorkingHours(true)
                                setIrrOfTheWeekends(false)
                                setIrrOfTheHolidays(false)
                            } else if (
                                e.target.value ==
                                'irrespective_of_Working_hours'
                            ) {
                                setIrrOfWorkingHours(false)
                                setIrrOfTheWeekends(false)
                                setIrrOfTheHolidays(true)
                            } else if (
                                e.target.value == 'irrespective_of_the_weekends'
                            ) {
                                setIrrOfWorkingHours(false)
                                setIrrOfTheWeekends(true)
                                setIrrOfTheHolidays(false)
                            }
                        }}
                    >
                        <Stack
                            direction={'row'}
                            columnGap={1}
                            alignItems={'center'}
                        >
                            <Radio
                                sx={{ marginRight: 0 }}
                                value="default_Working_hours"
                            />

                            <CommonTypography variant={'body2'}>
                                Working hours / On days
                            </CommonTypography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            columnGap={1}
                            alignItems={'center'}
                        >
                            <Radio
                                sx={{ marginRight: 0 }}
                                value="irrespective_of_Working_hours"
                            />
                            <CommonTypography variant={'body2'}>
                                24 H / On days
                            </CommonTypography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            columnGap={1}
                            alignItems={'center'}
                        >
                            <Radio
                                sx={{ marginRight: 0 }}
                                value="irrespective_of_the_weekends"
                            />
                            <CommonTypography variant={'body2'}>
                                24 H / 7
                            </CommonTypography>
                        </Stack>
                    </RadioGroup>
                </Stack>
                <ActionNdCancel
                    sx={{ marginTop: '48px' }}
                    actionBtnLabel="Confirm"
                    actionBtncolor="primary"
                    alignItems="center"
                    cancelBtnLabel="Cancel"
                    direction={'row'}
                    justifyContent="end"
                    spacing={4}
                    btnWidth={'146px'}
                    btnHeight={'40px'}
                    handleCancelAction={handleClose}
                    handleAction={() => {
                        handleMainAction(
                            irrOfWorkingHours,
                            irrOfTheWeekends,
                            irrOfTheHolidays
                        )
                    }}
                    pending={isPending}
                />
            </Box>
        </BasicModal>
    )
}

export default SlaOptionsModal
