import { Stack } from '@mui/material'
import BasicImageContainer from '../../BasicImageContainer/BasicImageContainer'

interface ICommonEmptyState {
    src: any
    alt: string
    width?: number | string
    height?: number | string
    sx: any
    children?: any
}

const CommonEmptyState = ({
    src,
    alt,
    width,
    height,
    sx,
    children,
}: ICommonEmptyState) => {
    return (
        <Stack direction={'column'} alignItems={'center'}>
            <BasicImageContainer
                src={src}
                alt={alt}
                sx={sx}
                height={height}
                width={width}
            />
            {children}
        </Stack>
    )
}

export default CommonEmptyState
