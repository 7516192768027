import { createReducer } from "@reduxjs/toolkit";
import {getEngineers} from "./actions";




// games Campaings initial state
const initCustomerReducer: any = {
    engineers: [],
    pending: true,
    error: false,
};

// departmentReducer reducer to handel action when dispatched
export const EngineersReducer = createReducer(initCustomerReducer, builder => {
    builder
        // game campaign Reducer action thunk and its stats.
        .addCase(getEngineers.pending, state => {
            state.pending = true;
            state.error = false;
        })
        .addCase(getEngineers.fulfilled, (state, { payload }) => {
            state.pending = false;
            state.engineers = payload;
            
        })
        .addCase(getEngineers.rejected, state => {
            state.pending = false;
            state.error = true;
        })
});

export default EngineersReducer;
 
