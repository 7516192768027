import { createAsyncThunk } from '@reduxjs/toolkit'
import cspService from '../../services/cspServices'

// async action for fetching a customer data
// AsynkThunk can only accept one paramter thats why we send user data as an object
export const getCustomersTableList = createAsyncThunk(
    'customersList/getCustomersTableList',
    async ({searchText,page}:any) => {
        const response = await cspService.getCustomersTableList(searchText,page)

        return response.data
    }
)
