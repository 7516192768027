import { Stack, Divider, Box } from '@mui/material'
import CommonPaper from '../../common/CommonPaper/CommonPaper'
import RectFrameLogo from '../../RectFrameLogo/RectFrameLogo'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import ContractPeriodProgress from '../../ContractPeriodProgress/ContractPeriodProgress'
import handleSupporPeriodPercentage from '../../../helper/handleSupporPeriodPercentage'
import handleSupporPeriodStr from '../../../helper/handleSupporPeriodStr'
import ProductNameWithSlaStatus from '../../ProductNameWithSlaStatus/ProductNameWithSlaStatus'
import type { ICustomerInList } from '../../../interfaces/ICustomerInList'
import { NavLink } from 'react-router-dom'

const CustomerCard = ({
    contracts,
    industry,
    name,
    photo,
    id,
    products,
}: ICustomerInList) => {
    return (
        <CommonPaper
            sx={{
                height: '372px',
                maxHeight: '372px',
                width: '100%',
                border: '1px solid #D7DBE1',
                paddingRight: 1,
                paddingLeft: 1,
                paddingTop: 2,
                paddingBottom: 2,
                borderRadius: '12px',
                overflow: 'scroll',
            }}
        >
            <Stack direction={'column'}>
                <Stack direction={'row'} alignItems={'center'}>
                    <RectFrameLogo
                        src={photo}
                        alt={`${name}'s logo`}
                        width={68}
                        height={68}
                    />
                    <Stack direction={'column'} sx={{ marginLeft: 1 }}>
                        <NavLink
                            style={{ color: 'white', textDecoration: 'none' }}
                            to={`/customers/${id}`}
                        >
                            <CommonTypography
                                gutterBottom
                                sx={{ fontWeight: 600, color: 'common.black' }}
                                variant={'h6'}
                            >
                                {name}
                            </CommonTypography>
                        </NavLink>
                        <CommonTypography
                            sx={{ fontWeight: 600, color: 'text.disabled' }}
                            variant={'body2'}
                        >
                            {industry}
                        </CommonTypography>
                    </Stack>
                </Stack>
                <Box sx={{ marginTop: 2.5, overflow: 'auto' }}>
                    {contracts.map((contract) => (
                        <ContractPeriodProgress
                            key={`${contract.name}${contract.id}`}
                            contractName={contract.name}
                            progressValue={handleSupporPeriodPercentage(
                                contract.support_starting_date,
                                contract.support_ending_date
                            )}
                            supporPeriod={handleSupporPeriodStr(
                                contract.support_starting_date,
                                contract.support_ending_date
                            )}
                        />
                    ))}
                </Box>
                <Divider sx={{ marginTop: 0.5 }} />
                <CommonTypography
                    variant={'caption'}
                    sx={{
                        color: 'success.main',
                        fontWeight: 500,
                        marginTop: 1.5,
                        marginLeft: '18px',
                        marginBottom: '16px',
                    }}
                >
                    Products
                </CommonTypography>
                {products.length === 0 && (
                    <CommonTypography
                        variant={'h5'}
                        sx={{
                            color: 'common.black',
                            marginTop: 8,
                            fontWeight: 500,
                        }}
                        align={'center'}
                    >
                        No products yet
                    </CommonTypography>
                )}
                {products.length !== 0 &&
                    products.map((product) => {
                        return (
                            <ProductNameWithSlaStatus
                                key={product.id}
                                productName={product.product.name}
                                followSla={product.follow_sla}
                                containerSx={{
                                    marginBottom: '32px',
                                    marginLeft: '18px',
                                    marginRight: '12px',
                                }}
                            />
                        )
                    })}
            </Stack>
        </CommonPaper>
    )
}

export default CustomerCard
