import {
    Avatar,
    Button,
    Stack,
    type SxProps,
    Typography,
    Box,
    IconButton,
    Switch,
    type SwitchProps,
    styled,
    FormControlLabel,
} from '@mui/material'
import React, { useEffect, useRef, useMemo } from 'react'
import CommonLetterAvatar from '../common/CommonLetterAvatar/CommonLetterAvatar'
import CommonTypography from '../common/CommonTypography/CommonTypography'
import { SvgIcon } from '@mui/material'

import { ReactComponent as UserSecurity } from '../../assets/images/security-user.svg'
import { ReactComponent as Call } from '../../assets/images/call.svg'
import { ReactComponent as People } from '../../assets/images/people.svg'
import { ReactComponent as Logout } from '../../assets/images/logout.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectUserReducer } from '../../features/user'
import { GetShortName } from '../../helper/shortName'
import MySwitch from '../common/CommonIOSSwitch/Switch'
import { useForm, Controller } from 'react-hook-form'
import TeamLeadSwitch from '../common/CommonIOSSwitch/TeamLeadSwitch'
import dashBoardServices, {
    refreshAccessToken,
} from '../../services/cspServices'
import { type IAlert } from '../../interfaces/IAlert'
import { addAlert } from '../../features/systemAlerts'

interface props {
    sx?: SxProps
    show?: any
    setChangePassword: () => void
}
const UserPassword = ({ sx, setChangePassword, show }: props) => {
    const dispatch = useAppDispatch()
    const { user } = useAppSelector(selectUserReducer)
    const renderRef = useRef(0)
    const {
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        clearErrors,

        formState: { errors },
    } = useForm({ reValidateMode: 'onSubmit' })

    useEffect(() => {
        console.log('user', user)
        if (user) {
            const res = window.localStorage.getItem('isOnline')
            console.log('res', res)
            console.log('res', typeof res)
            if (res && res == '1') {
                setValue('is_online', true)
            } else if (res && res == '0') {
                setValue('is_online', false)
            } else {
                setValue('is_online', user.is_online)
            }

            renderRef.current++
        }
        const subscription = watch((value, { name, type }) => {
            console.log('value', value)
            if (renderRef.current > 0 && user.user_type == 'ENGINEER')
                dashBoardServices
                    .updateEngineerOnline(
                        {
                            is_online: value.is_online,
                        },
                        user.user_id
                    )
                    .then((res) => {
                        window.localStorage.setItem(
                            'isOnline',
                            value.is_online ? '1' : '0'
                        )
                    })
                    .catch((e) => {
                        const alertObj: IAlert = {
                            id: 'error',
                            message: 'error update online state ',
                            state: true,
                            type: 'error',
                        }
                        dispatch(addAlert(alertObj))
                    })
        })
        return () => {
            console.log('unmount user')
            subscription.unsubscribe()
            renderRef.current = 0
        }
    }, [watch])
    const memoizedComponent = useMemo(() => {
        return (
            <Stack direction={'column'} spacing={0} sx={sx}>
                {show && (
                    <Stack
                        direction={'row'}
                        justifyContent={'flex-end'}
                        sx={{ marginRight: '15px' }}
                    >
                        {user.user_type == 'ENGINEER' && (
                            <Controller
                                name="is_online"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        sx={{ padding: '0px', margin: '0px' }}
                                        control={
                                            <TeamLeadSwitch
                                                ActiveLabel="Online"
                                                inActiveLabel="Offline"
                                                sx={{
                                                    width: '80px',
                                                    height: '30px',
                                                }}
                                                field={field}
                                                active={field.value}
                                                transition={25}
                                            />
                                        }
                                        label=""
                                    />
                                )}
                            />
                        )}
                    </Stack>
                )}
                <Stack
                    direction={'row'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        // minWidth: '60px',
                        // width: '60px',
                        marginBottom: '27px',
                    }}
                    justifyContent={'center'}
                    alignContent={'center'}
                >
                    <Avatar
                        sx={{ backgroundColor: '#D5D3EC', marginX: '14px' }}
                    >
                        {GetShortName(user.full_name)}
                    </Avatar>
                    <Stack direction={'column'} justifyContent={'flex-start'}>
                        <Typography
                            sx={{
                                color: 'var(--text-color, #000)',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                                letterSpacing: '-0.18px',
                            }}
                        >
                            {user.full_name}
                        </Typography>
                        <Typography
                            sx={{
                                color: '#929EAE',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                                letterSpacing: '-0.16px',
                            }}
                        >
                            {user.email}
                        </Typography>
                        <Button
                            onClick={setChangePassword}
                            sx={{
                                border: 'none',
                                padding: 0,
                                textAlign: 'start',
                                justifyContent: 'flex-start',
                                marginTop: '12px',
                                color: '#47A5A6',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                                letterSpacing: '-0.16px',
                                textDecorationLine: 'underline',
                                textTransform: 'none',
                            }}
                        >
                            change password
                        </Button>
                    </Stack>
                </Stack>

                {!user.is_superuser && (
                    <Box
                        sx={{
                            borderRadius: '5px',
                            background: '#FBFBFB',
                            marginLeft: '20px',
                            marginRight: '10px',
                            paddingY: '5px',
                        }}
                    >
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            sx={{ marginLeft: '16px', marginBottom: '17px' }}
                        >
                            <UserSecurity />
                            <Typography
                                sx={{
                                    marginLeft: '8px',
                                    color: '#929EAE',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: 'normal',
                                    letterSpacing: '-0.14px',
                                    textAlign: 'center',
                                }}
                            >
                                {user.jop_title}
                            </Typography>
                        </Stack>
                        {user.phone && user.phone != '' && (
                            <Stack
                                direction={'row'}
                                justifyContent={'flex-start'}
                                alignItems={'center'}
                                sx={{
                                    marginLeft: '16px',
                                    marginBottom: '17px',
                                }}
                            >
                                <Call />
                                <Typography
                                    sx={{
                                        marginLeft: '8px',
                                        color: '#929EAE',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: 'normal',
                                        letterSpacing: '-0.14px',
                                        textAlign: 'center',
                                    }}
                                >
                                    {user.phone}
                                </Typography>
                            </Stack>
                        )}
                        {/* <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    sx={{ marginLeft: '16px', marginBottom: '17px' }}
                >
                    <People />
                    <Typography
                        sx={{
                            marginLeft: '8px',
                            color: '#929EAE',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal',
                            letterSpacing: '-0.14px',
                            textAlign: 'center',
                        }}
                    >
                        asdasdsadasd
                    </Typography>
                </Stack> */}
                    </Box>
                )}

                <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    sx={{ marginLeft: '14px' }}
                >
                    <IconButton
                        onClick={() => {
                            window.localStorage.clear()
                            window.sessionStorage.clear()
                            window.sessionStorage.clear()
                            window.location.reload()
                        }}
                        sx={{
                            '&:hover': {
                                backgroundColor: '#FFF',
                            },
                        }}
                    >
                        <Logout />
                        <Typography
                            sx={{
                                marginLeft: '8px',
                                color: '#33303B',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: 'normal',
                                letterSpacing: '-0.14px',
                                textAlign: 'center',
                            }}
                        >
                            Logout
                        </Typography>
                    </IconButton>
                </Stack>
            </Stack>
        )
    }, [sx, setChangePassword, show])

    return memoizedComponent
}

export default UserPassword
