import {
    Box,
    Stack,
    type SxProps,
    Typography,
    Button,
    CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CreateEngineer from '../../assets/images/emptyEngneer.svg';
import CommonEmptyState from '../../components/common/CommonEmptyState/CommonEmptyState';
import CommonButton from '../../components/common/CommonButton/CommonButton';
import EngineerCreateModal from '../../components/Modals/CreateUpdateEngineerModal/CreateUpdateEngineerModal';
import CommonTypography from '../../components/common/CommonTypography/CommonTypography';
import dashBoardServices from '../../services/cspServices';
import { ReactComponent as NoRequests } from '../../assets/images/request.svg';
import ListingEngineers from '../../contents/CustomersContent/ListingEngineers/ListingEngineers';
import DeleteEngineerModal from '../../components/Modals/DeleteEngineerModal/DeleteEngineerModal';
import { useNavigate } from 'react-router-dom';
import NewRequestModal from '../../components/Modals/NewRequestModal/NewRequestModal';
import RequestsTable from './RequestsTable';
import RequestModalFilter from '../../components/Modals/RequestModalFilter/RequestModalFilter';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { userSelector } from '../../features/user';
import { type IAlert } from '../../interfaces/IAlert';
import { addAlert } from '../../features/systemAlerts';
import { customerAdminSelector, getCustomer } from '../../features/adminCustomer';
import { emptyResForLead } from './constants';

interface IStyle {
    text: SxProps;
    button: SxProps;
}

const RequestsPage = () => {
    const [newReqModalOpen, setNewReqModalOpen] = useState(false);
    const { user } = useAppSelector(userSelector);
    const { customer } = useAppSelector(customerAdminSelector);
    const [page, setPage] = useState(1);
    const [filterOpen, setFilterOpen] = useState(false);
    const [searchField, setSearchField] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [filters, setFilters] = useState({ yellow_flag: false, red_flag: false });
    const [createdByFilter, setCreatedByFilter] = useState<string>('');
    const dispatch = useAppDispatch();
    const [filtersSelected, setFiltersSelected] = useState(false);
    const [mountRest, setMountRest] = useState(false);
    const [error, setError] = useState<any>(undefined);
    const [searchStr, setSearchStr] = useState<string | undefined>(undefined);
    const [searchFilter, setSearchFilter] = useState<string | undefined>(
        undefined
    );
    const [isPending, setIsPending] = useState<any>();
    const [photoData, setPhotoData] = useState<any>(null);
    const [PhotoIsPending, setPhotoIsPending] = useState<any>(true);
    const [PhotoError, setPhotoError] = useState<any>(null);

    console.log('photoData', photoData);

    useEffect(() => {
        const fetchPhoto = async () => {
            try {
                const response = await dashBoardServices.getCustomerPhoto(
                    user.customer_id
                );
                setPhotoData(response.data);
                setPhotoIsPending(false);
            } catch (error) {
                setPhotoError(error);
                setPhotoIsPending(false);
            }
        };
        if (user.customer_id) fetchPhoto();
    }, [user.customer_id]);

    const handleCloseNewReq = () => {
        setNewReqModalOpen(false);
    };

    const handleopenNewReq = () => {
        setNewReqModalOpen(true);
    };

    const handleCloseFilter = () => {
        setFilterOpen(false);
    };

    const handleopenFilter = () => {
        setFilterOpen(true);
    };

    const callAPI = () => {
        setIsPending(true);
        if (user.user_type == 'ENGINEER') {
            if (user.is_leader) {
                dashBoardServices
                    .getrequestsTeamLead(
                        `?page=${page}&page_size=9${searchStr ?? ''}${searchFilter ?? ''}`
                    )
                    .then(({ data }: any) => {
                        setMountRest(true);
                        console.log('data', data);
                        if (data.count > 0) {
                            setData(filterData(data));
                        } else {
                            console.log('searchStr', searchStr);
                            console.log('searchFilter', searchFilter);
                            if (searchFilter ?? searchStr) {
                                setData(emptyResForLead);
                                const alertObj: IAlert = {
                                    id: `requests`,
                                    message: 'Not found',
                                    state: true,
                                    type: 'error',
                                };
                                dispatch(addAlert(alertObj));
                            }
                        }
                    })
                    .catch((e) => {
                        setError(e);
                        const alertObj: IAlert = {
                            id: `requests`,
                            message: 'there was an error getting requests',
                            state: true,
                            type: 'error',
                        };
                        dispatch(addAlert(alertObj));
                    })
                    .finally(() => {
                        setIsPending(false);
                    });
            } else {
                dashBoardServices
                    .getrequestsEngineer(
                        `?page=${page}&page_size=9${searchStr ?? ''}${searchFilter ?? ''}`
                    )
                    .then(({ data }: any) => {
                        setMountRest(true);
                        if (data.count > 0) {
                            setData(filterData(data));
                        } else {
                            setData(emptyResForLead);
                            console.log('searchStr', searchStr);
                            console.log('searchFilter', searchFilter);
                            if (searchFilter ?? searchStr) {
                                setData(emptyResForLead);
                                const alertObj: IAlert = {
                                    id: `requests`,
                                    message: 'Not found',
                                    state: true,
                                    type: 'error',
                                };
                                dispatch(addAlert(alertObj));
                            }
                        }
                    })
                    .catch((e) => {
                        setError(e);
                        const alertObj: IAlert = {
                            id: `requests`,
                            message: 'there was an error getting requests',
                            state: true,
                            type: 'error',
                        };
                        dispatch(addAlert(alertObj));
                    })
                    .finally(() => {
                        setIsPending(false);
                    });
            }
        } else {
            user.is_admin&&dispatch(getCustomer(user.customer_id));
            dashBoardServices
                .getrequestsCustomer(
                    `?page=${page}&page_size=9${searchStr ?? ''}${searchFilter ?? ''}`
                )
                .then(({ data }: any) => {
                    setMountRest(true);
                    console.log('data', data);
                    if (data.count > 0) {
                        setData(filterData(data));
                    } else {
                        setData(emptyResForLead);
                        console.log('searchStr', searchStr);
                        console.log('searchFilter', searchFilter);
                        if (searchFilter ?? searchStr) {
                            setData(emptyResForLead);
                            const alertObj: IAlert = {
                                id: `requests`,
                                message: 'Not found',
                                state: true,
                                type: 'error',
                            };
                            dispatch(addAlert(alertObj));
                        }
                    }
                })
                .catch((e) => {
                    setError(e);
                })
                .finally(() => {
                    setIsPending(false);
                });
        }
    };

    const filterData = (data: any) => {
        let results = data.results;
        if (filters.yellow_flag) {
            results = results.filter((request: any) => request.yellow_flag);
        }
        if (filters.red_flag) {
            results = results.filter((request: any) => request.red_flag);
        }
        if (createdByFilter === 'true') {
            results = results.filter((request: any) => request.created_by.id !== user.user_id);
        } else if (createdByFilter === 'false') {
            results = results.filter((request: any) => request.created_by.id === user.user_id);
        }
        if(results.length === 0) {
            setData(emptyResForLead);
            if (searchFilter ?? searchStr) {
                setData(emptyResForLead);
                const alertObj: IAlert = {
                    id: `requests`,
                    message: 'Not found',
                    state: true,
                    type: 'error',
                };
                dispatch(addAlert(alertObj));
            }
        }
        return { ...data, results };
    };

    useEffect(() => {
        callAPI();
    }, [page, searchStr, searchFilter, filters]);

    const handleFetch = (searchStr: string) => {
        console.log('searchStr', searchStr);
        setPage(1);
        setSearchFilter(searchStr);
        handleCloseFilter();
    };

    const handleSearch = () => {
        console.log('searchStr', searchField);
        setSearchStr(`&search=${searchField}`);
        setPage(1);
        handleCloseFilter();
    };

    return (
        <>
            <Stack direction={'row'} spacing={2} sx={{ paddingLeft: '32px' }}>
                {user.user_type == 'CUSTOMER' && (
                    <Stack
                        direction={'row'}
                        justifyContent={'flex-start'}
                        sx={{ maxHeight: '80px', maxWidth: '80px' }}
                    >
                        <img
                            src={
                                photoData?.photo ??
                                require('../../assets/images/logo.png')
                            }
                            alt="logo"
                            style={{ width: '100%', borderRadius: '12px' }}
                        />
                    </Stack>
                )}

                <CommonTypography variant={'h6'} sx={{ color: '#33303B' }}>
                    Your Requests
                </CommonTypography>
            </Stack>
            {isPending && (
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ minHeight: '80vh' }}
                >
                    <CircularProgress color="inherit" size={50} />
                </Stack>
            )}

            {!isPending && !error && data?.results?.length > 0 && (
                <RequestsTable
                    handleopenFilter={handleopenFilter}
                    handleopenNewReq={handleopenNewReq}
                    requestsList={data}
                    setSearchField={setSearchField}
                    searchText={searchField}
                    setSearchText={handleSearch}
                    page={page}
                    setPage={setPage}
                />
            )}
            {!isPending && data?.results?.length == 0 && (
                <Stack direction={'column'}>
                    <Stack
                        direction={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ minHeight: '93vh' }}
                    >
                        <NoRequests />
                        <Typography sx={styles.text}>
                            No requests until now
                        </Typography>
                        <Button
                            disableRipple
                            sx={{
                                ...styles.button,
                                visibility:
                                    user.user_type == 'CUSTOMER'
                                        ? 'visible'
                                        : 'hidden',
                            }}
                            onClick={handleopenNewReq}
                        >
                            New Request
                        </Button>
                    </Stack>
                </Stack>
            )}
            {mountRest && (
                <>
                    <NewRequestModal
                        handleClose={handleCloseNewReq}
                        open={newReqModalOpen}
                    />

                    <RequestModalFilter
                        filters={filters}
                        setFilters={setFilters}
                        createdByFilter={createdByFilter}
                        setCreatedByFilter={setCreatedByFilter}
                        handleClose={handleCloseFilter}
                        open={filterOpen}
                        handleFetch={handleFetch}
                        filtersSelected={filtersSelected}
                        setFiltersSelected={setFiltersSelected}
                    />
                </>
            )}
        </>
    );
};

export default RequestsPage;

const styles: IStyle = {
    text: {
        color: 'var(--gray-2, #353945)',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '33.6px',
    },
    button: {
        borderRadius: '4px',
        marginTop: '24px',
        backgroundColor: '#494454',
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
        textTransform: 'none',
        minWidth: '195px',

        '&:hover': {
            borderRadius: '4px',
            backgroundColor: '#494454',
            color: '#FFF',
            fontFamily: 'Inter',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
        },
    },
};
