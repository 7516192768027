import { EngineersReducer } from './../features/engineers/reducer';
import { configureStore } from '@reduxjs/toolkit'
import type { Action, ThunkAction } from '@reduxjs/toolkit'
import { customerReducer } from '../features/customers'
import { customerSitesReducer } from '../features/customerSites'
import { systemAlertsReducer } from '../features/systemAlerts'
import { customersListReducer } from '../features/customersList'
import { customersTableListReducer } from '../features/customersTableList'
import { userReducer } from '../features/user';
import { customerAdminReducer } from '../features/adminCustomer';

export const store = configureStore({
    reducer: {
        customersList: customersListReducer,
        customersTableList: customersTableListReducer,
        customer: customerReducer,
        customerAdmin: customerAdminReducer,
        customerSites: customerSitesReducer,
        systermAlerts: systemAlertsReducer,
        userReducer:userReducer,
        EngineersReducer:EngineersReducer,
    },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

//   the above code is just Redux toolkit configurations.
