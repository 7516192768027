import React, { useEffect, useState } from 'react'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'
import './StatusAllocation.css'
import { useMediaQuery } from '@mui/material'

const chartData = [
    { status: 'open', value: 0, color: '#82ca9d', key: 'open' },
    { status: 'Assigned', value: 0, color: '#54966e', key: 'assigned' },
    { status: 'In Progress', value: 1, color: '#ffc658', key: 'in_progress' },
    {
        status: 'Waiting For Customer',
        value: 1,
        color: '#e57373',
        key: 'waiting_for_customer',
    },
    {
        status: 'Waiting Vendor Reply',
        value: 0,
        color: '#ba68c8',
        key: 'waiting_vendor_reply',
    },
    {
        status: 'Solved',
        value: 0,
        color: '#4dd0e1',
        key: 'solved',
    },
    { status: 'Closed', value: 0, color: '#808000', key: 'closed' },
    {
        status: 'Permanently Closed',
        value: 0,
        color: '#800000',
        key: 'permanently_closed',
    },
]
const StatusAllocation = ({ data, counts }: any) => {
    console.log('data', data)
    console.log('counts', counts)
    const smallFont = useMediaQuery('(min-width:750px)')

    const [data01, setData01] = useState(chartData)
    // Calculate the total sum of all values
    const totalValue = data01.reduce((sum, entry) => sum + entry.value, 0)

    // Add the percentage value for each object
    data01.forEach((entry): any => {
        // Object.defineProperty(entry, "percent", ); // Object.defineProperty() method of JavaScript to define new properties to an existing object
        // @ts-expect-error skip
        entry.percent = ((entry.value / totalValue) * 100).toFixed(2)
    })
    useEffect(() => {
        const tempValues = [...chartData]
        if (Object.keys(data).length > 0) {
            tempValues.forEach((entry): any => {
                console.log('entry', entry.key)
                console.log('entry', data[entry.key])
                entry.value = data[entry.key]
            })
        }
        setData01(tempValues)
    }, [data])

    return (
        <div className="status-container">
            <h4>Status Allocation</h4>

            <div className="pie-container">
                <PieChart width={300} height={220} layout="centric">
                    <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={data01}
                        cx="40%"
                        cy="40%"
                        outerRadius={67}
                        label={({
                            cx,
                            cy,
                            midAngle,
                            innerRadius,
                            outerRadius,
                            percent,
                            index,
                        }: {
                            cx: number
                            cy: number
                            midAngle: number
                            innerRadius: number
                            outerRadius: number
                            percent: number
                            index: number
                        }) => {
                            const RADIAN: number = Math.PI / 180
                            const radius: number =
                                innerRadius + (outerRadius - innerRadius) * 0.5
                            const x: number =
                                cx + radius * Math.cos(-midAngle * RADIAN)
                            // const y = cy + radius * Math.sin(-midAngle * RADIAN);

                            const xOffset: number = 50
                            const yOffset: number = 50

                            const outerX: number =
                                cx +
                                (radius + xOffset) *
                                    Math.cos(-midAngle * RADIAN)
                            const outerY: number =
                                cy +
                                (radius + yOffset) *
                                    Math.sin(-midAngle * RADIAN)

                            return (
                                <text
                                    x={outerX}
                                    y={outerY}
                                    fill="black"
                                    textAnchor={x > cx ? 'start' : 'end'}
                                    dominantBaseline="central"
                                    fontSize="10px"
                                >
                                    {`${percent}%`}
                                </text>
                            )
                        }}
                    >
                        {data01.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>

                    <Tooltip
                        content={({ active, payload }: any) => {
                            if (active && payload && payload.length) {
                                const data: {
                                    status: string
                                    value: string
                                    percent: string
                                    key: string
                                } = payload[0].payload
                                console.log('dat111111a', data)
                                const value: string = counts[data?.key] || ''
                                console.log('data?.status', data?.status)
                                return (
                                    <div className="status-pop">
                                        <p>{`Status: ${data?.status}`}</p>

                                        <p>{`Count: ${value} `}</p>
                                    </div>
                                )
                            }
                            return null
                        }}
                        contentStyle={{ pointerEvents: 'none' }}
                    />
                </PieChart>

                <div className="status-legend">
                    {data01.map((entry, index) => (
                        <div key={`legend-${index}`} className="status-color">
                            <div
                                style={{
                                    width: '7px',
                                    height: '7px',
                                    backgroundColor: entry.color,
                                    marginRight: '5px',
                                    borderRadius: '10px',
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    fontSize: smallFont ? '1em' : '.6em',
                                    textOverflow: 'ellipsis',
                                    lineHeight: '12px',
                                }}
                            >
                                {entry.status}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default StatusAllocation
