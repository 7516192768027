import { useState } from 'react'
import { customerSelector } from '../../../../features/customers'
import { useAppSelector } from '../../../../app/hooks'
import FetchingData from '../../../../components/Loaders/FtechingData/FetchingData'
import ProductsEmptyState from '../../../../contents/CustomersContent/ProductsEmptyState/ProductsEmptyState'
// import CreateUpdateContract from '../../../../contents/CustomersContent/CreateUpdateContract/CreateUpdateContract'
import ListingCustomerContracts from '../../../../contents/CustomersContent/ListingCustomerContracts/ListingCustomerContracts'
import ProductCreateUpdateModal from '../../../../components/Modals/ProductCreateUpdateModal/ProductToBeEdited'
import ListingCustomerProducts from '../../../../contents/CustomersContent/ListingCustomerProducts/ListingCustomerProducts'

interface IProductsTab {
    customerId: string
}

const ProductsTab = ({ customerId }: IProductsTab) => {
    const { customer, pending } = useAppSelector(customerSelector)
    const [navigateToCreateUpdate, setNavigateToCreateUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [openProductModal, setOpenProductModal] = useState(false)
    const [productIndexToBeEdited, setProductIndexToBeEdited] = useState<
        number | null
    >(null)

    if (pending) {
        return <FetchingData />
    }

    return (
        <>
            {customer.products.length == 0 && !isEdit && (
                <ProductsEmptyState
                    createNew={() => {
                        setOpenProductModal(true)
                        setIsEdit(false)
                    }}
                />
            )}
            {customer.products.length !== 0 && (
                <ListingCustomerProducts
                    createNew={() => {
                        setOpenProductModal(true)
                        setIsEdit(false)
                    }}
                    seeDetails={(productIndexToBeEdited: number | null) => {
                        setIsEdit(true)
                        setOpenProductModal(true)
                        setProductIndexToBeEdited(productIndexToBeEdited)
                    }}
                />
            )}
            <ProductCreateUpdateModal
                isEdit={isEdit}
                handleClose={() => {
                    setOpenProductModal(false)
                    setIsEdit(false)
                    setProductIndexToBeEdited(null)
                }}
                ProductIndex={productIndexToBeEdited}
                open={openProductModal}
                customer={customer}
            />
        </>
    )
}

export default ProductsTab
