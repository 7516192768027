import { useState } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import Box from '@mui/material/Box'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import Stack from '@mui/material/Stack/Stack'
import CommonTextField from '../../common/CommonTextField/CommonTextField'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import CommonDatePicker from '../../common/CommonDatePicker/CommonDatePicker'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import type { IHoliday } from '../../../interfaces/IHoliday'
import { addAlert } from '../../../features/systemAlerts'
import { useAppDispatch } from '../../../app/hooks'
import type { IAlert } from '../../../interfaces/IAlert'
import { useWindowSize } from 'usehooks-ts'

interface INewHolidayModal {
    open: boolean
    handleClose: () => void
    handleMainAction: (newHoliday: IHoliday) => void
    holidaysList: IHoliday[] | null
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
    width: 714,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const NewHolidayModal = ({
    open,
    handleClose,
    handleMainAction,
    holidaysList,
}: INewHolidayModal) => {
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs())
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs())
    const { width, height } = useWindowSize()

    const dispatch = useAppDispatch()

    function createData(
        id: number | null,
        name: string,
        startDate: Dayjs,
        endDate: Dayjs
    ) {
        return { id, name, starting_date: startDate, ending_date: endDate }
    }

    const handleNewHolidayId = () => {
        console.log('holidaysList', holidaysList)
        if (holidaysList !== null && holidaysList.length > 0) {
            const lastHolidayId = holidaysList[holidaysList.length - 1].id
            return lastHolidayId !== null ? lastHolidayId + 1 : 1
        } else {
            return 1
        }
    }

    const hanldeCreateNewHoliday = () => {
        const holidayName = document.getElementById(
            'holidayName'
        ) as HTMLInputElement

        if (startDate && endDate) {
            if (
                holidayName.value.trim() === '' ||
                holidayName.value === undefined ||
                holidayName.value === null
            ) {
                const alertObj: IAlert = {
                    id: 'holidayNameError',
                    message: 'Holiday name is must',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            } else if (startDate >= endDate) {
                const alertObj: IAlert = {
                    id: 'holidayDatesError',
                    message: 'End date should be after start date',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            } else {
                handleMainAction(
                    createData(
                        handleNewHolidayId(),
                        holidayName.value,
                        startDate,
                        endDate
                    )
                )
                handleClose()
                setStartDate(dayjs())
                setEndDate(dayjs())
            }
        }
    }

    return (
        <>
            <BasicModal handleClose={handleClose} open={open}>
                <Box
                    sx={{
                        ...style,
                        width: (() => {
                            if (width <= 768) return width
                            return 932
                        })(),
                    }}
                >
                    <CommonTypography
                        sx={{ fontWeight: 600 }}
                        variant={'subtitle1'}
                        gutterBottom
                    >
                        New Holiday
                    </CommonTypography>
                    <Stack direction={'column'} sx={{ marginTop: 5 }}>
                        <CommonTextField
                            id="holidayName"
                            label="Holiday Name*"
                            variant="outlined"
                            key={'holidayName'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                        />
                        <CommonDatePicker
                            label="Start Date*"
                            setValue={setStartDate}
                            value={startDate}
                            key={'startDate'}
                            sx={{ marginTop: '40px' }}
                        />
                        <CommonDatePicker
                            label="End Date*"
                            setValue={setEndDate}
                            value={endDate}
                            key={'endDate'}
                            sx={{ marginTop: '24px' }}
                        />
                        <ActionNdCancel
                            actionBtnLabel="Confirm"
                            actionBtncolor="primary"
                            btnWidth={'140px'}
                            btnHeight={'40px'}
                            alignItems="center"
                            direction={'row'}
                            cancelBtnLabel="Cancel"
                            spacing={3}
                            handleAction={hanldeCreateNewHoliday}
                            handleCancelAction={handleClose}
                            justifyContent="end"
                            pending={false}
                            sx={{ marginTop: '48px' }}
                        />
                    </Stack>
                </Box>
            </BasicModal>
        </>
    )
}

export default NewHolidayModal
