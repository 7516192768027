import { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import Grid from '@mui/material/Grid'
import CommonTextField from '../../../components/common/CommonTextField/CommonTextField'
import CommonAutocomplete from '../../../components/common/CommonAutocomplete/CommonAutocomplete'
import SelectWorkingDaysModal from '../../../components/Modals/SelectWorkingDaysModal/SelectWorkingDaysModal'
import type { IDay } from '../../../interfaces/IDay'
import SelectDaysInput from '../../../components/SelectDaysInput/SelectDaysInput'
import CommonTimePicker from '../../../components/common/CommonTimePicker/CommonTimePicker'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import HolidaysTable from '../../../components/HolidaysTable/HolidaysTable'
import ActionNdCancel from '../../../components/ActionNdCancel/ActionNdCancel'
import NewHolidayModal from '../../../components/Modals/NewHolidayModal/NewHolidayModal'
import type { IHoliday } from '../../../interfaces/IHoliday'
import { useAppDispatch } from '../../../app/hooks'
import { addAlert } from '../../../features/systemAlerts'
import type { IAlert } from '../../../interfaces/IAlert'
import validateMobileNum from '../../../helper/validateMobileNum'
import dashBoardServices from '../../../services/cspServices'
import useFetch from '../../../hooks/useFetch'
import type { ITimeZone } from '../../../interfaces/ITimeZone'
import FetchingData from '../../../components/Loaders/FtechingData/FetchingData'
import { getCustomer } from '../../../features/customers'
import type { ISite } from '../../../interfaces/ISite'
import SaveDataConfirmationModal from '../../../components/Modals/SaveDataConfirmationModal/SaveDataConfirmationModal'

interface ICreateUpdateSite {
    customerId: string
    cancelAction: () => void
    isEdit: boolean
    siteDataToBeEdited: ISite
}

const daysArray: IDay[] = [
    {
        id: 1,
        name: 'Sunday',
        active: true,
    },
    {
        id: 2,
        name: 'Monday',
        active: true,
    },
    {
        id: 3,
        name: 'Tuesday',
        active: true,
    },
    {
        id: 4,
        name: 'Wednesday',
        active: true,
    },
    {
        id: 5,
        name: 'Thursday',
        active: true,
    },
    {
        id: 6,
        name: 'Friday',
        active: false,
    },
    {
        id: 7,
        name: 'Saturday',
        active: false,
    },
]
const keys = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
]
const CreateUpdateSite = ({
    customerId,
    cancelAction,
    isEdit,
    siteDataToBeEdited,
}: ICreateUpdateSite) => {
    console.log('siteDataToBeEdited', siteDataToBeEdited)
    const [workDaysPending, setWorkDaysPending] = useState(false)
    const [selectedDays, setSelectedDays] = useState<IDay[]>(daysArray)
    const [daysStr, setDaysStr] = useState('')
    const [open, setOpen] = useState(false)
    const [timeZoneInputValue, setTimeZoneInputValue] = useState('')

    const [wrkHoursFrom, setWrkHoursFrom] = useState<Dayjs | null>(
        dayjs().set('hour', 9).set('minute', 0).set('second', 0)
    )
    console.log('wrkHoursFrom', wrkHoursFrom?.get('hour'))
    const [wrkHoursTo, setWrkHoursTo] = useState<Dayjs | null>(
        dayjs().set('hour', 17).set('minute', 0).set('second', 0)
    )
    const [newHolidayModalOpen, setNewHolidayModalOpen] = useState(false)
    const [holidaysList, setHolidaysList] = useState<IHoliday[] | null>([])
    const [isPending, setIsPending] = useState(false)
    const [openSaveDataModal, setOpenSaveDataModal] = useState(false)
    const [isSavingData, setIsSavingData] = useState(false)

    const { data: timezonesList, isPending: isFetchingTimeZones } = useFetch(
        dashBoardServices.getTimeZones
    )

    const [timeZone, setTimeZone] = useState<{
        label: string
        value: number
    } | null>(null)
    const dispatch = useAppDispatch()

    const handleOpenWorkingDaysModal = () => {
        setOpen(true)
    }
    const handleCloseWorkingDaysModal = () => {
        setOpen(false)
    }

    const handleOpenNewHolidayModalModal = () => {
        setNewHolidayModalOpen(true)
    }
    const handleCloseNewHolidayModalModal = () => {
        setNewHolidayModalOpen(false)
    }
    useEffect(() => {
        if (siteDataToBeEdited) {
            const days = [...selectedDays]
            keys.map((day, i): any => {
                // @ts-expect-error  sadsdasdaasd
                days[i].active = siteDataToBeEdited[day]
            })
            setSelectedDays(days)
        }
    }, [siteDataToBeEdited])

    const handleToggleSelectDay = (id: number) => {
        const newDaysArray: IDay[] = selectedDays.map((day: IDay) => {
            if (day.id !== id) {
                // No change
                return day
            } else {
                // Return a new day with new active value
                return {
                    ...day,
                    active: !day.active,
                }
            }
        })
        let inactive = 0
        newDaysArray.map((day: IDay) => {
            if (!day.active) inactive++
        })
        if (inactive !== 7) {
            setSelectedDays(newDaysArray)
        } else {
            const alertObj: IAlert = {
                id: 'working days ',
                message: 'one day at least ',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
        }
    }

    const handleSelectedDaysStr = () => {
        setWorkDaysPending(true)
        setDaysStr('')
        const newDaysArray: IDay[] = selectedDays.filter(
            (day: IDay) => day.active
        )

        if (newDaysArray.length === 1) {
            setDaysStr(newDaysArray[0].name)
        } else if (newDaysArray.length === 7) {
            setDaysStr(`${newDaysArray[0].name} - ${newDaysArray[6].name}`)
        } else if (newDaysArray.length > 1 && newDaysArray.length !== 7) {
            let newDaysStr: string = ''
            for (let i = 0; i < newDaysArray.length; i++) {
                if (i === newDaysArray.length - 1) {
                    newDaysStr += `${newDaysArray[i].name}`
                } else {
                    newDaysStr += `${newDaysArray[i].name},`
                }
            }
            setDaysStr(newDaysStr)
        } else {
            setDaysStr('')
        }
        setWorkDaysPending(false)
    }

    const handleConfrimWorkinDays = () => {
        handleSelectedDaysStr()
        setOpen(false)
    }

    const handleAddNewHoliday = (newHoliday: IHoliday) => {
        if (holidaysList) {
            setHolidaysList([...holidaysList, newHoliday])
        } else {
            setHolidaysList([newHoliday])
        }
    }

    const handleRemoveHoliday = (holidayId: number) => {
        if (holidaysList !== null) {
            setHolidaysList(
                holidaysList?.filter(
                    (holiday: IHoliday) => holiday.id !== holidayId
                )
            )
            if (holidaysList.length === 1) setHolidaysList(null)
        }
    }

    const handleOpenSaveDataModal = () => {
        setOpenSaveDataModal(true)
    }

    const handleCloseSaveDataModal = () => {
        setOpenSaveDataModal(false)
    }

    const handleMainAction = () => {
        setIsPending(true)
        const siteName = document.getElementById(
            'site_name'
        ) as HTMLInputElement
        const address = document.getElementById(
            'site_address'
        ) as HTMLInputElement
        const contactNumber = document.getElementById(
            'contact_number'
        ) as HTMLInputElement
        const contactEmail = document.getElementById(
            'contact_email'
        ) as HTMLInputElement

        const siteObj = {
            name: siteName.value,
            address: address.value,
            contact_number: contactNumber.value,
            contact_email: contactEmail.value,
            time_zone: timeZone?.value, //    === 'Asia/Riyadh' ? '2' : '1',
            starting_hour: wrkHoursFrom?.format('HH:mm:ss'),
            ending_hour: wrkHoursTo?.format('HH:mm:ss'),
            holidays: holidaysList?.map((holiday: IHoliday) => {
                console.log('holiday', holiday)
                return {
                    id: holiday.id,
                    name: holiday.name,
                    starting_date: dayjs(holiday.starting_date).format(
                        'YYYY-MM-DD'
                    ),
                    ending_date: dayjs(holiday.ending_date).format(
                        'YYYY-MM-DD'
                    ),
                }
            }),
            customer: parseInt(customerId),
            saturday: selectedDays[6].active,
            sunday: selectedDays[0].active,
            monday: selectedDays[1].active,
            tuesday: selectedDays[2].active,
            wednesday: selectedDays[3].active,
            thursday: selectedDays[4].active,
            friday: selectedDays[5].active,
        }
        console.log('siteObj', siteObj)

        if (
            siteObj.name.trim() === '' ||
            siteObj.name === undefined ||
            siteObj.name === null
        ) {
            const alertObj: IAlert = {
                id: 'newSiteNameError',
                message: 'Site Name is a must',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        }
        if (!siteObj.time_zone) {
            const alertObj: IAlert = {
                id: 'newSiteTimeZoneError',
                message: 'Please select a timezone',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        }
        if (wrkHoursFrom && wrkHoursTo && wrkHoursFrom >= wrkHoursTo) {
            const alertObj: IAlert = {
                id: 'newSiteWorktimeError',
                message: 'Please set a valid Working Hours range',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        }
        // if (siteObj.contact_number == '') {
        //     // &&
        //     //     !validateMobileNum(siteObj.contact_number)) ||
        //     // siteObj.contact_number.length > 15
        //     const alertObj: IAlert = {
        //         id: 'newSiteMobilenumError',
        //         message: 'Contact number / name must be not be empty ',
        //         state: true,
        //         type: 'error',
        //     }
        //     dispatch(addAlert(alertObj))
        //     setIsPending(false)
        // }
        else {
            if (isEdit) {
                handleOpenSaveDataModal()
            } else {
                dashBoardServices
                    .createCustomerSite(customerId, siteObj)
                    .then((res) => {
                        console.log('resssssssssss', res)
                        if (res.status === 200) {
                            setIsPending(false)
                            dispatch(getCustomer(customerId))
                            cancelAction()
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            setIsPending(false)
                            console.log(err)
                            // Object.entries(err.response.data).map(
                            //     ([key, value]) => {
                            //         if (value instanceof Array) {
                            //             value.map((item, i) => {
                            //                 const alertObj: IAlert = {
                            //                     id: `${key}${i}key`,
                            //                     message: item,
                            //                     state: true,
                            //                     type: 'error',
                            //                 }
                            //                 dispatch(addAlert(alertObj))
                            //             })
                            //         }
                            //     }
                            // )
                        }
                    })
            }
        }
    }

    const handleUpdateSiteData = () => {
        setIsSavingData(true)

        const siteName = document.getElementById(
            'site_name'
        ) as HTMLInputElement
        const address = document.getElementById(
            'site_address'
        ) as HTMLInputElement
        const contactNumber = document.getElementById(
            'contact_number'
        ) as HTMLInputElement
        const contactEmail = document.getElementById(
            'contact_email'
        ) as HTMLInputElement
        // const timeZone = document.getElementById(
        //     'time_zone'
        // ) as HTMLInputElement

        const siteObj = {
            name: siteName.value,
            address: address.value,
            contact_number: contactNumber.value,
            contact_email: contactEmail.value,
            time_zone: timeZone?.value,
            starting_hour: wrkHoursFrom?.format('HH:mm:ss'),
            ending_hour: wrkHoursTo?.format('HH:mm:ss'),
            update_holidays: true,
            holidays: holidaysList?.map((holiday: IHoliday) => {
                console.log('holiday', holiday)
                return {
                    id: holiday.id,
                    name: holiday.name,
                    starting_date: dayjs(holiday.starting_date).format(
                        'YYYY-MM-DD'
                    ),
                    ending_date: dayjs(holiday.ending_date).format(
                        'YYYY-MM-DD'
                    ),
                }
            }),
            customer: parseInt(customerId),
            saturday: selectedDays[6].active,
            sunday: selectedDays[0].active,
            monday: selectedDays[1].active,
            tuesday: selectedDays[2].active,
            wednesday: selectedDays[3].active,
            thursday: selectedDays[4].active,
            friday: selectedDays[5].active,
        }

        dashBoardServices
            .updateCustomerSite(
                customerId,
                siteDataToBeEdited.id ? siteDataToBeEdited.id : '',
                siteObj
            )
            .then((res) => {
                if (res.status === 200) {
                    setIsSavingData(false)
                    setIsPending(false)
                    handleCloseSaveDataModal()
                    cancelAction()
                    dispatch(getCustomer(customerId))
                }
            })
            .catch((err) => {
                if (err) {
                    setIsPending(false)
                    setIsSavingData(false)
                    console.log(err)
                }
            })
    }

    useEffect(() => {
        console.log('siteDataToBeEdited', siteDataToBeEdited)
        if (isEdit && siteDataToBeEdited) {
            setHolidaysList(siteDataToBeEdited.holidays)
            const timeFormat = 'HH:mm:ss'
            setWrkHoursFrom(dayjs(siteDataToBeEdited.starting_hour, timeFormat))
            setWrkHoursTo(dayjs(siteDataToBeEdited.ending_hour, timeFormat))
        }
        const handleInitSelectedDaysStr = () => {
            setWorkDaysPending(true)
            setDaysStr('')
            const newDaysArray: IDay[] = selectedDays.filter(
                (day: IDay) => day.active
            )

            if (newDaysArray.length === 1) {
                setDaysStr(newDaysArray[0].name)
            } else if (newDaysArray.length === 7) {
                setDaysStr(`${newDaysArray[0].name} - ${newDaysArray[6].name}`)
            } else if (newDaysArray.length > 1 && newDaysArray.length !== 7) {
                let newDaysStr: string = ''
                for (let i = 0; i < newDaysArray.length; i++) {
                    if (i === newDaysArray.length - 1) {
                        newDaysStr += `${newDaysArray[i].name}`
                    } else {
                        newDaysStr += `${newDaysArray[i].name},`
                    }
                }
                setDaysStr(newDaysStr)
            } else {
                setDaysStr('')
            }
            setWorkDaysPending(false)
        }
        handleInitSelectedDaysStr()
    }, [isEdit, siteDataToBeEdited, selectedDays])

    if (isFetchingTimeZones) {
        return <FetchingData />
    }

    return (
        <>
            <Stack direction={'column'} rowGap={3}>
                <CommonTypography
                    variant={'subtitle1'}
                    sx={{ fontWeight: 600 }}
                >
                    Site Information
                </CommonTypography>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <CommonTextField
                            id="site_name"
                            label="Site Name*"
                            variant="outlined"
                            key={'site_name'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            defaultValue={isEdit ? siteDataToBeEdited.name : ''}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <CommonTextField
                            id="site_address"
                            label="Site Address"
                            variant="outlined"
                            key={'site_address'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            defaultValue={
                                isEdit ? siteDataToBeEdited.address : ''
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <CommonTextField
                            id="contact_number"
                            label="Contact Number / Name"
                            variant="outlined"
                            key={'contact_number'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            defaultValue={
                                isEdit ? siteDataToBeEdited.contact_number : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <CommonTextField
                            id="contact_email"
                            label="Contact E-Mail"
                            variant="outlined"
                            key={'contact_email'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            defaultValue={
                                isEdit ? siteDataToBeEdited.contact_email : ''
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={10}>
                        <CommonAutocomplete
                            inputValue={timeZoneInputValue}
                            setInputValue={setTimeZoneInputValue}
                            setValue={setTimeZone}
                            value={timeZone}
                            id="time_zone"
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            label="TimeZone*"
                            optionsList={timezonesList.map(
                                (timezone: ITimeZone) => {
                                    return {
                                        label: timezone.name,
                                        value: timezone.id,
                                    }
                                }
                            )}
                            defaultValue={
                                isEdit ? siteDataToBeEdited.time_zone : null
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <SelectDaysInput
                            value={daysStr}
                            sx={{ backgroundColor: '#F9F9F9', width: '100%' }}
                            onClick={handleOpenWorkingDaysModal}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6}>
                                <CommonTimePicker
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    label="Working Hours From"
                                    setValue={setWrkHoursFrom}
                                    value={wrkHoursFrom}
                                    key={'wrkHoursFrom'}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CommonTimePicker
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    label="Working Hours To"
                                    setValue={setWrkHoursTo}
                                    value={wrkHoursTo}
                                    key={'wrkHoursTo'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <CommonTypography
                    variant={'subtitle1'}
                    sx={{ fontWeight: 600 }}
                >
                    Holidays
                </CommonTypography>
                <Grid container columnSpacing={4}>
                    <Grid item xs={10}>
                        <HolidaysTable
                            rows={holidaysList}
                            openNewHolidayModal={handleOpenNewHolidayModalModal}
                            removeHoliday={handleRemoveHoliday}
                        />
                    </Grid>
                </Grid>
                <ActionNdCancel
                    actionBtnLabel="Confirm"
                    actionBtncolor="primary"
                    btnWidth={'140px'}
                    btnHeight={'40px'}
                    alignItems="center"
                    direction={'row'}
                    cancelBtnLabel="Cancel"
                    spacing={3}
                    handleAction={handleMainAction}
                    handleCancelAction={cancelAction}
                    justifyContent="end"
                    pending={false}
                    sx={{ marginTop: '64px' }}
                />
            </Stack>
            <SelectWorkingDaysModal
                open={open}
                handleClose={handleCloseWorkingDaysModal}
                DaysList={selectedDays}
                handleMainAction={handleConfrimWorkinDays}
                isPending={workDaysPending}
                handleToggleSelectDay={handleToggleSelectDay}
            />
            <NewHolidayModal
                open={newHolidayModalOpen}
                handleClose={handleCloseNewHolidayModalModal}
                handleMainAction={handleAddNewHoliday}
                holidaysList={holidaysList}
            />
            <SaveDataConfirmationModal
                open={openSaveDataModal}
                handleClose={handleCloseSaveDataModal}
                handleMainAction={handleUpdateSiteData}
                isPending={isSavingData}
                message="Are you sure you want to save all site information ?"
            />
        </>
    )
}

export default CreateUpdateSite
