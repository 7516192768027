import { createReducer } from '@reduxjs/toolkit'
import { getCustomersTableList } from './actions'
import type { ICustomerInTableList } from '../../interfaces/ICustomerInList'

interface IinitCustomersListReducer {
    customersTableList: ICustomerInTableList
    pending: boolean
    error: boolean
}

// games Campaings initial state
const initCustomersListReducer: IinitCustomersListReducer = {
    customersTableList: {
        count: 0,
        next: '',
        previous: '',
        results: [],
    },
    pending: true,
    error: false,
}

// departmentReducer reducer to handel action when dispatched
export const customersTableListReducer = createReducer(
    initCustomersListReducer,
    (builder) => {
        builder
            // game campaign Reducer action thunk and its stats.
            .addCase(getCustomersTableList.pending, (state) => {
                state.pending = true
                state.error = false
            })
            .addCase(getCustomersTableList.fulfilled, (state, { payload }) => {
                state.pending = false
                state.customersTableList = payload
            })
            .addCase(getCustomersTableList.rejected, (state) => {
                state.pending = false
                state.error = true
            })
    }
)

export default customersTableListReducer
