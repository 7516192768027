import Stack from '@mui/material/Stack/Stack'
import Grid from '@mui/material/Grid/Grid'
import { Button } from '@mui/material'
import EngineerCard from '../../../components/Cards/EngineerCard/EngineerCard'
import AvailabilityEngineerCard from '../../../components/Cards/AvailabilityEngineerCard/AvailabilityEngineerCard'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import dashBoardServices from '../../../services/cspServices'
interface Engineer {
    id: number
    user: User
    skills: Array<{
        skill: Skill
        rate: number
    }>
    team: {
        team: Team
        is_leader: boolean
    }
    is_online: boolean
}
interface User {
    email: string
    full_name: string
    jop_title: string
    phone: string
    is_active: boolean
}

interface Skill {
    id: number
    skill: string
    product: {
        id: number
        name: string
    }
}

interface Team {
    id: number
    name: string
    description: string
}
interface IListingCustomerSites {
    engineers: Engineer[]
    seeDetails: (siteIdToBeEdited: number | undefined, index: number) => void
}
const ListingTeamEngineers = ({
    engineers,
    seeDetails,
}: IListingCustomerSites) => {
    const {
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        clearErrors,

        formState: { errors },
    } = useForm({ reValidateMode: 'onSubmit' })
    const handleChangeOnline = (currentEng: any) => {
        console.log('currentEng', currentEng)
        const engineer = engineers.find((eng: Engineer, i) => {
            return eng.id == currentEng.id
        })
        if (engineer) {
            engineer.is_online = currentEng.status
            console.log('currentEng', currentEng)

            if (engineer)
                dashBoardServices
                    .updateEngineerOnline(
                        {
                            is_online: currentEng.status,
                        },
                        currentEng.id
                    )
                    .then((res) => {
                        console.log('res', res)
                    })
                    .catch((e) => {
                        console.log('e', e)
                    })
        }
    }
    useEffect(() => {
        engineers.map((eng: Engineer, i) => {
            setValue(`is_online${eng.id}`, eng.is_online)
        })
        const subscription = watch((value, { name, type }) => {
            console.log('watchwatch', type)
            console.log('watchwatch', name)
            console.log('watchwatch', value)
            // let engineer: any = null

            handleChangeOnline({
                // @ts-expect-error asdasd
                id: +name?.split('is_online')[1],
                // @ts-expect-error asdasd
                status: value[name],
            })
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch])

    return (
        <Stack direction={'column'} sx={{ marginTop: '50px' }}>
            <Stack
                direction={'row'}
                justifyContent={'center'}
                sx={{ paddingX: '80px' }}
            >
                <Grid container sx={{ marginTop: 3 }} rowGap={2} spacing={2}>
                    {engineers.map((eng: Engineer, i) => {
                        console.log('enwwwwwwwwwwwwwwwwwg', eng)
                        if (!eng.team.is_leader)
                            return (
                                <Grid item xs={12} md={6} lg={4} key={eng.id}>
                                    <AvailabilityEngineerCard
                                        JobTitle={eng.user.jop_title}
                                        name={eng.user.full_name}
                                        navigateToDetails={() => {
                                            seeDetails(eng.id, i)
                                        }}
                                        control={control}
                                        id={eng.id}
                                    />
                                </Grid>
                            )
                    })}
                </Grid>
            </Stack>
        </Stack>
    )
}

export default ListingTeamEngineers
