import { Paper, Stack, Typography, type SxProps } from '@mui/material'
import React from 'react'
interface styles {
    paper: SxProps
    label: SxProps
    value: SxProps
    stackRow: SxProps
}
const ProductDetails = ({ product }: any) => {
    return (
        <Paper sx={styles.paper}>
            <Stack direction={'row'}>
                <Stack direction={'row'} sx={styles.stackRow}>
                    <Typography sx={styles.label}>product name :</Typography>
                    <Typography sx={styles.value}>
                        {product.product.name}
                    </Typography>
                </Stack>
                <Stack direction={'row'}>
                    <Typography sx={styles.label}>
                        support contract :
                    </Typography>
                    <Typography sx={styles.value}></Typography>
                </Stack>
            </Stack>
            <Stack direction={'row'}>
                <Stack direction={'row'} sx={styles.stackRow}>
                    <Typography sx={styles.label}>release :</Typography>
                    <Typography sx={styles.value}>{product.release}</Typography>
                </Stack>
                <Stack direction={'row'}>
                    <Typography sx={styles.label}>site : </Typography>
                    <Typography sx={styles.value}>
                        {product.site.name}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction={'row'}>
                <Stack direction={'row'} sx={styles.stackRow}>
                    <Typography sx={styles.label}>platform : </Typography>
                    <Typography sx={styles.value}>
                        {product.paltform}
                    </Typography>
                </Stack>
                <Stack direction={'row'}>
                    <Typography sx={styles.label}>Follow SLA :</Typography>
                    <Typography sx={styles.value}>
                        {product.follow_sla ? 'Yes' : 'No'}
                    </Typography>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default ProductDetails

const styles: styles = {
    paper: {
        borderRadius: '4px',
        border: '0.6px solid #DCDCDC',
        backgroundColor: '#FFF',
        paddingTop: '32px',
        paddingLeft: '32px',
        marginBottom: '20px',
    },
    label: {
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginRight: '8px',
    },
    value: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
    },
    stackRow: {
        width: '420px',
        marginBottom: '32px',
    },
}
