import { DatePicker } from '@mui/x-date-pickers/DatePicker'

interface ICommonDatePicker {
    sx?: any
    label: string
    value: any
    setValue: any
    slotProps?: any
    disableFuture?: boolean
}

const CommonDatePicker = ({
    sx,
    label,
    value,
    setValue,
    slotProps,
    disableFuture,
}: ICommonDatePicker) => {
    return (
        <DatePicker
            disableFuture={disableFuture}
            sx={sx}
            label={label}
            value={value}
            onChange={(newValue) => setValue(newValue)}
            slotProps={slotProps}
        />
    )
}

export default CommonDatePicker
