import CommonEmptyState from '../../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import contractsEmptyState from '../../../assets/images/contracts_empty_state.svg'

interface IContractsEmptyState {
    createNew: () => void
}
const ContractsEmptyState = ({ createNew }: IContractsEmptyState) => {
    return (
        <CommonEmptyState
            src={contractsEmptyState}
            alt="site empty state"
            width={81}
            height={180}
            sx={{ marginTop: '88px' ,flex:1 }}
        >
            <CommonButton
                variant={'outlined'}
                sx={{ width: 160, height: 40 }}
                onClick={createNew}
            >
                Create Contract
            </CommonButton>
        </CommonEmptyState>
    )
}

export default ContractsEmptyState
