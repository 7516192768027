import { useState } from 'react'
import CommonButton from '../common/CommonButton/CommonButton'
import { Stack } from '@mui/material'
import CustomerDefaultLogo from '../../assets/images/customer_default_logo.svg'
import RectFrameLogo from '../RectFrameLogo/RectFrameLogo'

interface IUploadPhoto {
    isEdit?: boolean
    defaultImage: string | null
}

const UploadPhoto = ({ isEdit, defaultImage }: IUploadPhoto) => {
    const [uploadedPhoto, setUploadedPhoto] = useState<any>(
        defaultImage !== '' && defaultImage !== null
            ? defaultImage
            : CustomerDefaultLogo
    )

    const handleUpload = () => {
        const uploadPhotoInput = document.getElementById(
            'uploadPhotoInput'
        ) as HTMLInputElement
        uploadPhotoInput.click()
    }

    const handleRemovePhoto = (e: any) => {
        setUploadedPhoto(CustomerDefaultLogo)
        e.target.value = ''
    }

    return (
        <Stack direction={'row'} alignItems={'center'} spacing={4}>
            <RectFrameLogo
                src={uploadedPhoto}
                alt={'customer logo'}
                width={64}
                height={64}
            />
            <CommonButton
                variant={'contained'}
                color={'success'}
                sx={{
                    color: '#fff',
                    width: '168px',
                    height: '40px',
                    boxShadow: 'none',
                }}
                onClick={handleUpload}
            >
                Upload
            </CommonButton>
            {uploadedPhoto !== CustomerDefaultLogo && (
                <CommonButton
                    variant={'outlined'}
                    color={'error'}
                    sx={{
                        color: 'error.main',
                        width: '168px',
                        height: '40px',
                        boxShadow: 'none',
                    }}
                    onClick={handleRemovePhoto}
                >
                    Remove
                </CommonButton>
            )}
            <input
                type="file"
                id="uploadPhotoInput"
                hidden
                onChange={(e) => {
                    if (e.target.files) {
                        if (e.target.files.length === 0) {
                            handleRemovePhoto(e)
                        } else {
                            setUploadedPhoto(
                                URL.createObjectURL(e.target.files[0])
                            )
                        }
                    }
                }}
            />
        </Stack>
    )
}

export default UploadPhoto
