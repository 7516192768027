import type { AxiosPromise } from 'axios'
import { useState, useEffect } from 'react'

const useFetch = (
    handleFetchData: (params?: any, paramsSec?: any) => AxiosPromise,
    params?: any,
    paramsSec?: any,
    reftech?: boolean
) => {
    const [data, setData] = useState([] as any)
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState(false)

    useEffect(() => {
        setIsPending(true)
        setError(false)
        handleFetchData(params, paramsSec)
            .then((res) => {
                if (res.status === 200) {
                    setData(res.data)
                    setIsPending(false)
                }
            })
            .catch((err) => {
                if (err) setError(true)
                setIsPending(false)
            })
    }, [handleFetchData, params, paramsSec, reftech])

    return { data, isPending, error }
}

export default useFetch
