import {
    Paper,
    Stack,
    Typography,
    type SxProps,
    Chip,
    IconButton,
    Popover,
    Link,
    Button,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ReactComponent as MoreIcon } from '../../../assets/images/MoreIcon.svg'
import { ReactComponent as FilesIcon } from '../../../assets/images/document-download.svg'
import { ReactComponent as Dot } from '../../../assets/images/Dot.svg'
import { Collapse } from '@mui/material'
import dayjs from 'dayjs'
import CustomerPopover from './CustomerPopover'
import { useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user'
import EngineerPopover from './EngineerPopover'
import { status, statusNames } from '../../../helper/constants'

interface IStyle {
    paper: SxProps
    chip: SxProps
    requestHeader: SxProps
    TopBorder: SxProps
    opened: SxProps
    assigned: SxProps
    inProgress: SxProps
    waitingForCustomer: SxProps
    waitingVendorReply: SxProps
    solved: SxProps
    closed: SxProps
    attachLink: SxProps
    description: SxProps
    name: SxProps
    date: SxProps
    dateLabel: SxProps
    moreOrLess: SxProps
    popupButton: SxProps
}
const RequestCard = ({
    userSummary,
    handleOpenAttachments,
    handleOpenEscalate,
    handleReplyCancel,
    handleReplyReOpen,
    handleRequestUpate,
}: any) => {
    const { user } = useAppSelector(userSelector)
    const [showAll, setShowAll] = useState(false)
    const [more, setMore] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    useEffect(() => {
        if (userSummary.description) {
            if (userSummary.description.trim().split(/\s+/).length > 30) {
                setMore(true)
            } else {
                setMore(false)
            }
        }
    }, [userSummary])

    return (
        <Paper sx={styles.paper}>
            <Stack direction={'column'}>
                <Stack sx={styles.TopBorder}></Stack>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Typography sx={styles.requestHeader}>
                            {`# ${+userSummary.id} `}
                            {userSummary.subject}
                        </Typography>
                        <Chip
                            label={
                                statusNames[userSummary.status.toLowerCase()]
                            }
                            sx={
                                // @ts-expect-error skip error
                                styles[userSummary.status.toLowerCase()]
                            }
                        />
                    </Stack>
                    <Stack direction={'row'}>
                        {userSummary.attachments.length > 0 && (
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-end'}
                            >
                                <Stack sx={{ marginRight: '8px' }}>
                                    <FilesIcon />
                                </Stack>
                                <Link
                                    component="button"
                                    variant="body2"
                                    sx={styles.attachLink}
                                    onClick={() => {
                                        handleOpenAttachments(
                                            userSummary.attachments
                                        )
                                    }}
                                >
                                    {userSummary.attachments.length} files
                                    attached
                                </Link>
                            </Stack>
                        )}
                        <IconButton
                            aria-label="more"
                            id="long-button"
                            onClick={handleClick}
                            disabled={
                                userSummary.status == status.PERMANENTLY_CLOSED
                            }
                        >
                            <MoreIcon />
                        </IconButton>
                        {user.user_type == 'CUSTOMER' && (
                            <CustomerPopover
                                handleReplyReOpen={handleReplyReOpen}
                                handleReplyCancel={handleReplyCancel}
                                disabledEsclate={userSummary.esclation_status}
                                disabled={
                                    !(
                                        userSummary.status == 'CLOSED' ||
                                        userSummary.status == 'SOLVED'
                                    )
                                }
                                disabledCancel={
                                    userSummary.status == 'CLOSED' ||
                                    userSummary.status == 'SOLVED'
                                }
                                handleOpenEscalate={handleOpenEscalate}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                            />
                        )}
                        {user.user_type !== 'CUSTOMER' && (
                            <EngineerPopover
                                handleRequestUpate={handleRequestUpate}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                            />
                        )}
                    </Stack>
                </Stack>
                <Stack direction={'row'} sx={{ marginBottom: '24px' }}>
                    <Typography sx={styles.name}>
                        {' '}
                        {userSummary.created_by.user.full_name}
                    </Typography>
                    <Typography sx={styles.date} alignItems={'center'}>
                        {dayjs(userSummary.created_at).format(
                            'MMM D YYYY, h:mm A'
                        )}
                    </Typography>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        sx={{ marginLeft: '16px' }}
                    >
                        <Dot />
                        <Typography sx={styles.dateLabel}>DueBy :</Typography>
                    </Stack>
                    <Typography sx={styles.date}>
                        {(userSummary.resolution_time_out &&
                            dayjs(userSummary.resolution_time_out).format(
                                'MMM D YYYY, h:mm A'
                            )) ||
                            'N/A'}
                    </Typography>
                </Stack>
                <Stack direction={'row'}>
                    <Typography sx={styles.description}>
                        {!showAll &&
                            userSummary.description
                                .trim()
                                .split(/\s+/)
                                .slice(0, 30)
                                .join(' ')}
                        {more && (
                            <Typography
                                sx={styles.moreOrLess}
                                onClick={() => {
                                    setShowAll(true)
                                    setMore(false)
                                }}
                            >
                                See More
                            </Typography>
                        )}
                        {showAll && (
                            <Collapse in={showAll}>
                                {userSummary.description}
                                <Typography
                                    sx={styles.moreOrLess}
                                    onClick={() => {
                                        setShowAll(false)
                                        setMore(true)
                                    }}
                                >
                                    See less
                                </Typography>
                            </Collapse>
                        )}
                    </Typography>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default RequestCard

const styles: IStyle = {
    TopBorder: {
        borderRadius: '0px 0px 5px 5px',
        backgroundColor: '#47A5A6',
        minHeight: '5px',
        borderColor: '#47A5A6',
        height: '5px',
    },
    paper: {
        borderRadius: '0px 0px 10px 10px',
        borderColor: '#47A5A6',
        borderTopWidth: '5px',
        background: '#FbFbFb',
        paddingBottom: '16px',
        marginTop: '25px',
    },
    chip: {
        borderRadius: '0px',
    },
    requestHeader: {
        color: '#47A5A6',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginRight: '24px',
        marginLeft: '16px',
    },
    opened: {
        maxHeight: '24px',
        color: '#3788D8',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'capitalize',
        borderRadius: '0px',
        border: '0.4px solid #CCC',
        background: 'rgba(55, 136, 216, 0.10)',
    },
    assigned: {
        maxHeight: '24px',
        borderRadius: '0px',
        border: '0.4px solid #2031CD',
        background: 'rgba(32, 49, 205, 0.10)',
        color: '#2031CD',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'capitalize',
    },
    // @ts-expect-error no error
    in_progress: {
        maxHeight: '24px',
        borderRadius: '0px',
        border: '0.4px solid #F49321',
        background: 'rgba(244, 147, 33, 0.10)',
        color: '#F49321',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'capitalize',
    },
    waiting_for_customer: {
        maxHeight: '24px',
        borderRadius: '0px',
        border: '0.4px solid #676767',
        background: 'rgba(103, 103, 103, 0.10)',
        color: '#676767',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'capitalize',
    },
    waiting_vendor_reply: {
        maxHeight: '24px',
        borderRadius: '0px',
        border: '0.4px solid #2F4858',
        background: 'rgba(47, 72, 88, 0.10)',
        color: '#2F4858',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'capitalize',
    },
    solved: {
        maxHeight: '24px',
        borderRadius: '0px',
        border: '0.4px solid #00D586',
        background: 'rgba(0, 213, 134, 0.10)',
        color: '#00D586',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'capitalize',
    },
    closed: {
        maxHeight: '24px',
        borderRadius: '0px',
        border: '0.2px solid #2F4858',
        background: 'rgba(255, 99, 112, 0.10)',
        color: '#FF6370',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'capitalize',
    },
    attachLink: {
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '14.4px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '19.2px',
        textDecorationLine: 'none',
        textTransform: 'capitalize',
        marginRight: '16px',
        '&:hover': {
            cursor: 'pointer',
            textDecorationLine: 'underline',
        },
    },
    name: {
        color: '#172327',
        fontFamily: 'Inter',
        fontSize: '14.4px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        borderRight: '2px solid #306778',
        paddingX: '9px',
        marginLeft: '5px',
        height: '17px',
        marginRight: '9px',
    },
    date: {
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',
        alignItems: 'center',
    },
    dateLabel: {
        marginX: '4px',

        color: '#929EAE',
        fontFamily: 'Poppins',
        fontSize: '14.4px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textTransform: 'capitalize',
    },
    description: {
        display: 'flex',
        flexDirection: 'column',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',

        marginLeft: '15px',
    },
    moreOrLess: {
        marginX: '3px',
        color: '#47A5A6',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        textTransform: 'capitalize',
        minWidth: 'max-content',
        '&:hover': {
            cursor: 'pointer',
            textDecorationLine: 'underline',
        },
    },
    popupButton: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'none',
        width: '150px',
        textAlign: 'left',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
    },
}
