import { type SxProps } from '@mui/material'
import Typography from '@mui/material/Typography'

interface Props {
    children: any
    align?: any
    variant?: any
    gutterBottom?: boolean
    sx?: SxProps
}

const CommonTypography = ({
    children,
    align,
    variant,
    gutterBottom,
    sx,
}: Props) => {
    return (
        <Typography
            align={align}
            variant={variant}
            gutterBottom={gutterBottom}
            sx={sx}
        >
            {children}
        </Typography>
    )
}

export default CommonTypography
