import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import { TextField, IconButton, InputAdornment, Grid } from '@mui/material'
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import React, { type ChangeEvent, type KeyboardEvent } from 'react'
import { type ICustomerInTableList } from '../../interfaces/ICustomerInList'
import { NavLink } from 'react-router-dom'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4A4455',
        color: theme.palette.common.white,
        textAlign: 'center',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 17,
        fontWeight: 'bold',
        textAlign: 'center',

        '&:not(:first-child)': {
            // backgroundColor: theme.palette.action.hover,
            color: '#95A3B3',
        },
    },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}))

interface Props {
    customersList: ICustomerInTableList
    searchText: string
    setSearchText: (data: any) => void
}

export default function CustomersTable({
    customersList,
    searchText,
    setSearchText,
}: Props) {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            handleChange(event as ChangeEvent<HTMLInputElement>)
        }
    }

    const handleClearSearch = () => {
        setSearchText('')
    }

    return (
        <Grid
            container
            spacing={3}
            sx={{
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingTop: '32px',
            }}
        >
            <Grid item xs={12} lg={3}>
                <TextField
                    // value={searchText}
                    // onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Search by customer name"
                    sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    style={{
                        width: '100%',
                        backgroundColor: '#fff',
                        border: 'unset',
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <IconButton onClick={handleClearSearch} edge="end">
                                {searchText && <ClearIcon />}
                            </IconButton>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Customer Name</StyledTableCell>
                                <StyledTableCell align="right">
                                    # Products
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    Applicable Priorites
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    Created Time
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customersList.results.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                        <NavLink
                                            style={{
                                                color: 'black',
                                                textDecoration: 'none',
                                            }}
                                            to={`/customers/${row.id}`}
                                        >
                                            {row.name}
                                        </NavLink>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.products}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.applicable_priorities}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {new Date(
                                            row.created_at
                                        ).toLocaleString('en-EG')}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
