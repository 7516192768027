import baseURL from '../services/baseUrl'
import { apiClient } from '../services/cspServices'

export const fetchImage = async (customer: any) => {
    try {
        const photo: string = customer.photo || ''
        const { globalUrl: url } = baseURL()
        const response = await apiClient.get(`${url}/${photo}`, {
            responseType: 'arraybuffer',
        })

        if (response.status === 200) {
            const contentType = response.headers['content-type']
            const extension: string = contentType.split('/').pop() || ''

            if (!extension) {
                console.error('Unable to determine image extension')
                return null
            }

            const base64Image: string = btoa(
                new Uint8Array(response.data).reduce(
                    (data, byte) => data + String.fromCharCode(byte),
                    ''
                )
            )
            return {
                id: customer.id,
                base64: `data:image/${extension};base64,${base64Image}`,
            }
        }
    } catch (error) {
        console.error('Error downloading image:', error)
    }
    return null
}

export const fetchAndSetCustomerImages = async (
    customersList: any,
    setCustomerImages: any
) => {
    const imagePromises = customersList.map((customer: any) =>
        fetchImage(customer)
    )

    const resolvedImages = await Promise.all(imagePromises)

    const validImages = resolvedImages.filter((image) => image !== null)

    const imageMap = validImages.reduce((acc, image) => {
        acc[image.id] = image.base64
        return acc
    }, {})

    setCustomerImages((prevImages: any) => ({
        ...prevImages,
        ...imageMap,
    }))
}
export const PasswordStrengthChecker = (password: string) => {
    if (password.length < 6)
        return 'Password must be at least 6 characters long.'
    if (!/[A-Z]/.test(password))
        return 'Password must contain an uppercase letter.'
    if (!/[a-z]/.test(password))
        return 'Password must contain a lowercase letter.'
    if (!/[0-9]/.test(password)) return 'Password must contain a number.'
    if (!/[^A-Za-z0-9]/.test(password))
        return 'Password must contain a special character.'

    return null // indicates the password is strong
}
