import { useState } from 'react'
import { customerSelector } from '../../../../features/customers'
import { useAppSelector } from '../../../../app/hooks'
import FetchingData from '../../../../components/Loaders/FtechingData/FetchingData'
import ContractsEmptyState from '../../../../contents/CustomersContent/ContractsEmptyState/ContractsEmptyState'
import CreateUpdateContract from '../../../../contents/CustomersContent/CreateUpdateContract/CreateUpdateContract'
import ListingCustomerContracts from '../../../../contents/CustomersContent/ListingCustomerContracts/ListingCustomerContracts'

interface IContactsTab {
    customerId: string
}

const ContactsTab = ({ customerId }: IContactsTab) => {
    const { customer, pending } = useAppSelector(customerSelector)
    const [navigateToCreateUpdate, setNavigateToCreateUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [contractIdToBeEdited, setContractIdToBeEdited] = useState<
        number | undefined
    >(undefined)

    if (pending) {
        return <FetchingData />
    }
    return (
        <>
            {customer.contracts.length === 0 &&
                !navigateToCreateUpdate &&
                !isEdit && (
                    <ContractsEmptyState
                        createNew={() => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(false)
                        }}
                    />
                )}
            {customer.contracts.length !== 0 &&
                !navigateToCreateUpdate &&
                !isEdit && (
                    <ListingCustomerContracts
                        createNew={() => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(false)
                        }}
                        seeDetails={(
                            contractIdToBeEdited: number | undefined
                        ) => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(true)
                            setContractIdToBeEdited(contractIdToBeEdited)
                        }}
                    />
                )}
            {navigateToCreateUpdate && (
                <CreateUpdateContract
                    customerId={customerId}
                    cancelAction={() => {
                        setNavigateToCreateUpdate(false)
                        setIsEdit(false)
                    }}
                    isEdit={isEdit}
                    contractDataToBeEdit={
                        customer.contracts.filter(
                            (contract) => contract.id === contractIdToBeEdited
                        )[0]
                    }
                />
            )}
        </>
    )
}

export default ContactsTab
