import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import Grid from '@mui/material/Grid/Grid'
import { customerSelector } from '../../../features/customers'
import { useAppSelector } from '../../../app/hooks'
import SiteCard from '../../../components/Cards/SiteCard/SiteCard'
import ProductCard from '../../../components/Cards/ProductCard/ProductCard'
import TeamCard from '../../../components/Cards/TeamCard/TeamCard'
import { Button } from '@mui/material'

interface IListingCustomerSites {
    teams: Team[]
    createNew: () => void
    seeDetails: (siteIdToBeEdited: number | null, index: any) => void
}
interface Engineer {
    id: number
    engineer: number
    is_leader: boolean
}

interface Team {
    id: number
    name: string
    description: string
    engineers: Engineer[]
    engineers_count: number
}

const ListingTeams = ({
    teams,
    createNew,
    seeDetails,
}: IListingCustomerSites) => {
    console.log('teams', teams)
    return (
        <Stack direction={'column'} sx={{ marginTop: '50px' }}>
            <Stack
                direction={'row'}
                justifyContent={'flex-end'}
                sx={{ paddingX: '50px' }}
            >
                <Button
                    variant={'text'}
                    disableFocusRipple
                    disableRipple
                    color={'primary'}
                    sx={{
                        width: '140px',
                        height: '38px',
                        borderRadius: '2px',
                        border: '1px solid #494454',
                        backgroundColor: '#FFF',
                    }}
                    onClick={createNew}
                >
                    New Team
                </Button>
            </Stack>
            <Grid container sx={{ marginTop: 3 }} rowGap={4}>
                {teams.map((team: Team, i) => (
                    <Grid item xs={12} md={12} lg={6} xl={4} key={team.id}>
                        <TeamCard
                            description={team.description}
                            engineers={team.engineers}
                            name={team.name}
                            navigateToDetails={() => {
                                seeDetails(team.id, i)
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default ListingTeams
