import type { SxProps } from '@mui/material'
import Stack from '@mui/material/Stack'
import type { CSSProperties } from 'react'

interface IBasicImageContainer {
    src: any
    alt: string
    width?: number | string
    height?: number | string
    sx: SxProps
    style?: CSSProperties
}

const BasicImageContainer = ({
    src,
    alt,
    width,
    height,
    sx,
    style,
}: IBasicImageContainer) => {
    return (
        <Stack>
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                style={style}
            />
        </Stack>
    )
}

export default BasicImageContainer
