import { createReducer } from '@reduxjs/toolkit'
import { getCustomer } from './actions'
import type { ICustomer } from '../../interfaces/ICustomer'

interface IinitCustomerReducer {
    customer: ICustomer
    pending: boolean
    error: boolean
}

// games Campaings initial state
const initCustomerReducer: IinitCustomerReducer = {
    customer: {
        id: null,
        name: null,
        industry: null,
        address: null,
        contact_number: null,
        contact_email: null,
        description: null,
        photo: null,
        sites: [],
        contracts: [],
        products: [],
        skills: [],
        users: [],
        email_notification: undefined,
    },
    pending: true,
    error: false,
}

// departmentReducer reducer to handel action when dispatched
export const customerReducer = createReducer(initCustomerReducer, (builder) => {
    builder
        // game campaign Reducer action thunk and its stats.
        .addCase(getCustomer.pending, (state) => {
            state.pending = true
            state.error = false
        })
        .addCase(getCustomer.fulfilled, (state, { payload }) => {
            state.pending = false
            state.customer = payload
        })
        .addCase(getCustomer.rejected, (state) => {
            state.pending = false
            state.error = true
        })
})

export default customerReducer
