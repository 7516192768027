import * as React from 'react'
import CommonTable from '../common/CommonTable/CommonTable'
import CommonButton from '../common/CommonButton/CommonButton'
import CommonIconButton from '../common/CommonIconButton/CommonIconButton'
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'
import Grid from '@mui/material/Grid/Grid'
import Stack from '@mui/material/Stack/Stack'
import Divider from '@mui/material/Divider'
import type { IHoliday } from '../../interfaces/IHoliday'

interface IHolidaysTable {
    rows: IHoliday[] | null
    openNewHolidayModal: () => void
    removeHoliday: (holidayId: number) => void
}

const HolidaysTable = ({
    rows,
    openNewHolidayModal,
    removeHoliday,
}: IHolidaysTable) => {
    const getTableHead = () => {
        return (
            <Grid
                container
                spacing={0}
                sx={{
                    backgroundColor: '#494454',
                    height: '40px',
                    paddingLeft: 2,
                    paddingRight: 4,
                }}
                alignItems={'center'}
            >
                <Grid
                    item
                    xs={4}
                    justifyContent={'start'}
                    sx={{ color: 'common.white' }}
                >
                    Holliday Name
                </Grid>
                <Grid
                    item
                    xs={3}
                    justifyContent={'start'}
                    sx={{ color: 'common.white' }}
                >
                    Start Date
                </Grid>
                <Grid
                    item
                    xs={3}
                    justifyContent={'start'}
                    sx={{ color: 'common.white' }}
                >
                    End Date
                </Grid>
                <Grid item xs={2} alignItems={'end'}>
                    <Stack
                        sx={{ width: '100%' }}
                        direction={'row'}
                        justifyContent={'end'}
                    >
                        <CommonButton
                            sx={{
                                width: 104,
                                height: 28,
                                // backgroundColor: 'background.default',
                                borderColor: '#FFF',
                                color: '#fff',
                                fontSize: '14px',
                                p: 0,
                                // color: 'text.',s
                                '&:hover': {
                                    backgroundColor: 'background.default',
                                    color: 'text.primary',
                                },
                            }}
                            color={'primary'}
                            variant="outlined"
                            onClick={openNewHolidayModal}
                        >
                            New Holiday
                        </CommonButton>
                    </Stack>
                </Grid>
            </Grid>
        )
    }

    const getTableBody = () => {
        return rows?.map((row, index) => (
            <Grid container key={index}>
                <Grid
                    container
                    sx={{
                        borderRadius: '10px',
                        height: '50px',
                        marginLeft: 1,
                        marginRight: '36px',
                        padding: 2,

                        '&:hover': { backgroundColor: '#E5E5E5' },
                    }}
                >
                    <Grid item xs={4}>
                        {row.name}
                    </Grid>
                    <Grid item xs={3}>
                        {row.starting_date.toString().slice(0, 16)}
                    </Grid>
                    <Grid item xs={3}>
                        {row.ending_date.toString().slice(0, 16)}
                    </Grid>
                    <Grid item xs={2}>
                        <Stack
                            sx={{ width: '100%' }}
                            direction={'row'}
                            justifyContent={'end'}
                        >
                            <CommonIconButton
                                sx={{ width: 24, height: 24 }}
                                onClick={() => {
                                    if (row.id) removeHoliday(row.id)
                                }}
                            >
                                <DeleteForeverRoundedIcon />
                            </CommonIconButton>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid
                    container
                    key={`divider${index}`}
                    sx={{
                        marginLeft: 1,
                        marginRight: '36px',
                    }}
                >
                    <Grid item xs={12}>
                        {index !== rows.length - 1 && (
                            <Divider variant="middle" />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        ))
    }

    const getTableEmptyBody = () => {
        return (
            <Grid
                container
                sx={{
                    borderRadius: '10px',
                    height: '50px',
                    marginLeft: 1,
                    marginRight: '36px',
                    padding: 2,
                    marginTop: 0.5,
                    marginBottom: 0.5,
                }}
                alignItems={'center'}
            >
                <Grid item xs={6}>
                    -------
                </Grid>
                <Grid item xs={2}>
                    -------
                </Grid>
                <Grid item xs={2}>
                    -------
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        )
    }

    return (
        <CommonTable>
            {getTableHead()}
            {rows !== null && getTableBody()}
            {rows === null && getTableEmptyBody()}
        </CommonTable>
    )
}

export default HolidaysTable
