import { Button, Grid, Stack, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Image from '../../assets/images/Login.svg'
import ImageLogin from '../../assets/images/login.png'
import ITRanks from '../../assets/images/ITRanks.svg'
import BasicImageContainer from '../../components/BasicImageContainer/BasicImageContainer'
import { Controller, useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { login, userSelector } from '../../features/user'
import { useNavigate } from 'react-router-dom'
import {
    addAlert,
    removeAlert,
    systemAlertsSelector,
} from '../../features/systemAlerts'
import { type IAlert } from '../../interfaces/IAlert'
import LoginComponent from './LoginComponent'
import ForgetPassword from './ForgetPassword'
import SuccessMail from './SuccessMail'
import BasicErrorAlert from '../../components/Alerts/BasicErrorAlert/BasicErrorAlert'

const Login = () => {
    const data = useAppSelector(systemAlertsSelector)
    const { systemAlerts } = useAppSelector(systemAlertsSelector)
    const dispatch = useAppDispatch()

    console.log('data', data.systemAlerts)
    const [forgetPassword, setForgetPassword] = useState(false)
    const [showSuccessPage, setShowSuccessPage] = useState(false)
    const [msg, setMsg] = useState('')
    const [email, setEmail] = useState('')
    const showSuccess = (message: any, Email: any) => {
        setShowSuccessPage(true)
        setForgetPassword(false)
        setMsg(message)
        setEmail(Email)
    }
    return (
        <Grid
            container
            spacing={0}
            style={{
                overflow: 'hidden',
                maxHeight: '100vh',
            }}
        >
            <Grid
                item
                xs={6}
                sx={{
                    background:
                        'linear-gradient(180deg, #464153 0%, #282432 100%);',
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Stack
                    direction={'column'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                >
                    <Stack direction={'row'}>
                        <BasicImageContainer
                            sx={{
                                width: '100%',
                            }}
                            src={ITRanks}
                            alt=""
                            style={{
                                height: '100%',
                                width: '100%',
                            }}
                            key={'imagLogin'}
                        />
                    </Stack>
                    {!showSuccessPage && (
                        <Stack
                            direction={'row'}
                            sx={{
                                color: '#FFF',
                                fontSize: '22px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                                letterSpacing: '-0.22px',
                                marginY: '35px',
                            }}
                        >
                            {forgetPassword
                                ? 'Forgot your Password ?'
                                : 'IT-RANKS Customer Success Portal'}
                        </Stack>
                    )}
                    {!forgetPassword && !showSuccessPage && (
                        <LoginComponent
                            showForgetPassword={() => {
                                setForgetPassword(true)
                            }}
                        />
                    )}
                    {forgetPassword && !showSuccessPage && (
                        <ForgetPassword showSuccessPage={showSuccess} />
                    )}
                    {showSuccessPage && (
                        <SuccessMail email={email} message={msg} />
                    )}
                    <Stack direction={'row'}></Stack>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <BasicImageContainer
                    sx={{
                        height: '100vh',
                        maxHeight: '100vh',
                    }}
                    src={ImageLogin}
                    alt=""
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    key={'imagLogin'}
                />
            </Grid>
            {systemAlerts.map((alert: any, index: number) => (
                <BasicErrorAlert
                    key={alert.id}
                    isOpen={alert.state}
                    sx={{
                        alignItems: 'center',
                        width: 480,
                        height: 65,
                        position: 'fixed',
                        left: 0,
                        bottom: index * 70,
                        backgroundColor: '#FFFFFF',
                        zIndex: 9999,
                        marginBottom: 5,
                        marginLeft: 4,
                    }}
                    type={alert.type}
                    actionOnClick={() => {
                        dispatch(removeAlert(alert.id))
                    }}
                >
                    {alert.message}
                </BasicErrorAlert>
            ))}
        </Grid>
    )
}

export default Login
