import * as React from 'react'
import Modal from '@mui/material/Modal'
import { type SxProps } from '@mui/material'

interface IBasicModal {
    children: any
    open: boolean
    handleClose: () => void
    sx?:SxProps
}

const BasicModal = ({ children, open, handleClose ,sx}: IBasicModal) => {
    return (
        <div>
            <Modal
            sx={sx}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {children}
            </Modal>
        </div>
    )
}

export default BasicModal
