import {
    Paper,
    Stack,
    Typography,
    type SxProps,
    Grid,
    Icon,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import CommonTypography from '../common/CommonTypography/CommonTypography'
import SlaTable from '../SlaTable/SlaTable'
import { type IPriority } from '../../interfaces/IPriority'
import { ReactComponent as Calender } from '../../assets/images/calendarPeriod.svg'
import ProductDetails from './ProductDetails'
import NewRequestModal from '../Modals/NewRequestModal/NewRequestModal'
import RequestModalFilter from '../Modals/RequestModalFilter/RequestModalFilter'
import ReplayCard from '../Cards/ReplayCard/ReplayCard'
import RequestCard from '../Cards/RequestCard/RequestCard'
import dashBoardServices from '../../services/cspServices'
import { useAppSelector } from '../../app/hooks'
import { customerSelector } from '../../features/customers'
import { userSelector } from '../../features/user'

interface styles {
    paper: SxProps
    title: SxProps
    labelName: SxProps
    contractName: SxProps
    description: SxProps
}
const ContractDetails = () => {
    const { user } = useAppSelector(userSelector)
    console.log('user', user)
    const [contract, setContract] = useState<any>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [prioritiesList, setPrioritiesList] = useState<IPriority[] | null>(
        null
    )
    useEffect(() => {
        setLoading(true)
const fetchContract = async (getContractByCustomer:any) => {
    console.log('getContractByCustomer', getContractByCustomer)
    
          if(getContractByCustomer){
            await getContractByCustomer(user.customer_id)
            .then(({ data }:any): any => {
                if (
                    data.sites.length == 0 ||
                    data.products.length == 0 ||
                    data.length == 0
                ) {
                    setError(true)
                } else {
                    setContract(data)
                }
            })
            .catch((e:any) => {
                setError(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }
          }

      
            fetchContract(user.is_admin ? dashBoardServices.getCustomerForAdminUser:user.is_project_manager ?dashBoardServices.getCustomerForPrjectManagerUser:undefined)
    }, [])

    return (
        <Stack direction={'column'} sx={{ flexGrow: 1, marginInline: '20px' }}>
            {!loading && !error && (
                <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    sx={{ maxHeight: '50px', maxWidth: '50px' }}
                >
                    <img
                        src={
                            contract.photo ??
                            require('../../assets/images/logo.png')
                        }
                        alt="logo"
                        style={{ width: '100%' }}
                    />
                </Stack>
            )}
            {!loading && !error && (
                <Paper elevation={1} sx={styles.paper}>
                    <Typography sx={styles.title}>Contract details</Typography>
                    <Stack direction={'row'} sx={{ marginBottom: '32px' }}>
                        <Stack direction={'row'}>
                            <Typography sx={styles.labelName}>
                                contract name :
                            </Typography>
                            <Typography sx={styles.contractName}>
                                {contract.contracts[0].name}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Typography sx={styles.labelName}>
                                Description :
                            </Typography>
                            <Typography sx={styles.description}>
                                {contract.contracts[0].description}
                            </Typography>
                        </Stack>
                    </Stack>
                    <CommonTypography
                        variant={'subtitle1'}
                        sx={{ fontWeight: 700, marginBottom: '18px' }}
                    >
                        SLA
                    </CommonTypography>
                    <Grid container>
                        <Grid item xs={10}>
                            <SlaTable
                                rows={contract.contracts[0].priorities}
                                justView={true}
                            />
                        </Grid>
                    </Grid>
                    <CommonTypography
                        variant={'subtitle1'}
                        sx={{ fontWeight: 600, marginY: '32px' }}
                    >
                        Support Period
                    </CommonTypography>
                    <Stack direction={'row'}>
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                            sx={{ marginRight: '100px' }}
                        >
                            <Stack sx={{ marginRight: '8px' }}>
                                <Calender />
                            </Stack>
                            <Typography sx={styles.labelName}>
                                start date :
                            </Typography>
                            <Typography sx={styles.contractName}>
                                {contract.contracts[0].support_starting_date}
                            </Typography>
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                        >
                            <Stack sx={{ marginRight: '8px' }}>
                                <Calender />
                            </Stack>
                            <Typography sx={styles.labelName}>
                                End date :
                            </Typography>
                            <Typography sx={styles.contractName}>
                                {contract.contracts[0].support_ending_date}
                            </Typography>
                        </Stack>
                    </Stack>
                </Paper>
            )}
            <Typography sx={styles.title}>products </Typography>
            {!loading &&
                !error &&
                contract.products.map((porduct: any) => {
                    return <ProductDetails product={porduct} key={porduct.id} />
                })}
        </Stack>
    )
}

export default ContractDetails
const styles: styles = {
    paper: {
        marginTop: '50px',
        paddingLeft: '32px',
        paddingY: '30px',
        borderRadius: '4px',
        border: '0.6px solid #DCDCDC',
        backgroundColor: '#FEFEFE',
        marginBottom: '48px',
    },
    title: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginBottom: '32px',
    },
    contractName: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginInlineEnd: '15px',
    },
    description: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: '#33303B',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px',
        textTransform: 'capitalize',
        maxWidth: '500px',
    },
    labelName: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginRight: '8px',
    },
}
