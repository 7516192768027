import CommonPaper from '../../common/CommonPaper/CommonPaper'
import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import CommonButton from '../../common/CommonButton/CommonButton'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'

interface ISiteCard {
    name: string
    address: string
    timezone: string
    navigateToDetails: () => void
}

const SiteCard = ({
    name,
    address,
    timezone,
    navigateToDetails,
}: ISiteCard) => {
    return (
        <CommonPaper
            sx={{
                height: '184px',
                minHeight: '184px',
                border: '1px solid #DCDCDC',
                p: 3,
            }}
        >
            <Stack
                direction={'column'}
                sx={{ height: '100%' }}
                justifyContent={'space-between'}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Stack direction={'column'}>
                        <CommonTypography
                            variant={'h6'}
                            sx={{ fontWeight: 600 }}
                        >
                            {name}
                        </CommonTypography>
                        <CommonTypography sx={{ color: 'text.disabled' }}>
                            {address}
                        </CommonTypography>
                    </Stack>
                    <Stack direction={'row'} columnGap={1}>
                        <PlaceOutlinedIcon />
                        <CommonTypography sx={{ color: 'text.disabled' }}>
                            {timezone}
                        </CommonTypography>
                    </Stack>
                </Stack>
                <CommonButton
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={navigateToDetails}
                >
                    See Details
                </CommonButton>
            </Stack>
        </CommonPaper>
    )
}

export default SiteCard
