import React, { useEffect, useState } from 'react'
import CustomersDashboard from '../../contents/CustomersContent/CustomersDashboard/CustomersDashboard'
import {
    customersListSelector,
    getCustomersList,
} from '../../features/customersList'
// import {
//     customersTableListSelector,
//     getCustomersTableList,
// } from '../../features/customersTableList'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
// import FetchingData from '../../components/Loaders/FtechingData/FetchingData'
// import DashboardEmptyState from '../../contents/CustomersContent/DashboardEmptyState/DashboardEmptyState'
// import CustomersTable from './CustomersTable'

interface FilterCustomer {
    search: string
    contract_ends_by: string
    have_sla: string
    do_not_have_sla: string
    with_expired_contract: string
}

const Customers = () => {
    // const [searchTextTable, setSearchTextTable] = useState('')

    const { customersList, pending } = useAppSelector(customersListSelector)
    // const { customersTableList } = useAppSelector(customersTableListSelector)

    const [filterCustomerDashboard, setFilterCustomerDashboard] =
        useState<FilterCustomer>({
            search: '',
            contract_ends_by: '',
            have_sla: '',
            do_not_have_sla: '',
            with_expired_contract: '',
        })

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getCustomersList(filterCustomerDashboard))
    }, [dispatch, filterCustomerDashboard])

    // useEffect(() => {
    //     dispatch(getCustomersTableList(searchTextTable))
    // }, [dispatch, searchTextTable])

    // if (pending) {
    //     return <FetchingData />
    // }

    return (
        <>
            {/* {customersList.length === 0 && <DashboardEmptyState />}
            {customersList.length !== 0 && (
                <>
                    <CustomersDashboard
                        customersList={customersList}
                        setAdminSearch={setSearchValue}
                        adminSearchValue={searchValue}
                    />
                    <CustomersTable
                        customersList={customersList}
                        searchText={searchText}
                        setSearchText={setSearchText}
                    />
                </>
            )} */}
            <CustomersDashboard
                customersList={customersList}
                setAdminSearch={setFilterCustomerDashboard}
                adminSearchValue={filterCustomerDashboard}
                pending={pending}
            />
            {/* <CustomersTable
                customersList={customersTableList}
                searchText={searchTextTable}
                setSearchText={setSearchTextTable}
            /> */}
        </>
    )
}

export default Customers
