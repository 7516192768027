import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

interface ICommonAutocomplete {
    sx?: any
    id: string
    label: string
    optionsList: any
    defaultValue?: any
    value?: any
    setValue: any
    inputValue: any
    setInputValue: any
}

const CommonAutocomplete = ({
    sx,
    id,
    label,
    optionsList,
    defaultValue,
    value,
    setValue,
    inputValue,
    setInputValue,
}: ICommonAutocomplete) => {
    // const [value, setValue] = React.useState<string | null>(defaultValue)
    // const [inputValue, setInputValue] = React.useState('')

    React.useEffect(() => {
        if (optionsList.length > 0 && defaultValue == null) {
            setValue(optionsList[1])
            setInputValue(optionsList[1].label)
        } else if (defaultValue) {
            setValue({ label: defaultValue.name, value: defaultValue.id })
            setInputValue(defaultValue.name)
        }
    }, [])

    return (
        <Autocomplete
            value={value}
            onChange={(event: any, newValue: string | null) => {
                // console.log('newValue', newValue)
                setValue(newValue)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                // console.log('newValue', newInputValue)

                setInputValue(newInputValue)
            }}
            id={id}
            options={optionsList}
            sx={sx}
            defaultValue={defaultValue}
            renderInput={(params) => <TextField {...params} label={label} />}
        />
    )
}
export default CommonAutocomplete
