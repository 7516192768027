import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import './Priority.css'

const Priority = ({
    onPrioritySelect,
    priorityData,
    selectedPriority, // Rename to avoid naming conflict
    setSelectedPriority,
}: any) => {
    console.log('priorityData', priorityData)

    // Initialize selectedPriority as an empty array

    // Function to handle the priority selection
    const handlePrioritySelect = (priority: number) => {
        const updatedSelectedPriority = [...selectedPriority]

        // Check if the priority is already selected, if so, remove it; otherwise, add it.
        if (updatedSelectedPriority?.includes(priority)) {
            updatedSelectedPriority.splice(
                updatedSelectedPriority.indexOf(priority),
                1
            )
        } else {
            updatedSelectedPriority.push(priority)
        }

        setSelectedPriority(updatedSelectedPriority)

        // Pass the updated array to the callback function
        if (onPrioritySelect) {
            onPrioritySelect(updatedSelectedPriority)
        }
    }

    return (
        <div className="prio-container">
            <h4>Priority</h4>
            <div className="priority">
                <Grid container spacing={2}>
                    {priorityData[0]?.priorities.map((priority: any) => {
                        console.log('priority', priority)

                        return (
                            <Grid
                                item
                                key={priority.priority}
                                xs={6}
                                md={6}
                                justifyContent={'center'}
                                flexDirection={'row'}
                            >
                                <div
                                    className="priority-box"
                                    style={{
                                        backgroundColor:
                                            selectedPriority?.includes(
                                                priority.priority
                                            )
                                                ? '#808080'
                                                : 'white',
                                        color: selectedPriority?.includes(
                                            priority.priority
                                        )
                                            ? 'white'
                                            : 'black',
                                    }}
                                    onClick={() => {
                                        handlePrioritySelect(priority.priority)
                                    }}
                                >
                                    {priority.priority_name}
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </div>
    )
}

export default Priority
