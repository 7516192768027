import { Typography, type SxProps } from '@mui/material'
import { Box, Stack } from '@mui/system'
import React, { useEffect, useRef } from 'react'
interface Props {
    transition: number
    active: boolean
    field: any
    inActiveLabel?: string
    ActiveLabel?: string
    sx?: SxProps
    onchange?: any
}
const TeamLeadSwitch = ({
    transition,
    active,
    field,
    sx,
    inActiveLabel,
    ActiveLabel,
    onchange,
}: Props) => {
    const ref = useRef(null)
    const boxRef = useRef(null)
    const val: number = active ? 1 : -1

    return (
        <Box
            ref={boxRef}
            onClick={() => {
                // @ts-expect-error just try
                ref.current?.onClick()
            }}
            sx={{
                position: 'relative',
                width: '51px',
                height: '31px',
                flexShrink: 0,
                borderRadius: '24.4px',
                background: active ? '#47A5A6' : '#FF6370',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                ...sx,
            }}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{ minHeight: "'43.2px" }}
            >
                <Typography
                    sx={{
                        color: '#F1F2F4',
                        fontFamily: 'Inter',
                        fontSize: '12.8px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20.16px',
                        visibility: active ? 'visible' : 'hidden',
                        marginInline: '-3px',
                    }}
                >
                    {ActiveLabel ?? 'Active'}
                </Typography>

                <Typography
                    sx={{
                        color: '#F1F2F4',
                        fontFamily: 'Inter',
                        fontSize: '12.8px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20.16px',
                        visibility: active ? 'hidden' : 'visible',
                        marginInline: '-3px',
                    }}
                >
                    {inActiveLabel ?? 'Inactive'}
                </Typography>
            </Stack>
            <Box
                sx={{
                    zIndex: 9999,
                    position: 'absolute',
                    width: '26.4px',
                    height: '26.399px',
                    borderRadius: '26px',
                    backgroundColor: '#fff',
                    transitionDuration: '300ms',
                    transform: `translateX(${transition * val}px)`,
                    filter: 'drop-shadow(0px 4.3199687004089355px 1.439989447593689px rgba(0, 0, 0, 0.06)) drop-shadow(0px 4.3199687004089355px 11.519915580749512px rgba(0, 0, 0, 0.15))',
                }}
            ></Box>
            <input
                ref={ref}
                type="checkbox"
                style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    zIndex: -111,
                }}
                defaultChecked={field.value}
                {...field}
            />
        </Box>
    )
}

export default TeamLeadSwitch
