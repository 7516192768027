import * as React from 'react'
import SwipeableViews from 'react-swipeable-views'
import { useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import CommonTabPanel from '../common/CommonTabPanel/CommonTabPanel'
import SitesTab from '../../pages/Customers/tabs/SitesTab/SitesTab'
import ContactsTab from '../../pages/Customers/tabs/ContactsTab/ContactsTab'
import ProductsTab from '../../pages/Customers/tabs/ProductsTab/ProductsTab'
import UsersTab from '../../pages/Customers/tabs/UsersTab/UsersTab'
import { useMediaQuery } from '@mui/material'

interface ICustomerTabs {
    customerId: string
    value: number
    setValue: any
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    }
}

const CustomerTabs = ({ customerId, value, setValue }: ICustomerTabs) => {
    const theme = useTheme()
    const medium = useMediaQuery('(min-width:900px)')
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index: number) => {
        setValue(index)
    }
    return (
        <Box sx={{ width: '100%', marginTop: 4 }}>
            <AppBar
                position="static"
                sx={{
                    backgroundColor: '#fff !important',
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#47A5A6',
                    },
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                        color: '#47A5A6 !important',
                        paddingLeft: 5,
                        paddingRight: 5,
                    }}
                >
                    <Tab
                        label="Sites"
                        {...a11yProps(0)}
                        sx={{ color: '#47A5A6 !important' }}
                    />
                    <Tab
                        label="Contracts"
                        {...a11yProps(1)}
                        sx={{
                            color: '#47A5A6 !important',
                            marginLeft: medium ? '50px' : '60px',
                        }}
                    />
                    <Tab
                        label="Products"
                        {...a11yProps(2)}
                        sx={{
                            color: '#47A5A6 !important',
                            marginLeft: medium ? '50px' : '60px',
                        }}
                    />
                    <Tab
                        label="Customer Users"
                        {...a11yProps(3)}
                        sx={{
                            color: '#47A5A6 !important',
                            marginLeft: medium ? '50px' : '60px',
                        }}
                    />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <CommonTabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    sx={{
                        padding: 5,
                        marginTop: 2,
                        backgroundColor: 'common.white',
                    }}
                >
                    <SitesTab customerId={customerId} />
                </CommonTabPanel>
                <CommonTabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                    sx={{
                        padding: 5,
                        marginTop: 2,
                        backgroundColor: 'common.white',
                    }}
                >
                    <ContactsTab customerId={customerId} />
                </CommonTabPanel>
                <CommonTabPanel
                    value={value}
                    index={2}
                    dir={theme.direction}
                    sx={{
                        padding: 5,
                        marginTop: 2,
                        backgroundColor: 'common.white',
                    }}
                >
                    <ProductsTab customerId={customerId} />
                </CommonTabPanel>
                <CommonTabPanel
                    value={value}
                    index={3}
                    dir={theme.direction}
                    sx={{
                        padding: 5,
                        marginTop: 2,
                        backgroundColor: 'common.white',
                    }}
                >
                    <UsersTab customerId={customerId} />
                </CommonTabPanel>
            </SwipeableViews>
        </Box>
    )
}

export default CustomerTabs
