import { type SxProps } from '@mui/system'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

interface ICommonTimePicker {
    sx?: SxProps
    label: string
    value: any
    setValue: any
}

const CommonTimePicker = ({
    sx,
    label,
    value,
    setValue,
}: ICommonTimePicker) => {
    return (
        <TimePicker
            sx={sx}
            label={label}
            value={value}
            onChange={(newValue) => setValue(newValue)}
        />
    )
}

export default CommonTimePicker
