import CommonBorderLinearProgress from '../common/CommonBorderLinearProgress/CommonBorderLinearProgress'
import CommonTypography from '../common/CommonTypography/CommonTypography'
import Stack from '@mui/material/Stack/Stack'

interface IContractPeriodProgress {
    supporPeriod: string
    contractName: string
    progressValue: number
}

const ContractPeriodProgress = ({
    contractName,
    progressValue,
    supporPeriod,
}: IContractPeriodProgress) => {
    return (
        <Stack direction={'column'}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Stack direction={'row'} columnGap={1}>
                    <CommonTypography
                        variant={'body2'}
                        sx={{ color: 'text.disabled' }}
                    >
                        {contractName}
                    </CommonTypography>
                </Stack>
                <CommonTypography>{`${progressValue}%`}</CommonTypography>
            </Stack>
            <CommonBorderLinearProgress
                variant={'determinate'}
                value={progressValue}
            />
            <CommonTypography
                sx={{ color: 'text.disabled', marginTop: 1 }}
                align={'end'}
            >
                {supporPeriod}
            </CommonTypography>
        </Stack>
    )
}

export default ContractPeriodProgress
