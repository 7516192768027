export const GetShortName =(fullName :string)=>{

    const nameParts = fullName.split(' ')
let shortName = ''
    if (nameParts.length > 1) {
        const firstName = nameParts[0]
        const fatherName = nameParts[1]
        shortName = firstName.substring(0, 1) + fatherName.substring(0, 1)
    } else {
        shortName = fullName.substring(0, 2)
    }
    return shortName
}  