import { Card, Typography, type SxProps } from '@mui/material'
import { Stack } from '@mui/system'
import dayjs from 'dayjs'
import React from 'react'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import { ReactComponent as RedFlag } from '../../../assets/images/RedFlag.svg'
import { ReactComponent as YellowFlag } from '../../../assets/images/yellowFlag.svg'
interface styles {
    card: SxProps
    keyLabel: SxProps
    valueText: SxProps
}
dayjs.extend(customParseFormat)
dayjs.extend(duration)

const formatMinutesToTimeString = (minutes: any) => {
    const duration = dayjs.duration(minutes, 'minutes')
    const days = duration.days()
    const hours = duration.hours()
    const remainingMinutes = duration.minutes()

    let result: string = ''

    if (days > 0) {
        result += days.toString() + (days === 1 ? ' day' : ' days')
        if (hours > 0 || remainingMinutes > 0) {
            result += ', '
        }
    }

    if (hours > 0) {
        result += hours.toString() + (hours === 1 ? ' hour' : ' hours')
        if (remainingMinutes > 0) {
            result += ', '
        }
    }

    if (remainingMinutes > 0) {
        result +=
            remainingMinutes.toString() +
            (remainingMinutes === 1 ? ' minute' : ' minutes')
    }

    return result
}

const SLAInformationCard = ({ userSummary }: any) => {
    return (
        <Card sx={styles.card}>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>priority:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.priority.priority_name}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>response time:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.resolution_time_out &&
                    userSummary.priority.response_time
                        ? formatMinutesToTimeString(
                              userSummary.priority.response_time
                          )
                        : '-------'}

                    {userSummary.yellow_flag && (
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            sx={{ marginX: '9px' }}
                        >
                            <YellowFlag />
                        </Stack>
                    )}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>resolution time:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.resolution_time_out &&
                    userSummary.priority.resolution_time
                        ? formatMinutesToTimeString(
                              userSummary.priority.resolution_time
                          )
                        : '-------'}

                    {userSummary.red_flag && <RedFlag />}
                </Typography>
            </Stack>
        </Card>
    )
}

export default SLAInformationCard

const styles: styles = {
    card: {
        width: '360px',
        height: '157.05px',
        backgroundColor: '#FBFBFB',
        marginTop: '13px',
        paddingTop: '24px',
    },
    keyLabel: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '16.25px',
        width: '142px',
        color: '#929EAE',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
    valueText: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
}
