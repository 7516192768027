import { useState, useEffect, useCallback } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import BasicModal from '../../BasicModal/BasicModal'
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from '@mui/material'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import BasicRadio from '../../BasicRadio/BasicRadio'
// import CommonTextField from '../../common/CommonTextField/CommonTextField'
// import CommonAutocomplete from '../../common/CommonAutocomplete/CommonAutocomplete'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import { type ICustomer } from '../../../interfaces/ICustomer'
import { type IAlert } from '../../../interfaces/IAlert'
import { useAppDispatch } from '../../../app/hooks'
import { addAlert } from '../../../features/systemAlerts'
import { getCustomer } from '../../../features/customers'
import { type IContract } from '../../../interfaces/IContract'
import { type ISite } from '../../../interfaces/ISite'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useWindowSize } from 'usehooks-ts'

interface IProductCreateUpdateModal {
    open: boolean
    handleClose: () => void
    isEdit?: boolean
    customer?: ICustomer
    ProductIndex?: number | null
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}

interface FormData {
    fullName: string
    jobTitle: string
    phoneNumber: string
    email: string
    password: string
    confirmPassword: string
    role: string
    isAdmin: boolean
    isProjectManager: boolean
}

interface Product {
    id: string
    name: string
    skills: [
        {
            id: string
            skill: string
        }
    ]
}

const UserCreateUpdateModal = ({
    open,
    handleClose,
    customer,
    ProductIndex,
}: IProductCreateUpdateModal) => {
    const { data: productsData, isPending: isFetchingProdcuts } = useFetch(
        dashBoardServices.getProducts
    )
    const [isPending, setIsPending] = useState(false)
    const { width, height } = useWindowSize()

    const dispatch = useAppDispatch()
    const {
        control,
        handleSubmit,
        watch,
        reset,
        getValues,
        formState: { errors },
    } = useForm<FormData>()

    const passwordValue = watch('password') //
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            console.log(value, name, type)
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch])
    const onSubmit = (data: FormData) => {
        console.log(data) // Handle form submission here
        handleMainACtion()
    }

    const [chartDimensions, setChartDimensions] = useState(450)
    // declaring some mui media query to be used in redindering line charts.
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])

    const handleMainACtion = () => {
        const values = getValues()
        setIsPending(true)
        dashBoardServices
            .createCustomerUser(customer?.id ? customer?.id : 0, {
                user: {
                    email: values.email,
                    full_name: values.fullName,
                    jop_title: values.jobTitle,
                    phone: values.phoneNumber,
                    is_active: true,
                    password: values.password,
                    password2: values.confirmPassword,
                },
                is_admin: values.role=='isAdmin' ,
                is_project_manager: values.role == 'isProjectManager' ,
                customer: customer?.id,
            })
            .then(() => {
                handleClose()
                // @ts-expect-error number
                dispatch(getCustomer(customer?.id))
            })
            .catch((e) => {
                console.log('eeeeee', e)
                try {
                    Object.entries(e.response.data.user).map(([key, value]) => {
                        if (value instanceof Array) {
                            value.map((item, i) => {
                                const alertObj: IAlert = {
                                    id: `${key}${i}key`,
                                    message: item,
                                    state: true,
                                    type: 'error',
                                }
                                dispatch(addAlert(alertObj))
                            })
                        }
                    })
                } catch (error) {
                    const alertObj: IAlert = {
                        id: `Password`,
                        message: 'Password not vaild',
                        state: true,
                        type: 'error',
                    }
                    dispatch(addAlert(alertObj))
                }
            })
            .finally(() => {
                setIsPending(false)
            })

        console.log('values', values)
    }
    useEffect(() => {
        console.log('errors', errors)
    }, [errors])
    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            message: value?.message ?? '',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })
    const onClose = () => {
        reset()
        handleClose()
    }
    return (
        <BasicModal handleClose={onClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        maxHeight: chartDimensions,
                        overflow: 'scroll',
                        p: 3,
                    }}
                >
                    <CommonTypography sx={{ fontWeight: 600 }} variant={'h6'}>
                        New User
                    </CommonTypography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack
    direction={'row'}
    justifyContent={'end'}
    sx={{ marginTop: 2.75 }}
>
    <FormControl component="fieldset">
        <Controller
            name="role"
            control={control}
            defaultValue=""
            render={({ field }) => (
                <RadioGroup {...field} row>
                    <FormControlLabel
                        value="isProjectManager"
                        control={<Radio  />}
                        label="Project Manager"
                    />
                    <FormControlLabel
                        value="isAdmin"
                        control={<Radio />}
                        label="Admin"
                    />
                </RadioGroup>
            )}
        />
    </FormControl>
</Stack>
                        <Stack direction={'column'} rowGap={3}>
                            <Controller
                                name="fullName"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Full Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Full Name"
                                        error={!!errors.fullName}
                                        helperText={
                                            errors.fullName
                                                ? 'Full Name is required'
                                                : ''
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name="jobTitle"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Job Title"
                                    />
                                )}
                            />

                            <Controller
                                name="phoneNumber"
                                control={control}
                                defaultValue=""
                                rules={{
                                    pattern: {
                                        value: /^(\+\d{1,3}\s?)?(\d{1,4}[\s\-]?)?\d{1,14}$/i,
                                        message: 'Wrong Phone Number .',
                                    },

                                    maxLength: {
                                        value: 15,
                                        message: 'max length is 15 .',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Phone Number"
                                        error={!!errors.phoneNumber}
                                        helperText={
                                            errors.phoneNumber
                                                ? errors.phoneNumber?.message
                                                : ''
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Email is required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Email is not valid',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Email"
                                        error={!!errors.email}
                                        helperText={
                                            errors.email
                                                ? 'Invalid email address'
                                                : ''
                                        }
                                    />
                                )}
                            />
                            <Stack direction={'row'} gap={1}>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Password is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            sx={{ flex: 1 }}
                                            {...field}
                                            type="password"
                                            label="Password"
                                            error={!!errors.password}
                                            helperText={
                                                errors.password
                                                    ? 'Password is required'
                                                    : ''
                                            }
                                        />
                                    )}
                                />

                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Confirm Password required',
                                        validate: {},
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            sx={{ flex: 1 }}
                                            type="password"
                                            label="Confirm Password"
                                            error={!!errors.confirmPassword}
                                            helperText={
                                                errors.confirmPassword
                                                    ? 'Passwords do not match'
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            </Stack>
                        </Stack>
                        <Stack direction={'column'} sx={{ marginTop: 5 }}>
                            {/* <ActionNdCancel
                            actionBtnLabel="Confirm"
                            actionBtncolor="primary"
                            btnWidth={'140px'}
                            btnHeight={'40px'}
                            alignItems="center"
                            direction={'row'}
                            cancelBtnLabel="Cancel"
                            spacing={3}
                            handleAction={handleMainACtion}
                            handleCancelAction={handleClose}
                            justifyContent="end"
                            pending={isPending}
                            sx={{ marginTop: '48px' }}
                        /> */}
                            <Stack
                                sx={{ marginTop: '48px' }}
                                direction={'row'}
                                spacing={3}
                                alignItems={'center'}
                                justifyContent={'end'}
                            >
                                <Button
                                    color={'primary'}
                                    variant={'outlined'}
                                    sx={{ width: '140px', height: '40px' }}
                                    onClick={onClose}
                                >
                                    {'Cancel'}
                                </Button>
                                <LoadingButton
                                    type="submit"
                                    color="primary"
                                    variant={'contained'}
                                    sx={{
                                        width: '140px',
                                        height: '40px',
                                        boxShadow: 'none',
                                    }}
                                    // onClick={handleSubmit(onSubmit)}
                                    loading={isPending}
                                >
                                    {'Confirm'}
                                </LoadingButton>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const styles = {
    textField: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
    },
}
export default UserCreateUpdateModal
