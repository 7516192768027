import {
    Button,
    Stack,
    type SxProps,
    TextField,
    Typography,
} from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { type IAlert } from '../../interfaces/IAlert'
import { addAlert } from '../../features/systemAlerts'
import dashBoardServices from '../../services/cspServices'
interface IStyle {
    email: SxProps
    text: SxProps
    title: SxProps
}
const SuccessMail = ({ email, message }: any) => {
    return (
        <Stack
            direction={'column'}
            sx={{ width: '100%', minWidth: '358px' }}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Typography sx={styles.title}>Please Check Your Email</Typography>
            <Typography sx={styles.text}>{message}</Typography>
            <Typography sx={styles.email}>{email}</Typography>
        </Stack>
    )
}

export default SuccessMail

const styles: IStyle = {
    email: {
        color: '#47A5A6',
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    text: {
        marginTop: '24px',
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform: 'lowercase',
    },
    title: {
        marginTop: '24px',
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
    },
}
