import { createReducer } from '@reduxjs/toolkit'
import { getCustomersList } from './actions'
import type { ICustomerInList } from '../../interfaces/ICustomerInList'

interface IinitCustomersListReducer {
    customersList: ICustomerInList[]
    pending: boolean
    error: boolean
}

// games Campaings initial state
const initCustomersListReducer: IinitCustomersListReducer = {
    customersList: [],
    pending: true,
    error: false,
}

// departmentReducer reducer to handel action when dispatched
export const customersListReducer = createReducer(
    initCustomersListReducer,
    (builder) => {
        builder
            // game campaign Reducer action thunk and its stats.
            .addCase(getCustomersList.pending, (state) => {
                state.pending = true
                state.error = false
            })
            .addCase(getCustomersList.fulfilled, (state, { payload }) => {
                state.pending = false
                state.customersList = payload
            })
            .addCase(getCustomersList.rejected, (state) => {
                state.pending = false
                state.error = true
            })
    }
)

export default customersListReducer
