import CommonPaper from '../../common/CommonPaper/CommonPaper'
import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import CommonButton from '../../common/CommonButton/CommonButton'
import CommonBorderLinearProgress from '../../common/CommonBorderLinearProgress/CommonBorderLinearProgress'

interface IContractCard {
    name: string
    contractDescription: string
    supporPeriod: string
    progressValue: number
    navigateToDetails: () => void
}

const ContractCard = ({
    name,
    supporPeriod,
    progressValue,
    navigateToDetails,
    contractDescription,
}: IContractCard) => {
    return (
        <CommonPaper
            sx={{
                height: '271px',
                maxHeight: '271px',
                minHeight: '184px',
                border: '1px solid #DCDCDC',
                p: 3,
                overflow:"scroll"
            }}
        >
            <Stack
                direction={'column'}
                sx={{ height: '100%' }}
                justifyContent={'space-between'}
            >
                <Stack direction={'column'} rowGap={2}>
                    <CommonTypography variant={'h5'} sx={{ fontWeight: 600 }}>
                        {name}
                    </CommonTypography>
                    <Stack direction={'column'}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <CommonTypography
                                variant={'body2'}
                                sx={{ color: 'text.disabled' }}
                            >
                                support period
                            </CommonTypography>
                            <Stack direction={'row'} columnGap={1}>
                                <CommonTypography
                                    sx={{ color: 'text.disabled' }}
                                >
                                    {supporPeriod}
                                </CommonTypography>
                                <CommonTypography>{`${progressValue}%`}</CommonTypography>
                            </Stack>
                        </Stack>
                        <CommonBorderLinearProgress
                            variant={'determinate'}
                            value={progressValue}
                        />
                        <CommonTypography
                            variant={'body2'}
                            sx={{ color: 'text.disabled',overflow:"scroll" ,maxHeight:"120px"}}
                        >
                            {contractDescription}
                        </CommonTypography>
                    </Stack>
                </Stack>
                <CommonButton
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={navigateToDetails}
                >
                    See Details
                </CommonButton>
            </Stack>
        </CommonPaper>
    )
}

export default ContractCard
