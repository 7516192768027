import BasicModal from '../../BasicModal/BasicModal'
import Box from '@mui/material/Box/Box'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import Stack from '@mui/material/Stack/Stack'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import CommonIconButton from '../../common/CommonIconButton/CommonIconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CommonPaper from '../../common/CommonPaper/CommonPaper'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import type { SxProps } from '@mui/system'
import { IconButton, Typography } from '@mui/material'
import { ReactComponent as FileIcon } from '../../../assets/images/fileIcon.svg'
import { ReactComponent as Download } from '../../../assets/images/download.svg'
import baseURL from '../../../services/baseUrl'
import { saveAs } from 'file-saver'
import { useWindowSize } from 'usehooks-ts'
import { getToken } from '../../../services/cspServices'

interface attachment {
    id: number
    file: string
}
interface ISaveDataConfirmationModal {
    attachments?: attachment[]
    open: boolean
    handleClose: () => void

    isPending: boolean
}

interface IStyle {
    file: SxProps
    title: SxProps
    fileName: SxProps
}
const style: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '14px',
    transform: 'translate(-50%, -50%)',
    minWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
}
const AttachmentsModal = ({
    attachments,
    open,
    handleClose,

    isPending,
}: ISaveDataConfirmationModal) => {
    const { globalUrl: url } = baseURL()
    const handleMainAction = () => {}
    const { width, height } = useWindowSize()

    const handleDownload = (url: any, fileName: any) => {
        // Create a hidden anchor element
        const auth: string = getToken() ?? ''
        const headers = new Headers({
            Authorization: `Bearer ${auth}`,
        })

        fetch(url, { headers })
            .then((response) => response.blob())
            .then((blob) => {
                saveAs(blob, fileName)
            })
            .catch((error) => {
                console.error('Error downloading the file:', error)
            })
    }

    const handleDownloadAll = () => {
        attachments?.forEach((item) => {
            const parts = item.file ? item.file.split('/') : '/fileNum1'
            const lastPart = parts[parts.length - 1]
            handleDownload(`${url}${item.file}`, lastPart)
        })
    }
    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Stack direction={'column'}>
                    <Stack direction={'row'} justifyContent={'flex-start'}>
                        <Typography sx={styles.title}>Attachment</Typography>
                    </Stack>
                    {attachments &&
                        attachments.length > 0 &&
                        attachments.map((item) => {
                            const parts = item.file
                                ? item.file.split('/')
                                : '/fileNum1'
                            const lastPart = parts[parts.length - 1]
                            return (
                                <Stack
                                    key={item.file}
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                    alignItems={'center'}
                                    sx={styles.file}
                                >
                                    <Stack direction={'row'}>
                                        <Stack sx={{ marginX: '12px' }}>
                                            <FileIcon />
                                        </Stack>
                                        <Typography sx={styles.fileName}>
                                            {lastPart}
                                        </Typography>
                                    </Stack>
                                    <IconButton
                                        onClick={() => {
                                            handleDownload(
                                                `${url}${item.file}`,
                                                lastPart
                                            )
                                        }}
                                    >
                                        <Download />
                                    </IconButton>
                                </Stack>
                            )
                        })}
                    <ActionNdCancel
                        sx={{ marginTop: '48px' }}
                        actionBtnLabel="Download All "
                        actionBtncolor="primary"
                        alignItems="center"
                        cancelBtnLabel="Cancel"
                        direction={'row'}
                        justifyContent="end"
                        spacing={4}
                        btnWidth={'160px'}
                        btnHeight={'48px'}
                        handleCancelAction={handleClose}
                        handleAction={handleDownloadAll}
                        pending={isPending}
                    />
                </Stack>
            </Box>
        </BasicModal>
    )
}

export default AttachmentsModal
const styles: IStyle = {
    file: {
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        marginBottom: '32px',
    },
    title: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '22px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginBottom: '48px',
    },
    fileName: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',
        opacity: 0.8999999761581421,
    },
}
