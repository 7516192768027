import { useEffect, useState, useRef } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import Box from '@mui/material/Box'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import UploadPhoto from '../../UploadPhoto/UploadPhoto'
import { Grid, Stack, Typography, type SxProps } from '@mui/material'
import CommonTextField from '../../common/CommonTextField/CommonTextField'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import useMediaQuery from '@mui/material/useMediaQuery'
import cspService from '../../../services/cspServices'
import { customerSelector, getCustomer } from '../../../features/customers'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import FetchingData from '../../Loaders/FtechingData/FetchingData'
import { addAlert } from '../../../features/systemAlerts'
import type { IAlert } from '../../../interfaces/IAlert'
import { useNavigate } from 'react-router-dom'
import validateMobileNum from '../../../helper/validateMobileNum'
import validateEmail from '../../../helper/validateEmail'
import { useWindowSize } from 'usehooks-ts'
import { fetchImage } from '../../../helper/functions'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}

interface ICustomerCreateUpdateModal {
    open: boolean
    handleClose: () => void
    isEdit?: boolean
    customerId?: string
}

const CustomerCreateUpdateModal = ({
    handleClose,
    open,
    isEdit,
    customerId,
}: ICustomerCreateUpdateModal) => {
    // declaring use navigate to a varibale to use it when navbar item clicked.
    const navigate = useNavigate()
    const [isPending, setIsPending] = useState(false)
    const dispatch = useAppDispatch()
    const [img, setImg] = useState<any>('')
    const { width, height } = useWindowSize()
    const { customer, pending } = useAppSelector(customerSelector)
    // console.log('customer', customer)

    const handleMainAction = () => {
        setIsPending(true)
        const emailNotification = document.getElementById(
            'Email-notification'
        ) as HTMLInputElement
        // console.log('emailNotification', emailNotification.checked)
        const customerName = document.getElementById(
            'customer_name'
        ) as HTMLInputElement
        const industry = document.getElementById('industry') as HTMLInputElement
        const address = document.getElementById('address') as HTMLInputElement
        const contactNumber = document.getElementById(
            'contact_number'
        ) as HTMLInputElement
        const contactEmail = document.getElementById(
            'contact_email'
        ) as HTMLInputElement
        const description = document.getElementById(
            'description'
        ) as HTMLInputElement
        const uploadPhotoInput = document.getElementById(
            'uploadPhotoInput'
        ) as HTMLInputElement

        if (
            customerName.value.trim() === '' ||
            customerName.value === null ||
            customerName.value === undefined
        ) {
            const alertObj: IAlert = {
                id: 'customerNameError',
                message: 'Customer name is must',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        } else if (
            contactNumber.value !== '' &&
            !validateMobileNum(contactNumber.value)
        ) {
            const alertObj: IAlert = {
                id: 'customerContactNumberError',
                message: 'Contact number must be numbers only',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        } else if (
            contactEmail.value !== '' &&
            !validateEmail(contactEmail.value)
        ) {
            const alertObj: IAlert = {
                id: 'customerContactEmailError',
                message: 'Please enter a valid email',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        } else if (isEdit && customerId) {
            if (uploadPhotoInput.files) {
                if (uploadPhotoInput.files[0]) {
                    const inputsObj: any = {
                        name: customerName.value,
                        email_notification: emailNotification.checked,
                        industry: industry.value,
                        address: address.value,
                        contact_number: contactNumber.value,
                        contact_email: contactEmail.value,
                        description: description.value,
                        photo: uploadPhotoInput.files[0],
                    }

                    const raw = new FormData()
                    for (const key of Object.keys(inputsObj)) {
                        raw.append(key, inputsObj[key])
                    }

                    cspService
                        .updateCustomer(customerId, raw)
                        .then((res) => {
                            if (res.status === 200) {
                                setIsPending(false)
                                dispatch(getCustomer(customerId))
                                handleClose()
                            }
                        })
                        .catch((err) => {
                            setIsPending(false)
                            console.log(err)
                        })
                } else {
                    const inputsObj: any = {
                        name: customerName.value,
                        email_notification: emailNotification.checked,
                        industry: industry.value,
                        address: address.value,
                        contact_number: contactNumber.value,
                        contact_email: contactEmail.value,
                        description: description.value,
                    }

                    const raw = new FormData()
                    for (const key of Object.keys(inputsObj)) {
                        raw.append(key, inputsObj[key])
                    }

                    cspService
                        .updateCustomer(customerId, raw)
                        .then((res) => {
                            if (res.status === 200) {
                                setIsPending(false)
                                dispatch(getCustomer(customerId))
                                handleClose()
                            }
                        })
                        .catch((err) => {
                            setIsPending(false)
                            console.log(err)
                        })
                }
            }
        } else {
            if (uploadPhotoInput.files) {
                if (uploadPhotoInput.files[0]) {
                    const inputsObj: any = {
                        name: customerName.value,
                        email_notification: emailNotification.checked,
                        industry: industry.value,
                        address: address.value,
                        contact_number: contactNumber.value,
                        contact_email: contactEmail.value,
                        description: description.value,
                        photo: uploadPhotoInput.files[0],
                    }

                    const raw = new FormData()
                    for (const key of Object.keys(inputsObj)) {
                        raw.append(key, inputsObj[key])
                    }

                    cspService
                        .createNewCustomer(raw)
                        .then((res) => {
                            if (res.status === 201) {
                                setIsPending(false)
                                navigate(`/customers/${res.data.id as number}`)
                            }
                        })
                        .catch((err) => {
                            setIsPending(false)
                            console.log(err)
                        })
                } else {
                    const inputsObj: any = {
                        name: customerName.value,
                        industry: industry.value,
                        email_notification: emailNotification.checked,

                        address: address.value,
                        contact_number: contactNumber.value,
                        contact_email: contactEmail.value,
                        description: description.value,
                    }

                    const raw = new FormData()
                    for (const key of Object.keys(inputsObj)) {
                        raw.append(key, inputsObj[key])
                    }

                    cspService
                        .createNewCustomer(raw)
                        .then((res) => {
                            if (res.status === 201) {
                                setIsPending(false)
                                navigate(`/customers/${res.data.id as number}`)
                            }
                        })
                        .catch((err) => {
                            setIsPending(false)
                            console.log(err)
                        })
                }
            }
        }
    }
    // decalring a logical state to be used in responsive mui query.
    const [chartDimensions, setChartDimensions] = useState(450)
    // declaring some mui media query to be used in redindering line charts.
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])
    // useEffect(() => {
    //     console.log('customer', customer)
    //     fetchImage(customer).then((res) => {
    //         console.log('data', res?.base64)
    //         setImg(res?.base64)
    //     })
    // }, [customer])

    if (isEdit && pending) {
        return <FetchingData />
    }

    return (
        <>
            <BasicModal handleClose={handleClose} open={open}>
                <Box
                    sx={{
                        ...style,
                        width: (() => {
                            if (width <= 768) return width
                            return 932
                        })(),
                    }}
                >
                    <Box
                        sx={{
                            maxHeight: chartDimensions,
                            overflow: 'auto',
                            padding: 3,
                        }}
                    >
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Box>
                                <CommonTypography
                                    sx={{ fontSize: '22px', fontWeight: 600 }}
                                >
                                    Customer information
                                </CommonTypography>
                                <Box sx={{ marginTop: '5%' }}>
                                    <UploadPhoto
                                        isEdit={isEdit}
                                        defaultImage={
                                            isEdit ? customer.photo : ''
                                        }
                                    />
                                </Box>
                            </Box>
                            <Stack
                                direction={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Inter',
                                        fontSize: '16px',
                                        fontWeight: 600,
                                        marginBottom: '5%',
                                    }}
                                >
                                    Outgoing emails
                                </Typography>
                                <MySwitch
                                    active={true}
                                    transition={15}
                                    value={
                                        isEdit
                                            ? customer.email_notification
                                            : true
                                    }
                                />
                            </Stack>
                        </Stack>
                        <Grid container spacing={2} sx={{ marginTop: '32px' }}>
                            <Grid item xs={6}>
                                <CommonTextField
                                    id="customer_name"
                                    label="Customer Name*"
                                    variant="outlined"
                                    key={'customer_name'}
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    defaultValue={isEdit ? customer.name : ''}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CommonTextField
                                    id="industry"
                                    label="Industry"
                                    variant="outlined"
                                    key={'industry'}
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    defaultValue={
                                        isEdit ? customer.industry : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '24px' }}>
                            <Grid item xs={6}>
                                <CommonTextField
                                    id="address"
                                    label="Address"
                                    variant="outlined"
                                    key={'address'}
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    defaultValue={
                                        isEdit ? customer.address : ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CommonTextField
                                    id="contact_number"
                                    label="Contact Number"
                                    variant="outlined"
                                    key={'contact_number'}
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    defaultValue={
                                        isEdit ? customer.contact_number : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '24px' }}>
                            <Grid item xs={12}>
                                <CommonTextField
                                    id="contact_email"
                                    label="Contact Email"
                                    variant="outlined"
                                    key={'contact_email'}
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    defaultValue={
                                        isEdit ? customer.contact_email : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} sx={{ marginTop: '24px' }}>
                            <Grid item xs={12}>
                                <CommonTextField
                                    id="description"
                                    label="Description"
                                    variant="outlined"
                                    key={'description'}
                                    sx={{
                                        backgroundColor: '#F9F9F9',
                                        width: '100%',
                                    }}
                                    multiline={true}
                                    rows={4}
                                    defaultValue={
                                        isEdit ? customer.description : ''
                                    }
                                />
                            </Grid>
                        </Grid>
                        <ActionNdCancel
                            sx={{ marginTop: '48px' }}
                            actionBtnLabel="Confirm"
                            actionBtncolor="primary"
                            alignItems="center"
                            cancelBtnLabel="Cancel"
                            direction={'row'}
                            justifyContent="end"
                            spacing={4}
                            btnWidth={'146px'}
                            btnHeight={'40px'}
                            handleCancelAction={handleClose}
                            handleAction={handleMainAction}
                            pending={isPending}
                        />
                    </Box>
                </Box>
            </BasicModal>
        </>
    )
}

export default CustomerCreateUpdateModal

interface Props {
    transition: number
    active: boolean
    value: any
    inActiveLabel?: string
    ActiveLabel?: string
    sx?: SxProps
}
const MySwitch = ({
    transition,
    active,
    value,
    sx,
    inActiveLabel,
    ActiveLabel,
}: Props) => {
    const ref = useRef(null)
    const boxRef = useRef(null)
    const [checkValue, setCheckValue] = useState<boolean>(value)
    const val: number = checkValue ? 1 : -1

    return (
        <Box
            ref={boxRef}
            onClick={() => {
                // @ts-expect-error sasas
                ref.current?.click()
            }}
            sx={{
                position: 'relative',
                width: '60.2px',
                height: '35.2px',
                flexShrink: 0,
                borderRadius: '34.4px',
                background: checkValue ? '#47A5A6' : '#cdcdcd',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ...sx,
            }}
        >
            <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={checkValue ? 'flex-start' : 'flex-end'}
                sx={{ flex: 1, minHeight: "'43.2px" }}
            >
                <Typography
                    sx={{
                        color: '#F1F2F4',
                        fontFamily: 'Inter',
                        fontSize: '12.8px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20.16px',
                        marginInline: '5px',
                        visibility: checkValue ? 'visible' : 'hidden',
                    }}
                >
                    {checkValue ? 'ON' : ''}
                </Typography>

                <Typography
                    sx={{
                        color: '#FFFFFF',
                        fontFamily: 'Inter',
                        fontSize: '12.8px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        marginInline: '3px',
                        lineHeight: '20.16px',
                        visibility: checkValue ? 'hidden' : 'visible',
                    }}
                >
                    {!checkValue ? 'OFF' : ''}
                </Typography>
            </Stack>
            <Box
                sx={{
                    zIndex: 9999,
                    position: 'absolute',
                    width: '30px',
                    height: '30px',
                    borderRadius: '38px',
                    backgroundColor: '#fff',
                    transitionDuration: '300ms',
                    transform: `translateX(${transition * val}px)`,
                    filter: 'drop-shadow(0px 4.3199687004089355px 1.439989447593689px rgba(0, 0, 0, 0.06)) drop-shadow(0px 4.3199687004089355px 11.519915580749512px rgba(0, 0, 0, 0.15))',
                }}
            ></Box>
            <input
                ref={ref}
                id="Email-notification"
                type="checkbox"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCheckValue((val) => !val)
                }}
                style={{
                    visibility: 'hidden',
                    position: 'absolute',
                    zIndex: -111,
                }}
                checked={checkValue}
            />
        </Box>
    )
}
