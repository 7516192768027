import Stack from '@mui/material/Stack'
import RectFrameLogo from '../RectFrameLogo/RectFrameLogo'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import CommonTypography from '../common/CommonTypography/CommonTypography'

interface IUserInfoHeader {
    photo?: any
    name?: string
    address?: string
    industry?: string
}

const UserInfoHeader = ({
    photo,
    name,
    address,
    industry,
}: IUserInfoHeader) => {
    return (
        <Stack direction={'row'} spacing={2}>
            <RectFrameLogo
                src={photo}
                alt="customer logo"
                width={64}
                height={64}
            />
            <Stack direction={'column'}>
                <CommonTypography
                    gutterBottom
                    sx={{
                        color: 'text.primary',
                        textTransform: 'capitalize',
                        fontSize: '1.375rem',
                        fontWeight: 600,
                    }}
                >
                    {name}
                </CommonTypography>
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <CommonTypography
                        variant={'h6'}
                        sx={{
                            color: 'text.secondary',
                            textTransform: 'capitalize',
                            alignItems: 'center',
                            fontWeight: 400,
                        }}
                    >
                        {industry}{' '}
                    </CommonTypography>{' '}
                    {industry && (
                        <FiberManualRecordIcon sx={{ fontSize: '5px' }} />
                    )}{' '}
                    <CommonTypography
                        variant={'h6'}
                        sx={{
                            color: 'text.secondary',
                            textTransform: 'capitalize',
                            alignItems: 'center',
                            fontWeight: 400,
                        }}
                    >
                        {address}
                    </CommonTypography>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default UserInfoHeader
