import { styled } from '@mui/material/styles'
import LinearProgress, {
    linearProgressClasses,
} from '@mui/material/LinearProgress'

interface ICommonBorderLinearProgress {
    variant?: any
    value: number
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 4,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 2,
        backgroundColor:
            theme.palette.mode === 'light'
                ? 'rgba(43, 76, 160, 0.7)'
                : '#308fe8',
    },
}))

const CommonBorderLinearProgress = ({
    variant,
    value,
}: ICommonBorderLinearProgress) => {
    return (
        <BorderLinearProgress
            variant={variant}
            sx={{
                [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 2,
                    backgroundColor:
                        value > 70 ? 'red' : 'rgba(43, 76, 160, 0.7)',
                },
            }}
            value={value}
        />
    )
}

export default CommonBorderLinearProgress
