import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import { type Control, Controller } from 'react-hook-form'
interface props {
    control: any
    items: any
}
const FilterItem = ({ control, items }: props) => {
    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
        }
    return (
        <Accordion
            disableGutters
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
        >
            <AccordionSummary
                sx={{
                    height: '48px',
                    flexShrink: 0,
                    borderRadius: '4px',
                    border: '1px solid #F2F2F2',
                    background: '#F9F9F9',
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    products
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <FormGroup>
                    {items.map((item: any, i: number) => {
                        const name: string = item.product.name ?? ''
                        const id: string = item.product.id ?? ''

                        return (
                            <Controller
                                key={item.product.id}
                                name={`${name}productID${id}`}
                                control={control}
                                render={(field) => {
                                    // console.log('field', field)
                                    return (
                                        <FormControlLabel
                                            key={item.product.id}
                                            control={
                                                <Checkbox
                                                    value={item}
                                                    checked={
                                                        field.field.value ||
                                                        false
                                                    }
                                                    onChange={(e) => {
                                                        field.field.onChange(
                                                            e.target.checked
                                                        )
                                                    }}
                                                />
                                            }
                                            label={item.product.name}
                                        />
                                    )
                                }}
                            />
                        )
                    })}
                </FormGroup>
            </AccordionDetails>
        </Accordion>
    )
}

export default FilterItem
