import React, { useState, useEffect, useCallback } from 'react'
import {
    Box,
    Button,
    FormControlLabel,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material'
import BasicModal from '../../BasicModal/BasicModal'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import { useForm, Controller } from 'react-hook-form'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import Select from 'react-select'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'

import type { IAlert } from '../../../interfaces/IAlert'
import { TimePicker } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab'
import { addAlert } from '../../../features/systemAlerts'
import { useAppDispatch } from '../../../app/hooks'
import CommonTimePicker from '../../common/CommonTimePicker/CommonTimePicker'
import dayjs from 'dayjs'
import { useWindowSize } from 'usehooks-ts'

interface props {
    open: boolean
    handleClose: () => void
    setRefetch: () => void
    handleAddShift: (str1: string, str2: string) => void
    handleEditShift: (str1: string, str2: string) => void
    editObject?: any
}

const AddShift = ({
    open,
    handleClose,
    setRefetch,
    handleAddShift,
    editObject,
    handleEditShift,
}: props) => {
    const dispatch = useAppDispatch()
    const { width, height } = useWindowSize()

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm()
    // @ts-expect-error asd sadd a sd
    const onSubmit = (data) => {
        // console.log('data', data.start_time.toDate())

        const start = dayjs(data.start_time).format('HH:mm:ss')
        const end = dayjs(data.end_time).format('HH:mm:ss')
        if (editObject) {
            handleEditShift(start, end)
        } else {
            // console.log('addddddd')
            handleAddShift(start, end)
        }
    }
    const validateEndTime = (start: any, end: any) => {
        return dayjs(end).isAfter(dayjs(start))
    }
    useEffect(() => {
        // console.log('errors', errors)
    }, [errors])

    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            // @ts-expect-error no error here
            message: value?.message ?? '',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })
    useEffect(() => {
        if (editObject) {
            setValue('start_time', dayjs(editObject.event.start))

            setValue('end_time', dayjs(editObject.event.end))
        }
    }, [editObject])

    const closeModal = () => {
        reset()
        handleClose()
    }
    return (
        <BasicModal open={open} handleClose={closeModal}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        overflow: 'auto',
                        paddingInline: 3,
                        maxHeight: '700px',
                    }}
                >
                    <CommonTypography
                        sx={{
                            fontWeight: 600,
                            color: '#000',
                            fontSize: 22,
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            marginY: '50px',
                        }}
                        variant={'h6'}
                    >
                        Add Shift
                    </CommonTypography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction={'column'} rowGap={3}>
                            <Controller
                                name="start_time"
                                control={control}
                                rules={{ required: 'start time is required' }}
                                render={({ field }) => (
                                    <TimePicker
                                        label={'Start Time'}
                                        {...field}
                                    />
                                )}
                            />

                            <Controller
                                name="end_time"
                                control={control}
                                rules={{
                                    required: 'end time is required',
                                    validate: {
                                        afterStart: (value) =>
                                            validateEndTime(
                                                watch('start_time'),
                                                value
                                            ) ||
                                            'End time must be after start time',
                                    },
                                }}
                                render={({ field }) => (
                                    <TimePicker label="End Time" {...field} />
                                )}
                            />
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Stack direction={'column'}></Stack>
                            <Stack
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Stack
                                    sx={{ marginTop: '48px' }}
                                    direction={'row'}
                                    spacing={3}
                                    alignItems={'center'}
                                    justifyContent={'end'}
                                >
                                    <Button
                                        color={'primary'}
                                        variant={'outlined'}
                                        sx={{ width: '140px', height: '40px' }}
                                        onClick={closeModal}
                                    >
                                        {'Cancel'}
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        color="primary"
                                        variant={'contained'}
                                        sx={{
                                            width: '140px',
                                            height: '40px',
                                            boxShadow: 'none',
                                        }}
                                        // onClick={handleSubmit(onSubmit)}
                                        loading={false}
                                    >
                                        Confirm
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '770px',
    p: 2,
}

const styles = {
    rateButton: {
        borderRadius: '4px',
        border: '0.4px solid #A8A8A8',
        minWidth: '20.94px',
        maxWidth: '20.94px',
        height: '20px',
        marginInline: '4px',
        color: '#494454',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#47A5A6',
            color: '#FFF',
            border: '0.4px solid #A8A8A8',
        },
    },
    textField: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
    },
}

export default AddShift
