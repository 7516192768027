import Stack from '@mui/material/Stack'
import CommonLetterAvatar from '../common/CommonLetterAvatar/CommonLetterAvatar'
import CommonTypography from '../common/CommonTypography/CommonTypography'
import CommonIconButton from '../common/CommonIconButton/CommonIconButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Paper from '@mui/material/Paper'
import {
    Avatar,
    Button,
    Divider,
    ListItemIcon,
    Menu,
    MenuItem,
} from '@mui/material'
import { Logout, PersonAdd, Settings } from '@mui/icons-material'
import React, { useState, useRef, useEffect } from 'react'
import ProfileLayout from '../common/ProfileWraper/ProfileLayout'
import UserPassword from '../UserPassword/UserPassword'
import PasswordChange from '../UserPassword/PasswordChange'
import { useAppSelector } from '../../app/hooks'
import { selectUserReducer } from '../../features/user'
import { GetShortName } from '../../helper/shortName'
const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const { user } = useAppSelector(selectUserReducer)
    const [show, setShow] = useState('hidden')
    const [changePassword, setChangePassword] = useState(false)
    const handleClick = () => {
        if (show == 'hidden') {
            setShow('visible')
        } else {
            setShow('hidden')
        }
    }
    const profileRef = useRef<HTMLDivElement | null>(null)

    const handleOutsideClick = (event: any) => {
        if (
            profileRef.current &&
            profileRef?.current?.contains(event.target as Node)
        ) {
            console.log(' ')
        } else {
            setShow('hidden')
        }
    }

    useEffect(() => {
        window.addEventListener('mousedown', handleOutsideClick)

        return () => {
            window.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [])

    return (
        <>
            <Paper
                sx={{
                    backgroundColor: '#fff',
                    maxWidth: '350px',
                    width: 'fit-content',
                    borderRadius: '100px',
                    padding: '8px',
                    position: 'absolute',
                    right: '32px',
                    marginTop: '16px',
                }}
            >
                <Stack direction={'row'} alignItems={'center'} spacing={3}>
                    <CommonLetterAvatar sx={{ backgroundColor: '#D5D3EC' }}>
                        {GetShortName(user.full_name)}
                    </CommonLetterAvatar>
                    <CommonTypography variant={'button'} gutterBottom>
                        {user.full_name}
                    </CommonTypography>
                    <CommonIconButton onClick={handleClick}>
                        <ArrowDropDownIcon />
                    </CommonIconButton>
                </Stack>
            </Paper>
            <ProfileLayout
                ref={profileRef}
                sx={{
                    visibility: show,
                    border: '1px solid #D7DBE1',
                    zIndex: 99999,
                }}
            >
                {!changePassword && (
                    <UserPassword
                        setChangePassword={() => {
                            setChangePassword(!changePassword)
                        }}
                        sx={{ zIndex: 5 }}
                        show={show == 'visible'}
                    />
                )}
                {changePassword && (
                    <PasswordChange
                        setChangePassword={() => {
                            setChangePassword(!changePassword)
                        }}
                        sx={{
                            paddingInline: '20px',
                        }}
                    />
                )}
            </ProfileLayout>
        </>
    )
}

export default Header
