import { useEffect, useState } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import CommonCheckbox from '../../common/CommonCheckbox/CommonCheckbox'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import Box from '@mui/material/Box/Box'
import Stack from '@mui/material/Stack/Stack'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import CommonTextField from '../../common/CommonTextField/CommonTextField'
import Grid from '@mui/material/Grid/Grid'
import useMediaQuery from '@mui/material/useMediaQuery'
import type { IPriority } from '../../../interfaces/IPriority'
import type { IAlert } from '../../../interfaces/IAlert'
import { addAlert } from '../../../features/systemAlerts'
import { useAppDispatch } from '../../../app/hooks'
import { TextField } from '@mui/material'
import { useWindowSize } from 'usehooks-ts'

interface IEditPriorityModal {
    open: boolean
    handleClose: () => void
    isPending: boolean
    priorityId: number
    id: number
    priorityName: string
    priorityDescription: string
    isApplicable: boolean
    responseDays: number
    responseHours: number
    responseMinutes: number
    resolutionDays: number
    resolutionHours: number
    resolutionMinutes: number
    handleMainAction: (priortyObj: IPriority) => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}

const EditPriorityModal = ({
    open,
    handleClose,
    isPending,
    id,
    priorityId,
    priorityName,
    priorityDescription,
    isApplicable,
    responseDays,
    responseHours,
    responseMinutes,
    resolutionDays,
    resolutionHours,
    resolutionMinutes,
    handleMainAction,
}: IEditPriorityModal) => {
    const [isSlaApplicable, setIsSlaApplicable] = useState(isApplicable)

    const dispatch = useAppDispatch()
    const { width, height } = useWindowSize()

    const handleUpdateDefaultPriority = () => {
        const errors = {
            name: false,
            ResponseTime: false,
            ResolutionTime: false,
        }
        const priorityNameInput = document.getElementById(
            'priorityName'
        ) as HTMLInputElement
        const priorityDescriptionInput = document.getElementById(
            'priority_description'
        ) as HTMLInputElement
        const responseDaysInput = document.getElementById(
            'responseDays'
        ) as HTMLInputElement
        const responseHoursInput = document.getElementById(
            'responseHours'
        ) as HTMLInputElement
        const responseMinutesInput = document.getElementById(
            'responseMinutes'
        ) as HTMLInputElement
        const resolutionDaysInput = document.getElementById(
            'resolutionDays'
        ) as HTMLInputElement
        const resolutionHoursInput = document.getElementById(
            'resolutionHours'
        ) as HTMLInputElement
        const resolutionMinutesInput = document.getElementById(
            'resolutionMinutes'
        ) as HTMLInputElement

        const hanldeCalcResponseTime = () => {
            if (
                responseDaysInput &&
                responseDaysInput.value != '' &&
                responseHoursInput &&
                responseHoursInput.value != '' &&
                responseMinutesInput &&
                responseMinutesInput.value != ''
            ) {
                return (
                    parseInt(responseDaysInput.value) * 1440 +
                    parseInt(responseHoursInput.value) * 60 +
                    parseInt(responseMinutesInput.value)
                )
            } else {
                return 0
            }
        }

        const hanldeCalcResolutionTime = () => {
            if (
                resolutionDaysInput &&
                resolutionDaysInput.value != '' &&
                resolutionHoursInput &&
                resolutionHoursInput.value != '' &&
                resolutionMinutesInput &&
                resolutionMinutesInput.value != ''
            ) {
                return (
                    parseInt(resolutionDaysInput.value) * 1440 +
                    parseInt(resolutionHoursInput.value) * 60 +
                    parseInt(resolutionMinutesInput.value)
                )
            } else {
                return 0
            }
        }
        // console.log('priorityNameInput', priorityNameInput.value)
        if (priorityNameInput.value == '') {
            const alertObj: IAlert = {
                id: 'customerPriorityError',
                message: 'Priority Name Is Requiered',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            errors.name = true
        }
        if (
            responseDaysInput?.value == '' ||
            responseHoursInput?.value == '' ||
            responseMinutesInput?.value == ''
        ) {
            const alertObj: IAlert = {
                id: 'customerPriorityError',
                message: 'Fields can not be empty',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            errors.ResponseTime = true
        }
        if (
            resolutionDaysInput?.value == '' ||
            resolutionHoursInput?.value == '' ||
            resolutionMinutesInput?.value == ''
        ) {
            const alertObj: IAlert = {
                id: 'customerPriorityError',
                message: 'Fields can not be empty',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            errors.ResolutionTime = true
        }

        if (!errors.name && !errors.ResolutionTime && !errors.ResponseTime) {
            if (isSlaApplicable) {
                if (hanldeCalcResolutionTime() <= hanldeCalcResponseTime()) {
                    const alertObj: IAlert = {
                        id: 'customerPriorityError',
                        message: 'Resolution time have to be > Response time',
                        state: true,
                        type: 'error',
                    }
                    dispatch(addAlert(alertObj))
                } else {
                    const priorityObj: IPriority = {
                        id,
                        name: priorityNameInput.value,
                        priority_name: priorityNameInput.value,
                        response_time: hanldeCalcResponseTime(),
                        resolution_time: hanldeCalcResolutionTime(),
                        is_applicable: isSlaApplicable,
                        priority: priorityId,
                        priority_description: priorityDescriptionInput.value,
                    }
                    handleMainAction(priorityObj)
                    handleClose()
                }
            } else {
                const priorityObj: IPriority = {
                    id,
                    name: priorityNameInput.value,
                    priority_name: priorityNameInput.value,
                    response_time: 0,
                    resolution_time: 0,
                    is_applicable: isSlaApplicable,
                    priority: priorityId,
                    priority_description: priorityDescriptionInput.value,
                }
                handleMainAction(priorityObj)
                handleClose()
            }
        }
    }
    // decalring a logical state to be used in responsive mui query.
    const [chartDimensions, setChartDimensions] = useState(450)
    // declaring some mui media query to be used in redindering line charts.
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(746)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])

    useEffect(() => {
        setIsSlaApplicable(isApplicable)
    }, [isApplicable, priorityName])
    const handleNegativeValues = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.keyCode === 109 || e.keyCode === 189) {
            e.preventDefault()

            const alertObj: IAlert = {
                id: 'ResponseTimeNegativeError',
                message: "Time can't be negative value",
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
        }
    }

    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        maxHeight: chartDimensions,
                        overflow: 'auto',
                        padding: 3,
                    }}
                >
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <CommonTypography
                            sx={{ fontWeight: 600 }}
                            variant={'h5'}
                            gutterBottom
                        >
                            Priority Details
                        </CommonTypography>
                        <Stack
                            direction={'row'}
                            columnGap={1}
                            alignItems={'center'}
                        >
                            <CommonCheckbox
                                sx={{ marginRight: 0 }}
                                checked={!isSlaApplicable}
                                onChange={(e) => {
                                    // console.log(!isSlaApplicable)
                                    setIsSlaApplicable(!e.target.checked)
                                }}
                            />
                            <CommonTypography
                                variant={'h6'}
                                sx={{ fontWeight: 500 }}
                            >
                                Not Applicable
                            </CommonTypography>
                        </Stack>
                    </Stack>
                    <Stack
                        direction={'column'}
                        rowGap={3}
                        sx={{ marginTop: 5 }}
                    >
                        <TextField
                            id="priorityName"
                            label="Priority Name*"
                            variant="outlined"
                            key={'priorityName'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            defaultValue={priorityName}
                            disabled={true}
                        />
                        <TextField
                            id="priority_description"
                            label="Description"
                            variant="outlined"
                            key={'priority_description'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            multiline={true}
                            rows={4}
                            defaultValue={priorityDescription}
                        />
                        {isSlaApplicable && (
                            <>
                                <CommonTypography
                                    sx={{ fontWeight: 400 }}
                                    variant={'body2'}
                                >
                                    Response Time*
                                </CommonTypography>
                                <Grid
                                    container
                                    spacing={1}
                                    direction={'row'}
                                    sx={{ width: '100%' }}
                                >
                                    <Grid item xs={4}>
                                        <TextField
                                            onKeyDown={handleNegativeValues}
                                            id="responseDays"
                                            label="Days"
                                            variant="outlined"
                                            key={'responseDays'}
                                            sx={{
                                                backgroundColor: '#F9F9F9',
                                                width: '100%',
                                            }}
                                            type={'number'}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                min: 0,
                                            }}
                                            defaultValue={responseDays}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            onKeyDown={handleNegativeValues}
                                            id="responseHours"
                                            label="Hours"
                                            variant="outlined"
                                            key={'responseHours'}
                                            sx={{
                                                backgroundColor: '#F9F9F9',
                                                width: '100%',
                                            }}
                                            type={'number'}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                min: 0,
                                                max: 23,
                                            }}
                                            defaultValue={responseHours}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            onKeyDown={handleNegativeValues}
                                            id="responseMinutes"
                                            label="Minutes"
                                            variant="outlined"
                                            key={'responseMinutes'}
                                            sx={{
                                                backgroundColor: '#F9F9F9',
                                                width: '100%',
                                            }}
                                            type={'number'}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '^[1-9]+[0-9]*$',
                                                min: 0,
                                                max: 59,
                                            }}
                                            defaultValue={responseMinutes}
                                        />
                                    </Grid>
                                </Grid>
                                <CommonTypography
                                    sx={{ fontWeight: 400 }}
                                    variant={'body2'}
                                >
                                    Resolution Time*
                                </CommonTypography>
                                <Grid
                                    container
                                    spacing={1}
                                    direction={'row'}
                                    sx={{ width: '100%' }}
                                >
                                    <Grid item xs={4}>
                                        <TextField
                                            onKeyDown={handleNegativeValues}
                                            id="resolutionDays"
                                            label="Days"
                                            variant="outlined"
                                            key={'resolutionDays'}
                                            sx={{
                                                backgroundColor: '#F9F9F9',
                                                width: '100%',
                                            }}
                                            type={'number'}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                min: 0,
                                            }}
                                            defaultValue={resolutionDays}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            onKeyDown={handleNegativeValues}
                                            id="resolutionHours"
                                            label="Hours"
                                            variant="outlined"
                                            key={'resolutionHours'}
                                            sx={{
                                                backgroundColor: '#F9F9F9',
                                                width: '100%',
                                            }}
                                            type={'number'}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                min: 0,
                                                max: 23,
                                            }}
                                            defaultValue={resolutionHours}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            onKeyDown={handleNegativeValues}
                                            id="resolutionMinutes"
                                            label="Minutes"
                                            variant="outlined"
                                            key={'resolutionMinutes'}
                                            sx={{
                                                backgroundColor: '#F9F9F9',
                                                width: '100%',
                                            }}
                                            type={'number'}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                min: 0,
                                                max: 59,
                                            }}
                                            defaultValue={resolutionMinutes}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Stack>
                    <ActionNdCancel
                        sx={{ marginTop: '48px' }}
                        actionBtncolor="primary"
                        actionBtnLabel="Confirm"
                        alignItems="center"
                        cancelBtnLabel="Cancel"
                        direction={'row'}
                        justifyContent="end"
                        spacing={4}
                        btnWidth={'146px'}
                        btnHeight={'40px'}
                        handleCancelAction={handleClose}
                        handleAction={handleUpdateDefaultPriority}
                        pending={isPending}
                    />
                </Box>
            </Box>
        </BasicModal>
    )
}

export default EditPriorityModal
