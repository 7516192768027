import { createAsyncThunk } from '@reduxjs/toolkit'
import cspService from '../../services/cspServices'

interface FilterCustomer {
    search: string
    contract_ends_by: string
    have_sla: string
    do_not_have_sla: string
    with_expired_contract: string
}

// async action for fetching a customer data
// AsynkThunk can only accept one paramter thats why we send user data as an object
export const getCustomersList = createAsyncThunk(
    'customersList/getCustomersList',
    async (filterCustomerDashboard: FilterCustomer) => {
        const response = await cspService.getCustomersList(
            filterCustomerDashboard
        )

        return response.data
    }
)
