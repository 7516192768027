import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { login, userSelector } from '../../features/user'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { type IAlert } from '../../interfaces/IAlert'
import { addAlert } from '../../features/systemAlerts'
interface props {
    showForgetPassword: () => void
}
const LoginComponent = ({ showForgetPassword }: props) => {
    const user = useAppSelector(userSelector)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm()
    const onsubmit = (data: any) => {
        console.log('data', data)
        setLoading(true)
        dispatch(login(data))
            .then((res: any) => {
                console.log('res', res)
                if (res.error) {
                    const alertObj: IAlert = {
                        id: 'LoginErrorEmail',
                        message: 'User Or Password Is Wrong',
                        state: true,
                        type: 'error',
                    }
                    dispatch(addAlert(alertObj))
                } else {
                    navigate('/', { replace: true })

                    // Check if "Remember me" is selected
                    const rememberMe = data.rememberMe || false

                    if (!rememberMe) {
                        // Save in sessionStorage
                        window.sessionStorage.setItem('isLogedIn', 'true')
                        window.sessionStorage.setItem(
                            'accessToken',
                            res?.payload?.access
                        )
                        window.sessionStorage.setItem(
                            'refreshToken',
                            res?.payload?.refresh
                        )
                        window.localStorage.clear()
                    } else {
                        // Save in localStorage
                        window.localStorage.setItem('isLogedIn', 'true')
                        window.localStorage.setItem(
                            'accessToken',
                            res?.payload?.access
                        )
                        window.localStorage.setItem(
                            'refreshToken',
                            res?.payload?.refresh
                        )
                        window.sessionStorage.clear()
                    }
                }
            })
            .catch((e: any) => {
                console.log('e', e)
                const alertObj: IAlert = {
                    id: 'LoginErrorEmail',
                    message: 'User Or Password Is Wrong',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <>
            <label
                style={{
                    width: '100%',
                    color: '#FFF',
                    fontSize: '16px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '24px',
                }}
            >
                Email
            </label>
            <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        hiddenLabel
                        fullWidth
                        size="small"
                        sx={{ ...styles.textField, marginTop: '0px' }}
                        {...field}
                    />
                )}
            />
            <label
                style={{
                    width: '100%',
                    color: '#D1E2EC',
                    fontSize: '16px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                }}
            >
                Password
            </label>
            <Controller
                name="password"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <>
                        <TextField
                            hiddenLabel
                            fullWidth
                            size="small"
                            type="password"
                            sx={{
                                ...styles.textField,
                                marginTop: '0px',
                                marginBottom: '0px',
                            }}
                            {...field}
                        />
                    </>
                )}
            />
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{ display: 'flex', width: '100%', marginBottom: '60px' }}
            >
                <Stack direction={'column'} justifyContent={'flex-start'}>
                    <Box>
                        <Controller
                            name="rememberMe"
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <input
                                    type="checkbox"
                                    style={{
                                        width: '16px',
                                        height: '16px',
                                        flexShrink: 0,
                                        borderRadius: '4px',
                                        border: '1px solid #D5DAE1',
                                        background: '#FFF',
                                    }}
                                    {...field}
                                    name="rememberMe"
                                />
                            )}
                        />
                        <label
                            style={{
                                width: '100%',
                                color: '#FFF',
                                fontSize: '16px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                lineHeight: '24px',
                                marginBottom: '35px',
                            }}
                        >
                            Remember me
                        </label>
                    </Box>
                </Stack>
                <Link
                    sx={{
                        color: '#47A5A6',
                        textAlign: 'right',
                        fontFamily: 'Poppins',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '18px',
                    }}
                    underline="none"
                    component="button"
                    variant="body2"
                    onClick={() => {
                        console.info("I'm a button.")
                        showForgetPassword()
                    }}
                >
                    Forgot your password?
                </Link>
            </Stack>

            <Button
                onClick={handleSubmit(onsubmit)}
                fullWidth
                disabled={loading}
                sx={{
                    '&:hover': {
                        backgroundColor: '#47A5A6',
                        color: 'white',
                    },
                    backgroundColor: '#47A5A6',
                    color: '#FFF',
                    textAlign: 'center',
                    fontSize: '18px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '38px',
                    textTransform: 'capitalize',
                }}
            >
                {!loading ? 'Login' : <CircularProgress size={30} />}
            </Button>
        </>
    )
}

export default LoginComponent

const styles = {
    textField: {
        display: 'flex',
        gap: '8px',
        backgroundColor: '#fff',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
        marginY: '30px',
    },
}
