import { Stack } from '@mui/system'
import React, { useState } from 'react'
import CommonTypography from '../../components/common/CommonTypography/CommonTypography'
import CommonEmptyState from '../../components/common/CommonEmptyState/CommonEmptyState'
import ListingTeamEngineers from '../../contents/CustomersContent/ListingTeamEngineers/ListingTeamEngineers'
import useFetch from '../../hooks/useFetch'
import dashBoardServices from '../../services/cspServices'
import { useNavigate } from 'react-router'

const TeamEngineers = () => {
    const [refetch, setRefetch] = useState(false)
    const navigate = useNavigate()
    const [selectedTeam, setSelectedTeam] = useState<any>()
    const [selectedTeamID, setSelectedTeamID] = useState<any>()
    const { data: TeamsData, isPending: isFetching } = useFetch(
        dashBoardServices.getTeamEngineers,
        refetch
    )
    return (
        <Stack direction={'column'}>
            <Stack direction={'row'} spacing={0.5} sx={{ paddingLeft: '32px' }}>
                <CommonTypography variant={'h6'} sx={{ color: '#33303B' }}>
                    Team Availability
                </CommonTypography>
            </Stack>

            {!isFetching && TeamsData.length > 0 && (
                <ListingTeamEngineers
                    engineers={TeamsData}
                    seeDetails={(id, index) => {
                        const Id = id ?? ''
                        console.log('id', id, index)
                        setSelectedTeam(TeamsData[index])
                        setSelectedTeamID(id)
                        navigate(`/calender/${Id}`)
                    }}
                />
            )}
        </Stack>
    )
}

export default TeamEngineers
