import React, { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import { customerSelector, getCustomer } from '../../../features/customers'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import UserInfoHeader from '../../../components/UserInfoHeader/UserInfoHeader'
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined'
import CustomSuccessButton from '../../../components/Buttons/CustomSuccessButton/CustomSuccessButton'
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import CallOutlinedIcon from '@mui/icons-material/CallOutlined'
import CustomerTabs from '../../../components/CustomerTabs/CustomerTabs'
import FetchingData from '../../../components/Loaders/FtechingData/FetchingData'
import CustomerCreateUpdateModal from '../../../components/Modals/CustomerCreateUpdateModal/CustomerCreateUpdateModal'
import { CircularProgress } from '@mui/material'
import { fetchImage } from '../../../helper/functions'

const CustomerInfoPage = () => {
    // getting Customer id from route param.
    const { id } = useParams()
    // getting customer data form redux store.
    const { customer, pending } = useAppSelector(customerSelector)
    const [value, setValue] = React.useState(0)
    const [img, setImg] = useState<any>('')
    const dispatch = useAppDispatch()
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    //  a effect hook to fetch customer data
    useEffect(() => {
        if (id) dispatch(getCustomer(id.toString()))
    }, [dispatch, id])
    // useEffect(() => {
    //     fetchImage(customer)
    //         .then((res) => {
    //             const image: string[] =
    //                 res?.base64.split(' charset=utf-8;base64,') ?? []
    //             console.log('dataimage', image)
    //             console.log('data')
    //             console.log('undefinedundefinedundefinedset')
    //             const imageString: string = image[0] + image[1]
    //             setImg(imageString)
    //         })
    //         .catch((e) => {
    //             setImg(undefined)
    //             console.log('undefinedundefinedundefined')
    //         })
    // }, [customer])
    if (pending) {
        return (
            <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ minHeight: '80vh' }}
            >
                <CircularProgress color="inherit" size={50} />
            </Stack>
        )
    }

    return (
        <>
            <Stack
                direction={'column'}
                sx={{ paddingLeft: '32px', maxWidth: 846 }}
            >
                <CommonTypography variant={'h6'} gutterBottom>
                    Customer Information
                </CommonTypography>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    sx={{ marginTop: '3rem' }}
                >
                    <UserInfoHeader
                        photo={
                            customer.photo ??
                            require('../../../assets/images/logo.png')
                        }
                        name={customer.name ? customer.name : ''}
                        industry={customer.industry ? customer.industry : ''}
                        address={customer.address ? customer.address : ''}
                    />
                    <CustomSuccessButton
                        width={102}
                        height={40}
                        onClick={handleOpen}
                    >
                        <BorderColorOutlinedIcon />
                        Edit
                    </CustomSuccessButton>
                </Stack>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={4.5}
                    sx={{ marginTop: '16px' }}
                >
                    {customer.contact_email && (
                        <>
                            <MailOutlineOutlinedIcon />
                            <CommonTypography
                                variant={'subtitle1'}
                                sx={{ marginLeft: '10px !important' }}
                            >
                                {customer.contact_email}
                            </CommonTypography>
                        </>
                    )}
                    {customer.contact_number && (
                        <>
                            <CallOutlinedIcon />
                            <CommonTypography
                                variant={'subtitle1'}
                                sx={{ marginLeft: '10px !important' }}
                            >
                                {customer.contact_number}
                            </CommonTypography>
                        </>
                    )}
                </Stack>
                <Stack sx={{ marginTop: '16px' }}>
                    <CommonTypography
                        variant={'subtitle1'}
                        sx={{ color: 'text.disabled' }}
                    >
                        Description
                    </CommonTypography>
                    <CommonTypography
                        variant={'subtitle1'}
                        sx={{ color: 'text.primary' }}
                    >
                        {customer.description}
                    </CommonTypography>
                </Stack>
            </Stack>
            <CustomerTabs
                customerId={id ? id.toString() : ''}
                setValue={setValue}
                value={value}
            />
            <CustomerCreateUpdateModal
                open={open}
                handleClose={handleClose}
                isEdit={true}
                customerId={id}
            />
        </>
    )
}

export default CustomerInfoPage
