export const status = {
    OPENED: 'OPENED',
    ASSIGNED: 'ASSIGNED',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    WAITING_VENDOR_REPLY: 'WAITING_VENDOR_REPLY',
    SOLVED: 'SOLVED',
    CLOSED: 'CLOSED',
    PERMANENTLY_CLOSED: 'PERMANENTLY_CLOSED',
}
export const statusNames:any = {
    open: 'OPENED',
    opened: 'OPENED',
    assigned: 'ASSIGNED',
    in_progress: 'IN PROGRESS',
    waiting_for_customer: 'WAITING FOR CUSTOMER',
    waiting_vendor_reply: 'WAITING VENDOR REPLY',
    solved: 'SOLVED',
    closed: 'CLOSED',
    permanently_closed: 'PERMANENTLY CLOSED',
}
