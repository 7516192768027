import * as React from 'react'
import Grid from '@mui/material/Grid/Grid'
import { type  SxProps } from '@mui/material'

interface ICommonTable {
    sx?: SxProps
    children: any
}

const CommonTable = ({ sx, children }: ICommonTable) => {
    return (
        <Grid
            container
            sx={{
                backgroundColor: 'common.white',
                border: '1px solid #E5E5E5',
                width: '100%',
                ...sx,
            }}
        >
            {children}
        </Grid>
    )
}

export default CommonTable
