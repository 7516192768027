import dayjs from "dayjs"
import type { Dayjs } from "dayjs"

const handleSupporPeriodPercentage = (
    startDate: string,
    endDate: string
) => {
    const startOfDate: Dayjs = dayjs(startDate)
    const endOfDate: Dayjs = dayjs(endDate)
    const todayDate: Dayjs = dayjs()

    const daysDifference = endOfDate.diff(startOfDate, 'days', true)
    const difference = todayDate.diff(startOfDate, 'days', true)

    const result = Math.round((difference / daysDifference) * 100)
    if (result >= 100) {
        return 100
    } else {
        return result
    }
}
 
export default handleSupporPeriodPercentage