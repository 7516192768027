import { createReducer } from "@reduxjs/toolkit";
import {
    getSites
} from "./actions";
import type { ITimeZone } from "../../interfaces/ITimeZone";
import type { IHoliday } from "../../interfaces/IHoliday";


interface IinitCustomerSitesReducer  {
    customerSitesList: Array<{
        id: number | null
        name: string | null
        address: string | null
        contact_number: string | null
        contact_email: string | null
        time_zone: ITimeZone | null
        starting_hour: string | null
        ending_hour: string | null
        holidays: IHoliday[] | null
        customer: number | null
        saturday: boolean | null
        sunday: boolean | null
        monday: boolean | null
        tuesday: boolean | null
        wednesday: boolean | null
        thursday: boolean | null
    }>,
    pending: boolean,
    error: boolean,
};

// games Campaings initial state
const initCustomerSitesReducer: IinitCustomerSitesReducer = {
    customerSitesList: [],
    pending: true,
    error: false,
};

// departmentReducer reducer to handel action when dispatched
export const customerSitesReducer = createReducer(initCustomerSitesReducer, builder => {
    builder
        // game campaign Reducer action thunk and its stats.
        .addCase(getSites.pending, state => {
            state.pending = true;
            state.error = false;
        })
        .addCase(getSites.fulfilled, (state, { payload }) => {
            state.pending = false;
            state.customerSitesList = payload.sites;
        })
        .addCase(getSites.rejected, state => {
            state.pending = false;
            state.error = true;
        })
});

export default customerSitesReducer;
 
