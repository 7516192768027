import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import Grid from '@mui/material/Grid/Grid'
import { customerSelector } from '../../../features/customers'
import { useAppSelector } from '../../../app/hooks'
import SiteCard from '../../../components/Cards/SiteCard/SiteCard'
import ProductCard from '../../../components/Cards/ProductCard/ProductCard'

interface IListingCustomerSites {
    createNew: () => void
    seeDetails: (siteIdToBeEdited: number | null) => void
}

const ListingCustomerProducts = ({
    createNew,
    seeDetails,
}: IListingCustomerSites) => {
    const { customer, pending } = useAppSelector(customerSelector)
    console.log('customerSelector', customer)

    return (
        <Stack direction={'column'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <CommonTypography
                    variant={'subtitle1'}
                    sx={{ color: '#000', fontWeight: 600 }}
                >
                    All Products
                </CommonTypography>
                <CommonButton
                    variant={'outlined'}
                    color={'secondary'}
                    sx={{ width: '140px', height: '38px' }}
                    onClick={createNew}
                >
                    New Product
                </CommonButton>
            </Stack>
            <Grid container sx={{ marginTop: 3 }} rowGap={4} spacing={2}>
                {customer.products.map((product, index) => (
                    <Grid item xs={12} md={6} lg={4} key={product.id}>
                        <ProductCard
                            // @ts-expect-error sdasdad
                            name={product.product.name}
                            paltform={product.paltform}
                            release={product.release}
                            // @ts-expect-error saddasd
                            site={product.site.name}
                            sla={product.follow_sla}
                            navigateToDetails={() => {
                                if (!pending) seeDetails(index)
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default ListingCustomerProducts
