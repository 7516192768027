import CommonEmptyState from '../../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import CreateUser from '../../../assets/images/profile_create_user.svg'

interface IUsersEmptySatate {
    createNew: () => void
}

const UsersEmptyState = ({ createNew }: IUsersEmptySatate) => {
    return (
        <CommonEmptyState
            src={CreateUser}
            alt="site empty state"
            width={81}
            height={180}
            sx={{ marginTop: '88px' }}
        >
            <CommonButton
                variant={'outlined'}
                sx={{ width: 155, height: 40 }}
                onClick={createNew}
            >
                Create User
            </CommonButton>
        </CommonEmptyState>
    )
}

export default UsersEmptyState
