import React from 'react'
import { PieChart, Pie, Tooltip, Cell } from 'recharts'
import './PriorityAllocation.css'
import PropTypes from 'prop-types'
interface props {
    priorityData: any
    prioritiesCount: any
}
const PriorityAllocation = ({ priorityData, prioritiesCount }: props) => {
    const colors = ['#3888d8', '#f39422', '#be424b', '#00d585']
    const data02 = Object.keys(priorityData).map((priority, index) => ({
        status: priority,
        value: priorityData[priority].count ?? 0,
        color: colors[index],
    }))
    // Calculate the total sum of all values
    const totalValue = data02.reduce(
        (sum: number, entry: { value: number }) => sum + entry.value,
        0
    )

    // Add the percentage value for each object
    data02.forEach((entry) => {
        // @ts-expect-error skip
        entry.percent = ((entry.value / totalValue) * 100).toFixed(2)
    })

    return (
        <div className="status-container">
            <h4>Priority Allocation</h4>

            <div className="pie-container">
                <PieChart width={300} height={220} layout="centric">
                    <Pie
                        dataKey="value"
                        isAnimationActive={true}
                        data={data02}
                        cx="40%"
                        cy="40%"
                        outerRadius={67}
                        label={({
                            cx,
                            cy,
                            midAngle,
                            innerRadius,
                            outerRadius,
                            percent,
                            index,
                        }: {
                            cx: number
                            cy: number
                            midAngle: number
                            innerRadius: number
                            outerRadius: number
                            percent: number
                            index: number
                        }) => {
                            const RADIAN: number = Math.PI / 180
                            const radius: number =
                                innerRadius + (outerRadius - innerRadius) * 0.5
                            const x: number =
                                cx + radius * Math.cos(-midAngle * RADIAN)
                            // const y = cy + radius * Math.sin(-midAngle * RADIAN);

                            const xOffset: number = 50
                            const yOffset: number = 50

                            const outerX: number =
                                cx +
                                (radius + xOffset) *
                                    Math.cos(-midAngle * RADIAN)
                            const outerY: number =
                                cy +
                                (radius + yOffset) *
                                    Math.sin(-midAngle * RADIAN)

                            return (
                                <text
                                    x={outerX}
                                    y={outerY}
                                    fill="black"
                                    textAnchor={x > cx ? 'start' : 'end'}
                                    dominantBaseline="central"
                                    fontSize="10px"
                                >
                                    {`${percent}%`}
                                </text>
                            )
                        }}
                    >
                        {data02.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>

                    <Tooltip
                        content={({ active, payload }) => {
                            if (active && payload && payload.length) {
                                const data: {
                                    status: string
                                    value: string
                                    percent: string
                                } = payload[0].payload
                                return (
                                    <div className="status-pop">
                                        <p>{`Status: ${data?.status}`}</p>
                                        <p>{`Count: ${
                                            Math.round(
                                                parseFloat(data?.value) * 100
                                            ) / 100
                                        } `}</p>
                                    </div>
                                )
                            }
                            return null
                        }}
                        contentStyle={{ pointerEvents: 'none' }}
                    />
                </PieChart>

                <div className="status-legend">
                    {data02.map((entry, index) => (
                        <div key={`legend-${index}`} className="status-color">
                            <div
                                style={{
                                    width: '7px',
                                    height: '7px',
                                    backgroundColor: entry.color,
                                    marginRight: '5px',
                                    borderRadius: '10px',
                                }}
                            />
                            <span>{entry.status}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        //     <div className='priority-container'>
        //       <h4>Priority Allocation</h4>

        //       <div className='priority-pie'>
        //       <PieChart width={500} height={200} layout='horizontal'>
        //         <Pie
        //           dataKey="value"
        //           data={data02}
        //           cx="65%"
        //           cy="45%"
        //           outerRadius={67}

        //           label={({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }:{ cx:number, cy:number, midAngle:number, innerRadius:number, outerRadius:number, percent:number, index:number}) => {
        //             const RADIAN:number = Math.PI / 180;
        //             const radius:number = innerRadius + (outerRadius - innerRadius) * 0.5;
        //             const x:number = cx + radius * Math.cos(-midAngle * RADIAN);
        //             // const y = cy + radius * Math.sin(-midAngle * RADIAN);

        //             const xOffset:number = 50;
        //             const yOffset:number = 50;

        //             const outerX:number = cx + (radius + xOffset) * Math.cos(-midAngle * RADIAN);
        //             const outerY:number = cy + (radius + yOffset) * Math.sin(-midAngle * RADIAN);

        //             return (
        //               <text
        //                 x={outerX}
        //                 y={outerY}
        //                 fill='black'
        //                 textAnchor={x > cx ? 'start' : 'end'}
        //                 dominantBaseline='central'
        //                 fontSize='10px'
        //               >
        //                 {`${percent}%`}
        //               </text>
        //             );
        //           }}
        //         >
        //            {data02.map((entry, index) => (
        //               <Cell key={`cell-${index}`} fill={entry.color} />
        //             ))}
        // </Pie>

        //         <Tooltip
        //         content={({ active, payload }) => {
        //           if (active && payload && payload.length) {
        //             const data:{
        //               status:string
        //               value:string
        //               percent:string
        //             } = payload[0].payload;
        //             return (
        //               <div className='status-pop' >
        //                 <p>{`Status: ${data.status}`}</p>
        //                 <p>{`Count: ${data.value} (${data.percent}%)`}</p>
        //               </div>
        //             );
        //           }
        //           return null;
        //         }}
        //         contentStyle={{ pointerEvents: 'none' }}
        //         />

        //       </PieChart>

        //       <div className='priority-legend'>
        //           {data02.map((entry, index):any => (
        //             <div key={`legend-${index}`} className='status-color'>
        //               <div style={{ width: '7px', height: '7px', backgroundColor: entry.color, marginRight: '5px', borderRadius: '10px' }} />
        //               <span>{entry.status}</span>
        //             </div>
        //           ))}
        //         </div>

        //       </div>

        //     </div>
    )
}
PriorityAllocation.propTypes = {
    priorityData: PropTypes.any,
}
export default PriorityAllocation
