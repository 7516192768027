import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import Grid from '@mui/material/Grid/Grid'
import { customerSelector } from '../../../features/customers'
import { useAppSelector } from '../../../app/hooks'
import SiteCard from '../../../components/Cards/SiteCard/SiteCard'

interface IListingCustomerSites {
    createNew: () => void
    seeDetails: (siteIdToBeEdited: number | null) => void
}

const ListingCustomerSites = ({
    createNew,
    seeDetails,
}: IListingCustomerSites) => {
    const { customer } = useAppSelector(customerSelector)
    return (
        <Stack direction={'column'}>
            <CommonTypography variant={'subtitle1'} sx={{ fontWeight: 600 }}>
                All Sites
            </CommonTypography>
            <Stack direction={'row'} justifyContent={'end'}>
                <CommonButton
                    variant={'outlined'}
                    color={'secondary'}
                    sx={{ width: '140px', height: '38px' }}
                    onClick={createNew}
                >
                    New Site
                </CommonButton>
            </Stack>
            <Grid container sx={{ marginTop: 3 }} rowGap={4} spacing={1}>
                {customer.sites.map((site) => (
                    <Grid item xs={12} md={6} lg={4} key={site.id}>
                        <SiteCard
                            name={site.name !== null ? site.name : ''}
                            address={site.address !== null ? site.address : ''}
                            timezone={
                                site.time_zone !== null
                                    ? site.time_zone.name !== null
                                        ? site.time_zone.name
                                        : ''
                                    : ''
                            }
                            navigateToDetails={() => {
                                seeDetails(site.id)
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default ListingCustomerSites
