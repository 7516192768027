import * as React from 'react'
import { ThemeProvider } from '@mui/material'
import { dashboardTheme } from '../theme/dashboardTheme'
import CssBaseline from '@mui/material/CssBaseline'

interface IcolorModeContext {
    toggleColorMode: () => void
    mode: 'dark' | 'light'
}

export const ColorModeContext = React.createContext<IcolorModeContext>({
    toggleColorMode: () => {},
    mode: 'light',
})

export const ColorModeContextProvider = ({ children }: any) => {
    const [mode, setMode] = React.useState<'light' | 'dark'>('light')
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
            },
            mode,
        }),
        [mode]
    )

    const theme = React.useMemo(() => dashboardTheme(mode), [mode])

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline>{children}</CssBaseline>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}

export const useColorMode = () => React.useContext(ColorModeContext)
