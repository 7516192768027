import { Button, Drawer, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import dashBoardServices from '../../services/cspServices'
import RequestInfoCard from '../../components/Cards/SummaryCrads/RequestInfoCard'
import RequestCard from '../../components/Cards/RequestCard/RequestCard'
import { ReactComponent as Logo } from '../../assets/images/LogoCompany.svg'
import { ReactComponent as NoReplies } from '../../assets/images/Noreplies.svg'
import Summary from '../../components/Summary/Summary'
import NewReplyModal from '../../components/Modals/NewReplayModal/NewReplyModal'
import EscalateModal from '../../components/Modals/EscalateModal/EscalateModal'
import useFetch from '../../hooks/useFetch'
import ReplayCard from '../../components/Cards/ReplayCard/ReplayCard'
import AttachmentsModal from '../../components/Modals/AttachmentsModal/AttachmentsModal'
import UpdateRequestModal from '../../components/Modals/UpdateRequestModal/UpdateRequestModal'
import FetchingData from '../../components/Loaders/FtechingData/FetchingData'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { customerAdminSelector } from '../../features/adminCustomer'
import { type IAlert } from '../../interfaces/IAlert'
import { addAlert } from '../../features/systemAlerts'
import baseURL from '../../services/baseUrl'
import { status } from '../../helper/constants'
const Request = () => {
    const { id, customerId } = useParams()
    const [userSummary, setUserSummary] = useState<any>(null)
    const [replyOpen, setReplyOpen] = useState(false)
    const { customer } = useAppSelector(customerAdminSelector)

    const [escalateOpen, setEscalateOpen] = useState(false)
    const [updateOpen, setUpdateOpen] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [attachments, setAttachments] = useState<any>([])
    const [attachmentOpen, setAttachmentOpen] = useState(false)
    const [header, setHeader] = useState<string | undefined>(undefined)
    const [action, setAction] = useState<string | undefined>(undefined)
    const [replyMessage, setReplyMessage] = useState<any>(undefined)
    const dispatch = useAppDispatch()
    const { data, error, isPending } = useFetch(
        dashBoardServices.getrequestById,
        id,
        undefined,
        refetch
    )
    const xsmall = useMediaQuery('(min-width:425px)')
    const small = useMediaQuery('(min-width:600px)')
    const medium = useMediaQuery('(min-width:900px)')
    const laptop = useMediaQuery('(min-width:1024px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    const [state, setState] = useState(false)
    const { globalUrl: url } = baseURL()

    useEffect(() => {
        if (!error && !isPending) setUserSummary({ ...data })
    }, [data])
    const handleCloseReply = () => {
        setReplyMessage('')
        setReplyOpen(false)
        setAction(undefined)
        setHeader(undefined)
        setRefetch(!refetch)
    }
    const handleOpenReply = () => {
        setReplyMessage('')
        setReplyOpen(true)
    }
    const handleOpenReplyReply = (msg: any) => {
        setReplyMessage(msg)
        setReplyOpen(true)
    }
    const handleCloseEscalate = () => {
        setEscalateOpen(false)
        setRefetch(!refetch)
    }
    const handleCloseAttachments = () => {
        setAttachmentOpen(false)
    }
    const handleOpenAttachments = (attachments: any) => {
        setAttachments(attachments)
        setAttachmentOpen(true)
    }
    const handleOpenEscalate = () => {
        setEscalateOpen(true)
    }
    const handleReplyReOpen = () => {
        setAction('REOPEN')
        setHeader('Re-Open Reason')
        setReplyMessage('')
        setReplyOpen(true)
    }
    const handleOpenUpdate = () => {
        setUpdateOpen(true)
    }
    const handleCloseUpdate = () => {
        setUpdateOpen(false)
        setRefetch(!refetch)
    }
    const handleReplyCancel = () => {
        setAction('CANCELLATION')
        setHeader('Cancellation reason')
        setReplyMessage('')
        setReplyOpen(true)
    }
    const handleEscalate = () => {
        if (id)
            dashBoardServices
                .escalateRequest(+id, {
                    esclation_status: true,
                })
                .then((res) => {
                    const alertObj: IAlert = {
                        id: 'escalation',
                        message: 'Escalation was sent successfuly',
                        state: true,
                        type: 'success',
                    }
                    dispatch(addAlert(alertObj))
                })
                .catch((e) => {
                    console.log('e', e)
                    const alertObj: IAlert = {
                        id: 'escalation',
                        message: 'Escalation was sent successfuly',
                        state: true,
                        type: 'success',
                    }
                    dispatch(addAlert(alertObj))
                })
                .finally(() => {
                    handleCloseEscalate()
                })
    }
    console.log('id', id)
    const toggleDrawer =
        (anchor: string, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return
            }

            setState(open)
        }
    const logo: string = isPending ? '' : userSummary?.logo
    const URL = `${url.slice(0, -1)}${logo}`

    if (isPending) return <FetchingData />
    return (
        <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'column'} sx={{ flexGrow: 1, marginX: '20px' }}>
                <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    sx={{ maxHeight: '80px', maxWidth: '80px' }}
                >
                    <img src={logo} alt="logo" style={{ width: '100%' }} />
                </Stack>
                {userSummary && (
                    <>
                        <RequestCard
                            userSummary={userSummary}
                            handleOpenAttachments={handleOpenAttachments}
                            handleOpenEscalate={handleOpenEscalate}
                            handleEscalate={handleEscalate}
                            handleReplyCancel={handleReplyCancel}
                            handleReplyReOpen={handleReplyReOpen}
                            handleRequestUpate={handleOpenUpdate}
                        />

                        <Stack
                            direction={'row'}
                            justifyContent={
                                (medium || small || xsmall || laptop) &&
                                !extraLarge &&
                                !large
                                    ? 'space-between'
                                    : 'flex-end'
                            }
                        >
                            <Button
                                onClick={handleOpenReply}
                                disableRipple
                                sx={{
                                    borderRadius: '4px',
                                    border: '1px solid #DCDCDC',
                                    background: '#FFF',
                                    width: '110px',
                                    height: '42px',
                                    flexShrink: 0,
                                    marginTop: '12px',
                                    textTransform: 'none',
                                    color: '#000',
                                    textAlign: 'center',
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                }}
                                disabled={
                                    userSummary.status ==
                                    status.PERMANENTLY_CLOSED
                                }
                            >
                                Reply
                            </Button>
                            {(medium || small || xsmall || laptop) &&
                                !extraLarge &&
                                !large && (
                                    <Button
                                        onClick={toggleDrawer('right', true)}
                                        disableRipple
                                        sx={{
                                            borderRadius: '4px',
                                            border: '1px solid #DCDCDC',
                                            background: '#FFF',
                                            width: '110px',
                                            height: '42px',
                                            flexShrink: 0,
                                            marginTop: '12px',
                                            textTransform: 'none',
                                            color: '#929EAE',
                                            textAlign: 'center',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            lineHeight: '20px',
                                        }}
                                    >
                                        Summary
                                    </Button>
                                )}
                        </Stack>
                    </>
                )}
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={
                        userSummary && userSummary.replies.length > 0
                            ? ''
                            : 'center'
                    }
                    sx={{ marginTop: '16px' }}
                    spacing={1}
                >
                    {userSummary &&
                        userSummary.replies.length > 0 &&
                        userSummary.replies.reverse().map((reply: any,index:number) => {
                            return (
                                <ReplayCard
                                    hanldeOpenAttachments={
                                        handleOpenAttachments
                                    }
                                    reply={reply}
                                    key={`ReplayCard ${index}`}
                                    openReplyReply={handleOpenReplyReply}
                                />
                            )
                        })}

                    {/* {userSummary && } */}
                    {userSummary && userSummary.replies.length == 0 && (
                        <>
                            <NoReplies />
                            <Stack direction={'row'} justifyContent={'center'}>
                                <Typography
                                    sx={{
                                        color: 'var(--gray-2, #353945)',
                                        fontFamily: 'Inter',
                                        fontSize: '20px',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        lineHeight: '33.6px',
                                    }}
                                >
                                    No Replies until now
                                </Typography>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>
            {(medium || small || xsmall || laptop) && !extraLarge && !large ? (
                <Drawer
                    anchor={'right'}
                    open={state}
                    onClose={toggleDrawer('right', false)}
                >
                    <Stack sx={{ minWidth: 'max-content' }}>
                        {userSummary && <Summary userSummary={userSummary} />}
                    </Stack>
                </Drawer>
            ) : (
                <Stack sx={{ marginTop: '70px', marginRight: '28px' }}>
                    {userSummary && <Summary userSummary={userSummary} />}
                </Stack>
            )}
            <NewReplyModal
                id={id ? +id : 0}
                subject={userSummary ? userSummary.subject : ''}
                handleClose={handleCloseReply}
                open={replyOpen}
                message={replyMessage}
                action={action}
                header={header}
            />
            <EscalateModal
                open={escalateOpen}
                handleClose={handleCloseEscalate}
                handleMainAction={handleEscalate}
                isPending={false}
            />
            <AttachmentsModal
                attachments={attachments}
                handleClose={handleCloseAttachments}
                isPending={false}
                open={attachmentOpen}
            />
            {userSummary && (
                <UpdateRequestModal
                    id={id ? +id : 0}
                    subject={userSummary ? userSummary.subject : ''}
                    handleClose={handleCloseUpdate}
                    open={updateOpen}
                    initialValues={userSummary}
                    customerId={customerId}
                />
            )}
        </Stack>
    )
}

export default Request
