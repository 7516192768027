import Button from '@mui/material/Button'

interface ICommonButton {
    children: any
    color?: any
    disabled?: any
    size?: any
    sx?: any
    variant?: any
    onClick?: any
}

const CommonButton = ({
    children,
    color,
    disabled,
    size,
    sx,
    variant,
    onClick,
}: ICommonButton) => {
    return (
        <Button
            color={color}
            disabled={disabled}
            size={size}
            sx={sx}
            variant={variant}
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

export default CommonButton
