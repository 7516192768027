import { useState, useEffect, useCallback } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
    type SxProps,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormGroup,
    RadioGroup,
    Radio,
    FormControl,
    useMediaQuery,
    CircularProgress,
} from '@mui/material'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useForm, Controller, useWatch } from 'react-hook-form'
import { ReactComponent as Add } from '../../../assets/images/iconAdd.svg'
import { ReactComponent as Pdf } from '../../../assets/images/pdf.svg'
import { LoadingButton } from '@mui/lab'
import AttachmentFile from '../../common/AttachmentFileComponent/AttachmentFile'
import dashBoardServices from '../../../services/cspServices'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user'
import { useWindowSize } from 'usehooks-ts'
import { addAlert } from '../../../features/systemAlerts'
import { type IAlert } from '../../../interfaces/IAlert'

interface IReply {
    internal: boolean
    action: string
    subject: string
    message: string
    attachments: number[]
}
interface INewReplyModalModal {
    open: boolean
    handleClose: () => void
    id: number
    subject: any
    message?: string
    action?: string
    header?: string
}

const style = {}

interface FormData {
    subject: string
    product: string
    RelatedTo: string
    priority: string
    description: string
    internal: string
    attachments: File[]
}
interface IStyles {
    title: SxProps
    textField: SxProps
    attachments: SxProps
}

const NewReplyModal = ({
    handleClose,
    open,
    id,
    subject,
    message,
    action,
    header,
}: INewReplyModalModal) => {
    const [expanded, setExpanded] = useState<string | false>(false)
    const [chartDimensions, setChartDimensions] = useState(450)
    const small = useMediaQuery('(min-width:600px)')
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const { width, height } = useWindowSize()
    const [loading, setLoading] = useState(false)
    const extraLarge = useMediaQuery('(min-width:1536px)')
    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        } else {
            setChartDimensions(400)
        }
    }, [medium, large, extraLarge])

    const [valueBug, setBugValue] = useState('External')
    const [filesIds, setFilesIds] = useState<any>([])
    const { user } = useAppSelector(userSelector)
    // console.log('user', user)

    const handleBugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBugValue((event.target as HTMLInputElement).value)
    }
    const [inPending, setInPending] = useState(false)
    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
        }

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormData>()
    const onsubmit = (data: any) => {
        // console.log('data', data)
        setInPending(true)
        const reply: IReply = {
            action: action ?? '',
            attachments: filesIds.map((item: any) => {
                return +item.id
            }),
            internal: data.internal == 'Internal',
            message: data.description,
            subject,
        }
        // console.log('reply', reply)

        dashBoardServices
            .createRequestReply(id, reply)
            .then((res) => {
                // console.log('res', res)
            })
            .catch((e) => {
                // console.log('e', e)
            })
            .finally(() => {
                handleClose()
                setInPending(false)
            })
    }

    const [imageUrl, setImageUrl] = useState(null)
    const dispatch = useAppDispatch()
    const handleFileUpload = (event: any) => {
        const Ids = [...filesIds]
        // console.log('event', event.target.files[0])
        setLoading(true)
        Object.entries(event.target.files).map(([key, value]: any) => {
            const myformData = new FormData()
            myformData.append('file', value)
            dashBoardServices
                .newFileAttachmentToReplay(myformData)
                .then((res: any) => {
                    const FileObj: any = { id: res?.data?.id, name: value.name }
                    // console.log('res', res)
                    Ids.push(FileObj)
                    setFilesIds((prev: any) => {
                        // console.log('prev', prev)

                        return [...prev, FileObj]
                    })
                })
                .catch((e) => {
                    // console.log('e', e)
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    const handleDeleteRequest = (id: number) => {
        dashBoardServices
            .deleteFileAttachment(id)
            .then((res) => {
                if (res.status === 204) {
                    const newIds = filesIds.filter((x: any) => x.id != id)
                    setFilesIds(newIds)
                }
                // console.log('res', res)
            })
            .catch((e) => {
                // console.log('e', e)
            })
    }
    useEffect(() => {
        if (message) {
            setValue('description', '\n\n\n\n' + message)
        } else {
            setValue('description', '')
        }
    }, [message])
    useEffect(() => {
        setValue('internal', 'External')
    }, [])

    // const modalWidth = width > 932 ?932:width

    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            message: value?.message ?? 'validation error',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })
    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    borderRadius: '42px',
                    transform: 'translate(-50%, -50%)',
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                }}
            >
                <Box
                    sx={{
                        // maxHeight: '700px',

                        maxHeight: chartDimensions,
                        overflow: 'auto',
                        p: 3,
                    }}
                >
                    <form onSubmit={handleSubmit(onsubmit)}>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <CommonTypography
                                sx={{
                                    marginTop: '10px',
                                    marginLeft: '10px',

                                    color: '#000',
                                    fontFamily: 'Inter',
                                    fontSize: '22px',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    lineHeight: 'normal',
                                    textTransform: 'capitalize',
                                }}
                                variant={'h6'}
                            >
                                {header ?? 'Reply'}
                            </CommonTypography>
                            <Controller
                                name="internal"
                                control={control}
                                render={({ field }) => (
                                    <FormControl
                                        sx={{
                                            visibility:
                                                user.user_type == 'CUSTOMER'
                                                    ? 'hidden'
                                                    : 'visible',
                                        }}
                                    >
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            {...field}
                                        >
                                            <FormControlLabel
                                                value={'External'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            marginRight: '12px',
                                                        }}
                                                    />
                                                }
                                                label="External"
                                            />
                                            <FormControlLabel
                                                value={'Internal'}
                                                control={
                                                    <Radio
                                                        sx={{
                                                            marginRight: '12px',
                                                        }}
                                                    />
                                                }
                                                label="Internal"
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                        </Stack>
                        <Stack direction={'column'} sx={{ marginTop: '48px' }}>
                            <Typography sx={styles.title}>
                                Write Message
                            </Typography>
                            <Controller
                                name="description"
                                control={control}
                                rules={{
                                    required: 'you have to enter a message',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        multiline
                                        minRows={15}
                                        size="small"
                                        fullWidth
                                        error={!!errors.description}
                                        helperText={
                                            errors.description
                                                ? errors.description.message
                                                : ''
                                        }
                                        {...field}
                                        sx={styles.textField}
                                    />
                                )}
                            />
                        </Stack>
                        <Typography sx={styles.title}>Attachments</Typography>
                        <Stack direction="row" sx={styles.attachments}>
                            {filesIds.length > 0 &&
                                filesIds.map((item: any) => {
                                    return (
                                        <AttachmentFile
                                            key={item.id}
                                            name={item.name}
                                            IconFile={Pdf}
                                            Id={item.id}
                                            deleteFile={handleDeleteRequest}
                                        />
                                    )
                                })}
                            <Stack
                                alignSelf={'flex-end'}
                                sx={{ maxWidth: '95px', marginLeft: 'auto' }}
                            >
                                <label htmlFor="upload-image">
                                    <Button
                                        component="span"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderRadius: '4px',
                                            border: '1px solid #E7E5E5',
                                            backgroundColor: '#FFF',
                                            width: '95px',
                                            height: '32px',
                                            flexShrink: 0,
                                        }}
                                    >
                                        {!loading && (
                                            <Add
                                                style={{ marginRight: '6px' }}
                                            />
                                        )}
                                        {!loading && 'add'}
                                        {loading && (
                                            <CircularProgress size={25} />
                                        )}
                                    </Button>
                                    <input
                                        id="upload-image"
                                        hidden
                                        accept="*"
                                        type="file"
                                        multiple
                                        onChange={handleFileUpload}
                                    />
                                </label>
                            </Stack>
                        </Stack>
                        <Stack
                            sx={{ marginTop: '48px' }}
                            direction={'row'}
                            spacing={3}
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <Button
                                color={'primary'}
                                variant={'outlined'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    color: '#494454',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                }}
                                onClick={handleClose}
                            >
                                {'Cancel'}
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant={'contained'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    boxShadow: 'none',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                    borderRadius: '4px',
                                }}
                                // onClick={handleSubmit(onSubmit)}
                                loading={inPending}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const styles: IStyles = {
    textField: {
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        marginBottom: '22px',
    },
    title: {
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',
    },
    attachments: {
        display: 'flex',
        maxWidth: 'fit-content',
        minWidth: '100%',
        minHeight: '50px',
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        padding: '9px 12px 12px 9px',
        flexWrap: 'wrap',
    },
}
export default NewReplyModal
