import CommonFormControl from '../common/CommonFormControl/CommonFormControl'
import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'

interface ISelectDaysInput {
    value: string
    sx: any
    onClick: any
}

const SelectDaysInput = ({ sx, value, onClick }: ISelectDaysInput) => {
    return (
        <CommonFormControl sx={sx} onClick={onClick}>
            <InputLabel htmlFor="outlined-adornment-workingDays">
                Working Days
            </InputLabel>
            <OutlinedInput
                id="outlined-adornment-workingDays"
                type={'text'}
                endAdornment={
                    <InputAdornment position="end">
                        <CalendarMonthRoundedIcon />
                    </InputAdornment>
                }
                label="Working Days"
                readOnly
                value={value}
            />
        </CommonFormControl>
    )
}

export default SelectDaysInput
