import Grid from '@mui/material/Grid/Grid'
import Stack from '@mui/material/Stack/Stack'
import CommonIconButton from '../common/CommonIconButton/CommonIconButton'
import Divider from '@mui/material/Divider'
import CommonTable from '../common/CommonTable/CommonTable'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'
import type { IPriority } from '../../interfaces/IPriority'
import hanldeTimeInMinToStr from '../../helper/hanldeTimeInMinToStr'
import { Typography, useMediaQuery } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
interface ISlaTable {
    rows: IPriority[] | null
    openSlaOptionsModal?: () => void
    openEditPriorityModal?: (priortyObj: IPriority) => void
    isEdit?: boolean
    justView: boolean
}

const SlaTable = ({
    rows,
    openSlaOptionsModal,
    openEditPriorityModal,
    isEdit,
    justView,
}: ISlaTable) => {
    const getTableHead = () => {
        return (
            <Grid
                container
                spacing={0}
                sx={{
                    backgroundColor: '#494454',
                    height: '40px',
                    paddingLeft: 2,
                    paddingRight: 4,
                }}
                alignItems={'center'}
            >
                <Grid
                    item
                    xs={2}
                    justifyContent={'start'}
                    sx={{ color: 'common.white' }}
                >
                    Priority
                </Grid>
                <Grid
                    item
                    xs={4}
                    justifyContent={'start'}
                    sx={{ color: 'common.white' }}
                >
                    Response Time
                </Grid>
                <Grid
                    item
                    xs={4}
                    justifyContent={'start'}
                    sx={{ color: 'common.white' }}
                >
                    Resolution Time
                </Grid>
                {!justView && (
                    <Grid item xs={2} alignItems={'end'}>
                        <Stack
                            sx={{ width: '100%' }}
                            direction={'row'}
                            justifyContent={'end'}
                        >
                            <CommonIconButton
                                sx={{
                                    width: 24,
                                    height: 24,
                                    color: 'common.white',
                                }}
                                onClick={openSlaOptionsModal}
                            >
                                <OpenInNewRoundedIcon />
                            </CommonIconButton>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        )
    }

    const getTableBody = () => {
        return rows?.map((row, index) => (
            <Grid
                container
                key={row.id}
                onClick={() => {
                    if (!justView)
                        // @ts-expect-error no probelems
                        openEditPriorityModal({
                            id: row.id,
                            name:
                                isEdit && row.priority_name
                                    ? row.priority_name
                                    : row.name,
                            priority_description: row.priority_description,
                            resolution_time: row.resolution_time,
                            response_time: row.response_time,
                            is_applicable: row.is_applicable,
                            priority: row.priority,
                        })
                }}
            >
                <Grid
                    container
                    sx={{
                        borderRadius: '10px',
                        height: '50px',
                        marginLeft: 1,
                        marginRight: '36px',
                        padding: 2,
                        marginTop: 0.5,
                        marginBottom: 0.5,
                        '&:hover': { backgroundColor: '#E5E5E5' },
                        cursor: 'pointer',
                    }}
                    alignItems={'center'}
                >
                    <Grid item xs={2}>
                        {row.name || row.priority_name}
                    </Grid>
                    <Grid item xs={4}>
                        {`${
                            hanldeTimeInMinToStr(row.response_time).days
                        } Days, ${
                            hanldeTimeInMinToStr(row.response_time).hours
                        } Hours, ${
                            hanldeTimeInMinToStr(row.response_time).minutes
                        } Minutes`}
                    </Grid>
                    <Grid item xs={4}>
                        {`${
                            hanldeTimeInMinToStr(row.resolution_time).days
                        } Days, ${
                            hanldeTimeInMinToStr(row.resolution_time).hours
                        } Hours, ${
                            hanldeTimeInMinToStr(row.resolution_time).minutes
                        } Minutes`}
                    </Grid>
                    <Grid item xs={2}>
                        <Stack
                            sx={{ width: '100%' }}
                            direction={'row'}
                            justifyContent={'end'}
                        >
                            {row.is_applicable ? (
                                <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                >
                                    <DoneIcon />
                                    <Typography sx={{ marginInline: '15px' }}>
                                        Applicable
                                    </Typography>
                                </Stack>
                            ) : (
                                <Stack
                                    direction={'row'}
                                    justifyContent={'space-between'}
                                >
                                    <CloseIcon />
                                    <Typography sx={{ marginInline: '15px' }}>
                                        Not Applicable
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
                <Grid
                    container
                    key={`divider${index}`}
                    sx={{
                        marginLeft: 1,
                        marginRight: '36px',
                    }}
                >
                    <Grid item xs={12}>
                        {index !== rows.length - 1 && (
                            <Divider variant="middle" />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        ))
    }

    const getTableEmptyBody = () => {
        return (
            <Grid
                container
                sx={{
                    borderRadius: '10px',
                    height: '50px',
                    marginLeft: 1,
                    marginRight: '36px',
                    padding: 2,
                    marginTop: 0.5,
                    marginBottom: 0.5,
                }}
                alignItems={'center'}
            >
                <Grid item xs={2}>
                    -------
                </Grid>
                <Grid item xs={4}>
                    -------
                </Grid>
                <Grid item xs={4}>
                    -------
                </Grid>
                <Grid item xs={2}></Grid>
            </Grid>
        )
    }

    return (
        <CommonTable>
            {getTableHead()}
            {rows !== null && getTableBody()}
            {(rows === null || rows.length === 0) && getTableEmptyBody()}
        </CommonTable>
    )
}

export default SlaTable
