import { createReducer } from "@reduxjs/toolkit";
import {
    addAlert,
    removeAlert,
} from "./actions";
import type { IAlert } from "../../interfaces/IAlert";


interface IinitSystemAlertsReducer  {
    systemAlerts: IAlert[],
};

// system Alerts initial state
const initSystemAlertsReducer: IinitSystemAlertsReducer = {
    systemAlerts: [],
};

// departmentReducer reducer to handel action when dispatched
export const systemAlertsReducer = createReducer(initSystemAlertsReducer, builder => {
    builder
        .addCase(addAlert, (state, { payload }) => {
            if(state.systemAlerts.findIndex(alert => alert.id === payload.id) === -1)
           state.systemAlerts =  [...state.systemAlerts, payload]
        })
        .addCase(removeAlert, (state, { payload }) => {
            state.systemAlerts = state.systemAlerts.filter((alert: IAlert) => alert.id !== payload )
        })
});

export default systemAlertsReducer;
 
