import Stack from '@mui/material/Stack'
import CommonButton from '../common/CommonButton/CommonButton'
import CommonLoadingButton from '../common/CommonLoadingButton/CommonLoadingButton'

interface IActionNdCancel {
    sx?: any
    actionBtncolor: string
    justifyContent: string
    alignItems: string
    spacing: number
    direction: any
    actionBtnLabel: string
    cancelBtnLabel: string
    btnWidth: number | string
    btnHeight: number | string
    handleCancelAction: () => void
    handleAction: () => void
    pending: boolean
}

const ActionNdCancel = ({
    justifyContent,
    alignItems,
    spacing,
    direction,
    actionBtnLabel,
    cancelBtnLabel,
    btnWidth,
    btnHeight,
    sx,
    handleCancelAction,
    handleAction,
    pending,
    actionBtncolor,
}: IActionNdCancel) => {
    return (
        <Stack
            sx={sx}
            direction={direction}
            spacing={spacing}
            alignItems={alignItems}
            justifyContent={justifyContent}
        >
            <CommonButton
                color={'primary'}
                variant={'outlined'}
                sx={{ width: btnWidth, height: btnHeight }}
                onClick={handleCancelAction}
            >
                {cancelBtnLabel}
            </CommonButton>
            <CommonLoadingButton
                color={actionBtncolor}
                variant={'contained'}
                sx={{ width: btnWidth, height: btnHeight, boxShadow: 'none' }}
                onClick={handleAction}
                loading={pending}
            >
                {actionBtnLabel}
            </CommonLoadingButton>
        </Stack>
    )
}

export default ActionNdCancel
