import {
    Button,
    Stack,
    type SxProps,
    TextField,
    IconButton,
    Typography,
    CircularProgress,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import { useNavigate } from 'react-router-dom'
import dashBoardServices from '../../services/cspServices'
import { type IAlert } from '../../interfaces/IAlert'
import { addAlert } from '../../features/systemAlerts'
import { useState } from 'react'
import { PasswordStrengthChecker } from '../../helper/functions'
interface props {
    sx?: SxProps
    setChangePassword: () => void
}
const PasswordChange = ({ sx, setChangePassword }: props) => {
    const user = useAppSelector(userSelector)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const onsubmit = (data: any) => {
        console.log('data', data)
        setIsLoading(true)
        dashBoardServices
            .changePassword(data)
            .then((res: any) => {
                console.log('res', res)
                navigate('/', { replace: true })
                // if (data.rememberMe)
                //     window.localStorage.setItem('isLogedIn', 'true')
                // window.localStorage.setItem('accessToken', res.payload.access)
                const alertObj: IAlert = {
                    id: `password success`,
                    message: 'Password has been changed successfully',
                    state: true,
                    type: 'success',
                }
                dispatch(addAlert(alertObj))
            })
            .catch((e: any) => {
                console.log('e', e)
                let errorStr: string = ''
                Object.entries(e.response.data).map(([key, value]) => {
                    if (value instanceof Array) {
                        value.map((item: string, i) => {
                            errorStr += item + '\n'
                        })
                    }
                })
                const alertObj: IAlert = {
                    id: `password key`,
                    message: errorStr,
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm()
    return (
        <Stack direction={'column'} sx={sx}>
            <form onSubmit={handleSubmit(onsubmit)}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{ marginBottom: '10px' }}
                >
                    <Typography
                        sx={{
                            width: '100%',
                            color: 'var(--text-color, #000)',
                            fontSize: '18px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.18px',
                            textTransform: 'none',
                        }}
                    >
                        change password
                    </Typography>
                    <IconButton onClick={setChangePassword}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Stack>
                <label
                    style={{
                        width: '100%',
                        color: '#929EAE',
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        textTransform: 'capitalize',
                    }}
                >
                    Current Password
                </label>
                <Controller
                    name="currentPassword"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                        <>
                            <TextField
                                hiddenLabel
                                fullWidth
                                size="small"
                                type="password"
                                sx={{
                                    ...Styles.textField,
                                    marginTop: '0px',
                                    marginBottom: '24px',
                                }}
                                {...field}
                            />
                        </>
                    )}
                />
                <label
                    style={{
                        width: '100%',
                        color: '#929EAE',
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                    }}
                >
                    New Password
                </label>
                <Controller
                    name="new_password1"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Password required',
                        validate: (value) =>
                            PasswordStrengthChecker(value) ?? true,
                    }}
                    render={({ field }) => (
                        <>
                            <TextField
                                hiddenLabel
                                fullWidth
                                size="small"
                                type="password"
                                sx={{
                                    ...Styles.textField,
                                    marginTop: '0px',
                                    marginBottom: '24px',
                                }}
                                helperText={
                                    errors.new_password1
                                        ? errors.new_password1.message?.toString()
                                        : ''
                                }
                                {...field}
                            />
                        </>
                    )}
                />
                <label
                    style={{
                        width: '100%',
                        color: '#929EAE',
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '20px',
                        textTransform: 'none',
                    }}
                >
                    Confirm New Password
                </label>
                <Controller
                    name="new_password2"
                    control={control}
                    defaultValue=""
                    rules={{
                        required: 'Confirm Password is required',
                        validate: (value) =>
                            value === watch('new_password1')
                                ? true
                                : 'Password & Confirm Password do not match.',
                    }}
                    render={({ field }) => (
                        <>
                            <TextField
                                hiddenLabel
                                fullWidth
                                size="small"
                                type="password"
                                sx={{
                                    ...Styles.textField,
                                    marginTop: '0px',
                                    marginBottom: '24px',
                                }}
                                {...field}
                                helperText={
                                    errors.new_password2
                                        ? errors.new_password2.message?.toString()
                                        : ''
                                }
                            />
                        </>
                    )}
                />
                <Button type="submit" sx={Styles.button}>
                    {!isLoading ? 'Change' : <CircularProgress size={30} />}
                </Button>
            </form>
        </Stack>
    )
}
const Styles = {
    textField: {
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        textTransform: 'none',
        '& .focused': {
            border: '1px solid #F2F2F2',
        },
    },
    button: {
        width: '320px',
        height: '48.158px',
        flexShrink: 0,
        borderRadius: '4px',
        background: '#494454',
        color: '#fff',
        textTransform: 'none',
        '&:hover': {
            color: '#fff',
            background: '#494454',
        },
    },
}
export default PasswordChange
