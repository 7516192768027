import React, { useState, useEffect, useCallback } from 'react'
import './DateRangePicker.css'
import CommonDatePicker from '../../components/common/CommonDatePicker/CommonDatePicker'
import { IconButton, Stack } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

const DateRangePicker = ({ fromDate, toDate, setFromDate, setToDate }: any) => {
    // Wrap handleDateSelect with useCallback to memoize the function

    // Call the handleDateSelect function whenever fromDate or toDate changes

    return (
        <Stack direction={'column'} rowGap={3}>
            <Stack direction={'row'}>
                <CommonDatePicker
                    label="From"
                    value={fromDate}
                    disableFuture={true}
                    setValue={setFromDate}
                    slotProps={{
                        textField: {
                            sx: {
                                '& .css-7h74mh-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                    {
                                        borderColor: '#000',
                                        color: '#000',
                                    },
                                '& .css-3e0b97-MuiFormLabel-root-MuiInputLabel-root.Mui-error ':
                                    {
                                        color: '#000',
                                    },
                            },
                        },
                    }}
                />
                {/* <input
          type="date"
          value={fromDate}
          onChange={(e) => setFromDate(e.target.value)}
        /> */}
                <IconButton
                    size="small"
                    onClick={() => {
                        setFromDate('')
                    }}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#000',
                        },
                    }}
                    aria-label="delete"
                >
                    <ClearIcon />
                </IconButton>
            </Stack>
            <Stack direction={'row'}>
                <CommonDatePicker
                    label="To"
                    value={toDate}
                    setValue={setToDate}
                    disableFuture={true}
                    slotProps={{
                        textField: {
                            sx: {
                                '& .css-7h74mh-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':
                                    {
                                        borderColor: '#000',
                                        color: '#000',
                                    },
                                '& .css-3e0b97-MuiFormLabel-root-MuiInputLabel-root.Mui-error ':
                                    {
                                        color: '#000',
                                    },
                            },
                        },
                    }}
                />
                {/* <input
          type="date"
          value={toDate}
          onChange={(e) => setToDate(e.target.value)}
        /> */}
                <IconButton
                    size="small"
                    onClick={() => {
                        setToDate('')
                    }}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                        },
                    }}
                    aria-label="delete"
                >
                    <ClearIcon />
                </IconButton>
            </Stack>
        </Stack>
    )
}

export default DateRangePicker
