import Stack from '@mui/material/Stack/Stack'
import Grid from '@mui/material/Grid/Grid'
import { Button } from '@mui/material'
import EngineerCard from '../../../components/Cards/EngineerCard/EngineerCard'

interface Engineer {
    id: number
    user: User
    skills: Array<{
        skill: Skill
        rate: number
    }>
    team: {
        team: Team
        is_leader: boolean
    }
    is_online: boolean
}
interface User {
    email: string
    full_name: string
    jop_title: string
    phone: string
    is_active: boolean
}

interface Skill {
    id: number
    skill: string
    product: {
        id: number
        name: string
    }
}

interface Team {
    id: number
    name: string
    description: string
}
interface IListingCustomerSites {
    engineers: Engineer[]
    createNew: () => void
    seeDetails: (siteIdToBeEdited: number | undefined, index: number) => void
}
const ListingEngineers = ({
    engineers,
    createNew,
    seeDetails,
}: IListingCustomerSites) => {
    return (
        <Stack direction={'column'} sx={{ marginTop: '50px' }}>
            <Stack
                direction={'row'}
                justifyContent={'flex-end'}
                sx={{ paddingX: '50px' }}
            >
                <Button
                    variant={'text'}
                    disableFocusRipple
                    disableRipple
                    color={'primary'}
                    sx={{
                        width: '140px',
                        height: '38px',
                        borderRadius: '2px',
                        border: '1px solid #494454',
                        backgroundColor: '#FFF',
                    }}
                    onClick={createNew}
                >
                    New Engineer
                </Button>
            </Stack>
            <Stack
                direction={'row'}
                justifyContent={'center'}
                sx={{ paddingX: '80px' }}
            >
                <Grid container sx={{ marginTop: 3 }} rowGap={2} spacing={2}>
                    {engineers.map((eng: Engineer, i) => (
                        <Grid item xs={12}md={12} lg={6} xl={4} key={eng.id}>
                            <EngineerCard
                                JobTitle={eng.user.jop_title}
                                name={eng.user.full_name}
                                navigateToDetails={() => {
                                    seeDetails(eng.id, i)
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Stack>
    )
}

export default ListingEngineers
