import React from 'react'
import Box from '@mui/material/Box'

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
    sx?: any
}

const CommonTabPanel = ({
    children,
    value,
    index,
    sx,
    ...other
}: TabPanelProps) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={sx}>{children}</Box>}
        </div>
    )
}

export default CommonTabPanel
