import { type SxProps } from '@mui/material'
import Avatar from '@mui/material/Avatar'

interface Props {
    children: any
    sx: SxProps
}

const CommonLetterAvatar = ({ children, sx }: Props) => {
    return <Avatar sx={sx}>{children}</Avatar>
}

export default CommonLetterAvatar
