import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
    Box,
    Button,
    FormControlLabel,
    MenuItem,
    Stack,
    Switch,
    type SwitchProps,
    TextField,
    styled,
    FormLabel,
    CircularProgress,
} from '@mui/material'
import BasicModal from '../../BasicModal/BasicModal'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import { useForm, Controller } from 'react-hook-form'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import Select from 'react-select'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import { LoadingButton } from '@mui/lab'
import { type IAlert } from '../../../interfaces/IAlert'
import { useAppDispatch } from '../../../app/hooks'
import { addAlert } from '../../../features/systemAlerts'
import MySwitch from '../../common/CommonIOSSwitch/Switch'
import { useWindowSize } from 'usehooks-ts'

interface props {
    id?: number
    Engineer?: OldEngObject
    open: boolean
    handleClose: () => void
    handleDelete: () => void
}
interface OldEngObject {
    user: {
        email: string
        full_name: string
        job_title: string
        phone: string
        is_active: boolean
        password: string
        password2: string
    }
    skills: Array<{
        skill: {
            id: number
            product: {
                id: number
                name: string
            }
        }
        rate: number
    }>
    team: {
        team: {
            description: string
            id: number
            name: string
        }
    }
}
interface skill {
    id: string
    skill: string
    rate: number
}
interface Product {
    id: string
    name: string
    skills: skill[]
}
interface FormData {
    full_name: string
    jop_title: string
    phone: string
    email: string
    password: string // Only if not disabled
    password2: string // Only if not disabled
    team: any
    skill: string[]
    is_active: any
    // Add any other fields as needed
}
const EngineerCreateModal = ({
    id,
    Engineer,
    open,
    handleClose,
    handleDelete,
}: props) => {
    // console.log('Engineer', Engineer)
    const firstRender = useRef(0)
    const [teamOptions, setTeamOptions] = useState<any>()
    const [chartDimensions, setChartDimensions] = useState(450)
    const [selectedOptions, setSelectedOptions] = useState<any>([])
    const dispatch = useAppDispatch()
    const { width, height } = useWindowSize()
    const [loading, setLoading] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState(false)
    const [skills, setSkills] = useState<any>([])
    const { data: productsData, isPending: isFetchingProdcuts } = useFetch(
        dashBoardServices.getProducts
    )
    const { data: TeamsData, isPending: isFetching } = useFetch(
        dashBoardServices.getTeams
    )

    useEffect(() => {
        if (!isFetchingProdcuts) {
            let skillsArr: skill[] = []
            productsData.map((product: Product) => {
                product.skills.map((item) => {
                    item.skill = product.name + ' - ' + item.skill
                })
                skillsArr = [...skillsArr, ...product.skills]
            })
            const options = skillsArr.map((skill: any) => ({
                value: skill.id,
                label: skill.skill,
                rate: 1,
            }))
            setSkills(options)
        }
    }, [productsData])

    useEffect(() => {
        if (!isFetching) {
            // let skillsArr: skill[] = []
            TeamsData.map((product: any) => {
                // console.log('product', product)
            })

            const options = TeamsData?.map((item: any) => ({
                value: item.id,
                label: item.name,
            }))
            setTeamOptions(options)
            // setSkills(skillsArr)
        }
    }, [TeamsData])
    useEffect(() => {
        if (id) {
            const team = teamOptions.find(
                (item: any) => item.value == Engineer?.team.team.id
            )
            if (team) {
                setValue('team', team)
            }
            const selectedSkills: any = []
            Engineer?.skills.map((skill) => {
                const selectedSkill = skills.find(
                    (item: any) => item.value == skill.skill.id
                )
                if (selectedSkill) {
                    selectedSkill.rate = skill.rate
                    selectedSkills.push(selectedSkill)
                }
            })

            if (selectedSkills.length > 0) {
                setValue('skill', selectedSkills)
            }
            // @ts-expect-error will not enter if undefined
            Object.entries(Engineer?.user).map(([key, value]) => {
                // @ts-expect-error skip
                setValue(key, value)
            })
        }
        // console.log(Engineer)
    }, [id])

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        setError,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        reValidateMode: 'onChange',
    })
    // const watchSkill = watch('skill', [])
    const onSubmit = (form: any) => {
        if (!id) {
            // console.log('form', form)

            const data = getValues()

            // console.log('selectedOptions', selectedOptions)

            // console.log('dataasasaa', data) // Handle form submission here

            const newEngObject = {
                user: {
                    email: data.email,
                    full_name: data.full_name,
                    jop_title: data.jop_title,
                    phone: data.phone,
                    is_active: true,
                    password: data.password,
                    password2: data.password2,
                },
                skills:
                    data.skill?.map((item: any) => {
                        return {
                            skill: item.value,
                            rate: item.rate,
                        }
                    }) || undefined,
                team: {
                    team: data.team?.value,
                    is_leader: false,
                },
            }
            // // console.log('newEngObject', newEngObject)
            // const formData = new FormData()

            // for (const key in newEngObject) {
            //     // @ts-expect-error i meed
            //     formData.append(key, newEngObject[key])
            // }
            setLoading(true)
            dashBoardServices
                .createNewEngineer(newEngObject)
                .then((res) => {
                    // console.log('res', res)
                    closeModal()
                })
                .catch((e) => {
                    // console.log('e', e)
                    const errors = e.response.data
                    Object.entries(errors).map(([key, value]) => {
                        if (value instanceof Array) {
                            value.map((item, i) => {
                                const alertObj: IAlert = {
                                    id: `${key}${i}key`,
                                    message: item,
                                    state: true,
                                    type: 'error',
                                }
                                dispatch(addAlert(alertObj))
                            })
                        }
                    })
                })
                .finally(() => {
                    // closeModal()
                    setLoading(false)
                })
        } else {
            // console.log('form', form)

            const data = getValues()

            // console.log('selectedOptions', selectedOptions)

            // console.log('dataasasaa', data) // Handle form submission here

            const newEngObject = {
                user: {
                    email: data.email,
                    full_name: data.full_name,
                    jop_title: data.jop_title,
                    phone: data.phone,
                    is_active: data.is_active,
                },
                skills:
                    data.skill?.map((item: any) => {
                        return {
                            skill: item.value,
                            rate: item.rate,
                        }
                    }) || [],
                team: {
                    team: data.team?.value,
                    is_leader: false,
                },
            }
            // console.log('newEngObject', newEngObject)
            // const formData = new FormData()

            // for (const key in newEngObject) {
            //     // @ts-expect-error i meed
            //     formData.append(key, newEngObject[key])
            // }
            setLoading(true)
            dashBoardServices
                .updateEngineer(newEngObject, id)
                .then((res) => {
                    // console.log('res', res)
                    closeModal()
                })
                .catch((e) => {
                    // console.log('e', e)
                    const errors = e.response.data
                    Object.entries(errors)?.map(([key, value]) => {
                        if (value instanceof Array) {
                            value.map((item, i) => {
                                const alertObj: IAlert = {
                                    id: `${key}${i}key`,
                                    message: item,
                                    state: true,
                                    type: 'error',
                                }
                                dispatch(addAlert(alertObj))
                            })
                        }
                    })
                })
                .finally(() => {
                    // closeModal()
                    setLoading(false)
                })
        }
    }
    // console.log('errors', errors)
    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            // @ts-expect-error no error here
            message: value?.message ?? '',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })

    React.useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name == 'skill') {
                //
                setSelectedOptions(value.skill)

                // console.log(value.skill, name, type)
            }
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch])

    // useEffect(() => {
    //     // console.log('watchSkill', watchSkill)
    //     // setSelectedOptions(watchSkill)
    // }, [watchSkill])

    const handleRate = (selectionIndex: number, Rate: number) => {
        const dataArr = [...selectedOptions]
        dataArr[selectionIndex].rate = Rate + 1
        setValue('skill', dataArr)
        setSelectedOptions(dataArr)
    }
    const closeModal = () => {
        reset()
        setSelectedOptions([])
        handleClose()
    }
    return (
        <BasicModal open={open} handleClose={closeModal}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#000',
                            borderRadius: '12px',
                            zIndex: 2,
                            opacity: 0.2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress color="inherit" size={50} />
                    </Box>
                ) : null}
                <Box sx={{ overflow: 'auto', p: 3, maxHeight: '700px' }}>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{ marginBottom: '30px' }}
                    >
                        <Stack direction={'column'}>
                            <CommonTypography
                                sx={{
                                    fontWeight: 600,
                                    color: '#000',
                                    fontFamily: 'Inter',
                                    fontSize: '20px',
                                    fontStyle: 'normal',

                                    lineHeight: 'normal',
                                    textTransform: 'capitalize',
                                }}
                                variant={'h6'}
                            >
                                {id ? 'Engineer Details' : 'New Engineer'}
                            </CommonTypography>
                        </Stack>
                        <Stack direction={'column'}>
                            <Controller
                                name="is_active"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        sx={{ padding: '0px', margin: '0px' }}
                                        control={
                                            <MySwitch
                                                field={field}
                                                active={field.value}
                                                transition={33}
                                            />
                                        }
                                        label=""
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction={'column'} rowGap={3}>
                            <Controller
                                name="full_name"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Full Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Full Name"
                                        error={!!errors.full_name}
                                        helperText={
                                            errors.full_name
                                                ? 'Full Name is required'
                                                : ''
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name="jop_title"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Job Title"
                                    />
                                )}
                            />

                            <Controller
                                name="phone"
                                control={control}
                                defaultValue=""
                                rules={{
                                    pattern: {
                                        value: /^(\+\d{1,3}\s?)?(\d{1,4}[\s\-]?)?\d{1,14}$/i,
                                        message: 'Wrong Phone Number .',
                                    },

                                    maxLength: {
                                        value: 15,
                                        message: 'max length is 15 .',
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Phone Number"
                                        error={!!errors.phone}
                                        helperText={
                                            errors.phone
                                                ? errors.phone.message
                                                : ''
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name="email"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Email is required',
                                    pattern:
                                        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i ||
                                        'invalid mail pattern',
                                }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        // @ts-expect-error accepted
                                        disabled={id}
                                        label="Email"
                                        error={!!errors.email}
                                        helperText={
                                            errors.email
                                                ? 'Invalid email address'
                                                : ''
                                        }
                                    />
                                )}
                            />
                            <Stack direction={'row'} gap={1}>
                                {!id && (
                                    <Controller
                                        name="password"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required:
                                                id == undefined
                                                    ? 'Password is required'
                                                    : false,
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                sx={{
                                                    ...styles.textField,
                                                    flex: 1,
                                                }}
                                                {...field}
                                                // @ts-expect-error accepted
                                                disabled={id}
                                                type="password"
                                                label="Password"
                                                error={!!errors.password}
                                                helperText={
                                                    errors.password
                                                        ? 'Password is required'
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                )}

                                {!id && (
                                    <Controller
                                        name="password2"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required:
                                                'Confirm Password is required',
                                            validate: (value) =>
                                                value === watch('password')
                                                    ? true
                                                    : 'Password & Confirm Password do not match.',
                                        }}
                                        render={({ field }) => (
                                            <TextField
                                                sx={{
                                                    ...styles.textField,
                                                    flex: 1,
                                                }}
                                                {...field}
                                                type="password"
                                                // @ts-expect-error accepted
                                                disabled={id}
                                                label="Confirm Password"
                                                error={!!errors.password2}
                                                helperText={
                                                    errors.password2
                                                        ? 'Passwords do not match'
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                )}
                            </Stack>
                            <Stack
                                direction={'column'}
                                sx={{ marginTop: '-10px' }}
                            >
                                <FormLabel>Team * </FormLabel>
                                <Controller
                                    name="team"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'You must select a team',
                                    }}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isClearable
                                            // Enable multi-select functionality
                                            placeholder="Select Team" // Add a placeholder text
                                            // Use the selectedProduct state as the value
                                            // onChange={(val) => {
                                            //     // Handle the change event to update the selectedProduct state
                                            //     // @ts-expect-error there is value
                                            //     setSelectedTeam(val)
                                            // }}
                                            options={teamOptions}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: '50px', // Set the desired height
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: '#fff', // Change the background color
                                                    borderRadius: '4px', // Apply border radius
                                                    boxShadow:
                                                        '0 2px 4px rgba(0, 0, 0, 0.2)', // Apply box shadow
                                                    zIndex: 9999, // Set the desired z-index value
                                                }),
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack
                                direction={'column'}
                                sx={{ marginTop: '-10px' }}
                            >
                                <FormLabel>Skills</FormLabel>
                                <Controller
                                    name="skill" // Specify the name for your select field
                                    control={control} // Pass the control prop from useForm
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isMulti // Enable multi-select functionality
                                            placeholder="Select skills" // Add a placeholder text
                                            // // value={selectedOptions} // Use the selectedProduct state as the value
                                            // onChange={(Optionsselected) => {
                                            //     // Handle the change event to update the selectedProduct state
                                            //     setSelectedOptions(Optionsselected)
                                            // }}
                                            options={skills}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: '50px', // Set the desired height
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: '#fff', // Change the background color
                                                    borderRadius: '4px', // Apply border radius
                                                    boxShadow:
                                                        '0 2px 4px rgba(0, 0, 0, 0.2)', // Apply box shadow
                                                    zIndex: 9999, // Set the desired z-index value
                                                }),
                                            }}
                                        />
                                    )}
                                />
                            </Stack>

                            {selectedOptions?.length == 0
                                ? ''
                                : selectedOptions.map(
                                      (option: any, index: number) => {
                                          return (
                                              <Stack
                                                  sx={{
                                                      borderRadius: '5px',
                                                      border: ' 0.6px solid #E9E9E9',
                                                      backgroundColor: '#FFF',
                                                      paddingX: '10px',
                                                      height: '32.63px',
                                                      alignItems: 'center',
                                                  }}
                                                  key={option.skill}
                                                  direction={'row'}
                                                  justifyContent={
                                                      'space-between'
                                                  }
                                              >
                                                  <Box>{option.label}</Box>
                                                  <Box
                                                      sx={{
                                                          display: 'flex',
                                                          flexDirection: 'row',
                                                          alignItems: 'center',
                                                      }}
                                                  >
                                                      {Array.from({
                                                          length: 5,
                                                      }).map(
                                                          (item, RateIndex) => {
                                                              return (
                                                                  <Button
                                                                      key={
                                                                          index.toString() +
                                                                          '' +
                                                                          RateIndex.toString()
                                                                      }
                                                                      variant="outlined"
                                                                      size="small"
                                                                      sx={{
                                                                          ...styles.rateButton,
                                                                          backgroundColor:
                                                                              option.rate ===
                                                                              RateIndex +
                                                                                  1
                                                                                  ? '#47A5A6'
                                                                                  : '#F8F8F8',
                                                                          color:
                                                                              option.rate ===
                                                                              RateIndex +
                                                                                  1
                                                                                  ? '#FFF'
                                                                                  : '#494454',
                                                                      }}
                                                                      onClick={() => {
                                                                          handleRate(
                                                                              index,
                                                                              RateIndex
                                                                          )
                                                                      }}
                                                                  >
                                                                      {RateIndex +
                                                                          1}
                                                                  </Button>
                                                              )
                                                          }
                                                      )}
                                                  </Box>
                                              </Stack>
                                          )
                                      }
                                  )}
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Stack direction={'column'}></Stack>
                            <Stack
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {id && (
                                    <Button
                                        onClick={handleDelete}
                                        sx={{
                                            color: '#FC5632',
                                            fontSize: '18px',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                            textTransform: 'capitalize',
                                            marginTop: '48px',
                                            marginX: '15px',
                                        }}
                                    >
                                        delete
                                    </Button>
                                )}

                                <Stack
                                    sx={{ marginTop: '48px' }}
                                    direction={'row'}
                                    spacing={3}
                                    alignItems={'center'}
                                    justifyContent={'end'}
                                >
                                    <Button
                                        color={'primary'}
                                        variant={'outlined'}
                                        sx={{ width: '140px', height: '40px' }}
                                        onClick={closeModal}
                                    >
                                        {'Cancel'}
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        color="primary"
                                        variant={'contained'}
                                        sx={{
                                            width: '140px',
                                            height: '40px',
                                            boxShadow: 'none',
                                        }}
                                        // onClick={handleSubmit(onSubmit)}
                                        loading={false}
                                    >
                                        {id ? 'Save' : 'Create'}
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '770px',
    p: 2,
}

const styles = {
    rateButton: {
        borderRadius: '4px',
        border: '0.4px solid #A8A8A8',
        minWidth: '20.94px',
        maxWidth: '20.94px',
        height: '20px',
        marginInline: '4px',
        color: '#494454',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#47A5A6',
            color: '#FFF',
            border: '0.4px solid #A8A8A8',
        },
    },
    textField: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
    },
}

export default EngineerCreateModal
