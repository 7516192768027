import { useEffect, useState } from 'react'
import { Stack, Grid, CircularProgress } from '@mui/material'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import SearchBar from '../../../components/SearchBar/SearchBar'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import CustomerCreateUpdateModal from '../../../components/Modals/CustomerCreateUpdateModal/CustomerCreateUpdateModal'
import CommonCheckbox from '../../../components/common/CommonCheckbox/CommonCheckbox'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import CustomerCard from '../../../components/Cards/CustomerCard/CustomerCard'
import type { ICustomerInList } from '../../../interfaces/ICustomerInList'
import PickerWithButtonField from '../../../components/common/CommonBtnDatePicker/CommonBtnDatePicker'
import { type Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import { useAppSelector } from '../../../app/hooks'
import { selectUserReducer } from '../../../features/user'
import { apiClient } from '../../../services/cspServices'
import baseURL from '../../../services/baseUrl'
import { fetchAndSetCustomerImages } from '../../../helper/functions'
type CustomerImages = Record<number, string>
interface FilterCustomer {
    search: string
    contract_ends_by: string
    have_sla: string
    do_not_have_sla: string
}

interface ICustomersDashboard {
    customersList: ICustomerInList[]
    adminSearchValue: FilterCustomer
    setAdminSearch: (data: any) => void
    pending: boolean
}

const CustomersDashboard = ({
    customersList,
    adminSearchValue,
    setAdminSearch,
    pending,
}: ICustomersDashboard) => {
    const [open, setOpen] = useState(false)
    const [dateValue, setDateValue] = useState<Dayjs | null>(null)
    const { user } = useAppSelector(selectUserReducer)
    const [customerImages, setCustomerImages] = useState<CustomerImages>({})
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const [isSlaChecked, setIsSlaChecked] = useState(false)

    const handleCheckboxChange = () => {
        setIsSlaChecked(!isSlaChecked)
        setAdminSearch((prevState: FilterCustomer) => ({
            ...prevState,
            have_sla: isSlaChecked ? '' : 'true',
        }))
    }

    const [isNoSlaChecked, setNoIsSlaChecked] = useState(false)

    const handleNoSlaCheckboxChange = () => {
        console.log('handleNoSlaCheckboxChange', isNoSlaChecked)

        setAdminSearch((prevState: FilterCustomer) => {
            const curr = isNoSlaChecked
            setNoIsSlaChecked(!isNoSlaChecked)

            return {
                ...prevState,
                do_not_have_sla: curr ? '' : 'false',
            }
        })
    }
    useEffect(() => {
        fetchAndSetCustomerImages(customersList, setCustomerImages)
    }, [customersList])

    useEffect(() => {
        console.log('dateValue', dateValue)
        setAdminSearch((prevState: FilterCustomer) => ({
            ...prevState,
            contract_ends_by: dateValue
                ? dayjs(dateValue).format('YYYY-MM-DD')
                : '',
        }))
    }, [dateValue])

    return (
        <>
            <Stack
                direction={'column'}
                spacing={4} // TODO: CHange that
                sx={{ paddingLeft: '32px', paddingRight: '32px' }}
            >
                <Stack direction={'row'} spacing={0.5}>
                    <CommonTypography variant={'h6'} sx={{ color: '#33303B' }}>
                        Wlecome Back,
                    </CommonTypography>
                    <CommonTypography
                        variant={'h6'}
                        sx={{ color: 'rgba(71, 165, 166, 1)' }}
                    >
                        {user.full_name}
                    </CommonTypography>
                </Stack>
                <Stack direction={'row'}>
                    <CommonTypography variant={'h5'}>
                        Customers Dashboard
                    </CommonTypography>
                </Stack>
                <Grid
                    container
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Grid item xs={6}>
                        <SearchBar
                            id="customer-dashboard-search-bar"
                            label="Search by customer name"
                            onChange={(searchValueStr) => {
                                setAdminSearch((prevState: FilterCustomer) => ({
                                    ...prevState,
                                    search: searchValueStr,
                                }))
                            }}
                            searchValue={adminSearchValue.search}
                            handleClearAction={() => {
                                setAdminSearch((prevState: FilterCustomer) => ({
                                    ...prevState,
                                    search: '',
                                }))
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} textAlign={'end'}>
                        <CommonButton
                            color={'primary'}
                            variant={'contained'}
                            sx={{
                                width: 209,
                                height: 40,
                                boxShadow: 'none',
                            }}
                            onClick={handleOpen}
                        >
                            New Customer
                        </CommonButton>
                    </Grid>
                </Grid>
                <Stack direction={'row'} columnGap={3}>
                    <CommonCheckbox
                        label="Have SLA"
                        checked={isSlaChecked}
                        onChange={handleCheckboxChange}
                    />
                    <CommonCheckbox
                        label="Don't Have SLA"
                        checked={isNoSlaChecked}
                        onChange={handleNoSlaCheckboxChange}
                    />
                    <CommonCheckbox
                        label="With Expired Contract"
                        onChange={(value) => {
                            console.log('value', value.target.checked)
                            setAdminSearch((prevState: FilterCustomer) => ({
                                ...prevState,
                                with_expired_contract: value.target.checked
                                    ? dayjs().format('YYYY-MM-DD')
                                    : '',
                            }))
                        }}
                    />
                    <PickerWithButtonField
                        value={dateValue}
                        setValue={setDateValue}
                    />
                </Stack>
            </Stack>
            {pending && (
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ minHeight: '80vh' }}
                >
                    <CircularProgress color="inherit" size={50} />
                </Stack>
            )}
            <Grid
                container
                columnSpacing={2}
                rowSpacing={2}
                sx={{
                    paddingRight: '32px',
                    paddingLeft: '32px',
                    marginTop: 1.5,
                }}
            >
                {!pending &&
                    customersList &&
                    customersList.map((customer) => {
                        console.log('customer', customer)

                        console.log('customerImages', customerImages)
                        return (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={4}
                                key={`customerCard${customer.id}`}
                            >
                                <CustomerCard
                                    id={customer.id}
                                    name={customer.name}
                                    contracts={customer.contracts}
                                    photo={customer.photo || ''}
                                    industry={customer.industry}
                                    products={customer.products}
                                />
                            </Grid>
                        )
                    })}
            </Grid>
            <CustomerCreateUpdateModal
                open={open}
                handleClose={handleClose}
                isEdit={false}
            />
        </>
    )
}

export default CustomersDashboard
