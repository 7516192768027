import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import Grid from '@mui/material/Grid/Grid'
import { customerSelector } from '../../../features/customers'
import { useAppSelector } from '../../../app/hooks'
import SiteCard from '../../../components/Cards/SiteCard/SiteCard'
import UserCard from '../../../components/Cards/UserCard/UserCard'

interface IListingCustomerUsers {
    createNew: () => void
    seeDetails: (siteIdToBeEdited: number) => void
}

const ListingCustomerUsers = ({
    createNew,
    seeDetails,
}: IListingCustomerUsers) => {
    const { customer } = useAppSelector(customerSelector)
    return (
        <Stack direction={'column'}>
            <CommonTypography variant={'subtitle1'} sx={{ fontWeight: 600 }}>
                All Customer Users
            </CommonTypography>
            <Stack direction={'row'} justifyContent={'end'}>
                <CommonButton
                    variant={'outlined'}
                    color={'secondary'}
                    sx={{ width: '140px', height: '38px' }}
                    onClick={createNew}
                >
                    New User
                </CommonButton>
            </Stack>
            <Grid container spacing={2} sx={{ marginTop: 3 }}>
                {customer.users.map((user, index) => (
                    <Grid item xs={12} sm={12} md={4} key={user.id}>
                        <UserCard
                            JobTitle={user.user.jop_title}
                            name={user.user.full_name}
                            // name={user.name !== null ? user.name : ''}
                            // address={user.address !== null ? site.address : ''}
                            navigateToDetails={() => {
                                seeDetails(index)
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </Stack>
    )
}

export default ListingCustomerUsers
