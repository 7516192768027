import CommonAlert from '../../common/CommonAlert/CommonAlert'
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded'
import CommonIconButton from '../../common/CommonIconButton/CommonIconButton'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import { type AlertColor } from '@mui/material'

interface IBasicErrorAlert {
    children: any
    sx?: any
    actionOnClick: any
    isOpen: boolean
    type?: AlertColor
}

const BasicErrorAlert = ({
    children,
    sx,
    actionOnClick,
    isOpen,
    type,
}: IBasicErrorAlert) => {
    // console.log('type', type)

    return (
        <Collapse in={isOpen}>
            <CommonAlert
                severity={type}
                sx={sx}
                action={
                    <CommonIconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={actionOnClick}
                    >
                        <CloseIcon fontSize="inherit" />
                    </CommonIconButton>
                }
            >
                {children}
            </CommonAlert>
        </Collapse>
    )
}

export default BasicErrorAlert
