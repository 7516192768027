import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { setUser, userSelector } from '../features/user'

const RequireAuth = () => {
    const dispatch = useAppDispatch()
    const { user } = useAppSelector(userSelector)
    console.log('userBeforRefrsh', user)
    const location = useLocation()
    const logedIn = window.localStorage.getItem('isLogedIn')
    console.log('logedIn', logedIn)
    const accessToken = window.localStorage.getItem('accessToken')
    console.log('accessToken', accessToken)
    const refreshToken = window.localStorage.getItem('refreshToken')
    console.log('refreshToken', refreshToken)
    if (!user) {
        dispatch(setUser(accessToken ?? ''))
    }
    return user?.is_superuser ? <Outlet /> : <Navigate to="/requests" replace />
    // return user?.is_superuser ? <Outlet /> : <Outlet />
}

export default RequireAuth
