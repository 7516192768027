import { useState, useEffect, useCallback } from 'react'
import BasicModal from '../../BasicModal/BasicModal'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Typography,
    type SxProps,
    useMediaQuery,
    CircularProgress,
} from '@mui/material'

import CommonTypography from '../../common/CommonTypography/CommonTypography'
import type { IAlert } from '../../../interfaces/IAlert'

import { useForm, Controller, useWatch, useFieldArray } from 'react-hook-form'
import { ReactComponent as Add } from '../../../assets/images/iconAdd.svg'
import { ReactComponent as Pdf } from '../../../assets/images/pdf.svg'
import { LoadingButton } from '@mui/lab'
import AttachmentFile from '../../common/AttachmentFileComponent/AttachmentFile'
import dashBoardServices from '../../../services/cspServices'
import { useSelector } from 'react-redux'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectUserReducer } from '../../../features/user'
import useFetch from '../../../hooks/useFetch'
import { useNavigate } from 'react-router'
import { addAlert } from '../../../features/systemAlerts'
import { useWindowSize } from 'usehooks-ts'

interface INewRequestModalModal {
    open: boolean
    handleClose: () => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}
interface requestSpecificationItem {
    key: string
    value: string
}
interface FormData {
    subject: string
    product: string
    RelatedTo: string
    priority: string
    description: string
    attachments: File[]
    CustomerProductRequestSpecifications: requestSpecificationItem[]
}
interface IStyles {
    title: SxProps
    textField: SxProps
    attachments: SxProps
}

const NewRequestModal = ({ handleClose, open }: INewRequestModalModal) => {
    const [chartDimensions, setChartDimensions] = useState(450)
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    const [loading, setLoading] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])
    const dispatch = useAppDispatch()
    const { width, height } = useWindowSize()

    const [filesIds, setFilesIds] = useState<any>([])
    const { user } = useAppSelector(selectUserReducer)
    const navigate = useNavigate()
    const [products, setProducts] = useState<any>([])
    const [priority, setPriority] = useState<any>([])
    const [relatedTo, setRelatedTo] = useState<any>([])
    const [productsData, setProductsData] = useState<any>([])
    const [isPending, setIsPending] = useState(true)
    const [error, setError] = useState<any>(null)

    const [priorityData, setPriorityData] = useState<any>([])
    const [isPendingPriority, setIsPendingPriority] = useState(true)
    const [errorPriority, setErrorPriority] = useState<any>(null)

    useEffect(() => {
        if (user.customer_id) {
            const fetchProducts = async () => {
                try {
                    const response =
                        await dashBoardServices.getCustomerProducts(
                            user.customer_id
                        )
                    setProductsData(response.data)
                    setIsPending(false)
                } catch (error) {
                    setError(error)
                    setIsPending(false)
                }
            }

            const fetchPriorityData = async () => {
                try {
                    const response =
                        await dashBoardServices.getCustomerpriority(
                            user.customer_id
                        )
                    setPriorityData(response.data)
                    setIsPendingPriority(false)
                } catch (error) {
                    setErrorPriority(error)
                    setIsPendingPriority(false)
                }
            }

            fetchProducts()
            fetchPriorityData()
        }
    }, [user.customer_id])
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<FormData>()
    // console.log('errors', errors)
    const productId = useWatch({
        control,
        name: 'product', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
        // default value before the render
    })
    const { fields, append, prepend, remove, swap, move, insert } =
        useFieldArray({
            control, // control props comes from useForm (optional: if you are using FormContext)
            name: 'CustomerProductRequestSpecifications', // unique name for your Field Array
        })
    const onsubmit = (data: any) => {
        // console.log('data', data)
        setLoading(true)
        const requset = {
            subject: data.subject,
            description: data.description,
            related_to: data.RelatedTo,
            priority: data.priority,
            attachments: filesIds.map((item: any) => {
                return item.id
            }),
            RequestSpecifications: data.CustomerProductRequestSpecifications,
        }
        // console.log('requset', requset)
        dashBoardServices
            .createNewRequest(requset)
            .then(({ data: { id } }: any) => {
                navigate(`/requests/${+id}/0`)
            })
            .catch((e) => {
                // console.log('e', e)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    // console.log('firstName', productId)

    const onErrors = () => {}
    const handleDeleteRequest = (id: number) => {
        dashBoardServices
            .deleteFileAttachment(id)
            .then((res) => {
                if (res.status === 204) {
                    const newIds = filesIds.filter((x: any) => x.id != id)
                    setFilesIds(newIds)
                }
                // console.log('res', res)
            })
            .catch((e) => {
                // console.log('e', e)
            })
    }
    const handleFileUpload = (event: any) => {
        const Ids = [...filesIds]
        // console.log('event', event.target.files[0])
        setFileLoading(true)
        Object.entries(event.target.files).map(([key, value]: any) => {
            const myformData = new FormData()
            myformData.append('file', value)
            dashBoardServices
                .newFileAttachment(myformData)
                .then((res: any) => {
                    const FileObj: any = { id: res?.data?.id, name: value.name }
                    // console.log('res', res)
                    Ids.push(FileObj)
                    setFilesIds((prev: any) => {
                        // console.log('prev', prev)

                        return [...prev, FileObj]
                    })
                })
                .catch((e) => {
                    // console.log('e', e)
                })
                .finally(() => {
                    setFileLoading(false)
                })
        })
    }
    useEffect(() => {
        if (productsData && !error && productsData.length > 0) {
            setProducts(productsData)
        }
    }, [productsData])
    useEffect(() => {
        if (
            priorityData &&
            !errorPriority &&
            !isPendingPriority &&
            priorityData.length > 0
        ) {
            // console.log('priorityData', priorityData)
            setPriority(priorityData[0].priorities)
        }
    }, [priorityData])
    useEffect(() => {
        // // console.log('productId', productId
        fields.length = 0
        setValue('CustomerProductRequestSpecifications', [])
        if (productId) {
            let skillsTest: any = null
            products.map((x: any) => {
                if (x.id == productId) {
                    skillsTest = x
                    // console.log('xforFields ', x)
                    if (x.CustomerProductRequestSpecifications.length > 0) {
                        x.CustomerProductRequestSpecifications.map(
                            (item: any) => {
                                append({ key: item.key, value: '' })
                            }
                        )
                    }
                }
            })
            if (skillsTest) {
                // console.log('skillsTest', skillsTest)
                setRelatedTo(skillsTest.skills)
            }
        }
    }, [productId])

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name == 'product') {
                let skillsTest: any = null
                products.map((x: any) => {
                    if (x.product.id == value.product) skillsTest = x
                })
                if (skillsTest) {
                    // console.log('skillsTest', skillsTest)
                    // setRelatedTo(skills.skills)
                }
            }
            // console.log(value, name, type)
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch])
    const handleModalClose = () => {
        reset()
        setFilesIds([])
        setValue('CustomerProductRequestSpecifications', [])
        fields.length = 0
        handleClose()
    }

    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            message: value?.message ?? 'validation error',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })
    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        // maxHeight: '600px',
                        maxHeight: chartDimensions,
                        overflow: 'auto',
                        p: 3,
                    }}
                >
                    <CommonTypography
                        sx={{
                            marginTop: '10px',
                            marginLeft: '10px',
                            marginBottom: '48px',
                            color: '#000',
                            fontFamily: 'Inter',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                        }}
                        variant={'h6'}
                    >
                        New Request
                    </CommonTypography>
                    <form onSubmit={handleSubmit(onsubmit)}>
                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>Subject*</Typography>
                            <Controller
                                name="subject"
                                control={control}
                                rules={{ required: 'subject is required' }}
                                render={({ field }) => (
                                    <TextField
                                        size="small"
                                        fullWidth
                                        {...field}
                                        sx={styles.textField}
                                        error={!!errors.subject}
                                        helperText={
                                            errors.subject
                                                ? errors.subject.message
                                                : ''
                                        }
                                    />
                                )}
                            />
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>Product* </Typography>
                            <Controller
                                name="product"
                                control={control}
                                rules={{ required: 'product is required' }}
                                render={({ field }) => (
                                    <TextField
                                        select
                                        helperText={
                                            errors.product
                                                ? errors.product.message
                                                : ''
                                        }
                                        error={!!errors.product}
                                        SelectProps={{
                                            MenuProps: {
                                                elevation: 5,
                                                sx: {
                                                    '.MuiMenu-paper': {
                                                        borderRadius: '4px',
                                                        border: '1.5px solid #DCDCDC',
                                                        background: '#FEFEFE',
                                                        boxShadow:
                                                            '0 5px 5px rgba(0, 0, 0, 0.7)', // Custom box shadow,
                                                    },
                                                },
                                            },
                                        }}
                                        size="small"
                                        fullWidth
                                        {...field}
                                        sx={styles.textField}
                                    >
                                        {products.map(
                                            (item: any, i: number) => {
                                                return (
                                                    <MenuItem
                                                        sx={{
                                                            boxShadow:
                                                                'inherit',
                                                        }}
                                                        key={`product-${i}-${+item.id}`}
                                                        value={item.id}
                                                    >
                                                        {item.product.name}
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </TextField>
                                )}
                            />
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>
                                Related to*
                            </Typography>
                            <Controller
                                name="RelatedTo"
                                control={control}
                                rules={{ required: 'Related To is required' }}
                                render={({ field }) => (
                                    <TextField
                                        select
                                        size="small"
                                        fullWidth
                                        {...field}
                                        error={!!errors.RelatedTo}
                                        SelectProps={{
                                            MenuProps: {
                                                sx: {
                                                    '.MuiMenu-paper': {
                                                        borderRadius: '4px',
                                                        border: '1.5px solid #DCDCDC',
                                                        background: '#FEFEFE',
                                                        boxShadow:
                                                            '0 5px 5px rgba(0, 0, 0, 0.7)', // Custom box shadow,
                                                    },
                                                },
                                            },
                                        }}
                                        helperText={
                                            errors.RelatedTo
                                                ? errors.RelatedTo.message
                                                : ''
                                        }
                                        sx={styles.textField}
                                    >
                                        {relatedTo.map((item: any) => {
                                            return (
                                                <MenuItem
                                                    value={item.id}
                                                    key={item.skill.id}
                                                >
                                                    {item.skill.skill}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                )}
                            />
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>Priority*</Typography>
                            <Controller
                                name="priority"
                                control={control}
                                rules={{ required: 'priority is required' }}
                                render={({ field }) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        {...field}
                                        select
                                        error={!!errors.priority}
                                        SelectProps={{
                                            MenuProps: {
                                                sx: {
                                                    '.MuiMenu-paper': {
                                                        borderRadius: '4px',
                                                        border: '1.5px solid #DCDCDC',
                                                        background: '#FEFEFE',
                                                        boxShadow:
                                                            '0 5px 5px rgba(0, 0, 0, 0.7)', // Custom box shadow,
                                                    },
                                                },
                                            },
                                        }}
                                        sx={styles.textField}
                                        helperText={
                                            errors.priority
                                                ? errors.priority.message
                                                : ''
                                        }
                                    >
                                        {priority.map(
                                            (item: any, i: number) => {
                                                return (
                                                    <MenuItem
                                                        key={`priority${i}`}
                                                        value={item.id}
                                                    >
                                                        {item.priority_name}
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </TextField>
                                )}
                            />
                        </Stack>
                        <Stack direction={'column'}>
                            <Typography sx={styles.title}>
                                Description
                            </Typography>
                            <Controller
                                name="description"
                                control={control}
                                rules={{ required: 'description is required' }}
                                render={({ field }) => (
                                    <TextField
                                        multiline
                                        minRows={4}
                                        size="small"
                                        fullWidth
                                        {...field}
                                        error={!!errors.description}
                                        sx={styles.textField}
                                        helperText={
                                            errors.description
                                                ? errors.description.message
                                                : ''
                                        }
                                    />
                                )}
                            />
                        </Stack>
                        <Typography sx={styles.title}>Attachments</Typography>
                        <Stack direction="row" sx={styles.attachments}>
                            {filesIds.length > 0 &&
                                filesIds.map((item: any) => {
                                    return (
                                        <AttachmentFile
                                            key={item.id}
                                            name={item.name}
                                            IconFile={Pdf}
                                            Id={item.id}
                                            deleteFile={handleDeleteRequest}
                                        />
                                    )
                                })}

                            <Stack
                                alignSelf={'flex-end'}
                                sx={{ maxWidth: '95px', marginLeft: 'auto' }}
                            >
                                <label htmlFor="upload-image">
                                    <Button
                                        component="span"
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderRadius: '4px',
                                            border: '1px solid #E7E5E5',
                                            backgroundColor: '#FFF',
                                            width: '95px',
                                            height: '32px',
                                            flexShrink: 0,
                                        }}
                                    >
                                        {!fileLoading && (
                                            <Add
                                                style={{ marginRight: '6px' }}
                                            />
                                        )}
                                        {!fileLoading && 'add'}
                                        {fileLoading && (
                                            <CircularProgress size={25} />
                                        )}
                                    </Button>
                                    <input
                                        id="upload-image"
                                        hidden
                                        accept="*"
                                        type="file"
                                        multiple
                                        onChange={handleFileUpload}
                                    />
                                </label>
                            </Stack>
                        </Stack>
                        {fields.length > 0 && (
                            <>
                                <Typography
                                    sx={{
                                        ...styles.title,
                                        marginTop: '50px',
                                        fontSize: 16,
                                        fontWeight: 800,
                                    }}
                                >
                                    Request Specifications
                                </Typography>
                                {fields.map((field, index) => {
                                    // console.log('field', field)
                                    return (
                                        <Stack
                                            display={'flex'}
                                            key={field.key}
                                            direction={'row'}
                                            gap={2}
                                            alignItems={'center'}
                                        >
                                            <Typography
                                                sx={{
                                                    ...styles.title,
                                                    marginBottom: '3%',
                                                }}
                                            >
                                                {field.key.toString()}
                                            </Typography>
                                            <Controller
                                                key={field.id}
                                                name={`CustomerProductRequestSpecifications.${index}.value`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        size="small"
                                                        fullWidth
                                                        {...field}
                                                        sx={styles.textField}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    )
                                })}
                            </>
                        )}

                        <Stack
                            sx={{ marginTop: '48px' }}
                            direction={'row'}
                            spacing={3}
                            alignItems={'center'}
                            justifyContent={'end'}
                        >
                            <Button
                                color={'primary'}
                                variant={'outlined'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    color: '#494454',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                }}
                                onClick={handleModalClose}
                            >
                                {'Cancel'}
                            </Button>
                            <LoadingButton
                                type="submit"
                                color="primary"
                                variant={'contained'}
                                sx={{
                                    width: '140px',
                                    height: '40px',
                                    boxShadow: 'none',
                                    fontFamily: 'Inter',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '24px',
                                    textTransform: 'capitalize',
                                    borderRadius: '4px',
                                }}
                                // onClick={handleSubmit(onSubmit)}
                                loading={loading}
                            >
                                Confirm
                            </LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const styles: IStyles = {
    textField: {
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        width: '838px',
        marginBottom: '22px',
    },
    title: {
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',
        marginBottom: '8px',
    },
    attachments: {
        display: 'flex',
        maxWidth: 'fit-content',
        minWidth: '100%',
        minHeight: '50px',
        borderRadius: '4px',
        border: '1px solid #F2F2F2',
        background: '#F9F9F9',
        padding: '9px 12px 12px 9px',
        flexWrap: 'wrap',
    },
}
export default NewRequestModal
