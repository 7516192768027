import CommonEmptyState from '../../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import ProductsEmptyStateImage from '../../../assets/images/products_empty_state.svg'

interface IProductsEmptyState {
    createNew: () => void
}

const ProductsEmptyState = ({ createNew }: IProductsEmptyState) => {
    return (
        <CommonEmptyState
            src={ProductsEmptyStateImage}
            alt="Products empty state"
            width={148}
            height={180}
            sx={{ marginTop: '88px' }}
        >
            <CommonButton
                variant={'outlined'}
                sx={{ width: 251, height: 37, marginTop: 4 }}
                onClick={createNew}
            >
                Create Customer Product
            </CommonButton>
        </CommonEmptyState>
    )
}

export default ProductsEmptyState
