import FormControl from '@mui/material/FormControl'

interface ICommonFormControl {
    sx?: any
    variant?: any
    children?: any
    onClick?: any
    fullWidth?: any
}

const CommonFormControl = ({
    sx,
    variant,
    children,
    onClick,
    fullWidth,
}: ICommonFormControl) => {
    return (
        <FormControl
            fullWidth={fullWidth}
            sx={sx}
            variant={variant}
            onClick={onClick}
        >
            {children}
        </FormControl>
    )
}

export default CommonFormControl
