import { Button, Stack, TextField } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { type IAlert } from '../../interfaces/IAlert'
import { addAlert } from '../../features/systemAlerts'
import dashBoardServices, {
    apiClient,
    apiClientForm,
} from '../../services/cspServices'

const ForgetPassword = ({ showSuccessPage }: any) => {
    const user = useAppSelector(userSelector)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm()
    const onsubmit = (data: any) => {
        console.log('data', data)
        delete apiClient.defaults.headers.common.Authorization
        delete apiClientForm.defaults.headers.common.Authorization

        dashBoardServices
            .reset(data)
            .then((res: any) => {
                console.log('res', res)
                showSuccessPage(res.data.detail, data.email)
            })
            .catch((e: any) => {
                console.log('e', e)
                const alertObj: IAlert = {
                    id: 'LoginError',
                    message: 'Email not found ',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            })
    }
    return (
        <Stack sx={{ width: '100%', minWidth: '358px' }}>
            <label
                style={{
                    width: '100%',
                    color: '#FFF',
                    fontSize: '16px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: '24px',
                }}
            >
                Email
            </label>
            <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                    <TextField
                        hiddenLabel
                        fullWidth
                        size="small"
                        sx={{ ...styles.textField, marginTop: '0px' }}
                        {...field}
                    />
                )}
            />

            <Button
                onClick={handleSubmit(onsubmit)}
                fullWidth
                sx={{
                    '&:hover': {
                        backgroundColor: '#47A5A6',
                        color: 'white',
                    },
                    backgroundColor: '#47A5A6',
                    color: '#FFF',
                    textAlign: 'center',
                    fontSize: '18px',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '38px',
                    textTransform: 'capitalize',
                    marginTop: '60px',
                }}
            >
                Reset Password
            </Button>
        </Stack>
    )
}

export default ForgetPassword

const styles = {
    textField: {
        display: 'flex',
        gap: '8px',
        backgroundColor: '#fff',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
        marginY: '30px',
    },
}
