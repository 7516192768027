import Navbar from '../../components/Navbar/Navbar'
import { Navigate, Outlet } from 'react-router-dom'
import { Stack, Box, useMediaQuery, Drawer, Button } from '@mui/material'
import Header from '../../components/Header/Header'
import BasicErrorAlert from '../../components/Alerts/BasicErrorAlert/BasicErrorAlert'
import { systemAlertsSelector, removeAlert } from '../../features/systemAlerts'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import type { IAlert } from '../../interfaces/IAlert'
import { setUser, userSelector } from '../../features/user'
import IconsNavBar from '../../components/IconsNavBar/IconsNavBar'
import Summary from '../../components/Summary/Summary'
import ContractDetails from '../../components/ContractDetails/ContractDetails'
import { decodeToken } from 'react-jwt'
import { apiClient, apiClientForm } from '../../services/cspServices'
import { useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
const SharedLayout = () => {
    // ... (existing code)
    const { systemAlerts } = useAppSelector(systemAlertsSelector)
    const { user } = useAppSelector(userSelector)
    const [state, setState] = useState(false)
    const toggleDrawer =
        (anchor: string, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return
            }

            setState(open)
        }
    const xsmall = useMediaQuery('(min-width:425px)')
    const small = useMediaQuery('(min-width:600px)')
    const medium = useMediaQuery('(min-width:900px)')
    const laptop = useMediaQuery('(min-width:1024px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    console.log('user', user)
    const dispatch = useAppDispatch()
    let accessToken = window.localStorage.getItem('accessToken') ?? ''
    let refreshToken = window.localStorage.getItem('refreshToken')

    // If not found in localStorage, fall back to sessionStorage
    if (
        !window.localStorage.getItem('accessToken') ||
        !window.localStorage.getItem('refreshToken')
    ) {
        accessToken = window.sessionStorage.getItem('accessToken') ?? ''
        refreshToken = window.sessionStorage.getItem('refreshToken')
    }
    if (user && Object.keys(user).length == 0) {
        console.log('usersssssssssss', user)

        dispatch(setUser(accessToken ?? ''))
        apiClient.defaults.headers.Authorization = `Bearer ${accessToken}`
        apiClientForm.defaults.headers.Authorization = `Bearer ${accessToken}`
    }
    const obj = decodeToken(accessToken) ?? {}
    return (
        <>
            {(user && Object.keys(user).length > 0) ||
            (accessToken && Object.keys(obj).length > 0) ? (
                (medium || small || xsmall || laptop) &&
                !extraLarge &&
                !large ? (
                    <Stack direction={'column'}>
                        <Stack direction={'row'} justifyContent={'flex-start'}>
                            <Button onClick={toggleDrawer('left', true)}>
                                <MenuIcon />
                            </Button>
                        </Stack>
                        <Drawer
                            anchor={'left'}
                            open={state}
                            onClose={toggleDrawer('left', false)}
                        >
                            <Stack sx={{ minWidth: 'max-content' }}>
                                {user.is_superuser ? (
                                    <Navbar />
                                ) : (
                                    <IconsNavBar />
                                )}
                            </Stack>{' '}
                        </Drawer>

                        <Header />
                        <Stack
                            flexGrow={1}
                            sx={{
                                bgcolor: 'background.default',
                                paddingTop: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    minHeight: '95vh',
                                    marginTop: 2,
                                    flexGrow: 1,
                                }}
                            >
                                <Outlet />
                            </Box>
                        </Stack>
                    </Stack>
                ) : (
                    <Stack direction="row" spacing={0}>
                        <Stack sx={{ minWidth: 'max-content' }}>
                            {user.is_superuser ? <Navbar /> : <IconsNavBar />}
                        </Stack>
                        <Stack
                            flexGrow={1}
                            sx={{
                                bgcolor: 'background.default',
                                paddingTop: 1,
                            }}
                        >
                            <Header />
                            <Box sx={{ minHeight: '95vh', marginTop: 2 }}>
                                <Outlet />
                            </Box>
                        </Stack>
                    </Stack>
                )
            ) : (
                <Navigate to="/login" />
            )}
            {systemAlerts.map((alert: IAlert, index: number) => (
                <BasicErrorAlert
                    key={alert.id}
                    isOpen={alert.state}
                    sx={{
                        alignItems: 'center',
                        width: 480,
                        height: 'max-content',
                        position: 'fixed',
                        left: 0,
                        bottom: index * 70,
                        backgroundColor: '#ffffff',
                        zIndex: 9999,
                        marginBottom: 5,
                        marginLeft: 4,
                        border: '.1px solid #47A5A6',
                    }}
                    actionOnClick={() => {
                        dispatch(removeAlert(alert.id))
                    }}
                    // @ts-expect-error sadsdas  sadsad
                    type={alert.type}
                >
                    {alert.message}
                </BasicErrorAlert>
            ))}
        </>
    )
}

export default SharedLayout
