import { Box, CircularProgress, Stack } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CreateEngineer from '../../assets/images/emptyEngneer.svg'
import CommonEmptyState from '../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../components/common/CommonButton/CommonButton'
import EngineerCreateModal from '../../components/Modals/CreateUpdateEngineerModal/CreateUpdateEngineerModal'
import CommonTypography from '../../components/common/CommonTypography/CommonTypography'
import SkillsTable from '../../components/SkillsTable/SkillsTable'
import AddNewSkillsModal from '../../components/Modals/AddNewSkillsModal/AddNewSkillsModal'
import useFetch from '../../hooks/useFetch'
import dashBoardServices from '../../services/cspServices'
import { type ISkills } from '../../interfaces/ISkills'

interface skill {
    id: string
    skill: string
    rate: number
}
interface Product {
    id: string
    name: string
    skills: skill[]
}
const SkillsState = () => {
    const [open, setOpen] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [tableData, setTableData] = useState<ISkills[]>([])
    const { data: productsData, isPending: isFetchingProdcuts } = useFetch(
        dashBoardServices.getProducts,
        refetch
    )
    const [selectedRow, setSelectedRow] = useState<number | undefined>(
        undefined
    )
    useEffect(() => {
        if (!isFetchingProdcuts) {
            const skillsArr: ISkills[] = []
            productsData.map((product: Product, index: number) => {
                console.log('Product', product)
                let skillName = ''
                product.skills.map((item,i) => {
                    if(i>3)skillName +="."
                    if(i<=3)skillName += item.skill + ', '
                })
                skillsArr.push({
                    name: product.name,
                    skills: skillName,
                    id: index,
                })
            })
            setTableData(skillsArr)
        }
    }, [productsData, selectedRow])
    return (
        <Stack direction={'column'} paddingX={5}>
            <Stack direction={'row'} spacing={0.5} sx={{ paddingLeft: '32px' }}>
                <CommonTypography variant={'h6'} sx={{ color: '#33303B' }}>
                    IT-RANKS Products
                </CommonTypography>
            </Stack>

            <Stack
                sx={{
                    marginTop: '3rem',
                    minHeight: '85vh',
                    borderRadius: '4px',
                    backgroundColor: '#FFF',
                }}
                direction={'column'}
            >
                <Box
                    sx={{
                        paddingTop: '80px',
                        paddingInline: '35px',
                        borderRadius: '4px',
                    }}
                >
                    {isFetchingProdcuts && (
                        <Stack
                            direction={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{ minHeight: '80vh' }}
                        >
                            <CircularProgress color="inherit" size={50} />
                        </Stack>
                    )}
                    <SkillsTable
                        selectRow={setSelectedRow}
                        rows={tableData}
                        openNewSkillModal={() => {
                            setOpen(true)
                        }}
                    />
                </Box>
                <AddNewSkillsModal
                    // @ts-expect-error i will provide it
                    id={productsData[selectedRow]?.id}
                    // @ts-expect-error i will provide it
                    product={productsData[selectedRow]}
                    handleClose={() => {
                        setOpen(false)
                        setSelectedRow(undefined)
                        setRefetch(!refetch)
                    }}
                    open={open}
                />
            </Stack>
        </Stack>
    )
}

export default SkillsState
