import React, { useState, type KeyboardEvent, useEffect } from 'react'
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material'
import BasicModal from '../../BasicModal/BasicModal'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import { useForm, Controller } from 'react-hook-form'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import cspService from '../../../services/cspServices'
import { useNavigate } from 'react-router-dom'
import { LoadingButton } from '@mui/lab'
import { type IAlert } from '../../../interfaces/IAlert'
import { addAlert } from '../../../features/systemAlerts'
import { useAppDispatch } from '../../../app/hooks'
import { useWindowSize } from 'usehooks-ts'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'

interface Props {
    id?: number
    open: boolean
    handleClose: () => void
    product?: Product
}
interface Product {
    name: string
    skills: skill[]
}
interface skill {
    skill: string
    id: number
}

const AddNewSkillsModal = ({ id, open, handleClose, product }: Props) => {
    const [loading, setLoading] = useState(false)
    const [chartDimensions, setChartDimensions] = useState(450)
    const [skills, setSkills] = useState<skill[]>([])
    const [isPending, setIsPending] = useState(false)
    const navigate = useNavigate()
    const [editedSkillIndex, setEditedSkillIndex] = useState(-1)
    const [editedSkillValue, setEditedSkillValue] = useState('')
    useEffect(() => {
        // console.log(skills)
    }, [skills])
    const { width, height } = useWindowSize()

    const {
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        clearErrors,

        formState: { errors },
    } = useForm({ reValidateMode: 'onSubmit' })
    useEffect(() => {
        if (id != undefined && product) {
            setSkills(product.skills)
            setValue('productName', product.name)
        }
    }, [id])
    const dispatch = useAppDispatch()
    const startEditingSkill = (skillValue: string, index: number) => {
        setEditedSkillIndex(index)
        setEditedSkillValue(skillValue)
    }

    const handleSkillEdit = (index: number, skillId: number) => {
        const newSkill = {
            skill: editedSkillValue,
            product: id,
        }
        cspService
            .updateProductSkill(skillId, newSkill)
            .then((res) => {
                // console.log('res', res)
                const modifiedData = [...skills]
                modifiedData.splice(index, 1)
                setSkills(modifiedData)
                const newSkills = [...skills]
                newSkills[index].skill = editedSkillValue
                setSkills(newSkills)
                setEditedSkillIndex(-1)
                setEditedSkillValue('')
            })
            .catch((e) => {})
    }
    const onSubmit = (data: any) => {
        setIsPending(true)

        // console.log(data) // Handle form submission here

        const productObject = {
            name: data.productName,
        }
        // console.log('productObject', productObject)
        if (id != undefined) {
            cspService
                .updateProduct(id, productObject)
                .then((res) => {
                    if (res.status === 200) {
                        setIsPending(false)
                        navigate(`/skills/`)
                        handleCloseModal()
                    }
                })
                .catch((err) => {
                    setIsPending(false)
                    console.log(err)
                    const alertObj: IAlert = {
                        id: 'save error ',
                        message:
                            'Sorry, There are running requests related to this skill.',
                        state: true,
                        type: 'error',
                    }
                    dispatch(addAlert(alertObj))
                    if (id != undefined && product) {
                        setSkills(product.skills)
                        setValue('productName', product.name)
                    }
                })
        } else {
            cspService
                .createNewProduct(productObject)
                .then((res) => {
                    if (res.status === 200) {
                        setIsPending(false)
                        navigate(`/skills/`)
                        handleCloseModal()
                    }
                })
                .catch((err) => {
                    setIsPending(false)
                    console.log(err)
                })
        }
    }
    const handleCloseModal = () => {
        reset()
        setSkills([])
        handleClose()
    }
    const handleAdd = (data: skill[]) => {
        setValue('skills', '', { shouldValidate: false })

        setSkills(data)
    }
    const CheckEnter = (e: KeyboardEvent<HTMLDivElement>) => {
        const key = e.key
        if (key == 'Enter') {
            setLoading(true)
            e.preventDefault()
            const values = getValues()
            const newSkill = {
                skill: values.skills,
                product: id,
            }
            const newSkills = [...skills]
            cspService
                .createNewProductSkill(newSkill)
                .then(({ data }) => {
                    // console.log('res', data)
                    newSkills.push({ skill: values.skills, id: data.id })
                    handleAdd(newSkills)
                })
                .catch((e) => {
                    // console.log('e', e)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const removeSkill = (index: number, id: number) => {
        // console.log('index', index)
        setLoading(true)
        cspService
            .deleteProductSkill(id)
            .then((res) => {
                // console.log('res', res)
                const modifiedData = [...skills]
                modifiedData.splice(index, 1)
                setSkills(modifiedData)
            })
            .catch((e) => {
                // console.log('e', e)
                const alertObj: IAlert = {
                    id: 'save error ',
                    message:
                        'Sorry, There are running requests related to this skill.',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            })
            .finally(() => {
                setLoading(false)
            })
    }
    // console.log(watch('productName'))

    // console.log('product?.name', product?.name)
    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            // @ts-expect-error no error here
            message: value?.message ?? '',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })
    return (
        <BasicModal open={open} handleClose={handleCloseModal}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                {loading ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            height: '100%',
                            width: '100%',
                            backgroundColor: '#000',
                            borderRadius: '12px',
                            zIndex: 2,
                            opacity: 0.2,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircularProgress color="inherit" size={50} />
                    </Box>
                ) : null}
                <Box sx={{ overflow: 'auto', p: 3, maxHeight: 600 }}>
                    <CommonTypography
                        sx={{ fontWeight: 600, marginBottom: '15px' }}
                        variant={'h6'}
                    >
                        {id != undefined ? 'Edit Product' : 'New Product'}
                    </CommonTypography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction={'column'} rowGap={3}>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Controller
                                    name="productName"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Product name is Required ',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            sx={{ ...styles.textField }}
                                            {...field}
                                            label="Product Name"
                                        />
                                    )}
                                />
                                {product?.name != watch('productName') &&
                                    product && (
                                        <LoadingButton
                                            type="submit"
                                            color="primary"
                                            variant={'contained'}
                                            sx={{
                                                width: '140px',
                                                height: '40px',
                                                boxShadow: 'none',
                                            }}
                                            // onClick={handleSubmit(onSubmit)}
                                            loading={isPending}
                                        >
                                            {id ? 'Save' : 'Create'}
                                        </LoadingButton>
                                    )}
                            </Stack>

                            {id && (
                                <Controller
                                    name="skills"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            sx={{ ...styles.textField }}
                                            {...field}
                                            label="Skills"
                                            onKeyDown={CheckEnter}
                                        />
                                    )}
                                />
                            )}
                            {skills.length == 0
                                ? ''
                                : skills.map((skill, index) => {
                                      return (
                                          <Stack
                                              sx={{
                                                  borderRadius: '5px',
                                                  border: ' 0.6px solid #E9E9E9',
                                                  backgroundColor: '#FFF',
                                                  paddingX: '10px',
                                              }}
                                              key={
                                                  skill.skill +
                                                  '' +
                                                  index.toString()
                                              }
                                              direction={'row'}
                                              justifyContent={'space-between'}
                                          >
                                              {editedSkillIndex === index ? (
                                                  <Stack
                                                      display={'flex'}
                                                      direction={'row'}
                                                      alignItems={'center'}
                                                  >
                                                      <IconButton
                                                          onClick={() => {
                                                              handleSkillEdit(
                                                                  index,
                                                                  skill.id
                                                              )
                                                          }}
                                                          disableRipple
                                                          size="small"
                                                      >
                                                          <CheckIcon />
                                                      </IconButton>
                                                      <TextField
                                                          variant="outlined"
                                                          fullWidth
                                                          focused
                                                          sx={{
                                                              '&.MuiOutlinedInput-notchedOutline':
                                                                  {
                                                                      borderWidth: 0.1,
                                                                      borderColor:
                                                                          '#a09e9e',
                                                                  },
                                                              '&:hover .MuiOutlinedInput-notchedOutline':
                                                                  {
                                                                      borderColor:
                                                                          '#a09e9e',
                                                                  },
                                                              '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                  {
                                                                      borderColor:
                                                                          '#a09e9e',
                                                                  },
                                                          }}
                                                          InputProps={{
                                                              disableUnderline:
                                                                  true,
                                                          }}
                                                          size="small"
                                                          value={
                                                              editedSkillValue
                                                          }
                                                          onChange={(e) => {
                                                              setEditedSkillValue(
                                                                  e.target.value
                                                              )
                                                          }}
                                                          onBlur={() => {
                                                              handleSkillEdit(
                                                                  index,
                                                                  skill.id
                                                              )
                                                          }}
                                                          //   onKeyDown={(e) => {
                                                          //       if (
                                                          //           e.key === 'Enter'
                                                          //       ) {
                                                          //           handleSkillEdit(
                                                          //               index
                                                          //           )
                                                          //       }
                                                          //   }}
                                                      />
                                                  </Stack>
                                              ) : (
                                                  //   <TextField
                                                  //   variant='outlined'
                                                  //   focused
                                                  //   InputProps={{disableUnderline: true,

                                                  // sx:{
                                                  //     border:"none"
                                                  // }}}
                                                  //   size='small'
                                                  //       value={editedSkillValue}
                                                  //       onChange={(e) => {
                                                  //           setEditedSkillValue(
                                                  //               e.target.value
                                                  //           )
                                                  //       }}
                                                  //       onBlur={() => {
                                                  //           handleSkillEdit(index,skill.id)
                                                  //       }}
                                                  //     //   onKeyDown={(e) => {
                                                  //     //       if (
                                                  //     //           e.key === 'Enter'
                                                  //     //       ) {
                                                  //     //           handleSkillEdit(
                                                  //     //               index
                                                  //     //           )
                                                  //     //       }
                                                  //     //   }}
                                                  //   />
                                                  <Stack
                                                      direction={'row'}
                                                      justifyContent={
                                                          'flex-start'
                                                      }
                                                      gap={2}
                                                      alignItems={'center'}
                                                  >
                                                      <IconButton
                                                          onClick={() => {
                                                              startEditingSkill(
                                                                  skill.skill,
                                                                  index
                                                              )
                                                          }}
                                                          disableRipple
                                                          size="small"
                                                      >
                                                          <EditIcon />
                                                      </IconButton>

                                                      <Box>{skill.skill}</Box>
                                                  </Stack>
                                                  //   <Box
                                                  //       onClick={() => {
                                                  //           startEditingSkill(
                                                  //               skill.skill,
                                                  //               index
                                                  //           )
                                                  //       }}
                                                  //   >
                                                  //       {skill.skill}
                                                  //   </Box>
                                              )}

                                              <Box>
                                                  <IconButton
                                                      onClick={() => {
                                                          removeSkill(
                                                              index,
                                                              skill.id
                                                          )
                                                      }}
                                                      disableRipple
                                                      size="small"
                                                  >
                                                      <RemoveIcon />
                                                  </IconButton>
                                              </Box>
                                          </Stack>
                                      )
                                  })}
                            <Stack
                                sx={{ marginTop: '48px' }}
                                direction={'row'}
                                spacing={3}
                                alignItems={'center'}
                                justifyContent={'end'}
                            >
                                <Button
                                    color={'primary'}
                                    variant={'outlined'}
                                    sx={{ width: '140px', height: '40px' }}
                                    onClick={handleCloseModal}
                                >
                                    {'Cancel'}
                                </Button>
                                {!product && (
                                    <LoadingButton
                                        type="submit"
                                        color="primary"
                                        variant={'contained'}
                                        disabled={
                                            watch('productName')?.length == 0 ||
                                            !watch('productName')
                                        }
                                        sx={{
                                            width: '140px',
                                            height: '40px',
                                            boxShadow: 'none',
                                        }}
                                        // onClick={handleSubmit(onSubmit)}
                                        loading={isPending}
                                    >
                                        {id ? 'Save' : 'Create'}
                                    </LoadingButton>
                                )}
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}

const styles = {
    textField: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
    },
}

export default AddNewSkillsModal
