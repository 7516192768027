// styles for all navbar items
export const navbarStyles = {
    drawer: {
       
        '& .MuiDrawer-paper': {
            
            boxSizing: 'border-box',
            backgroundColor: '#33303B',
            color: 'default',
            // borderTopRightRadius: '16px',
            // borderBottomRightRadius: '16px',
            transition: `transitions.easing.easeIn`,
        },
        '& .MuiListItemButton-root': {
            borderRadius: '100px',
            marginRight: '12px',
            marginLeft: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
        },
        '& .MuiListItemButton-root:hover': {
            backgroundColor: 'transparent',
            borderRadius: '100px',
        },
        '& .MuiListItemButton-root:hover *': {
            color: 'success.main',
        },
        '& .Mui-selected *': {
            color: 'success.main',
        },
    },
    icons: {
        color: '#FFFFFF',
        marginLeft: '20px',
        minWidth: '42px',
    },
    text: {
        '& span': {
            color: 'customText.light',
            marginLeft: '-10px',
            fontWeight: '600',
            fontSize: '1rem',
        },
    },
    collapsedItem: {
        paddingLeft: '48px !important',
        paddingRight: '16px',
    },

}

// styles for logout navbar item
export const subNavbarStyles = {
    icons: {
        color: 'error.main',
        marginLeft: '20px',
    },
    text: {
        '& span': {
            color: 'error.main',
            marginLeft: '-10px',
            fontWeight: '600',
            fontSize: '16px',
        },
    },
}
