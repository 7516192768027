import React, { useEffect, useState } from 'react'
import CustomersTable from './CustomersTable'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
    customersTableListSelector,
    getCustomersTableList,
} from '../../features/customersTableList'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { Box, CircularProgress } from '@mui/material'

export default function CustomersTableIndex() {
    const dispatch = useAppDispatch()
    const [searchTextTable, setSearchTextTable] = useState('')
    const { customersTableList, pending } = useAppSelector(
        customersTableListSelector
    )
    console.log('customersTableList', customersTableList)
    const [page, setPage] = React.useState(1)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
    }
    useEffect(() => {
        dispatch(getCustomersTableList({ searchText: searchTextTable, page }))
    }, [dispatch, searchTextTable, page])

    return (
        <>
            {pending && (
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ minHeight: '80vh' }}
                >
                    <CircularProgress color="inherit" size={50} />
                </Stack>
            )}
            {!pending && (
                <CustomersTable
                    customersList={customersTableList}
                    searchText={searchTextTable}
                    setSearchText={setSearchTextTable}
                />
            )}

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {!pending && (
                    <Stack spacing={2}>
                        <Pagination
                            count={Math.ceil(customersTableList.count / 9)}
                            page={page}
                            onChange={handleChange}
                            size="large"
                        />
                    </Stack>
                )}
            </Box>
        </>
    )
}
