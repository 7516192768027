import { Paper, Typography, type SxProps, Stack, Card } from '@mui/material'
import React from 'react'
import RequestInfoCard from '../Cards/SummaryCrads/RequestInfoCard'
import ProductInformationCard from '../Cards/SummaryCrads/ProductInformationCard'
import SLAInformationCard from '../Cards/SummaryCrads/SLAInformationCard'
import RequestSpecificationCard from '../Cards/SummaryCrads/RequestSpecificationCard'
interface styles {
    paper: SxProps
    title: SxProps
    requestInfo: SxProps
}
const Summary = ({ userSummary }: any) => {
    console.log('userSummary', userSummary)
    return (
        <Paper elevation={2} sx={styles.paper}>
            <Stack direction={'column'}>
                <Typography sx={styles.title}>Summary</Typography>

                <Typography sx={styles.requestInfo}>
                    Request information
                </Typography>
                <RequestInfoCard userSummary={userSummary} />
                <Typography sx={styles.requestInfo}>
                    Product information
                </Typography>
                <ProductInformationCard userSummary={userSummary} />
                <Typography sx={styles.requestInfo}>SLA information</Typography>
                <SLAInformationCard userSummary={userSummary} />
                {userSummary.RequestSpecifications.length > 0 ? (
                    <>
                        <Typography sx={styles.requestInfo}>
                            Request Specification
                        </Typography>
                        <RequestSpecificationCard
                            RequestSpecifications={
                                userSummary.RequestSpecifications
                            }
                        />
                    </>
                ) : null}
            </Stack>
        </Paper>
    )
}

export default Summary

const styles: styles = {
    paper: {
        borderRadius: '10px',
        backgroundColor: '#FFF',
        maxHeight: '88vh',
        paddingLeft: '22px',
        paddingBottom: '32px',
        overflowY: 'scroll',
    },
    title: {
        color: 'var(--text, #030229)',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginTop: '27px',
    },
    requestInfo: {
        color: 'var(--text, #030229)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textTransform: 'capitalize',
        marginTop: '27px',
    },
}
