import CommonPaper from '../../common/CommonPaper/CommonPaper'
import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import CommonButton from '../../common/CommonButton/CommonButton'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import { type IProduct } from '../../../interfaces/IProduct'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'

interface IProductCard {
    name:string|null
    site:string|null
    release: string |null
    paltform: string |null
    sla:boolean |null
    navigateToDetails: () => void
}
interface Product {
    id: string
    name: string
    skills: [
        {
            id: string
            skill: string
        }
    ]
}
const ProductCard = ({
    
    name,
    site,
    release,
    paltform,
    sla,
    navigateToDetails,
}: IProductCard) => {
   
    return (
        <CommonPaper
            sx={{
                height: '184px',
                minHeight: '184px',
                border: '1px solid #DCDCDC',
                p: 3,
            }}
        >
            <Stack
                direction={'column'}
                sx={{ height: '100%' }}
                justifyContent={'space-between'}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                >
                    <Stack direction={'column'}>
                        <CommonTypography
                            variant={'h6'}
                            sx={{ fontWeight: 600 }}
                        >
                            {name}
                        </CommonTypography>
                        <CommonTypography sx={{ color: 'text.disabled' }}>
                            {release}
                        </CommonTypography>
                        <CommonTypography sx={{ color: 'text.disabled' }}>
                            {paltform}
                        </CommonTypography>
                    </Stack>
                    <Stack direction={'row'} columnGap={1}>
                        
                        <CommonTypography sx={ sla?{...styles.haveSLA}:{...styles.NoSLA}}>
                            {sla?"Follow SLA":"No SLA"}
                        </CommonTypography>
                    </Stack>
                </Stack>
                <Stack direction={"row-reverse"}>
                        <CommonTypography >
                            {site}
                        </CommonTypography>
                <PlaceOutlinedIcon />

                </Stack>
                <CommonButton
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={navigateToDetails}
                >
                    See Details
                </CommonButton>
            </Stack>
        </CommonPaper>
    )
}

export default ProductCard
const styles = {
    haveSLA:{
        
            background: "rgba(53, 176, 229, 0.06)",
            color:"#35B0E5",
            border:"0.48px solid #35B0E5",
            paddingInline:"1rem",
            alignSelf:"flex-start" ,
            marginTop:"5px" ,
        
    },
    NoSLA:{
        background: "rgba(244, 147, 33, 0.06)",
        border:" 0.48px solid #F49321",
        paddingInline:"1rem",
        alignSelf:"flex-start" ,
        marginTop:"5px" ,
        color:"#F49321",

    }
}