import * as React from 'react'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

interface ICommonCheckbox {
    checked?: boolean
    label?: string
    required?: boolean
    disabled?: boolean
    sx?: any
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CommonCheckbox = ({
    checked,
    label,
    required,
    disabled,
    sx,
    onChange,
}: ICommonCheckbox) => {
    return (
        <FormControlLabel
            required={required}
            disabled={disabled}
            control={
                <Checkbox
                    checked={checked}
                    onChange={(e) => {
                        onChange(e)
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            }
            label={label}
            sx={sx}
        />
    )
}

export default CommonCheckbox
