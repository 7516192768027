import { Stack, useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { statusNames } from '../../helper/constants'

export const AreaUnderLine = ({ rawData }: any) => {
    console.log('rawData', rawData)
    const statusData = Object.keys(rawData).map((status) => ({
        status: statusNames[status],
        count: rawData[status],
    }))
    console.log('statusData', statusData)

    const [chartDimensions, setChartDimensions] = useState(450)
    const smallScreen = useMediaQuery('(max-width:599px)')
    const mediumScreen = useMediaQuery(
        '(min-width:600px) and (max-width:959px)'
    )
    const largeScreen = useMediaQuery(
        '(min-width:960px) and (max-width:1279px)'
    )
    const extraLargeScreen = useMediaQuery('(min-width:1280px)')
    const moreExtraLargeScreen = useMediaQuery('(min-width:1280px)')
    const [currentPageWidth, setCurrentPageWidth] = useState(window.innerWidth)
    console.log('currentPageWidth', currentPageWidth)

    const handleResize = () => {
        if (window.innerWidth > 1000) {
            setCurrentPageWidth(window.innerWidth - 500)
        } else {
            setCurrentPageWidth(window.innerWidth - 400)
        }
    }

    useEffect(() => {
        // Attach the event listener when the component mounts
        window.addEventListener('resize', handleResize)

        // Detach the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []) // Empty dependency array means this effect runs only once on mount

    useEffect(() => {
        if (moreExtraLargeScreen) setChartDimensions(1300)
        if (extraLargeScreen) {
            setChartDimensions(1000)
        } else if (largeScreen) {
            setChartDimensions(800)
        } else if (mediumScreen) {
            setChartDimensions(500)
        } else if (smallScreen) {
            setChartDimensions(400)
        }
        if (window.innerWidth > 1200) {
            setCurrentPageWidth(window.innerWidth - 650)
        } else if (window.innerWidth > 1000) {
            setCurrentPageWidth(window.innerWidth - 500)
        } else {
            setCurrentPageWidth(window.innerWidth - 400)
        }
    }, [smallScreen, mediumScreen, largeScreen, extraLargeScreen])

    return (
        <Stack direction={'row'} sx={{ flexGrow: 1 }}>
            <AreaChart width={currentPageWidth} height={400} data={statusData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" />
                <YAxis />
                <Tooltip />
                <Area
                    type="monotone"
                    dataKey="count"
                    stroke="#90cd97"
                    fill="#cee7d1"
                />
            </AreaChart>
        </Stack>
    )
}
