import { Box, CircularProgress, Stack } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CreateEngineer from '../../assets/images/emptyEngneer.svg'
import CommonEmptyState from '../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../components/common/CommonButton/CommonButton'
import EngineerCreateModal from '../../components/Modals/CreateUpdateEngineerModal/CreateUpdateEngineerModal'
import CommonTypography from '../../components/common/CommonTypography/CommonTypography'
import TeamsCreateModal from '../../components/Modals/AddNewTeamModal/AddNewTeamModal'
import useFetch from '../../hooks/useFetch'
import dashBoardServices from '../../services/cspServices'
import ListingTeams from '../../contents/CustomersContent/ListingTeams/ListingTeams'
import DeleteTeamModal from '../../components/Modals/DeleteTeamModal/DeleteTeamModal'
import { addAlert } from '../../features/systemAlerts'
import { type IAlert } from '../../interfaces/IAlert'
import { useAppDispatch } from '../../app/hooks'

const TeamsPage = () => {
    const [open, setOpen] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState<any>()
    const [selectedTeamID, setSelectedTeamID] = useState<any>()
    const [selectedID, setSelectedID] = useState<any>(undefined)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
const dispatch = useAppDispatch()
    const { data: TeamsData, isPending: isFetching } = useFetch(
        dashBoardServices.getTeams,
        refetch
    )
    const createNew = () => {
        setOpen(true)
    }
    useEffect(() => {}, [isFetching])
    const deleteSelected = () => {
        dashBoardServices
            .deleteTeam(selectedTeamID)
            .then((res) => {
                console.log('res', res)
            }).catch(e=>{
                const alertObj: IAlert = {
                    id: "team delete Error ",
                    message:  'please assign the team members to other team to can delete this team',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            })
            .finally(() => {
                setRefetch(!refetch)
                setSelectedTeamID(undefined)
                setSelectedTeam(undefined)
                setOpen(false)
                setOpenDeleteModal(false)
            })
    }
    return (
        <Stack direction={'column'}>
            <Stack direction={'row'} spacing={0.5} sx={{ paddingLeft: '32px' }}>
                <CommonTypography variant={'h6'} sx={{ color: '#33303B' }}>
                    IT-Ranks Engineers
                </CommonTypography>
            </Stack>
            {isFetching && (
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ minHeight: '80vh' }}
                >
                    <CircularProgress color="inherit" size={50} />
                </Stack>
            )}
            {!isFetching && TeamsData.length == 0 && (
                <Stack
                    sx={{
                        minHeight: '95vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    direction={'row'}
                >
                    <CommonEmptyState
                        src={CreateEngineer}
                        alt="site empty state"
                        width={81}
                        height={180}
                        sx={{ marginTop: '88px' }}
                    >
                        <CommonButton
                            variant={'contained'}
                            sx={{ width: 200, height: 40 }}
                            onClick={createNew}
                        >
                            New Team
                        </CommonButton>
                    </CommonEmptyState>
                </Stack>
            )}
            {!isFetching && TeamsData.length > 0 && (
                <ListingTeams
                    teams={TeamsData}
                    seeDetails={(id, index) => {
                        console.log('id', id, index)
                        setSelectedTeam(TeamsData[index])
                        setSelectedTeamID(id)
                        setOpen(true)
                    }}
                    createNew={createNew}
                />
            )}
            <TeamsCreateModal
                setRefetch={() => {
                    setRefetch(!refetch)
                }}
                id={selectedTeamID}
                team={selectedTeam}
                open={open}
                handleClose={() => {
                    setSelectedTeamID(undefined)
                    setSelectedTeam(undefined)
                    setOpen(false)
                }}
                handleDelete={() => {
                    setOpen(false)
                    setOpenDeleteModal(true)
                }}
            />
            <DeleteTeamModal
                handleClose={() => {
                    setOpenDeleteModal(false)
                    setSelectedTeamID(undefined)
                    setSelectedTeam(undefined)
                }}
                open={openDeleteModal}
                handleMainAction={deleteSelected}
                isPending={false}
            />
        </Stack>
    )
}

export default TeamsPage
