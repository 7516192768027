import {
    Paper,
    Stack,
    Typography,
    type SxProps,
    Chip,
    Link,
    IconButton,
} from '@mui/material'
import React from 'react'
import CommonLetterAvatar from '../../common/CommonLetterAvatar/CommonLetterAvatar'
import { ReactComponent as FilesIcon } from '../../../assets/images/document-download.svg'
import { ReactComponent as Replay } from '../../../assets/images/ReplayIcon.svg'
import { useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user'
import { GetShortName } from '../../../helper/shortName'
import dayjs from 'dayjs'
interface IStyle {
    paper: SxProps
    padge: SxProps
    date: SxProps
    name: SxProps
    job: SxProps
    avatar: SxProps
    chip: SxProps
    responseState: SxProps
    attachLink: SxProps
    replay: SxProps
}
const ReplayCard = ({ reply, openReplyReply, hanldeOpenAttachments }: any) => {
    const texts: string = reply.action != 'RESOLUTION' ? ' Reason' : ''
    const text: string = reply.action
    const text2: string = text + texts

    console.log('reply.message.split', reply.message.split('\n'))

    return (
        <Paper key={reply?.id} sx={styles.paper}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'column'}>
                    {reply.action != '' && (
                        <Stack
                            direction={'row'}
                            sx={styles.padge}
                            alignItems={'center'}
                        >
                            <Typography sx={styles.responseState}>
                                {text2}
                            </Typography>
                        </Stack>
                    )}

                    <Stack
                        direction={'row'}
                        justifyContent={'flex-start'}
                        sx={{ marginTop: '12px' }}
                    >
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                        >
                            <Stack
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                            >
                                <CommonLetterAvatar sx={styles.avatar}>
                                    {GetShortName(reply.sender.full_name)}
                                </CommonLetterAvatar>
                            </Stack>
                            <Stack
                                direction={'column'}
                                justifyContent={'center'}
                                alignItems={'flex-start'}
                            >
                                <Typography sx={styles.name}>
                                    {reply.sender.full_name}
                                </Typography>
                                <Typography sx={styles.job}>
                                    {reply.sender.jop_title}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Typography sx={styles.replay}>
                        {reply.message
                            .split('\n')
                            .map((line: string, index: number) => {
                                if (line == '') return <br key={`br ${index}`}/>
                                return (
                                    <Typography key={`${index}Typography`}>{line}</Typography>
                                )
                            })}
                    </Typography>
                </Stack>
                <Stack
                    direction={'column'}
                    justifyContent={'space-between'}
                    sx={{
                        marginTop: '12px',
                        marginRight: '20px',
                        minHeight: '129px',
                    }}
                >
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{
                            height: '28px',
                        }}
                    >
                        <Chip
                            label="Internal"
                            sx={{
                                ...styles.chip,
                                visibility: reply.internal
                                    ? 'visible'
                                    : 'hidden',
                            }}
                        />

                        <Typography sx={styles.date}>
                            {dayjs(reply.created_at).format(
                                'MMM D YYYY, h:mm A'
                            )}
                        </Typography>
                    </Stack>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                    >
                        {reply.attachments.length > 0 && (
                            <Stack direction={'row'}>
                                <Stack sx={{ marginRight: '8px' }}>
                                    <FilesIcon />
                                </Stack>
                                <Link
                                    component="button"
                                    variant="body2"
                                    sx={styles.attachLink}
                                    onClick={() => {
                                        hanldeOpenAttachments(reply.attachments)
                                    }}
                                >
                                    {reply.attachments.length}{' '}
                                    {' files attached'}
                                </Link>
                            </Stack>
                        )}
                        <IconButton
                            onClick={() => {
                                const str1: string = reply.sender.full_name
                                const str2: string = reply.message
                                openReplyReply(str1 + ' : \n ------- \n' + str2)
                            }}
                        >
                            <Replay />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
        </Paper>
    )
}

export default ReplayCard

const styles: IStyle = {
    paper: {
        borderRadius: '4px',
        border: '0.6px solid #DCDCDC',
        background: '#FFF',
        minHeight: '129.83px',
        flexShrink: 0,
        paddingBottom: '20px',
    },
    padge: {
        width: '194.06px',
        height: '27.39px',
        flexShrink: 0,
        borderRadius: '3px 0px 40px 0px',
        background: '#494454',
    },
    date: {
        color: '#929EAE',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',
        minWidth: '138px',
    },
    name: {
        color: '#494454',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        textTransform: 'capitalize',
    },
    job: {
        color: '#929EAE',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'capitalize',
    },
    avatar: {
        backgroundColor: '#D5D3EC',
        width: '48px',
        height: '48px',
        textAlign: 'center',
        marginLeft: '12px',
        marginRight: '9px',
    },
    chip: {
        color: '#F49321',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24.363px',
        backgroundColor: '#F7F7F7',
        width: '70px',
        height: '28px',
        borderRadius: '0px',
        marginRight: '16px',
    },
    responseState: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: 'capitalize',
        marginLeft: '12px',
    },
    attachLink: {
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '14.4px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '19.2px',
        textDecorationLine: 'none',
        textTransform: 'capitalize',
        marginRight: '16px',
        '&:hover': {
            cursor: 'pointer',
            textDecorationLine: 'underline',
        },
    },
    replay: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'none',
        marginLeft: '68px',
    },
}
