import { createReducer } from '@reduxjs/toolkit'
import { login, setUser } from './actions'
import { isExpired, decodeToken } from 'react-jwt'

// games Campaings initial state
const initUserReducer: any = {
    user: {},
    pending: true,
    error: false,
}

// departmentReducer reducer to handel action when dispatched
export const userReducer = createReducer(initUserReducer, (builder) => {
    builder
        .addCase(login.pending, (state) => {
            state.pending = true
            state.error = false
        })
        .addCase(login.fulfilled, (state, { payload }) => {
            state.pending = false
            state.user = decodeToken(payload.access)
            // Assuming the payload contains access token
        })
        .addCase(login.rejected, (state) => {
            state.pending = false
            state.error = true
        })
        .addCase(setUser, (state, { payload }) => {
            console.log('payload', payload)
            console.log('state', state)
            state.user = decodeToken(payload)
        })
})

export default userReducer
