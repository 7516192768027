import { useState, useEffect, useCallback } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import BasicModal from '../../BasicModal/BasicModal'
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Stack,
    Switch,
    type SxProps,
    TextField,
    FormControl,
    RadioGroup,
    Radio,
} from '@mui/material'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import BasicRadio from '../../BasicRadio/BasicRadio'
// import CommonTextField from '../../common/CommonTextField/CommonTextField'
// import CommonAutocomplete from '../../common/CommonAutocomplete/CommonAutocomplete'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import { type ICustomer } from '../../../interfaces/ICustomer'
import { type IAlert } from '../../../interfaces/IAlert'
import { useAppDispatch } from '../../../app/hooks'
import { addAlert } from '../../../features/systemAlerts'
import { getCustomer } from '../../../features/customers'
import { type IContract } from '../../../interfaces/IContract'
import { type ISite } from '../../../interfaces/ISite'
import { useForm, Controller, useWatch } from 'react-hook-form'
import { type IUser } from '../../../interfaces/IUser'
import { IOSSwitch } from '../../common/CommonIOSSwitch/CommonIOSSwitch'
import Select from 'react-select'
import MySwitch from '../../common/CommonIOSSwitch/Switch'
import { useWindowSize } from 'usehooks-ts'

interface IProductCreateUpdateModal {
    open: boolean
    handleClose: () => void
    isEdit?: boolean
    User?: any
    CustomerId?: number | null
}

const style: SxProps = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingRight: '52px',
    paddingLeft: '48px',
    paddingBottom: '48px',
}
interface FormData {
    fullName: string
    jobTitle: string
    phoneNumber: string
    email: string
    password: string
    confirmPassword: string
    isAdmin: boolean
    role: string
    is_active: boolean
}
interface Product {
    id: string
    name: string
    skills: [
        {
            id: string
            skill: string
        }
    ]
}

const UpdateModal = ({
    isEdit,
    open,
    handleClose,
    User,
    CustomerId,
}: IProductCreateUpdateModal) => {
    const { data: productsData, isPending: isFetchingProdcuts } = useFetch(
        dashBoardServices.getProducts
    )
    // console.log('User', User)
    const [isPending, setIsPending] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const { width, height } = useWindowSize()

    const dispatch = useAppDispatch()
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<FormData>()

    const passwordValue = watch('password') //
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // console.log(value, name, type)
        })
        return () => {
            subscription.unsubscribe()
        }
    }, [watch])
    const onSubmit = (data: FormData) => {
        // console.log(data) // Handle form submission here
    }

    const [chartDimensions, setChartDimensions] = useState(450)
    // declaring some mui media query to be used in redindering line charts.
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])

    const handleMainACtion = (DATA: FormData) => {
        setIsPending(true)
        // console.log('DATA', DATA)
        const values = DATA
        dashBoardServices
            // @ts-expect-error accepted
            .updateCustomerUser(CustomerId, User.id, {
                user: {
                    email: values.email,
                    full_name: values.fullName,
                    jop_title: values.jobTitle,
                    phone: values.phoneNumber,
                    is_active: values.is_active,
                },
                is_admin: values.role=='isAdmin' ,
                is_project_manager: values.role==='isProjectManager' ,
                customer: CustomerId,
            })
            .then(() => {
                handleClose()
                // @ts-expect-error number
                dispatch(getCustomer(CustomerId))
            })
            .catch((e) => {
                // console.log('eeeeee', e)
                const alertObj: IAlert = {
                    id: 'validation Error',
                    message: 'error in response ',
                    state: true,
                    type: 'error',
                }
                dispatch(addAlert(alertObj))
            })
            .finally(() => {
                setIsPending(false)
            })
    }
    useEffect(() => {
        // console.log('User', User)
        if (User) {
            setValue('fullName', User?.user.full_name)
            setValue('email', User?.user.email)
            setValue('jobTitle', User?.user.jop_title)
            // setValue('password', User?.user.password)
            // setValue('confirmPassword', User?.user.password2)
            setValue('phoneNumber', User?.user.phone)
            setValue('isAdmin', User?.is_admin)
            setValue('is_active', User?.user?.is_active)
            // console.log('User?.user?.is_active', User?.user?.is_active)

            setDisabled(true)
        }
    }, [User])
    useEffect(() => {
        // console.log('errors', errors)
        Object.entries(errors).map(([key, value]) => {
            const alertObj: IAlert = {
                id: key,
                message: value?.message ?? '',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
        })
    }, [errors])
    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        // maxHeight: chartDimensions,
                        overflow: 'auto',
                        p: 1,
                    }}
                >
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{ marginTop: '40px' }}
                    >
                        <CommonTypography
                            sx={{
                                color: '#000',
                                fontFamily: 'Inter',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: 'normal',
                                textTransform: 'capitalize',
                            }}
                        >
                            User Information
                        </CommonTypography>
                        <Controller
                            name="is_active"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    sx={{ padding: '0px', margin: '0px' }}
                                    control={
                                        <MySwitch
                                            field={field}
                                            active={field.value}
                                            transition={33}
                                        />
                                    }
                                    label=""
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction={'column'} rowGap={3}>
                    <Stack
    direction={'row'}
    justifyContent={'end'}
    sx={{ marginTop: 2.75 }}
>
    <FormControl component="fieldset">
        <Controller
        name='role'
            defaultValue={User.is_admin ? 'isAdmin' : User.is_project_manager ? 'isProjectManager' : ''}
            control={control}
            render={({ field }) => (
                <RadioGroup {...field} row>
                    <FormControlLabel
                        value="isProjectManager"
                        control={<Radio />}
                        label="Project Manager"
                    />
                    <FormControlLabel
                        value="isAdmin"
                        control={<Radio />}
                        label="Admin"
                    />
                    <FormControlLabel
                        value=""
                        control={<Radio />}
                        label="User"
                    />
                </RadioGroup>
            )}
        />
    </FormControl>
</Stack>
                        <Controller
                            name="fullName"
                            control={control}
                            defaultValue=""
                            rules={{ required: 'Full Name is required' }}
                            render={({ field }) => (
                                <TextField
                                    sx={{ ...styles.textField }}
                                    {...field}
                                    label="Full Name"
                                    error={!!errors.fullName}
                                    helperText={
                                        errors.fullName
                                            ? 'Full Name is required'
                                            : ''
                                    }
                                />
                            )}
                        />

                        <Controller
                            name="jobTitle"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <TextField
                                    sx={{ ...styles.textField }}
                                    {...field}
                                    label="Job Title"
                                />
                            )}
                        />

                        <Controller
                            name="phoneNumber"
                            control={control}
                            defaultValue=""
                            rules={{
                                pattern: {
                                    value: /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
                                    message: 'Wrong Phone Number .',
                                },

                                maxLength: {
                                    value: 15,
                                    message: 'max length is 15 .',
                                },
                            }}
                            render={({ field }) => {
                                let error = {}
                                if (errors.phoneNumber) {
                                    error = styles.error
                                }
                                return (
                                    <TextField
                                        sx={{
                                            ...styles.textField,
                                            ...error,
                                        }}
                                        {...field}
                                        label="Phone Number"
                                    />
                                )
                            }}
                        />

                        <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    sx={{ ...styles.textField }}
                                    {...field}
                                    label="Email"
                                    disabled={true}
                                />
                            )}
                        />

                        <Stack direction={'row'} gap={1}>
                            {!disabled && (
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            sx={{ flex: 1 }}
                                            {...field}
                                            type="password"
                                            label="Password"
                                            disabled={disabled}
                                            error={!!errors.password}
                                            helperText={
                                                errors.password
                                                    ? 'Password is required'
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            )}

                            {!disabled && (
                                <Controller
                                    name="confirmPassword"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        validate: (value) =>
                                            value === passwordValue,
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            sx={{ flex: 1 }}
                                            type="password"
                                            label="Confirm Password"
                                            error={!!errors.confirmPassword}
                                            helperText={
                                                errors.confirmPassword
                                                    ? 'Passwords do not match'
                                                    : ''
                                            }
                                        />
                                    )}
                                />
                            )}
                        </Stack>
                    </Stack>
                    <Stack direction={'column'}>
                        <ActionNdCancel
                            actionBtnLabel="Confirm"
                            actionBtncolor="primary"
                            btnWidth={'140px'}
                            btnHeight={'40px'}
                            alignItems="center"
                            direction={'row'}
                            cancelBtnLabel="Cancel"
                            spacing={3}
                            handleAction={handleSubmit(handleMainACtion)}
                            handleCancelAction={handleClose}
                            justifyContent="end"
                            pending={isPending}
                            sx={{ marginTop: '48px' }}
                        />
                    </Stack>
                </Box>
            </Box>
        </BasicModal>
    )
}

const styles = {
    textField: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
    },
    error: {
        border: '1px solid #be1515',
    },
}
export default UpdateModal
