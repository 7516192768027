import { Box, type SxProps } from '@mui/material'
import React, { forwardRef } from 'react'
interface Props {
    children: React.ReactNode
    sx?: SxProps
}
const ProfileLayout = forwardRef<HTMLDivElement, Props>(
    ({ children, sx }, ref) => {
        return (
            <Box
                ref={ref}
                sx={{
                    position: 'absolute',
                    top: '97px',
                    right: '30px',
                    width: '360px',
                    borderRadius: '20px',
                    background: '#FFF',
                    backgroundColor: '#FFF',
                    height: 'max-content',
                    zIndex: 9999,
                    paddingY: '10px',
                    ...sx,
                }}
            >
                {children}
            </Box>
        )
    }
)
ProfileLayout.displayName = 'ProfileLayout'
export default ProfileLayout
