
import { FileIcon } from 'react-file-icon';
import {
    Stack,
    Typography,
    type SxProps,
    Icon,
    IconButton,
    Button,
} from '@mui/material'
import React from 'react'
import { ReactComponent as Close } from '../../../assets/images/x.svg'

interface IStyle {
    text: SxProps
    icon: SxProps
}
function getFileExtension(filename:any) {
    const parts = filename.split('.');
    return parts.length > 1 ? parts[parts.length - 1] : null;
  }
const AttachmentFile = ({ name, IconFile, deleteFile, Id }: any) => {
    return (
        <Stack
            direction={'row'}
            sx={{
                borderRadius: '4.8px',
                border: '0.72px solid #F2F2F2',
                background: '#FFF',
                maxHeight: '30px',
                margin:"5px"
            }}
            alignItems={'center'}
        >
            <Stack sx={styles.icon}>
                <FileIcon extension={getFileExtension(name)} type='3d' labelColor='red' />
            </Stack>
            <Typography sx={styles.text}>{name}</Typography>
            <Button
                onClick={() => {
                    deleteFile(Id)
                }}
                component="span"
                sx={{
                    minWidth: '24px',
                    height: '24px',
                    padding: '0px',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '4px',
                }}
            >
                <Close />
            </Button>
        </Stack>
    )
}

export default AttachmentFile
const styles: IStyle = {
    icon: {
        marginRight: '5px',
        marginLeft: '5px',
        width: "30px",
        height: "25px"
    },
    text: {
        marginRight: '30px',
        color: '#929EAE',
        fontFamily: 'Inter',
        fontSize: '14.4px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '19.2px',
        textTransform: 'capitalize',
        minWidth:"max-content"
    },
}
