import { Card, Typography, type SxProps } from '@mui/material'
import { Stack } from '@mui/system'
import dayjs from 'dayjs'
import React from 'react'
interface styles {
    card: SxProps
    keyLabel: SxProps
    valueText: SxProps
}

const RequestInfoCard = ({ userSummary }: any) => {
    return (
        <Card sx={styles.card}>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>Opened:</Typography>

                <Typography sx={styles.valueText}>
                    {dayjs(userSummary.created_at).format('MMM D YYYY, h:mm A')}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>Assigned to: </Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.assigned_to.user.full_name}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>Last updated:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.updated_at &&
                        dayjs(userSummary.updated_at).format(
                            'MMM D YYYY, h:mm A'
                        )}
                    {!userSummary.updated_at && '------'}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>Response time: </Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.response_time &&
                        dayjs(userSummary.response_time).format(
                            'MMM D YYYY, h:mm A'
                        )}
                    {!userSummary.response_time && '------'}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>Resolved time:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.resolved_time &&
                        dayjs(userSummary.resolved_time).format(
                            'MMM D YYYY, h:mm A'
                        )}
                    {!userSummary.resolved_time && '------'}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>Escalation status:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.esclation_status ? 'ture' : 'false'}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>bug related:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.bug_related}
                </Typography>
            </Stack>
        </Card>
    )
}

export default RequestInfoCard

const styles: styles = {
    card: {
        width: '360px',
        height: '259.05px',
        backgroundColor: '#FBFBFB',
        marginTop: '13px',
        paddingTop: '24px',
    },
    keyLabel: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '16.25px',
        width: '142px',
        color: '#929EAE',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
    valueText: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
}
