import { Box, CircularProgress, Stack } from '@mui/material'
import React, { useState } from 'react'
import CreateEngineer from '../../assets/images/emptyEngneer.svg'
import CommonEmptyState from '../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../components/common/CommonButton/CommonButton'
import EngineerCreateModal from '../../components/Modals/CreateUpdateEngineerModal/CreateUpdateEngineerModal'
import CommonTypography from '../../components/common/CommonTypography/CommonTypography'
import dashBoardServices from '../../services/cspServices'
import useFetch from '../../hooks/useFetch'
import ListingEngineers from '../../contents/CustomersContent/ListingEngineers/ListingEngineers'
import DeleteEngineerModal from '../../components/Modals/DeleteEngineerModal/DeleteEngineerModal'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../app/hooks'
import { addAlert } from '../../features/systemAlerts'
import { type IAlert } from '../../interfaces/IAlert'
const EmptyEngineersState = () => {
    const [selectedID, setSelectedID] = useState<any>(undefined)
    const [selectedEng, setSelectedEng] = useState(undefined)
    const [open, setOpen] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const dispatch = useAppDispatch()
    console.log('selectedEng', selectedEng)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const {
        data: engineers,
        isPending: isFetching,
        error,
    } = useFetch(dashBoardServices.getEngineers, refetch)
    const navigate = useNavigate()
    console.log('engineers', engineers)
    const createNew = () => {
        setOpen(true)
    }
    const deleteSelected = () => {
        dashBoardServices
            .deleteEngineer(selectedID)
            .then((res) => {
                console.log('res', res)
                setRefetch(!refetch)
                navigate('/engineers', { replace: true })
            })
            .catch((e) => {
                console.log('e', e)
                const errors = e.response.data
                Object.entries(errors)?.map(([key, value]) => {
                    if (value instanceof Array) {
                        value.map((item, i) => {
                            const alertObj: IAlert = {
                                id: `${key}${i}key`,
                                message: item,
                                state: true,
                                type: 'error',
                            }
                            dispatch(addAlert(alertObj))
                        })
                    } else {
                        const msg: string = value?.toString() ?? ''
                        const alertObj: IAlert = {
                            id: `${key}key`,
                            message: `${msg}`,
                            state: true,
                            type: 'error',
                        }
                        dispatch(addAlert(alertObj))
                    }
                })
            })
            .finally(() => {
                setOpenDeleteModal(false)
                setSelectedID(undefined)
                setSelectedEng(undefined)
            })
    }
    return (
        <Stack direction={'column'}>
            <Stack direction={'row'} spacing={0.5} sx={{ paddingLeft: '32px' }}>
                <CommonTypography variant={'h6'} sx={{ color: '#33303B' }}>
                    IT-RANKS Engineers
                </CommonTypography>
            </Stack>
            {!isFetching && engineers.length == 0 && (
                <Stack
                    sx={{
                        minHeight: '95vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    direction={'row'}
                >
                    <CommonEmptyState
                        src={CreateEngineer}
                        alt="site empty state"
                        width={81}
                        height={180}
                        sx={{ marginTop: '88px' }}
                    >
                        <CommonButton
                            variant={'contained'}
                            sx={{ width: 200, height: 40 }}
                            onClick={createNew}
                        >
                            New Engineer
                        </CommonButton>
                    </CommonEmptyState>
                </Stack>
            )}
            {isFetching && (
                <Stack
                    direction={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{ minHeight: '80vh' }}
                >
                    <CircularProgress color="inherit" size={50} />
                </Stack>
            )}
            {!isFetching && engineers.length > 0 && (
                <ListingEngineers
                    engineers={engineers}
                    seeDetails={(e, index) => {
                        console.log('asasasa', e, index)
                        setSelectedID(e)
                        setSelectedEng(engineers[index])
                        setOpen(true)
                    }}
                    createNew={createNew}
                />
            )}
            <EngineerCreateModal
                id={selectedID}
                Engineer={selectedEng}
                open={open}
                handleClose={() => {
                    setSelectedID(undefined)
                    setSelectedEng(undefined)
                    setOpen(false)
                    setRefetch(!refetch)
                }}
                handleDelete={() => {
                    setOpen(false)
                    setOpenDeleteModal(true)
                }}
            />
            <DeleteEngineerModal
                handleClose={() => {
                    setOpenDeleteModal(false)
                    setSelectedID(undefined)
                    setSelectedEng(undefined)
                }}
                open={openDeleteModal}
                handleMainAction={deleteSelected}
                isPending={false}
            />
        </Stack>
    )
}

export default EmptyEngineersState
