import * as React from 'react'
import Alert from '@mui/material/Alert'
import type { AlertColor } from '@mui/lab'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
interface ICommonAlert {
    sx: any
    icon?: React.ReactNode
    severity: AlertColor | undefined
    action?: React.ReactNode
    children: any
}

const customIconMapping = {
    error: <CloseIcon />,
    success: <DoneIcon />,
    info: <CloseIcon />,
    warning: <CloseIcon />,
}
const CommonAlert = ({
    sx,
    icon,
    severity,
    action,
    children,
}: ICommonAlert) => {
    return (
        <Alert
            severity={severity}
            sx={sx}
            action={action}
            iconMapping={customIconMapping}
        >
            {children}
        </Alert>
    )
}

export default CommonAlert
