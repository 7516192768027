import { Card, Typography, type SxProps } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
interface styles {
    card: SxProps
    keyLabel: SxProps
    valueText: SxProps
}

const ProductInformationCard = ({ userSummary }: any) => {
    console.log('userSummary', userSummary)
    return (
        <Card sx={styles.card}>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>product name:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.related_to.customer_product.product.name}{' '}
                    {' - '} {userSummary.related_to.skill.skill}{' '}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>Release:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.related_to.customer_product.release}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>platform:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.related_to.customer_product.paltform}
                </Typography>
            </Stack>
            <Stack direction={'row'}>
                <Typography sx={styles.keyLabel}>site:</Typography>

                <Typography sx={styles.valueText}>
                    {userSummary.related_to.customer_product.site.name}
                </Typography>
            </Stack>
        </Card>
    )
}

export default ProductInformationCard

const styles: styles = {
    card: {
        width: '360px',
        minHeight: '157.05px',
        backgroundColor: '#FBFBFB',
        marginTop: '13px',
        paddingTop: '24px',
    },
    keyLabel: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '16.25px',
        minWidth: '120px',
        color: '#929EAE',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
    valueText: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
}
