import * as React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'

interface ICommonLoadingButton {
    children: any
    color?: any
    disabled?: boolean
    size?: any
    sx?: any
    variant?: any
    onClick: () => void
    loading?: boolean
}

const CommonLoadingButton = ({
    children,
    color,
    disabled,
    size,
    sx,
    variant,
    onClick,
    loading,
}: ICommonLoadingButton) => {
    return (
        <LoadingButton
            onClick={onClick}
            loading={loading}
            variant={variant}
            color={color}
            disabled={disabled}
            size={size}
            sx={sx}
        >
            {children}
        </LoadingButton>
    )
}

export default CommonLoadingButton
