import { useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import FetchingData from '../../../../components/Loaders/FtechingData/FetchingData'
import { customerSelector } from '../../../../features/customers'
import SitesEmptyState from '../../../../contents/CustomersContent/SitesEmptyState/SitesEmptyState'
import CreateUpdateSite from '../../../../contents/CustomersContent/CreateUpdateSite/CreateUpdateSite'
import ListingCustomerSites from '../../../../contents/CustomersContent/ListingCustomerSites/ListingCustomerSites'
import { CircularProgress, Stack } from '@mui/material'

interface ISitesTab {
    customerId: string
}

const SitesTab = ({ customerId }: ISitesTab) => {
    const { customer, pending } = useAppSelector(customerSelector)
    const [navigateToCreateUpdate, setNavigateToCreateUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [siteIdToBeEdited, setSiteIdToBeEdited] = useState<number | null>(
        null
    )

    if (pending) {
        return (
            <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ minHeight: '40vh' }}
            >
                <CircularProgress color="inherit" size={50} />
            </Stack>
        )
    }
    return (
        <>
            {customer.sites.length === 0 &&
                !navigateToCreateUpdate &&
                !isEdit && (
                    <SitesEmptyState
                        createNew={() => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(false)
                        }}
                    />
                )}
            {customer.sites.length !== 0 &&
                !navigateToCreateUpdate &&
                !isEdit && (
                    <ListingCustomerSites
                        createNew={() => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(false)
                        }}
                        seeDetails={(siteIdToBeEdited: number | null) => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(true)
                            setSiteIdToBeEdited(siteIdToBeEdited)
                        }}
                    />
                )}
            {navigateToCreateUpdate && (
                <CreateUpdateSite
                    customerId={customerId}
                    cancelAction={() => {
                        setNavigateToCreateUpdate(false)
                        setIsEdit(false)
                    }}
                    isEdit={isEdit}
                    siteDataToBeEdited={
                        customer.sites.filter(
                            (site) => site.id === siteIdToBeEdited
                        )[0]
                    }
                />
            )}
        </>
    )
}

export default SitesTab
