import React from 'react'
import { ColorModeContextProvider } from './context/ColorModeContext'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import SharedLayout from './layout/SharedLayout/SharedLayout'
import Home from './pages/Home/Home'
import Customers from './pages/Customers/Customers'
import CustomersTable from './pages/CustomersTable'
import CustomerInfoPage from './pages/Customers/[id]/CustomerInfoPage'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CommonTypography from './components/common/CommonTypography/CommonTypography'
import EmptyEngineersState from './pages/Engineers/EmptyEngineersState'
import SkillsState from './pages/Skills/Skills'
import TeamsPage from './pages/Teams/Teams'
import Login from './pages/Login/Login'
import ProtectedRoute from './navigation/navigation'
import RequestsPage from './pages/Requests/Requests'
import Request from './pages/Requests/Request'
import RequireAuth from './hooks/useAuth'
import RequireCustomerAuth from './hooks/useCustomerAuth'
import ContractDetails from './components/ContractDetails/ContractDetails'
import CalenderIO from './components/CalenderIO/CalenderIO'
import TeamEngineers from './pages/TeamEngineers/TeamEngineers'
import ChangePassword from './pages/Login/ChangePassword'
import { DashboardPage } from './pages/DashboardPage/DashboardPage'

const App = () => {
    return (
        <Provider store={store}>
            <ColorModeContextProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/resetPassword/:data/:data2"
                                element={<ChangePassword />}
                            />

                            <Route path="/" element={<SharedLayout />}>
                                {/* <Route index element={<Home />} /> */}
                                <Route element={<RequireAuth />}>
                                    <Route path="/">
                                        <Route index element={<Customers />} />
                                        <Route
                                            path="/customers/:id"
                                            element={<CustomerInfoPage />}
                                        />
                                    </Route>
                                    <Route path="/customers">
                                        <Route
                                            index
                                            element={<CustomersTable />}
                                        />
                                        <Route
                                            path="/customers/:id"
                                            element={<CustomerInfoPage />}
                                        />
                                    </Route>
                                    <Route path="/engineers">
                                        <Route
                                            index
                                            element={<EmptyEngineersState />}
                                        />
                                        {/* <Route
                                        path="/customers/:id"
                                        element={<CustomerInfoPage />}
                                    /> */}
                                    </Route>
                                    <Route path="/skills">
                                        <Route
                                            index
                                            element={<SkillsState />}
                                        />
                                        {/* <Route
                                        path="/customers/:id"
                                        element={<CustomerInfoPage />}
                                    /> */}
                                    </Route>
                                    <Route path="/teams">
                                        <Route index element={<TeamsPage />} />
                                        {/* <Route
                                        path="/customers/:id"
                                        element={<CustomerInfoPage />}
                                    /> */}
                                    </Route>
                                </Route>
                                <Route element={<RequireCustomerAuth />}>
                                    <Route path="/requests">
                                        <Route
                                            index
                                            element={<RequestsPage />}
                                        />
                                        <Route
                                            path=":id/:customerId"
                                            element={<Request />}
                                        />
                                    </Route>

                                    <Route path="/contract">
                                        <Route
                                            index
                                            element={<ContractDetails />}
                                        />
                                    </Route>
                                    <Route path="/dashboard">
                                        <Route
                                            index
                                            element={<DashboardPage />}
                                        />
                                    </Route>

                                    <Route path="/calender/:id">
                                        <Route index element={<CalenderIO />} />
                                    </Route>
                                    <Route path="/availability">
                                        <Route
                                            index
                                            element={<TeamEngineers />}
                                        />
                                    </Route>
                                </Route>
                            </Route>

                            <Route path="/login" element={<Login />} />
                            <Route
                                path="resetPassword/:data"
                                element={<ChangePassword />}
                            />
                        </Routes>
                        {/* TODO: Remove Version component */}
                        <CommonTypography
                            sx={{
                                position: 'fixed',
                                right: 0,
                                bottom: 0,
                                zIndex: 9999,
                                color: 'antiquewhite',
                            }}
                            align={'end'}
                        >
                            Version 1.0.42
                        </CommonTypography>
                    </BrowserRouter>
                </LocalizationProvider>
            </ColorModeContextProvider>
        </Provider>
    )
}

export default App
