import CommonFormControl from '../common/CommonFormControl/CommonFormControl'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CommonIconButton from '../common/CommonIconButton/CommonIconButton'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded'

interface ISearchBar {
    id: string
    label: string
    handleClearAction: () => void
    searchValue: string
    onChange: (searchValue: string) => void
}

const SearchBar = ({
    id,
    label,
    handleClearAction,
    searchValue,
    onChange,
}: ISearchBar) => {
    return (
        <CommonFormControl fullWidth variant={'outlined'}>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchRoundedIcon />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <CommonIconButton
                            aria-label="toggle password visibility"
                            onClick={handleClearAction}
                            edge="end"
                            sx={{ marginRight: 0.1 }}
                            size={'small'}
                        >
                            {searchValue === '' ? '' : <ClearRoundedIcon />}
                        </CommonIconButton>
                    </InputAdornment>
                }
                label={label}
                value={searchValue}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
            />
        </CommonFormControl>
    )
}

export default SearchBar
