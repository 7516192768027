import BasicImageContainer from '../BasicImageContainer/BasicImageContainer'

interface IRectFrameLogo {
    src: any
    alt: string
    width?: number | string
    height?: number | string
}
const style = {
    border: '1px solid rgba(188, 188, 188, 1)',
    borderRadius: '14px',
}
const RectFrameLogo = ({ src, alt, width, height }: IRectFrameLogo) => {
    return (
        <BasicImageContainer
            src={src}
            alt={alt}
            width={width}
            height={height}
            sx={style}
            style={style}
        />
    )
}

export default RectFrameLogo
