import { CircularProgress, Stack } from '@mui/material'

const FetchingData = () => {
    return (
        <Stack
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ minHeight: '40vh' }}
        >
            <CircularProgress color="inherit" size={50} />
        </Stack>
    )
}

export default FetchingData
