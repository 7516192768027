import { createAction } from "@reduxjs/toolkit";
import type { IAlert } from "../../interfaces/IAlert";

// async action for fetching a customer data
// AsynkThunk can only accept one paramter thats why we send alertObj data as an object
export const addAlert = createAction('systemAlerts/addAlert',  (alertObj: IAlert) => {
    return {
        payload: alertObj 
    }
});

export const removeAlert = createAction('systemAlerts/removeAlert', (alertId: string) => {
    return {
        payload: alertId
    }
})