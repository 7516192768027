import { Card, Typography, type SxProps } from '@mui/material'
import { Stack } from '@mui/system'

interface styles {
    card: SxProps
    keyLabel: SxProps
    valueText: SxProps
}

const RequestSpecificationCard = ({ RequestSpecifications }: any) => {
    return (
        <Card sx={styles.card}>
            {RequestSpecifications.map((item: any) => {
                return (
                    <Stack key={item.id} direction={'row'}>
                        <Typography sx={styles.keyLabel}>
                            {item.key} {' : '}
                        </Typography>

                        <Typography sx={styles.valueText}>
                            {item.value != '' ? item.value : '-------'}
                        </Typography>
                    </Stack>
                )
            })}
        </Card>
    )
}

export default RequestSpecificationCard

const styles: styles = {
    card: {
        width: '360px',
        height: 'max-content',
        backgroundColor: '#FBFBFB',
        marginTop: '13px',
        paddingTop: '24px',
    },
    keyLabel: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: '16.25px',
        width: '142px',
        color: '#929EAE',
        textAlign: 'center',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
    valueText: {
        display: 'flex',
        justifyContent: 'flex-start',
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px' /* 142.857% */,
        textTransform: 'capitalize',
        marginBottom: '13px',
    },
}
