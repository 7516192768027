import { useState } from 'react'
import { useAppSelector } from '../../../../app/hooks'
import FetchingData from '../../../../components/Loaders/FtechingData/FetchingData'
import { customerSelector } from '../../../../features/customers'
import SitesEmptyState from '../../../../contents/CustomersContent/SitesEmptyState/SitesEmptyState'
import CreateUpdateSite from '../../../../contents/CustomersContent/CreateUpdateSite/CreateUpdateSite'
import ListingCustomerSites from '../../../../contents/CustomersContent/ListingCustomerSites/ListingCustomerSites'
import UsersEmptyState from '../../../../contents/CustomersContent/UsersEmptySatate/UsersEmptySatate'
import UserCreateUpdateModal from '../../../../components/Modals/UserCreateUpdateModal/UserCreateUpdateModal'
import ListingCustomerUsers from '../../../../contents/CustomersContent/ListingCustomerUsers/ListingCustomerUsers'
import UserUpdateModal from '../../../../components/Modals/NewUserUpdateModal/UpdateUser'

interface IUsersTab {
    customerId: string
}

const UsersTab = ({ customerId }: IUsersTab) => {
    const { customer, pending } = useAppSelector(customerSelector)
    console.log('customer', customer)
    const [navigateToCreateUpdate, setNavigateToCreateUpdate] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [openProductModal, setOpenProductModal] = useState(false)
    const [openUpdateModal, setOpenUpdateModal] = useState(false)
    const [userIdToBeEdited, setUserIdToBeEdited] = useState<number>()

    if (pending) {
        return <FetchingData />
    }

    return (
        <>
            {customer.users.length === 0 &&
                !navigateToCreateUpdate &&
                !isEdit && (
                    <UsersEmptyState
                        createNew={() => {
                            setOpenProductModal(true)

                            // setNavigateToCreateUpdate(true)
                            setIsEdit(false)
                        }}
                    />
                )}
            {customer.users.length !== 0 &&
                !navigateToCreateUpdate &&
                !isEdit && (
                    <ListingCustomerUsers
                        createNew={() => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(false)
                            setOpenProductModal(true)
                        }}
                        seeDetails={(userIdToBeEdited: number) => {
                            setNavigateToCreateUpdate(true)
                            setIsEdit(true)
                            setOpenUpdateModal(true)
                            setUserIdToBeEdited(userIdToBeEdited)
                        }}
                    />
                )}

            <UserCreateUpdateModal
                isEdit={isEdit}
                handleClose={() => {
                    setOpenProductModal(false)
                    setNavigateToCreateUpdate(false)

                    setIsEdit(false)
                }}
                // CustomerId={customer.id}
                open={openProductModal}
                customer={customer}
            />
            {isEdit && (
                <UserUpdateModal
                    isEdit={isEdit}
                    handleClose={() => {
                        setOpenUpdateModal(false)
                        setOpenProductModal(false)
                        setNavigateToCreateUpdate(false)

                        setIsEdit(false)
                    }}
                    CustomerId={customer.id}
                    open={openUpdateModal}
                    // @ts-expect-error escape
                    User={customer.users[userIdToBeEdited]}
                />
            )}
        </>
    )
}

export default UsersTab
