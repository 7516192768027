import { useEffect, useState } from 'react'
import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../../components/common/CommonTypography/CommonTypography'
import Grid from '@mui/material/Grid/Grid'
import CommonTextField from '../../../components/common/CommonTextField/CommonTextField'
import ActionNdCancel from '../../../components/ActionNdCancel/ActionNdCancel'
import SlaTable from '../../../components/SlaTable/SlaTable'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import type { IPriority } from '../../../interfaces/IPriority'
import CommonDatePicker from '../../../components/common/CommonDatePicker/CommonDatePicker'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import SlaOptionsModal from '../../../components/Modals/SlaOptionsModal/SlaOptionsModal'
import FetchingData from '../../../components/Loaders/FtechingData/FetchingData'
import EditPriorityModal from '../../../components/Modals/EditPriorityModal/EditPriorityModal'
import hanldeTimeInMinToStr from '../../../helper/hanldeTimeInMinToStr'
import type { IContract } from '../../../interfaces/IContract'
import { useAppDispatch } from '../../../app/hooks'
import type { IAlert } from '../../../interfaces/IAlert'
import { addAlert } from '../../../features/systemAlerts'
import SaveDataConfirmationModal from '../../../components/Modals/SaveDataConfirmationModal/SaveDataConfirmationModal'
import { getCustomer } from '../../../features/customers'

interface ICreateUpdateContract {
    customerId: string
    cancelAction: () => void
    isEdit: boolean
    contractDataToBeEdit?: IContract
}

const CreateUpdateContract = ({
    customerId,
    cancelAction,
    isEdit,
    contractDataToBeEdit,
}: ICreateUpdateContract) => {
    const { data, isPending: isFetchingPriorities } = useFetch(
        dashBoardServices.getDefaultPriorities
    )
    const [prioritiesList, setPrioritiesList] = useState<IPriority[] | null>(
        data
    )
    const [startDate, setStartDate] = useState<Dayjs | null>(dayjs())
    const [endDate, setEndDate] = useState<Dayjs | null>(dayjs())
    const [openSlaOptionsModal, setOpenSlaOptionsModal] = useState(false)
    const [openEditPriorityModal, setOpenEditPriorityModal] = useState(false)
    const [openSaveDataModal, setOpenSaveDataModal] = useState(false)
    const [irrOfWorkingHours, setIrrOfWorkingHours] = useState<boolean>(true)
    const [irrOfTheWeekends, setIrrOfTheWeekends] = useState<boolean>(false)
    const [irrOfTheHolidays, setIrrOfTheHolidays] = useState<boolean>(false)
    const [selectedPriority, setSelectedPriority] = useState<IPriority | null>(
        null
    )
    const [isPending, setIsPending] = useState(false)
    const [isSavingData, setIsSavingData] = useState(false)

    const dispatch = useAppDispatch()

    const handleOpenSlaOptionsModal = () => {
        setOpenSlaOptionsModal(true)
    }

    const handleCloseSlaOptionsModal = () => {
        setOpenSlaOptionsModal(false)
    }

    const handleSetSlaOptions = (
        irrOfWorkingHours: boolean,
        irrOfTheWeekends: boolean,
        irrOfTheHolidays: boolean
    ) => {
        setIrrOfWorkingHours(irrOfWorkingHours)
        setIrrOfTheWeekends(irrOfTheWeekends)
        setIrrOfTheHolidays(irrOfTheHolidays)
        handleCloseSlaOptionsModal()
    }
    const [keyDefault, setKeyDefault] = useState('')
    const handleOpenEditPriorityModal = (priortyObj: IPriority) => {
        setOpenEditPriorityModal(true)
        setSelectedPriority(priortyObj)
    }

    const handleCloseEditPriorityModal = () => {
        setOpenEditPriorityModal(false)
    }

    const handleSetPriority = (priortyObj: IPriority) => {
        if (prioritiesList) {
            const newprioritiesList = prioritiesList.map(
                (priorty: IPriority) => {
                    if (priorty.id === priortyObj.id) {
                        // replace object
                        return priortyObj
                    } else {
                        // The rest haven't changed
                        return priorty
                    }
                }
            )
            setPrioritiesList(newprioritiesList)
        }
    }

    const handleReformatPrioritiesList = (List: IPriority[]) => {
        const newprioritiesList = List.map((priorty: IPriority) => {
            const newPriorityObj: IPriority = {
                id: priorty.id,
                name: priorty.name,
                priority_name: priorty.name,
                response_time: priorty.response_time,
                resolution_time: priorty.resolution_time,
                is_applicable: priorty.is_applicable
                    ? priorty.is_applicable
                    : true,
                priority: priorty.id,
                priority_description: priorty.priority_description
                    ? priorty.priority_description
                    : '',
            }
            return newPriorityObj
        })
        return newprioritiesList
    }

    const handleOpenSaveDataModal = () => {
        setOpenSaveDataModal(true)
    }

    const handleCloseSaveDataModal = () => {
        setOpenSaveDataModal(false)
    }

    const handleMainAction = () => {
        setIsPending(true)

        const contractName = document.getElementById(
            'contract_name'
        ) as HTMLInputElement
        const contractDescription = document.getElementById(
            'contract_description'
        ) as HTMLInputElement

        if (
            contractName.value.trim() === '' ||
            contractName.value === undefined ||
            contractName.value === null
        ) {
            const alertObj: IAlert = {
                id: 'customerContractNameError',
                message: 'Please fill all the required data.',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        }
        if (startDate && endDate && startDate >= endDate) {
            const alertObj: IAlert = {
                id: 'customerContractDateError',
                message: 'End date must be after start date ',
                state: true,
                type: 'error',
            }
            dispatch(addAlert(alertObj))
            setIsPending(false)
        } else {
            const contractObj: IContract = {
                name: contractName.value,
                description: contractDescription.value,
                support_starting_date: startDate
                    ? startDate.format().slice(0, 10)
                    : '',
                support_ending_date: endDate
                    ? endDate.format().slice(0, 10)
                    : '',
                customer: parseInt(customerId),
                sla_option: {
                    default_Working_hours: irrOfWorkingHours,
                    irrespective_of_the_weekends: irrOfTheWeekends,
                    irrespective_of_Working_hours: irrOfTheHolidays,
                },
                priorities: prioritiesList !== null ? prioritiesList : [],
                update_priorities: false,
            }
            if (isEdit) {
                handleOpenSaveDataModal()
            } else {
                dashBoardServices
                    .createCustomerContract(customerId, contractObj)
                    .then((res) => {
                        if (res.status === 200) {
                            setIsPending(false)
                            cancelAction()
                            dispatch(getCustomer(customerId))
                        }
                    })
                    .catch((err) => {
                        if (err) {
                            setIsPending(false)
                            console.log(err)
                        }
                    })
            }
        }
    }

    const handleUpdateContractData = () => {
        setIsSavingData(true)

        const contractName = document.getElementById(
            'contract_name'
        ) as HTMLInputElement
        const contractDescription = document.getElementById(
            'contract_description'
        ) as HTMLInputElement

        const contractObj: IContract = {
            name: contractName.value,
            description: contractDescription.value,
            support_starting_date: startDate
                ? startDate.format().slice(0, 10)
                : '',
            support_ending_date: endDate ? endDate.format().slice(0, 10) : '',
            customer: parseInt(customerId),
            sla_option: {
                default_Working_hours: irrOfWorkingHours,
                irrespective_of_Working_hours: irrOfTheWeekends,
                irrespective_of_the_weekends: irrOfTheHolidays,
            },
            priorities: prioritiesList !== null ? prioritiesList : [],
            update_priorities: true,
        }
        if (contractDataToBeEdit?.id)
            dashBoardServices
                .updateCustomerContract(
                    customerId,
                    contractDataToBeEdit?.id,
                    contractObj
                )
                .then((res) => {
                    if (res.status === 200) {
                        setIsSavingData(false)
                        setIsPending(false)
                        handleCloseSaveDataModal()
                        cancelAction()
                        dispatch(getCustomer(customerId))
                    }
                })
                .catch((err) => {
                    if (err) {
                        setIsPending(false)
                        setIsSavingData(false)
                        console.log(err)

                        const alertObj: IAlert = {
                            id: 'updateContract',
                            message: 'update contract failed.',
                            state: true,
                            type: 'error',
                        }
                        dispatch(addAlert(alertObj))
                    }
                })
    }

    useEffect(() => {
        if (isEdit && contractDataToBeEdit) {
            setPrioritiesList(contractDataToBeEdit.priorities)
            setStartDate(dayjs(contractDataToBeEdit.support_starting_date))
            setEndDate(dayjs(contractDataToBeEdit.support_ending_date))
            const data = contractDataToBeEdit.sla_option

            Object.entries(data).map(([key, value]) => {
                if (value && key != 'id') setKeyDefault(key)
            })
            setIrrOfWorkingHours(
                contractDataToBeEdit.sla_option.default_Working_hours
            )
            setIrrOfTheHolidays(
                contractDataToBeEdit.sla_option.irrespective_of_Working_hours
            )
            setIrrOfTheWeekends(
                contractDataToBeEdit.sla_option.irrespective_of_the_weekends
            )
        } else {
            setPrioritiesList(handleReformatPrioritiesList(data))
        }
    }, [data, isFetchingPriorities, isEdit, contractDataToBeEdit])

    if (isFetchingPriorities) {
        return <FetchingData />
    }

    return (
        <>
            <Stack direction={'column'} rowGap={3}>
                <CommonTypography
                    variant={'subtitle1'}
                    sx={{ fontWeight: 600 }}
                >
                    Contract Information
                </CommonTypography>
                <Grid container>
                    <Grid item xs={10}>
                        <CommonTextField
                            id="contract_name"
                            label="Contract Name*"
                            variant="outlined"
                            key={'contract_name'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            defaultValue={
                                isEdit ? contractDataToBeEdit?.name : ''
                            }
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={10}>
                        <CommonTextField
                            id="contract_description"
                            label="Description"
                            variant="outlined"
                            key={'contract_description'}
                            sx={{
                                backgroundColor: '#F9F9F9',
                                width: '100%',
                            }}
                            multiline={true}
                            rows={4}
                            defaultValue={
                                isEdit ? contractDataToBeEdit?.description : ''
                            }
                        />
                    </Grid>
                </Grid>
                <CommonTypography
                    variant={'subtitle1'}
                    sx={{ fontWeight: 600 }}
                >
                    Support Period
                </CommonTypography>
                <Grid container columnSpacing={4}>
                    <Grid item xs={5}>
                        <CommonDatePicker
                            label="Start Date*"
                            setValue={setStartDate}
                            value={startDate}
                            key={'startDate'}
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <CommonDatePicker
                            label="End Date*"
                            setValue={setEndDate}
                            value={endDate}
                            key={'endDate'}
                            sx={{ width: '100%' }}
                        />
                    </Grid>
                </Grid>
                <CommonTypography
                    variant={'subtitle1'}
                    sx={{ fontWeight: 600 }}
                >
                    SLA
                </CommonTypography>
                <Grid container>
                    <Grid item xs={10}>
                        <SlaTable
                            rows={prioritiesList}
                            openSlaOptionsModal={handleOpenSlaOptionsModal}
                            openEditPriorityModal={handleOpenEditPriorityModal}
                            isEdit={isEdit}
                            justView={false}
                        />
                    </Grid>
                </Grid>

                <ActionNdCancel
                    actionBtnLabel="Confirm"
                    actionBtncolor="primary"
                    btnWidth={'140px'}
                    btnHeight={'40px'}
                    alignItems="center"
                    direction={'row'}
                    cancelBtnLabel="Cancel"
                    spacing={3}
                    handleAction={handleMainAction}
                    handleCancelAction={cancelAction}
                    justifyContent="end"
                    pending={isPending}
                    sx={{ marginTop: '64px' }}
                />
            </Stack>
            <SlaOptionsModal
                open={openSlaOptionsModal}
                handleClose={handleCloseSlaOptionsModal}
                handleMainAction={handleSetSlaOptions}
                irrespectiveOfTheHolidays={irrOfTheHolidays}
                irrespectiveOfTheWeekends={irrOfTheWeekends}
                irrespectiveOfWorkingHours={irrOfWorkingHours}
                isPending={false}
                Default={isEdit ? keyDefault : 'default_Working_hours'}
            />
            <EditPriorityModal
                open={openEditPriorityModal}
                handleClose={handleCloseEditPriorityModal}
                priorityName={
                    selectedPriority?.name ? selectedPriority?.name : ''
                }
                priorityDescription={
                    selectedPriority?.priority_description
                        ? selectedPriority?.priority_description
                        : ''
                }
                responseDays={
                    selectedPriority?.response_time
                        ? hanldeTimeInMinToStr(selectedPriority?.response_time)
                              .days
                        : 0
                }
                responseHours={
                    selectedPriority?.response_time
                        ? hanldeTimeInMinToStr(selectedPriority?.response_time)
                              .hours
                        : 0
                }
                responseMinutes={
                    selectedPriority?.response_time
                        ? hanldeTimeInMinToStr(selectedPriority?.response_time)
                              .minutes
                        : 0
                }
                resolutionDays={
                    selectedPriority?.resolution_time
                        ? hanldeTimeInMinToStr(
                              selectedPriority?.resolution_time
                          ).days
                        : 0
                }
                resolutionHours={
                    selectedPriority?.resolution_time
                        ? hanldeTimeInMinToStr(
                              selectedPriority?.resolution_time
                          ).hours
                        : 0
                }
                resolutionMinutes={
                    selectedPriority?.resolution_time
                        ? hanldeTimeInMinToStr(
                              selectedPriority?.resolution_time
                          ).minutes
                        : 0
                }
                isApplicable={
                    selectedPriority?.is_applicable
                        ? selectedPriority.is_applicable
                        : false
                }
                id={selectedPriority?.id ? selectedPriority?.id : 0}
                priorityId={
                    selectedPriority?.priority ? selectedPriority?.priority : 0
                }
                handleMainAction={handleSetPriority}
                isPending={false}
            />
            <SaveDataConfirmationModal
                open={openSaveDataModal}
                handleClose={handleCloseSaveDataModal}
                handleMainAction={handleUpdateContractData}
                isPending={isSavingData}
                message="Are you sure you want to save all contract information ?"
            />
        </>
    )
}

export default CreateUpdateContract
