import CommonButton from '../../common/CommonButton/CommonButton'

interface ICustomSuccessButton {
    children: any
    width: number | string
    height: number | string
    onClick: () => void
}

const CustomSuccessButton = ({
    children,
    width,
    height,
    onClick,
}: ICustomSuccessButton) => {
    return (
        <CommonButton
            variant={'contained'}
            sx={{
                color: 'success.main',
                backgroundColor: 'rgba(200, 223, 225, 0.72) !important',
                border: '1px solid #47A5A6',
                boxShadow: 'none',
                width,
                height,
            }}
            onClick={onClick}
        >
            {children}
        </CommonButton>
    )
}

export default CustomSuccessButton
