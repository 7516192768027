import CommonPaper from '../../common/CommonPaper/CommonPaper'
import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import CommonButton from '../../common/CommonButton/CommonButton'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import CommonLetterAvatar from '../../common/CommonLetterAvatar/CommonLetterAvatar'
import { Button } from '@mui/material'

interface IUserCard {
    name: string
    JobTitle: string
    navigateToDetails: () => void
}

const EngineerCard = ({ name, JobTitle, navigateToDetails }: IUserCard) => {
    let shortName = ''
    const fullName = name

    const nameParts = fullName.split(' ')

    if (nameParts.length > 1) {
        const firstName = nameParts[0]
        const fatherName = nameParts[1]
        shortName =
            firstName.substring(0, 1) + fatherName.substring(0, 1).toUpperCase()
    } else {
        shortName = name.substring(0, 2).toUpperCase()
    }

    return (
        <CommonPaper
            sx={{
                height: '230px',
                minHeight: '184px',
                border: '1px solid #DCDCDC',
                p: 2,
            }}
        >
            <Stack
                direction={'column'}
                sx={{ height: '100%' }}
                justifyContent={'space-between'}
            >
                <Stack direction={'row'} justifyContent={'center'}>
                    <Stack
                        direction={'column'}
                        sx={{ height: '100%' }}
                        justifyContent={'center'}
                    >
                        <Stack direction={'row'} justifyContent={'center'}>
                            <CommonLetterAvatar
                                sx={{
                                    backgroundColor: '#D5D3EC',
                                    width: '64px',
                                    height: '64px',
                                    textAlign: 'center',
                                }}
                            >
                                {shortName}
                            </CommonLetterAvatar>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'center'}>
                            <CommonTypography
                                variant={'h6'}
                                sx={{ fontWeight: 600, textAlign: 'center' }}
                            >
                                {name}
                            </CommonTypography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'center'}>
                            <CommonTypography
                                sx={{
                                    color: '#929EAE',
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    fontFamily: 'Inter',
                                    lineHeight: '20px',
                                    textTransform: 'capitalize',
                                }}
                            >
                                {JobTitle}
                            </CommonTypography>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} justifyContent={'flex-end'}>
                    <Button
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={navigateToDetails}
                        sx={{
                            borderRadius: '2px',
                            border: '1px solid #DADADA',
                            backgroundColor: '#FFF',
                            color: '#494454',
                            fontSize: '14px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineWeight: 'normal',
                            textTransform: 'capitalize',
                            width: '156px',
                            height: '37px',
                        }}
                    >
                        See Details
                    </Button>
                </Stack>
            </Stack>
        </CommonPaper>
    )
}

export default EngineerCard
