import React from 'react'
import { Popover, Paper, Stack, Button, type SxProps } from '@mui/material'

interface IStyle {
    paper: SxProps
    popupButton: SxProps

    TopBorder: SxProps
}
const CustomerPopover = ({
    id,
    open,
    anchorEl,
    onClose,
    handleOpenEscalate,
    disabled,
    disabledCancel,
    handleReplyReOpen,
    disabledEsclate,
    handleReplyCancel,
}: any) => {
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                width: '200px',
                borderRadius: '10px',
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    p: 1,
                    width: '200px',
                    borderRadius: '10px',
                    border: '1px solid #D7DBE1',
                }}
            >
                <Stack
                    spacing={1}
                    direction={'column'}
                    alignItems={'flex-start'}
                >
                    <Stack direction={'row'}>
                        <Button
                            sx={styles.popupButton}
                            onClick={handleOpenEscalate}
                            disabled={disabledEsclate}
                        >
                            Escalate
                        </Button>
                    </Stack>

                    <Stack direction={'row'}>
                        <Button
                            sx={styles.popupButton}
                            onClick={handleReplyCancel}
                            disabled={disabledCancel}
                        >
                            Cancel Request
                        </Button>
                    </Stack>

                    <Stack direction={'row'}>
                        <Button
                            disabled={disabled}
                            onClick={handleReplyReOpen}
                            sx={styles.popupButton}
                        >
                            re-Open
                        </Button>
                    </Stack>
                </Stack>
            </Paper>
        </Popover>
    )
}
export default CustomerPopover

const styles: IStyle = {
    popupButton: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        textTransform: 'none',
        width: '150px',
        textAlign: 'left',
        alignContent: 'flex-start',
        justifyContent: 'flex-start',
    },
    TopBorder: {
        borderRadius: '0px 0px 5px 5px',
        backgroundColor: '#47A5A6',
        minHeight: '5px',
        borderColor: '#47A5A6',
        height: '5px',
    },
    paper: {
        borderRadius: '0px 0px 10px 10px',
        borderColor: '#47A5A6',
        borderTopWidth: '5px',
        background: '#FbFbFb',
        paddingBottom: '16px',
        marginTop: '25px',
    },
}
