import TextField from '@mui/material/TextField'

interface ICommonTextField {
    label: string
    variant: 'standard' | 'filled' | 'outlined' | undefined
    id: string
    sx: any
    required?: boolean
    error?: boolean
    multiline?: boolean
    rows?: any
    defaultValue?: any
    type?: any
    inputProps?: any
}

const CommonTextField = ({
    id,
    label,
    variant,
    sx,
    required,
    error,
    rows,
    multiline,
    defaultValue,
    type,
    inputProps,
}: ICommonTextField) => {
    return (
        <TextField
            id={id}
            label={label}
            variant={variant}
            sx={sx}
            required={required}
            error={error}
            multiline={multiline}
            rows={rows}
            defaultValue={defaultValue}
            type={type}
            inputProps={inputProps}
        />
    )
}

export default CommonTextField
