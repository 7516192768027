import IconButton from '@mui/material/IconButton'

interface Props {
    children: any
    color?: any
    sx?: any
    disabled?: boolean
    size?: any
    onClick?: any
    edge?: any
}

const CommonIconButton = ({
    children,
    color,
    sx,
    size,
    disabled,
    onClick,
    edge,
}: Props) => {
    return (
        <IconButton
            disabled={disabled}
            size={size}
            sx={sx}
            color={color}
            onClick={() => onClick()}
            edge={edge}
        >
            {children}
        </IconButton>
    )
}

export default CommonIconButton
