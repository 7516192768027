import BasicModal from '../../BasicModal/BasicModal'
import Box from '@mui/material/Box/Box'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import Stack from '@mui/material/Stack/Stack'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import CommonIconButton from '../../common/CommonIconButton/CommonIconButton'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import CommonPaper from '../../common/CommonPaper/CommonPaper'
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded'
import dashBoardServices from '../../../services/cspServices'
import { useWindowSize } from 'usehooks-ts'

interface ISaveDataConfirmationModal {
    open: boolean
    handleClose: () => void
    handleMainAction: () => void
    isPending: boolean
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '14px',
    transform: 'translate(-50%, -50%)',
    width: 405,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 3,
}
const EscalateModal = ({
    open,
    handleClose,
    handleMainAction,
    isPending,
}: ISaveDataConfirmationModal) => {
    const { width, height } = useWindowSize()

    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box sx={{...style,
                 width: (()=>{
                    if(width<=768)
                    return width
                 return 932})(),}}>
                <Stack direction={'row'} justifyContent={'end'}>
                    <CommonIconButton onClick={handleClose}>
                        <CloseRoundedIcon />
                    </CommonIconButton>
                </Stack>
                <Stack direction={'column'} alignItems={'center'}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <CommonPaper
                            sx={{
                                width: 75,
                                height: 75,
                                backgroundColor: 'rgba(53, 176, 229, 0.1)',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ErrorOutlineRoundedIcon
                                color="info"
                                sx={{ width: 26, height: 26 }}
                            />
                        </CommonPaper>
                    </Stack>
                    <CommonTypography
                        variant={'h5'}
                        sx={{ fontWeight: 500, marginTop: 1 }}
                        gutterBottom
                    >
                        Escalation !
                    </CommonTypography>
                    <CommonTypography
                        variant={'caption'}
                        sx={{ fontWeight: 400, color: 'text.disabled' }}
                    >
                        Are you sure you want to escalate this request ?
                    </CommonTypography>
                    <ActionNdCancel
                        sx={{ marginTop: '48px' }}
                        actionBtnLabel="Escalate"
                        actionBtncolor="info"
                        alignItems="center"
                        cancelBtnLabel="Cancel"
                        direction={'row'}
                        justifyContent="end"
                        spacing={4}
                        btnWidth={'129px'}
                        btnHeight={'48px'}
                        handleCancelAction={handleClose}
                        handleAction={handleMainAction}
                        pending={isPending}
                    />
                </Stack>
            </Box>
        </BasicModal>
    )
}

export default EscalateModal
