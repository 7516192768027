import { Button, Grid, Stack, TextField } from '@mui/material'
import React from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { login, userSelector } from '../../features/user'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import BasicImageContainer from '../../components/BasicImageContainer/BasicImageContainer'
import ITRanks from '../../assets/images/ITRanks.svg'
import ImageLogin from '../../assets/images/login.png'
import {
    addAlert,
    removeAlert,
    systemAlertsSelector,
} from '../../features/systemAlerts'
import BasicErrorAlert from '../../components/Alerts/BasicErrorAlert/BasicErrorAlert'
import dashBoardServices from '../../services/cspServices'
import { type IAlert } from '../../interfaces/IAlert'
import { PasswordStrengthChecker } from '../../helper/functions'

const ChangePassword = () => {
    const { systemAlerts } = useAppSelector(systemAlertsSelector)

    const user = useAppSelector(userSelector)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { data, data2 } = useParams()
    console.log('data', data)
    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm<any>()
    const onsubmit = (form: any) => {
        if (data && data2)
            dashBoardServices
                .resetForgetPassword(
                    {
                        new_password1: form.new_password1,
                        new_password2: form.new_password2,
                        uid: data,
                        token: data2,
                    },
                    data,
                    data2
                )
                .then((res) => {
                    console.log('res', res)
                    const alertObj: IAlert = {
                        id: '',
                        message: 'Password has been changed successfuly',
                        state: true,
                        type: 'success',
                    }
                    dispatch(addAlert(alertObj))
                    navigate(`/`)
                })
                .catch((e) => {})
                .finally(() => {})
    }
    return (
        <>
            <Grid container spacing={0}>
                <Grid
                    item
                    xs={6}
                    sx={{
                        background:
                            'linear-gradient(180deg, #464153 0%, #282432 100%);',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Stack
                        direction={'column'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                    >
                        <Stack direction={'row'}>
                            <BasicImageContainer
                                sx={{
                                    width: '100%',
                                }}
                                src={ITRanks}
                                alt=""
                                style={{
                                    height: '100%',
                                    width: '100%',
                                }}
                                key={'imagLogin'}
                            />
                        </Stack>
                        <Stack
                            direction={'row'}
                            sx={{
                                color: '#FFF',
                                fontSize: '22px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: 'normal',
                                letterSpacing: '-0.22px',
                                marginY: '35px',
                            }}
                        >
                            {'New Password '}
                        </Stack>
                        <label
                            style={{
                                width: '100%',
                                color: '#FFF',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '26px',
                                textTransform: 'capitalize',
                            }}
                        >
                            Set new password
                        </label>
                        <Controller
                            name="new_password1"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Password required',
                                validate: (value) =>
                                    PasswordStrengthChecker(value) ?? true,
                            }}
                            render={({ field }) => (
                                <TextField
                                    hiddenLabel
                                    fullWidth
                                    size="small"
                                    type="password"
                                    sx={{
                                        ...styles.textField,
                                        marginTop: '0px',
                                    }}
                                    {...field}
                                    helperText={
                                        errors.new_password1
                                            ? errors.new_password1.message?.toString()
                                            : ''
                                    }
                                />
                            )}
                        />
                        <label
                            style={{
                                width: '100%',
                                color: '#FFF',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '26px',
                                textTransform: 'capitalize',
                            }}
                        >
                            Confirm new password
                        </label>
                        <Controller
                            name="new_password2"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Confirm Password is required',
                                validate: (value) =>
                                    value === watch('new_password1')
                                        ? true
                                        : 'Password & Confirm Password do not match.',
                            }}
                            render={({ field }) => (
                                <>
                                    <TextField
                                        hiddenLabel
                                        fullWidth
                                        size="small"
                                        type="password"
                                        sx={{
                                            ...styles.textField,
                                            marginTop: '0px',
                                            marginBottom: '50px',
                                        }}
                                        {...field}
                                        helperText={
                                            errors.new_password2
                                                ? errors.new_password2.message?.toString()
                                                : ''
                                        }
                                    />
                                </>
                            )}
                        />
                        <Button
                            onClick={handleSubmit(onsubmit)}
                            fullWidth
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#47A5A6',
                                    color: 'white',
                                },
                                backgroundColor: '#47A5A6',
                                color: '#FFF',
                                textAlign: 'center',
                                fontSize: '18px',
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '38px',
                                textTransform: 'capitalize',
                            }}
                        >
                            Reset
                        </Button>
                    </Stack>
                </Grid>
                <Grid item xs={6}>
                    <BasicImageContainer
                        sx={{
                            height: '100vh',
                        }}
                        src={ImageLogin}
                        alt=""
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        key={'imagLogin'}
                    />
                </Grid>
                {systemAlerts.map((alert: any, index: number) => (
                    <BasicErrorAlert
                        key={alert.id}
                        isOpen={alert.state}
                        sx={{
                            alignItems: 'center',
                            width: 480,
                            height: 65,
                            position: 'fixed',
                            left: 0,
                            bottom: index * 70,
                            backgroundColor: '#FFFFFF',
                            zIndex: 9999,
                            marginBottom: 5,
                            marginLeft: 4,
                        }}
                        type={alert.type}
                        actionOnClick={() => {
                            dispatch(removeAlert(alert.id))
                        }}
                    >
                        {alert.message}
                    </BasicErrorAlert>
                ))}
            </Grid>
        </>
    )
}

export default ChangePassword

const styles = {
    textField: {
        display: 'flex',
        gap: '8px',
        backgroundColor: '#fff',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
        marginY: '30px',
        width: '358px',
    },
}
