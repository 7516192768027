import BasicModal from '../../BasicModal/BasicModal'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import CommonPaper from '../../common/CommonPaper/CommonPaper'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import type { IDay } from '../../../interfaces/IDay'
import CommonTypography from '../../common/CommonTypography/CommonTypography'

interface ISelectWorkingDaysModal {
    open: boolean
    handleClose: () => void
    DaysList: IDay[]
    handleMainAction: () => void
    isPending: boolean
    handleToggleSelectDay: (id: number) => void
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
}

const SelectWorkingDaysModal = ({
    open,
    handleClose,
    DaysList,
    handleMainAction,
    isPending,
    handleToggleSelectDay,
}: ISelectWorkingDaysModal) => {
    return (
        <BasicModal handleClose={handleClose} open={open}>
            <Box sx={style}>
                <CommonTypography
                    sx={{ fontWeight: 600 }}
                    variant={'subtitle1'}
                    gutterBottom
                >
                    Select working days
                </CommonTypography>
                <Stack direction={'row'} spacing={1}>
                    {DaysList.map((day: IDay) => (
                        <CommonPaper
                            key={day.id}
                            sx={{
                                width: 52,
                                height: 48,
                                backgroundColor: day.active
                                    ? 'rgba(71, 165, 166, 0.37)'
                                    : '#D9D9D9',
                                color: day.active ? '#47A5A6' : '#494454',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer !important',
                            }}
                            onClick={() => {
                                handleToggleSelectDay(day.id)
                            }}
                            elemId={day.id}
                        >
                            {day.name.slice(0, 3)}
                        </CommonPaper>
                    ))}
                </Stack>
                {/* <ActionNdCancel
                    sx={{ marginTop: '48px' }}
                    actionBtnLabel="Confirm"
                    actionBtncolor="primary"
                    alignItems="center"
                    cancelBtnLabel="Cancel"
                    direction={'row'}
                    justifyContent="end"
                    spacing={4}
                    btnWidth={'146px'}
                    btnHeight={'40px'}
                    handleCancelAction={handleClose}
                    handleAction={handleMainAction}
                    pending={isPending}
                /> */}
            </Box>
        </BasicModal>
    )
}

export default SelectWorkingDaysModal
