import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { Box, Stack } from '@mui/system'
import {
    CircularProgress,
    Menu,
    MenuItem,
    Popover,
    Typography,
} from '@mui/material'
import { formatDate } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import DeleteShiftModal from '../Modals/DeleteShift/DeleteShift'
import dashBoardServices from '../../services/cspServices'
import { useParams } from 'react-router'
import dayjs from 'dayjs'
import AddShift from '../Modals/AddShift/AddShift'
import useFetch from '../../hooks/useFetch'
import { type IAlert } from '../../interfaces/IAlert'
import { useAppDispatch } from '../../app/hooks'
import { addAlert } from '../../features/systemAlerts'
const dictionary: any = {}

const CalenderIO = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const [addShiftModal, setAddShiftModal] = useState(false)
    const [showHolidayOption, setShowHolidayOption] = useState(false)
    const [deleteHolidayOption, setDeleteHolidayOption] = useState(false)
    const [editOption, setEditOption] = useState(false)
    const [addOption, setAddOption] = useState(false)
    const [deleteOption, setDeleteOption] = useState(false)
    const [refetch, setRefetch] = useState(false)
    const [weekendsVisible, setWeekendsVisible] = useState(true)
    const [editObject, setEditObject] = useState<any>()
    const [currentAction, setCurrentAction] = useState<any>()
    const [currentEvents, setCurrentEvents] = useState([])
    const [initialEvents, setInitialEvents] = useState<any>([])
    const [defaultEvents, setDefaultEvents] = useState<any>([])
    const [recalc, setRecalc] = useState(false)
    const [loading, setLoading] = useState(false)

    const [selectedDate, setSelectedDate] = useState<any>()

    const [popoverAnchorEl, setPopoverAnchorEl] = useState<any>(null)
    const { data, error, isPending } = useFetch(
        dashBoardServices.getCalenderById,
        id,
        refetch
    )

    useEffect(() => {
        setLoading(true)
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear() // Get the current year

        const INITIAL_EVENTS: any = []

        // Loop through each month of the year
        for (let month = 0; month < 12; month++) {
            const daysInMonth = dayjs(
                `${currentYear}-${month + 1}`
            ).daysInMonth()

            // Loop through days 1 to the number of days in the current month
            for (let day = 1; day <= daysInMonth; day++) {
                const dayOfMonth = dayjs(
                    `${currentYear}-${month + 1}-${day}`
                ).format('YYYY-MM-DD')
                const startDateTime = dayjs(
                    `${dayOfMonth} 09:00:00`,
                    'YYYY-MM-DD HH:mm:ss'
                )
                const endDateTime = dayjs(
                    `${dayOfMonth} 17:00:00`,
                    'YYYY-MM-DD HH:mm:ss'
                )
                if (dictionary[dayOfMonth]) {
                    const item = dictionary[dayOfMonth]
                    // if (!item.holiday) {
                    item.day_shifts.map((shift: any) => {
                        const eventDate: string = item.date ?? ''
                        // console.log('eventDate', eventDate)
                        const startTime: string = shift.start_time ?? ''
                        // console.log('startTime', startTime)
                        const endTime: string = shift.end_time ?? ''
                        // console.log('endTime', endTime)
                        const str: string = shift.id
                        INITIAL_EVENTS.push({
                            id: dayjs(eventDate).format('YYYY-MM-DD') + str,
                            title: `${dayjs(
                                shift.start_time,
                                'HH:mm:ss'
                            ).format('hh:mm A')} - ${dayjs(
                                shift.end_time,
                                'HH:mm:ss'
                            ).format('hh:mm A')}`,
                            start: dayjs(
                                `${eventDate} ${startTime}`,
                                'YYYY-MM-DD HH:mm:ss'
                            ).toDate(),
                            end: dayjs(
                                `${eventDate} ${endTime}`,
                                'YYYY-MM-DD HH:mm:ss'
                            ).toDate(),
                            groupId: shift.id,
                            allDay: false,
                        })
                    })
                    // } else {

                    const eventDate: string = item.date ?? ''
                    if (item.holiday)
                        INITIAL_EVENTS.push({
                            id: dayjs(eventDate).format('YYYY-MM-DD'),
                            title: 'holiday',
                            start: new Date(item.date),
                            end: new Date(item.date),
                            allDay: true,
                            groupId: item.id,
                        })
                    // }
                } else {
                    const dayOfWeek = startDateTime.day() // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)

                    if (dayOfWeek === 5 || dayOfWeek === 6) {
                        // Handle Friday and Saturday logic here
                        // You can choose to skip these days or modify events as needed
                        // Skip the rest of the loop for Friday and Saturday
                        INITIAL_EVENTS.push({
                            id: dayOfMonth,
                            title: 'holiday',
                            start: startDateTime.toDate(),
                            end: endDateTime.toDate(),
                            allDay: true,
                        })

                        continue
                    }
                    INITIAL_EVENTS.push({
                        id: dayOfMonth,
                        title: `${startDateTime.format(
                            'h:mm A'
                        )} - ${endDateTime.format('h:mm A')}`,
                        start: startDateTime.toDate(),
                        end: endDateTime.toDate(),
                        allDay: false,
                    })
                }
            }
        }

        // console.log('INITIAL_EVENTS', INITIAL_EVENTS)
        setInitialEvents(INITIAL_EVENTS)
        setLoading(false)
    }, [recalc])

    useEffect(() => {
        if (id && data) {
            data.map((item: any) => {
                dictionary[item.date] = item
            })
        }
        setRecalc(!recalc)

        if (error) {
            console.log('error', error)
        }
    }, [data, error])

    const handleWeekendsToggle = () => {
        setWeekendsVisible(!weekendsVisible)
    }
    const handlecloseAll = () => {
        setEditObject(undefined)
        setCurrentAction(undefined)
        setAddShiftModal(false)
    }

    const handleDateSelect = (selectInfo: any) => {
        // console.log('selectInfo', selectInfo)

        setSelectedDate(selectInfo)
        if (selectInfo?.event?.allDay) {
            setDeleteHolidayOption(true)
            setShowHolidayOption(false)
            setEditOption(false)
            setAddOption(false)
            setDeleteOption(false)
        }
        if (selectInfo?.event) {
            if (!selectInfo.event.allDay) {
                setDeleteHolidayOption(false)
                setShowHolidayOption(false)
                setEditOption(true)
                setAddOption(false)
                setDeleteOption(true)
            }
        }
        if (selectInfo.allDay) {
            setShowHolidayOption(true)
            setDeleteHolidayOption(false)
            setAddOption(true)
        }
        const res = selectInfo.view.getCurrentData()

        const calenderDay = res.calendarOptions.events.find((day: any) => {
            return dayjs(day.start).format('YYYY-MM-DD') == selectInfo.startStr
        })

        // let title = prompt('Please enter a new title for your event')
        // const calendarApi = selectInfo.view.calendar
        // console.log('calendarApi', calendarApi)

        // calendarApi.unselect() // clear date selection
        if (calenderDay?.title != 'holiday')
            setPopoverAnchorEl({
                clientX: selectInfo.jsEvent.clientX,
                clientY: selectInfo.jsEvent.clientY,
            })
    }

    const handleDayRightClick = (info: any) => {
        // Prevent the default right-click behavior
        info.preventDefault()

        // Open the popover
        setPopoverAnchorEl(info.currentTarget)
    }

    const handlePopoverClose = () => {
        // Close the popover
        setCurrentAction(undefined)
        setPopoverAnchorEl(null)
    }

    const handleEventClick = (clickInfo: any) => {
        setCurrentAction(clickInfo)
        handleDateSelect(clickInfo)
        // clickInfo.event.remove()
    }

    const handleEvents = (events: any) => {
        setCurrentEvents(events)
    }
    const handleRemoveEvent = () => {
        setLoading(true)
        const ID = id ?? ''
        const str = dayjs(currentAction.event.start).format('YYYY-MM-DD')

        if (dictionary[str]) {
            dashBoardServices
                .deleteShift(ID, str, currentAction.event.groupId)
                .then((res) => {
                    setRefetch(!refetch)
                    handlecloseAll()
                })
                .catch((e) => {
                    // console.log('e', e)
                })
                .finally(() => {
                    setLoading(false)
                })
            handlePopoverClose()
        } else {
            const currentData: any = {
                start_time: '09:00:00',
                end_time: '17:00:00',
            }
            dashBoardServices
                .AddShift(ID, str, currentData)
                .then(({ data }: any) => {
                    dashBoardServices
                        .deleteShift(ID, str, data.id)
                        .then((res) => {
                            setRefetch(!refetch)
                            handlecloseAll()
                        })
                        .catch((e) => {
                            // console.log('e', e)
                        })
                        .finally(() => {
                            setLoading(false)
                        })
                })
                .catch((e) => {
                    Object.entries(e.response.data).map(([key, value]) => {
                        if (value instanceof Array) {
                            value.map((item) => {
                                const alertObj: IAlert = {
                                    id: item,
                                    message: item ?? '',
                                    state: true,
                                    type: 'error',
                                }
                                dispatch(addAlert(alertObj))
                            })
                        }
                    })
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    const handleAddShift = (start: string, end: string) => {
        setLoading(true)

        const ID: string = id ?? ''
        const inputData: any = {
            start_time: start,
            end_time: end,
        }
        // console.log('inputData', inputData)
        // console.log('selectedDate', selectedDate)
        const strDate: string =
            selectedDate.startStr ?? selectedDate.event.startStr
        if (dictionary[strDate]) {
            dashBoardServices
                .AddShift(ID, strDate, inputData)
                .then(({ data }: any) => {
                    // console.log('data', data)
                    setRefetch(!refetch)
                    handlecloseAll()
                })
                .catch((e) => {
                    handleErrorResponse(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            const currentData: any = {
                start_time: '09:00:00',
                end_time: '17:00:00',
            }
            dashBoardServices
                .AddShift(ID, strDate, currentData)
                .then(({ data }: any) => {
                    dashBoardServices
                        .AddShift(ID, strDate, inputData)
                        .then(({ data }: any) => {
                            // console.log('data', data)
                            setRefetch(!refetch)
                            handlecloseAll()
                        })
                        .catch((e) => {
                            handleErrorResponse(e)
                        })
                })
                .catch((e) => {
                    handleErrorResponse(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const handleErrorResponse = (error: any) => {
        Object.entries(error.response.data).forEach(([key, value]) => {
            if (value instanceof Array) {
                value.forEach((item) => {
                    const alertObj = {
                        id: item,
                        message: item ?? '',
                        state: true,
                        type: 'error',
                    }
                    dispatch(addAlert(alertObj))
                })
            }
        })
    }
    const handleEditShift = (start: string, end: string) => {
        const ID: string = id ?? ''
        const inputData: any = {
            start_time: start,
            end_time: end,
        }
        if (dictionary[dayjs(editObject.event.start).format('YYYY-MM-DD')]) {
            dashBoardServices
                .editShift(
                    ID,
                    dayjs(editObject.event.start).format('YYYY-MM-DD'),
                    editObject.event.groupId,
                    inputData
                )
                .then(({ data }: any) => {
                    // console.log('data', data)
                    setRefetch(!refetch)
                    handlecloseAll()
                })
                .catch((e) => {
                    handleErrorResponse(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            dashBoardServices
                .AddShift(
                    ID,
                    dayjs(editObject.event.start).format('YYYY-MM-DD'),
                    inputData
                )
                .then(({ data }: any) => {
                    // console.log('data', data)
                    setRefetch(!refetch)
                    handlecloseAll()
                })
                .catch((e) => {
                    handleErrorResponse(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    const hanldeMarkHoliday = (flag: boolean) => {
        setLoading(true)

        const ID: string = id ?? ''
        const strDate: string =
            selectedDate.startStr ?? selectedDate.event.startStr
        const inputData: any = {
            holiday: flag,
            date: strDate,
            engineer: ID,
        }
        // console.log('selectedDate', selectedDate)

        // console.log('inputData', inputData)
        // console.log('dictionary', dictionary)
        if (dictionary[strDate]) {
            dashBoardServices
                .updateDaykHoliday(
                    ID,
                    inputData,
                    strDate,
                    dictionary[strDate].id
                )

                .then(({ data }: any) => {
                    // console.log('data', data)

                    setRefetch(!refetch)
                    handlecloseAll()
                    handlePopoverClose()
                })
                .catch((e) => {
                    handleErrorResponse(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            dashBoardServices
                .markHoliday(ID, inputData)
                .then(({ data }: any) => {
                    // console.log('data', data)
                    setRefetch(!refetch)
                    handlecloseAll()
                    handlePopoverClose()
                })
                .catch((e) => {
                    handleErrorResponse(e)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }
    const handleEdit = () => {
        setEditObject(currentAction)
        setAddShiftModal(true)
        handlePopoverClose()
    }
    const renderEventContent = (eventInfo: any) => {
        if (eventInfo.event.allDay) {
            return (
                <Stack
                    sx={{
                        borderRadius: '0px 10px 10px 0px',
                        backgroundColor: '#F49321',
                        minHeight: '60px',
                    }}
                >
                    {eventInfo.event.title}
                </Stack>
            )
        }
        return (
            <Stack
                direction={'row'}
                sx={{
                    width: '100%',
                    borderRadius: '0px 3.6px 3.6px 0px',
                    borderTop: '1.2px solid #3788D8',
                    borderRight: '1.2px solid #3788D8',
                    borderBottom: '1.2px solid #3788D8',
                    background: '#3788D8',
                }}
            >
                <Typography
                    sx={{
                        color: '#FFF',
                        fontFamily: 'Inter',
                        fontSize: '13.08px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '18.564px',
                    }}
                >
                    {eventInfo.event.title}
                </Typography>
            </Stack>
        )
    }

    return (
        <Box
            sx={{
                marginTop: loading ? '0px' : '100px',
                marginInline: '15px',
                flexGrow: 1,
            }}
        >
            <Stack
                direction={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                {loading && <CircularProgress color="inherit" size={50} />}
            </Stack>
            {
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: '',
                    }}
                    initialView="dayGridMonth"
                    editable={false}
                    selectable={true}
                    droppable={false}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={weekendsVisible}
                    events={initialEvents}
                    // initialEvents={initialEvents} // alternatively, use the `events` setting to fetch from a feed
                    select={handleDateSelect}
                    eventContent={renderEventContent} // custom render function
                    eventClick={handleEventClick}
                    eventsSet={handleEvents}
                    height={750}
                    eventChange={function (e) {
                        // console.log('eventtt', e)
                    }}
                    // called after events are initialized/added/changed/removed
                    /* you can update a remote database when these fire:
          eventAdd={function(){}}
          eventRemove={function(){}}
          */
                />
            }

            <Menu
                id="basic-menu"
                anchorReference="anchorPosition"
                sx={{ zIndex: 9999999 }}
                anchorPosition={
                    popoverAnchorEl
                        ? {
                              top: popoverAnchorEl.clientY,
                              left: popoverAnchorEl.clientX,
                          }
                        : undefined
                }
                open={!!popoverAnchorEl}
                onClose={handlePopoverClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {currentAction && editOption && (
                    <MenuItem
                        disabled={!currentAction}
                        sx={{ minWidth: '300px' }}
                        onClick={handleEdit}
                    >
                        Edit shift
                    </MenuItem>
                )}
                {!currentAction && addOption && (
                    <MenuItem
                        onClick={() => {
                            setAddShiftModal(true)
                            setEditObject(undefined)
                            handlePopoverClose()
                        }}
                    >
                        Add Shift
                    </MenuItem>
                )}
                {showHolidayOption && (
                    <MenuItem
                        onClick={() => {
                            hanldeMarkHoliday(true)
                        }}
                    >
                        Mark as Holiday
                    </MenuItem>
                )}
                {deleteHolidayOption && (
                    <MenuItem
                        onClick={() => {
                            hanldeMarkHoliday(false)
                        }}
                    >
                        Delete Holiday
                    </MenuItem>
                )}
                {currentAction && deleteOption && (
                    <MenuItem
                        disabled={!currentAction}
                        onClick={handleRemoveEvent}
                    >
                        Delete Shift
                    </MenuItem>
                )}
            </Menu>
            {/* <DeleteShiftModal
                handleClose={}
                handleMainAction={}
                isPending={false}
                open={}
            /> */}
            <AddShift
                editObject={editObject}
                open={addShiftModal}
                handleAddShift={handleAddShift}
                handleClose={() => {
                    setAddShiftModal(false)
                }}
                setRefetch={() => {
                    setRefetch(!refetch)
                }}
                handleEditShift={handleEditShift}
            />
        </Box>
    )
}

export default CalenderIO
