import * as React from 'react';
import { type Dayjs } from 'dayjs';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker,type DatePickerProps } from '@mui/x-date-pickers/DatePicker';

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'


// interface ButtonFieldProps
//   extends UseDateFieldProps<Dayjs>,
//     BaseSingleInputFieldProps<
//       Dayjs | null,
//       Dayjs,
//       FieldSection,
      
//     > {
//   setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
// }

interface props {
    value:Dayjs|null;
    setValue:any;
}

function ButtonField(props: any) {
    const {
      setOpen = null,
      label = null,
      id = null,
      disabled = false,
      InputProps: { ref=null } = {},
      inputProps: { 'aria-label': ariaLabel=null } = {},
    } = props;
  
    return (
      <Button
        variant="outlined"
        id={id}
        disabled={disabled}
        ref={ref}
        aria-label={ariaLabel}
        onClick={() => {setOpen?.((prev: any) => !prev)}}
        sx={{
            backgroundColor: 'common.white',
            borderRadius: '4px',
        }}
      >
        <CalendarMonthRoundedIcon sx={{ marginRight: 0.5 }} />
                        {label??"Contracts Ends By"}
      </Button>
    );
  }
  

function ButtonDatePicker(
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,
) {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } as any,
      actionBar: {
        actions: ['clear'],
      },
     }
    }
      {...props}
      open={open}
      onClose={() => {setOpen(false)}}
      onOpen={() => {setOpen(true)}}
      
    />
  );
}

export default function PickerWithButtonField({value,setValue}:props) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={1}>
        <ButtonDatePicker
          label={`Contracts Ends By${
            value == null ? '' : value.format('YYYY-MM-DD')
          }`}
          value={value}
          onChange={(newValue) => {setValue(newValue)}}
        />
      </Stack>
    </LocalizationProvider>
  );
}