import {type  SxProps } from '@mui/material'
import Paper from '@mui/material/Paper'

interface ICommonPaper {
    sx?: SxProps
    children?: any
    onClick?: (params?: any) => void
    elemId?: any
}

const CommonPaper = ({ sx, children, onClick, elemId }: ICommonPaper) => {
    return (
        <Paper sx={sx} onClick={onClick}>
            {children}
        </Paper>
    )
}

export default CommonPaper
