import CommonPaper from '../../common/CommonPaper/CommonPaper'
import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import CommonButton from '../../common/CommonButton/CommonButton'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import CommonLetterAvatar from '../../common/CommonLetterAvatar/CommonLetterAvatar'
import GroupsIcon from '@mui/icons-material/Groups'
import { Button } from '@mui/material'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'

interface IUserCard {
    name: string
    description: string
    navigateToDetails: () => void
    engineers: any
}

const TeamCard = ({
    name,
    description,
    navigateToDetails,
    engineers,
}: IUserCard) => {
    return (
        <CommonPaper
            sx={{
                height: '230px',
                minHeight: '184px',
                border: '1px solid #DCDCDC',
                marginX: '15px',
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Stack direction={'row'} justifyContent={'flex-start'}>
                <Stack
                    direction={'column'}
                    sx={{ height: '100%' }}
                    justifyContent={'center'}
                >
                    <CommonTypography
                        variant={'h6'}
                        sx={{ fontWeight: 600, textAlign: 'center' }}
                    >
                        {name}
                    </CommonTypography>
                    <CommonTypography
                        sx={{
                            marginTop: '10px',
                            color: '#929EAE',
                            textAlign: 'center',
                            fontSize: '14px',
                            fontFamily: 'Inter',
                            lineHeight: '20px',
                            textTransform: 'capitalize',
                        }}
                    >
                        {description}
                    </CommonTypography>
                </Stack>
            </Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row-reverse'}>
                    <CommonTypography
                        sx={{
                            color: '#494454',
                            fontSize: '16px',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                        }}
                    >
                        {engineers.length.toString()} {' IT-Ranks Engineers'}
                    </CommonTypography>
                    <GroupsOutlinedIcon />
                </Stack>
                <Button
                    sx={{
                        width: '156px',
                        borderRadius: '2px',
                        border: '1px solid #DADADA',
                        backgroundColor: '#FFF',
                    }}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={navigateToDetails}
                >
                    See Details
                </Button>
            </Stack>
        </CommonPaper>
    )
}

export default TeamCard
