import React, { useState, useEffect, useCallback } from 'react'
import {
    Box,
    Button,
    Chip,
    FormControlLabel,
    MenuItem,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import BasicModal from '../../BasicModal/BasicModal'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import { useForm, Controller } from 'react-hook-form'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import Select, {
    ActionMeta,
    OnChangeValue,
    type StylesConfig,
} from 'react-select'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'

import type { IAlert } from '../../../interfaces/IAlert'
import { LoadingButton } from '@mui/lab'
import { addAlert } from '../../../features/systemAlerts'
import { useAppDispatch } from '../../../app/hooks'
import { useWindowSize } from 'usehooks-ts'

interface props {
    id?: number
    team?: any
    open: boolean
    handleClose: () => void
    handleDelete: () => void
    setRefetch: () => void
}
interface engineer {
    id: number
    name: string
}

interface User {
    email: string
    full_name: string
    jop_title: string
    phone: string
    is_active: boolean
}

interface Product {
    id: number
    name: string
}

interface Skill {
    id: number
    skill: string
    product: Product
}

interface Team {
    id: number
    name: string
    description: string
}

interface UserSkill {
    skill: Skill
    rate: number
}

interface TeamMember {
    team: Team
    is_leader: boolean
}

interface UserRecord {
    id: number
    user: User
    skills: UserSkill[]
    team: TeamMember
    is_online: boolean
}

const TeamsCreateModal = ({
    id,
    team,
    open,
    handleClose,
    handleDelete,
    setRefetch,
}: props) => {
    const [chartDimensions, setChartDimensions] = useState(450)
    // declaring some mui media query to be used in redindering line charts.
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    const { width, height } = useWindowSize()

    //  a effect hook to whats the media queries to apply
    // new dimentions for the Line charts
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])
    const [selectedOptions, setSelectedOptions] = useState<readonly string[]>(
        []
    )

    const [selectedLeader, setSelectedLeader] = useState(null)
    const [engineersList, setEngineersList] = useState<any>([])
    const [engineersListTeam, setEngineersListTeam] = useState<any>([])
    const { data: EngineersData, isPending: isFetching } = useFetch(
        dashBoardServices.getEngineers,
        undefined,
        undefined,
        open
    )
    const dispatch = useAppDispatch()
    const [isPending, setIsPending] = useState(false)
    useEffect(() => {
        if (!isFetching) {
            const engineersArr: engineer[] = []
            EngineersData.map((engineer: UserRecord) => {
                engineersArr.push({
                    name: engineer.user.full_name,
                    id: engineer.id,
                })
            })
            const options = engineersArr?.map((eng: any) => ({
                value: eng.id,
                label: eng.name,
                isFixed: true,
            }))
            setEngineersList(options)
        }
    }, [EngineersData])

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        reset,
        formState: { errors },
    } = useForm()
    // @ts-expect-error asd sadd a sd
    const onSubmit = (data) => {
        console.log('data', data.leader)
        setIsPending(true)
        // console.log(data) // Handle form submissi on here
        // console.log('data', data)
        try {
            const engineers = engineersListTeam?.map((eng: any) => {
                // console.log('eng', eng)
                if (eng) {
                    const test = data?.leader?.find(
                        (item: any) => eng?.value == item.value
                    )
                    console.log('test', test)

                    return {
                        engineer: eng?.value,
                        is_leader: eng?.value == test?.value,
                    }
                }
            })
            const newTeam = {
                name: data.name,
                description: data.description,
                engineers: engineers ?? [],
            }
            // console.log('newTeam', newTeam)
            if (id != undefined) {
                dashBoardServices
                    .updateTeam(newTeam, id)
                    .then((res: any) => {
                        // console.log('res', res)
                    })
                    .then((res) => {
                        handleClose()
                        setRefetch()
                    })
                    .catch((e) => {})
                    .finally(() => {
                        setIsPending(false)
                    })
            } else {
                dashBoardServices
                    .createTeam(newTeam)
                    .then((res: any) => {
                        // console.log('res', res)
                    })
                    .then((res) => {
                        handleClose()
                        setRefetch()
                    })
                    .catch((e) => {})
                    .finally(() => {
                        setIsPending(false)
                    })
            }
        } catch (error) {
            console.log('error', error)
        }
    }
    // @ts-expect-error sasasas
    const selectStyles: StylesConfig<ColourOption, true> = {
        multiValue: (base, state) => {
            return state.data.isFixed
                ? { ...base, backgroundColor: 'gray' }
                : base
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? {
                      ...base,
                      fontWeight: 'bold',
                      color: 'white',
                      paddingRight: 6,
                  }
                : base
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base
        },
    }
    const handleRate = (selectionIndex: number, Rate: number) => {
        const dataArr = [...selectedOptions]
        // @ts-expect-error data is valid
        dataArr[selectionIndex].rate = Rate + 1

        setSelectedOptions(dataArr)
    }
    useEffect(() => {
        if (id) {
            setValue('description', team.description)
            setValue('name', team.name)
            const selectedOptions: any = []
            const leaders: any = []
            team.engineers.map((eng: any) => {
                const element = engineersList.find(
                    (item: any) => item.value == eng.engineer
                )
                if (element) {
                    selectedOptions.push(element)
                    if (eng.is_leader) {
                        leaders.push({
                            value: element.value,
                            label: element.label,
                        })
                    }
                }
            })
            setValue('leader', leaders)
            // console.log('selectedOptions', selectedOptions)
            setEngineersListTeam(selectedOptions)
        }
        // console.log('team', team)
    }, [id])
    useEffect(() => {
        // console.log('errors', errors)
    }, [errors])

    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            // @ts-expect-error no error here
            message: value?.message ?? '',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })

    const closeModal = () => {
        setValue('leader', undefined)
        reset()
        handleClose()
        setEngineersList([])
    }

    return (
        <BasicModal open={open} handleClose={closeModal}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        paddingInline: 3,
                        maxHeight: chartDimensions,
                    }}
                >
                    <CommonTypography
                        sx={{
                            fontWeight: 600,
                            color: '#000',
                            fontSize: 22,
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            textTransform: 'capitalize',
                            marginY: '50px',
                        }}
                        variant={'h6'}
                    >
                        {id ? 'Edit Team' : 'New Team'}
                    </CommonTypography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack direction={'column'} rowGap={3}>
                            <Controller
                                name="name"
                                control={control}
                                defaultValue=""
                                rules={{ required: 'Name is required' }}
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Name"
                                        error={!!errors.fullName}
                                        helperText={
                                            errors.Name
                                                ? ' Name is required'
                                                : ''
                                        }
                                    />
                                )}
                            />

                            <Controller
                                name="description"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <TextField
                                        sx={{ ...styles.textField }}
                                        {...field}
                                        label="Description"
                                    />
                                )}
                            />

                            {id && (
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    border={'GrayText'}
                                    justifyContent={'flex-start'}
                                    alignItems={'center'}
                                    rowGap={2}
                                    sx={{ flexWrap: 'wrap' }}
                                >
                                    <Typography>Engineers :</Typography>
                                    {engineersListTeam.length == 0 && (
                                        <Chip
                                            key={'no Engineers'}
                                            label={'----'}
                                        />
                                    )}
                                    {engineersListTeam.map(
                                        (engineer: any, index: number) => (
                                            <Chip
                                                key={engineer.id}
                                                label={engineer.label}
                                            />
                                        )
                                    )}
                                </Stack>
                            )}
                            {id && (
                                <Controller
                                    name="leader" // Specify the name for your select field
                                    control={control} // Pass the control prop from useForm
                                    render={({ field }) => (
                                        <Select
                                            isClearable={true}
                                            isMulti={true} // Enable multi-select functionality
                                            placeholder="Select TeamLead" // Add a placeholder text
                                            // value={selectedLeader} // Use the selectedProduct state as the value
                                            // onChange={(Val) => {
                                            //     // console.log('Val', Val)
                                            //     // @ts-expect-error i need it to be
                                            //     setSelectedLeader(Val)
                                            // }}
                                            {...field}
                                            options={engineersListTeam.map(
                                                (item: any) => ({
                                                    value: item.value,
                                                    label: item.label,
                                                })
                                            )}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    minHeight: '50px', // Set the desired height
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: '#fff', // Change the background color
                                                    borderRadius: '4px', // Apply border radius
                                                    boxShadow:
                                                        '0 2px 4px rgba(0, 0, 0, 0.2)', // Apply box shadow
                                                    zIndex: 9999, // Set the desired z-index value
                                                }),
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Stack>
                        <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                        >
                            <Stack direction={'column'}></Stack>
                            <Stack
                                direction={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {id && (
                                    <Button
                                        onClick={handleDelete}
                                        sx={{
                                            color: '#FC5632',
                                            fontSize: '18px',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: 'normal',
                                            textTransform: 'capitalize',
                                            marginTop: '48px',
                                        }}
                                    >
                                        delete
                                    </Button>
                                )}
                                {/* <ActionNdCancel
                                actionBtnLabel={id ? 'Save' : 'Create'}
                                actionBtncolor="primary"
                                btnWidth={'140px'}
                                btnHeight={'40px'}
                                alignItems="center"
                                direction={'row'}
                                cancelBtnLabel="Cancel"
                                spacing={3}
                                handleAction={handleSubmit(onSubmit)}
                                handleCancelAction={closeModal}
                                justifyContent="end"
                                sx={{ marginTop: '48px' }}
                                pending={false}
                            /> */}

                                <Stack
                                    sx={{ marginTop: '48px' }}
                                    direction={'row'}
                                    spacing={3}
                                    alignItems={'center'}
                                    justifyContent={'end'}
                                >
                                    <Button
                                        color={'primary'}
                                        variant={'outlined'}
                                        sx={{ width: '140px', height: '40px' }}
                                        onClick={closeModal}
                                    >
                                        {'Cancel'}
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        color="primary"
                                        variant={'contained'}
                                        sx={{
                                            width: '140px',
                                            height: '40px',
                                            boxShadow: 'none',
                                        }}
                                        // onClick={handleSubmit(onSubmit)}
                                        loading={isPending}
                                    >
                                        {id ? 'Save' : 'Create'}
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </BasicModal>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '42px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxHeight: '770px',
    p: 2,
}

const styles = {
    rateButton: {
        borderRadius: '4px',
        border: '0.4px solid #A8A8A8',
        minWidth: '20.94px',
        maxWidth: '20.94px',
        height: '20px',
        marginInline: '4px',
        color: '#494454',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#47A5A6',
            color: '#FFF',
            border: '0.4px solid #A8A8A8',
        },
    },
    textField: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
    },
}

export default TeamsCreateModal
