import { useState, useEffect, useCallback, type KeyboardEvent } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import BasicModal from '../../BasicModal/BasicModal'
import {
    Box,
    Button,
    ButtonGroup,
    CircularProgress,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Radio,
    RadioGroup,
    Stack,
    TextField,
} from '@mui/material'
import CommonTypography from '../../common/CommonTypography/CommonTypography'
import ActionNdCancel from '../../ActionNdCancel/ActionNdCancel'
import RemoveIcon from '@mui/icons-material/Remove'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
// import CommonTextField from '../../common/CommonTextField/CommonTextField'
// import CommonAutocomplete from '../../common/CommonAutocomplete/CommonAutocomplete'
import useFetch from '../../../hooks/useFetch'
import dashBoardServices from '../../../services/cspServices'
import { type ICustomer } from '../../../interfaces/ICustomer'
import { type IAlert } from '../../../interfaces/IAlert'
import { useAppDispatch } from '../../../app/hooks'
import { addAlert } from '../../../features/systemAlerts'
import { getCustomer } from '../../../features/customers'
import { type IContract } from '../../../interfaces/IContract'
import { type ISite } from '../../../interfaces/ISite'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'
import { useWindowSize } from 'usehooks-ts'
interface productRequestSpecifications {
    key: string
}
interface IProductCreateUpdateModal {
    open: boolean
    handleClose: () => void
    isEdit?: boolean
    customer?: ICustomer
    ProductIndex?: number | null
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '12px',
    transform: 'translate(-50%, -50%)',
    width: 932,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
}

interface Product {
    id: string
    name: string
    skills: [
        {
            id: string
            skill: string
        }
    ]
}

const ProductCreateUpdateModal = ({
    open,
    handleClose,
    customer,
    ProductIndex,
    isEdit,
}: IProductCreateUpdateModal) => {
    console.log('ProductIndex', ProductIndex)
    const { data: productsData, isPending: isFetchingProdcuts } = useFetch(
        dashBoardServices.getProducts
    )
    const [isPending, setIsPending] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState<string | null>(null)
    const [productSkills, setProductSkills] = useState<Product>()

    const dispatch = useAppDispatch()

    // decalring a logical state to be used in responsive mui query.
    const [chartDimensions, setChartDimensions] = useState(450)
    // declaring some mui media query to be used in redindering line charts.
    const small = useMediaQuery('(min-width:600px)')
    const medium = useMediaQuery('(min-width:900px)')
    const large = useMediaQuery('(min-width:1200px)')
    const extraLarge = useMediaQuery('(min-width:1536px)')
    const [skillsOptions, setSkillsOptions] = useState<any>([])
    const [selectedRequestSpecs, setSelectedRequestSpecs] = useState(-1)
    const [editedSkillValue, setEditedSkillValue] = useState('')

    const [productRequestSpecifications, setProductRequestSpecifications] =
        useState<productRequestSpecifications[]>([])

    const { width, height } = useWindowSize()
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        reset,
    } = useForm()
    const handleModalClose = () => {
        reset()
        handleClose()
        setSkillsOptions([])
        setProductRequestSpecifications([])
    }
    const onSubmit = (formData: any) => {
        setIsPending(true)
        // Process your form data here
        console.log(formData)
        const skills: any = []
        formData.selectedOptions?.map((x: any) => {
            let item: any = null
            item = x
            console.log('item', item)
            skills.push({ skill: x?.value })
        })
        if (!customer) return
        if (isEdit) {
            dashBoardServices
                .updateCustomerProduct(
                    customer?.id ? customer.id : 0,
                    {
                        product: formData.selectedProduct,
                        skills,
                        release: formData.selectedRelease,
                        site: formData.selectedSite,
                        contract: formData.selectedContract,
                        customer: customer?.id,
                        follow_sla: formData.followSla == 'Follow SLA',
                        paltform: formData.selectedPlatform,
                        CustomerProductRequestSpecifications:
                            productRequestSpecifications,
                    },
                    // @ts-expect-error sasasasas
                    customer?.products[ProductIndex].id
                )
                .then((res) => {
                    if (res.status === 200) {
                        const customerId = customer.id?.toString() ?? ''
                        handleClose()
                        setIsPending(false)
                        dispatch(getCustomer(customerId))
                        handleClose()
                    }
                })
                .catch((err) => {
                    setIsPending(false)
                    console.log(err)
                    const alertObj: IAlert = {
                        id: 'error update ',
                        message:
                            'you can not update this product it is related to a rquest ',
                        state: true,
                        type: 'error',
                    }
                    dispatch(addAlert(alertObj))
                })
        } else {
            dashBoardServices
                .createCustomerProduct(customer?.id ? customer.id : 0, {
                    product: formData.selectedProduct,
                    skills,
                    release: formData.selectedRelease,
                    site: formData.selectedSite,
                    contract: formData.selectedContract,
                    customer: customer?.id,
                    follow_sla: formData.followSla == 'Follow SLA',
                    paltform: formData.selectedPlatform,
                    CustomerProductRequestSpecifications:
                        productRequestSpecifications,
                })
                .then((res) => {
                    if (res.status === 200) {
                        setIsPending(false)

                        const customerId = customer.id?.toString() ?? ''
                        handleClose()
                        dispatch(getCustomer(customerId))
                        handleClose()
                    }
                })
                .catch((err) => {
                    setIsPending(false)
                    console.log(err)
                })
        }
    }
    useEffect(() => {
        if (extraLarge) {
            setChartDimensions(600)
        } else if (large) {
            setChartDimensions(550)
        } else if (medium) {
            setChartDimensions(450)
        }
    }, [medium, large, extraLarge])

    useEffect(() => {
        console.log('selectedProduct', selectedProduct)
    }, [selectedProduct])
    const startEditingSkill = (key: string, index: number) => {
        setSelectedRequestSpecs(index)
        setEditedSkillValue(key)
    }
    const handleSkillEdit = (index: number) => {
        const modifiedData = [...productRequestSpecifications]
        modifiedData.splice(index, 1)
        setProductRequestSpecifications(modifiedData)
        const newKeys = [...productRequestSpecifications]
        newKeys[index].key = editedSkillValue
        setProductRequestSpecifications(newKeys)
        setSelectedRequestSpecs(-1)
        setEditedSkillValue('')
    }

    useEffect(() => {
        if (ProductIndex != null && !isFetchingProdcuts) {
            const product = customer?.products[ProductIndex]
            console.log('product', product)

            console.log(
                'customerCustomerProductRequestSpecifications',
                customer
            )

            console.log('productsData', productsData)
            const productToEdit = productsData.find(
                // @ts-expect-error no check
                (el: Product) => el.id == product?.product?.id?.toString()
            )
            const contractToEdit = customer?.contracts.find(
                (el: IContract) => el.id == product?.contract?.toString()
            )
            const siteToEdit =
                customer?.sites
                    // @ts-expect-error no check
                    .find((el: ISite) => el.id == product?.site.id)
                    ?.id?.toString() ?? ''
            const release = product?.release ?? ''
            const paltform = product?.paltform ?? ''
            const contract = contractToEdit?.id?.toString() ?? ''

            const skillsOptions: any = productToEdit?.skills?.map(
                (skill: any) => ({
                    value: skill.id,
                    label: skill.skill,
                    isFixed: true,
                })
            )
            setSkillsOptions(skillsOptions)

            console.log('productToEdit', productToEdit)
            setValue('selectedProduct', productToEdit.id)
            setValue('selectedRelease', release)
            setValue('selectedPlatform', paltform)
            setValue('selectedContract', contract)
            setValue('selectedSite', siteToEdit)
            setValue('followSla', product?.follow_sla ? 'Follow SLA' : 'No SLA')
            dashBoardServices
                .getCustomerIdProductIdSkills(
                    // @ts-expect-error no error
                    customer?.id,
                    product?.id
                )
                .then((res) => {
                    console.log('res', res)
                    if (res.status === 200) {
                        console.log('skillsOptions', skillsOptions)
                        console.log('heeeeeeeeeeeeeeeeeeeeeeeeeeere', res.data)
                        setProductRequestSpecifications(
                            res.data.CustomerProductRequestSpecifications
                        )
                        const selectedOptions = res.data.skills?.map(
                            (skill: any) => {
                                return skillsOptions?.find(
                                    (item: any) => item.value == skill.skill.id
                                )
                            }
                        )
                        setValue('selectedOptions', selectedOptions)
                        console.log('selectedOptions', selectedOptions)
                    }
                })

            console.log('customerProduct', customer)
        }

        return () => {}
    }, [ProductIndex, isFetchingProdcuts])

    Object.entries(errors).map(([key, value]) => {
        const alertObj: IAlert = {
            id: key,
            // @ts-expect-error no error here
            message: value?.message ?? '',
            state: true,
            type: 'error',
        }
        dispatch(addAlert(alertObj))
    })
    const removeSkill = (index: number) => {
        console.log('index', index)
        const modifiedData = [...productRequestSpecifications]
        modifiedData.splice(index, 1)
        setProductRequestSpecifications(modifiedData)
    }
    const handleAdd = (data: productRequestSpecifications[]) => {
        setValue('productRequestSpecifications', '', {
            shouldValidate: false,
        })

        setProductRequestSpecifications(data)
    }
    const CheckEnter = (e: KeyboardEvent<HTMLDivElement>) => {
        const key = e.key
        if (key == 'Enter') {
            e.preventDefault()
            const values = getValues()
            const newKey = {
                key: values.productRequestSpecifications,
            }
            const newKeys = [...productRequestSpecifications]
            newKeys.push(newKey)
            handleAdd(newKeys)
        }
    }
    return (
        <BasicModal handleClose={handleModalClose} open={open}>
            <Box
                sx={{
                    ...style,
                    width: (() => {
                        if (width <= 768) return width
                        return 932
                    })(),
                }}
            >
                <Box
                    sx={{
                        maxHeight: chartDimensions,
                        // maxWidth:small?"500px":medium?"700":"100%" ,
                        overflow: 'scroll',
                        p: 3,
                    }}
                >
                    <CommonTypography sx={{ fontWeight: 600 }} variant={'h6'}>
                        Product Details
                    </CommonTypography>
                    {(isFetchingProdcuts || isPending) && (
                        <Stack
                            direction={'row'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <CircularProgress size={50} />
                        </Stack>
                    )}
                    {!isFetchingProdcuts && !isPending && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack
                                direction={'row'}
                                justifyContent={'end'}
                                sx={{ marginTop: 2.75 }}
                            >
                                <Controller
                                    name="followSla"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                {...field}
                                            >
                                                <FormControlLabel
                                                    value={'Follow SLA'}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                marginRight:
                                                                    '12px',
                                                            }}
                                                        />
                                                    }
                                                    label="Follow SLA"
                                                />
                                                <FormControlLabel
                                                    value={'No SLA'}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                marginRight:
                                                                    '12px',
                                                            }}
                                                        />
                                                    }
                                                    label="No SLA"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    )}
                                />
                            </Stack>
                            <Stack direction={'column'} rowGap={3}>
                                <Controller
                                    name="selectedProduct"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: 'Product name is required',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            fullWidth
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: {
                                                        '.MuiMenu-paper': {
                                                            borderRadius: '4px',
                                                            border: '1.5px solid #DCDCDC',
                                                            background:
                                                                '#FEFEFE',
                                                            boxShadow:
                                                                '0 5px 5px rgba(0, 0, 0, 0.7)', // Custom box shadow,
                                                        },
                                                    },
                                                },
                                            }}
                                            label="Product name*"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)

                                                const productToEdit =
                                                    productsData.find(
                                                        (el: Product) =>
                                                            el.id ==
                                                            e.target.value
                                                    )
                                                console.log(
                                                    'value',
                                                    e.target.value
                                                )
                                                console.log(
                                                    'productsData',
                                                    productsData
                                                )
                                                const skillsOptions: any =
                                                    productToEdit?.skills?.map(
                                                        (skill: any) => ({
                                                            value: skill.id,
                                                            label: skill.skill,
                                                            isFixed: true,
                                                        })
                                                    )
                                                console.log(
                                                    'skillsOptions',
                                                    skillsOptions
                                                )
                                                setSkillsOptions(skillsOptions)
                                            }}
                                            error={!!errors.selectedProduct}
                                        >
                                            {productsData.map(
                                                (prod: Product) => (
                                                    <MenuItem
                                                        key={prod.id}
                                                        value={prod.id}
                                                    >
                                                        {prod.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    )}
                                />
                                <Controller
                                    name="selectedOptions"
                                    control={control}
                                    defaultValue={[]}
                                    rules={{ required: 'Skills are required' }}
                                    render={({ field }) => (
                                        <Select
                                            isMulti
                                            placeholder="Select skills"
                                            value={field.value}
                                            onChange={(selectedOptions) => {
                                                field.onChange(selectedOptions)
                                            }}
                                            options={skillsOptions}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    height: '50px',
                                                }),
                                                menu: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: '#fff',
                                                    borderRadius: '4px',
                                                    boxShadow:
                                                        '0 2px 4px rgba(0, 0, 0, 0.2)',
                                                    zIndex: 9999,
                                                }),
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="selectedRelease"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            label="Release"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="selectedPlatform"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            fullWidth
                                            label="Platform"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)
                                            }}
                                        />
                                    )}
                                />

                                <Controller
                                    name="selectedSite"
                                    control={control}
                                    defaultValue=""
                                    rules={{ required: 'Site is required' }}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            fullWidth
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: {
                                                        '.MuiMenu-paper': {
                                                            borderRadius: '4px',
                                                            border: '1.5px solid #DCDCDC',
                                                            background:
                                                                '#FEFEFE',
                                                            boxShadow:
                                                                '0 5px 5px rgba(0, 0, 0, 0.7)', // Custom box shadow,
                                                        },
                                                    },
                                                },
                                            }}
                                            label="Site*"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)
                                            }}
                                            error={!!errors.selectedSite}
                                        >
                                            {customer?.sites.map(
                                                (site: any) => (
                                                    <MenuItem
                                                        key={site.id}
                                                        value={site.id}
                                                    >
                                                        {site.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    )}
                                />

                                <Controller
                                    name="selectedContract"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required:
                                            'Support Contract is required',
                                    }}
                                    render={({ field }) => (
                                        <TextField
                                            select
                                            SelectProps={{
                                                MenuProps: {
                                                    sx: {
                                                        '.MuiMenu-paper': {
                                                            borderRadius: '4px',
                                                            border: '1.5px solid #DCDCDC',
                                                            background:
                                                                '#FEFEFE',
                                                            boxShadow:
                                                                '0 5px 5px rgba(0, 0, 0, 0.7)', // Custom box shadow,
                                                        },
                                                    },
                                                },
                                            }}
                                            fullWidth
                                            label="Support Contract*"
                                            value={field.value}
                                            onChange={(e) => {
                                                field.onChange(e.target.value)
                                            }}
                                            error={!!errors.selectedContract}
                                        >
                                            {customer?.contracts?.map(
                                                (cont: any) => (
                                                    <MenuItem
                                                        key={cont.id}
                                                        value={cont.id}
                                                    >
                                                        {cont.name}
                                                    </MenuItem>
                                                )
                                            )}
                                        </TextField>
                                    )}
                                />
                                <CommonTypography
                                    sx={{ fontWeight: 100 }}
                                    variant={'h6'}
                                >
                                    Request Specifications
                                </CommonTypography>
                                <Controller
                                    name="productRequestSpecifications"
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <TextField
                                            sx={{ ...styles.textField }}
                                            {...field}
                                            placeholder="Write then press Enter "
                                            onKeyDown={CheckEnter}
                                        />
                                    )}
                                />
                                {productRequestSpecifications.length == 0
                                    ? ''
                                    : productRequestSpecifications.map(
                                          (skill, index) => {
                                              return (
                                                  <Stack
                                                      sx={{
                                                          borderRadius: '5px',
                                                          border: ' 0.6px solid #E9E9E9',
                                                          backgroundColor:
                                                              '#FFF',
                                                          paddingX: '10px',
                                                          alignItems: 'center',
                                                      }}
                                                      key={
                                                          skill.key +
                                                          '' +
                                                          index.toString()
                                                      }
                                                      direction={'row'}
                                                      justifyContent={
                                                          'space-between'
                                                      }
                                                  >
                                                      {selectedRequestSpecs ===
                                                      index ? (
                                                          <Stack
                                                              display={'flex'}
                                                              direction={'row'}
                                                              alignItems={
                                                                  'center'
                                                              }
                                                          >
                                                              <IconButton
                                                                  onClick={() => {
                                                                      handleSkillEdit(
                                                                          index
                                                                      )
                                                                  }}
                                                                  disableRipple
                                                                  size="small"
                                                              >
                                                                  <CheckIcon />
                                                              </IconButton>
                                                              <TextField
                                                                  variant="outlined"
                                                                  fullWidth
                                                                  focused
                                                                  sx={{
                                                                      '&.MuiOutlinedInput-notchedOutline':
                                                                          {
                                                                              borderWidth: 0.1,
                                                                              borderColor:
                                                                                  '#a09e9e',
                                                                          },
                                                                      '&:hover .MuiOutlinedInput-notchedOutline':
                                                                          {
                                                                              borderColor:
                                                                                  '#a09e9e',
                                                                          },
                                                                      '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                                                          {
                                                                              borderColor:
                                                                                  '#a09e9e',
                                                                          },
                                                                  }}
                                                                  InputProps={{
                                                                      disableUnderline:
                                                                          true,
                                                                  }}
                                                                  size="small"
                                                                  value={
                                                                      editedSkillValue
                                                                  }
                                                                  onChange={(
                                                                      e
                                                                  ) => {
                                                                      setEditedSkillValue(
                                                                          e
                                                                              .target
                                                                              .value
                                                                      )
                                                                  }}
                                                                  onBlur={() => {
                                                                      handleSkillEdit(
                                                                          index
                                                                      )
                                                                  }}
                                                                  //   onKeyDown={(e) => {
                                                                  //       if (
                                                                  //           e.key === 'Enter'
                                                                  //       ) {
                                                                  //           handleSkillEdit(
                                                                  //               index
                                                                  //           )
                                                                  //       }
                                                                  //   }}
                                                              />
                                                          </Stack>
                                                      ) : (
                                                          <Stack
                                                              direction={'row'}
                                                              justifyContent={
                                                                  'flex-start'
                                                              }
                                                              gap={2}
                                                              alignItems={
                                                                  'center'
                                                              }
                                                          >
                                                              <IconButton
                                                                  onClick={() => {
                                                                      startEditingSkill(
                                                                          skill.key,
                                                                          index
                                                                      )
                                                                  }}
                                                                  disableRipple
                                                                  size="small"
                                                              >
                                                                  <EditIcon />
                                                              </IconButton>

                                                              <Box>
                                                                  {skill.key}
                                                              </Box>
                                                          </Stack>
                                                      )}

                                                      <Box>
                                                          <IconButton
                                                              onClick={() => {
                                                                  removeSkill(
                                                                      index
                                                                  )
                                                              }}
                                                              disableRipple
                                                              size="small"
                                                          >
                                                              <RemoveIcon />
                                                          </IconButton>
                                                      </Box>
                                                  </Stack>
                                              )
                                          }
                                      )}
                            </Stack>
                            <Stack direction={'column'} sx={{ marginTop: 5 }}>
                                <Stack
                                    sx={{ marginTop: '48px' }}
                                    direction={'row'}
                                    spacing={3}
                                    alignItems={'center'}
                                    justifyContent={'end'}
                                >
                                    <Button
                                        color={'primary'}
                                        variant={'outlined'}
                                        sx={{
                                            width: '140px',
                                            height: '40px',
                                            color: '#494454',
                                            fontFamily: 'Inter',
                                            fontSize: '18px',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            lineHeight: '24px',
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={handleModalClose}
                                    >
                                        {'Cancel'}
                                    </Button>
                                    <LoadingButton
                                        type="submit"
                                        color="primary"
                                        variant={'contained'}
                                        sx={{
                                            width: '140px',
                                            height: '40px',
                                            boxShadow: 'none',
                                            fontFamily: 'Inter',
                                            fontSize: '18px',
                                            fontStyle: 'normal',
                                            fontWeight: 500,
                                            lineHeight: '24px',
                                            textTransform: 'capitalize',
                                            borderRadius: '4px',
                                        }}
                                        // onClick={handleSubmit(onSubmit)}
                                        loading={isPending}
                                    >
                                        {isEdit ? 'Save ' : 'Submit'}
                                    </LoadingButton>
                                </Stack>

                                {/* <ActionNdCancel
                            actionBtnLabel="Confirm"
                            actionBtncolor="primary"
                            btnWidth={'140px'}
                            btnHeight={'40px'}
                            alignItems="center"
                            direction={'row'}
                            cancelBtnLabel="Cancel"
                            spacing={3}
                            handleAction={handleMainACtion}
                            handleCancelAction={handleClose}
                            justifyContent="end"
                            pending={isPending}
                            sx={{ marginTop: '48px' }}
                        /> */}
                            </Stack>
                        </form>
                    )}
                </Box>
            </Box>
        </BasicModal>
    )
}

export default ProductCreateUpdateModal

const styles = {
    textField: {
        background: '#F9F9F9',
        border: '1px solid #F2F2F2',
        borderRadius: '4px',
    },
}
