import CommonEmptyState from '../../../components/common/CommonEmptyState/CommonEmptyState'
import CommonButton from '../../../components/common/CommonButton/CommonButton'
import SitesEmptyStateImage from '../../../assets/images/sites_empty_state.svg'

interface ISitesEmptyState {
    createNew: () => void
}

const SitesEmptyState = ({ createNew }: ISitesEmptyState) => {
    return (
        <CommonEmptyState
            src={SitesEmptyStateImage}
            alt="site empty state"
            width={81}
            height={180}
            sx={{ marginTop: '88px' }}
        >
            <CommonButton
                variant={'outlined'}
                sx={{ width: 155, height: 40 }}
                onClick={createNew}
            >
                Create Site
            </CommonButton>
        </CommonEmptyState>
    )
}

export default SitesEmptyState
