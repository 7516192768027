import Stack from '@mui/material/Stack/Stack'
import CommonTypography from '../common/CommonTypography/CommonTypography'
import { type SxProps } from '@mui/system'

interface IProductNameWithSlaStatus {
    productName: string
    followSla: boolean
    containerSx?: SxProps
}

const ProductNameWithSlaStatus = ({
    productName,
    followSla,
    containerSx,
}: IProductNameWithSlaStatus) => {
    console.log('productName', productName)
    return (
        <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={containerSx}
            rowGap={4}
        >
            <CommonTypography
                variant={'body1'}
                sx={{
                    color: '#494454',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    textTransform: 'capitalize',
                }}
            >
                {productName}
            </CommonTypography>
            <CommonTypography
                variant={'caption'}
                sx={{
                    color: !followSla ? 'info.main' : 'warning.main',
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '16px',
                    textTransform: 'capitalize',
                }}
            >
                {followSla && 'Follow SLA'}
                {!followSla && 'No SLA'}
            </CommonTypography>
        </Stack>
    )
}

export default ProductNameWithSlaStatus
